import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import SchoolIcon from '@mui/icons-material/School';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ListItemText from '@mui/material/ListItemText';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { CoPresent } from '@mui/icons-material';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import { CollectionsBookmark, ExitToApp, InsertDriveFileRounded, Settings } from '@material-ui/icons';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useHistory } from 'react-router-dom';
import { Avatar, Button, Grid, Select, SelectChangeEvent, Stack, useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import { SessionContext } from 'contexts';
import { auth } from 'services';
import { db } from 'services';
import { toast } from 'react-toastify';
import { colors } from 'styles';
import { useTranslation } from 'react-i18next';
import DrawerMenuItem from './DrawerMenuItem';
import { IFile } from 'pages/Files/File';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface MiniDrawerProps {
  currentPage?: string;
}

export default function MiniDrawer({ currentPage }: MiniDrawerProps) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const widthLessThan600 = useMediaQuery('(max-width:600px)');

  const { files, authUser, user, file } = React.useContext(SessionContext);
  const userDoc = doc(db, `users/${authUser?.email}`);
  const history = useHistory();

  const languageOptions = [
    { code: 'BR', label: 'PT', description: 'Português (PT)' },
    { code: 'US', label: 'EN', description: 'English (US)' },
    { code: 'ES', label: 'ES', description: 'Español (ES)' },
    { code: 'DE', label: 'DE', description: 'Deutsch (DE)' },
  ];

  function signOut() {
    auth
      .signOut()
      .then(() => {
        history.push('/');
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [currentFile, setCurrentFile] = React.useState<string | undefined>(user?.selectedFile);
  const [currentCountryCode, setCurrentCountryCode] = React.useState<string | undefined>(
    languageOptions.find((option) => option.label === i18n.language.toUpperCase())
      ? languageOptions.find((option) => option.label === i18n.language.toUpperCase())?.code
      : 'US'
  );

  React.useEffect(() => {
    setCurrentFile(user?.selectedFile);
  }, [user?.selectedFile]);

  React.useEffect(() => {
    setCurrentCountryCode(user?.countryCode);
    if (user?.countryCode === 'US') i18n.changeLanguage('en');
    else if (user?.countryCode === 'BR') i18n.changeLanguage('pt');
  }, [user?.email, user?.countryCode, i18n]);

  const handleFileSelectChange = async (event: SelectChangeEvent) => {
    const fileName = event.target.value;
    const filesPath = `users/${authUser?.email}/files`;
    const docRef = doc(db, filesPath, fileName);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const fileData = docSnap.data() as IFile;
        //Update selected file
        updateDoc(userDoc, {
          selectedFile: fileData.name,
        })
          .then(() => {
            toast.success(docSnap.data().name + t(' is selected'));
            history.push({ pathname: '/file', state: fileData });
            // window.location.reload();
          })
          .catch(() => {
            toast.error(t('An error has occurred'));
          });
      } else {
        toast.error(t('No such document'));
      }
    });
  };

  const handleFileNameClick = async (fileName: string) => {
    const filesPath = `users/${authUser?.email}/files`;
    const docRef = doc(db, filesPath, fileName);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const fileData = docSnap.data() as IFile;
        //Update selected file
        updateDoc(userDoc, {
          selectedFile: fileData.name,
        })
          .then(() => {
            toast.success(docSnap.data().name + t(' is selected'));
            history.push({ pathname: '/file', state: fileData });
            // window.location.reload();
          })
          .catch(() => {
            toast.error(t('An error has occurred'));
          });
      } else {
        toast.error(t('No such document'));
      }
    });
  };

  const handleCountryCodeSelectChange = (event: SelectChangeEvent) => {
    if (event.target.value === 'US') {
      i18n.changeLanguage('en');
      toast.success(
        'Language set to ' + languageOptions.find((option) => option.code === event.target.value)?.description
      );
    } else if (event.target.value === 'BR') {
      i18n.changeLanguage('pt');
      toast.success(
        'Idioma definido como ' + languageOptions.find((option) => option.code === event.target.value)?.description
      );
    } else if (event.target.value === 'ES') {
      toast.warning('Aún no disponible en ESPAÑOL.. Utilice la función del navegador para traducir la página');
    } else if (event.target.value === 'DE') {
      toast.warning('Noch nicht auf DEUTSCH verfügbar.. Bitte benutzen Sie die Übersetzungsfunktion des Browsers');
    }
    updateDoc(userDoc, {
      countryCode: event.target.value,
    });
    setCurrentCountryCode(event.target.value);
  };

  const location = useLocation();

  const [selectedPage, setSelectedPage] = React.useState<string>(currentPage ? currentPage : location.pathname);

  React.useEffect(() => {
    setSelectedPage(currentPage ? currentPage : location.pathname);
  }, [currentPage, location.pathname]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: colors['homeBlue'] }}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Grid container item xs={12} spacing={1} justifyContent={'space-between'} paddingRight={2}>
            <Grid item xs={9} sm={8} lg={4}>
              <Stack direction="row" alignItems={'center'} spacing={1}>
                {<img height={20} width={20} src="/img/logoWhite.png" alt="Optables logo" />}
                {!widthLessThan600 && <Typography color="#ffffff">{'Optables'}</Typography>}
                {currentFile && (
                  <Tooltip placement="right-end" title={t('Current file')}>
                    <Button
                      style={{
                        textTransform: 'none',
                        color: 'white',
                        minWidth: 'auto',
                        minHeight: 'auto',
                        padding: 0, // Adjust padding as needed
                        margin: 0,
                      }}
                      onClick={() => {
                        handleFileNameClick(currentFile!);
                      }}
                    >
                      <Typography noWrap style={{ textDecoration: 'underline' }} translate="no">
                        {`/${currentFile}`}
                      </Typography>
                    </Button>
                  </Tooltip>
                )}

                {/* <Tooltip placement="right-end" title={t('Current file')}> */}
                {/* <Select
                    className="selected-file"
                    id="demo-select-small"
                    value={'' + currentFile}
                    size="small"
                    fullWidth
                    variant="standard"
                    onChange={handleFileSelectChange}
                    style={{ outline: 'none', color: '#ffffff' }}
                  >
                    {files
                      .map((file) => file.name)
                      .map((option, index) => {
                        return (
                          <MenuItem key={`${index}`} value={option} sx={{ minWidth: '100%' }}>
                            <Typography noWrap translate="no">
                              {`/${option}`}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                  </Select> */}
                {/* </Tooltip> */}
              </Stack>
            </Grid>
            <Grid item xs={3} sm={2} lg={1}>
              <Tooltip placement="left-end" title={t('Language')}>
                <Select
                  id="demo-select-small"
                  value={'' + currentCountryCode}
                  size="small"
                  fullWidth
                  variant="standard"
                  onChange={handleCountryCodeSelectChange}
                  sx={{ outline: 'none', color: widthLessThan600 ? '#1A73E8' : '#ffffff', paddingBottom: '1px' }}
                >
                  {languageOptions.map((option, index) => {
                    return (
                      <MenuItem key={`${index}`} value={option.code} sx={{ minWidth: '100%' }}>
                        <Box
                          alignContent="center"
                          alignItems="center"
                          display="flex"
                          sx={{
                            '& > img': { mr: 1, flexShrink: 0 },
                            color: widthLessThan600 ? colors['homeBlue'] : 'default',
                          }}
                        >
                          <img
                            loading="lazy"
                            width="26"
                            height="auto"
                            src={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt="Country flag"
                          />
                          {option.label}
                        </Box>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Tooltip>
            </Grid>
          </Grid>
          {user && (
            <div>
              <Tooltip title={user?.name === undefined ? '' : user?.name} placement="right-end">
                <IconButton
                  size="small"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <Avatar sx={{ width: 32, height: 32 }} alt={user?.name} src={user?.photoURL} />
                </IconButton>
              </Tooltip>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    history.push('/settings');
                  }}
                >
                  {t('Profile')}
                </MenuItem>
                <MenuItem onClick={signOut}>{t('Sign Out')}</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{ minHeight: '48px', maxHeight: '48px', color: '#ffffff', backgroundColor: colors['homeBlue'] }}
        >
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                color: '#ffffff',
              }}
              onClick={() => {
                history.push('/files');
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <HomeIcon htmlColor="#fff" />
                {/* {<img height={20} width={20} src="/img/logoWhite.png" alt="Optables logo" />} */}
              </ListItemIcon>
              <ListItemText primary={t('Home')} color="#ffffff" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon style={{ color: '#ffffff' }} />
            ) : (
              <ChevronLeftIcon style={{ color: '#ffffff' }} />
            )}
          </IconButton>
        </DrawerHeader>
        <DrawerMenuItem
          label={t('File data')}
          drawerOpen={open}
          selected={selectedPage === '/file'}
          disabled={false}
          targetPage={'/file'}
          setSelectedPage={setSelectedPage}
          icon={<InsertDriveFileRounded />}
        />
        <div className={'file-elements'}>
          <DrawerMenuItem
            label={t('Classes')}
            drawerOpen={open}
            selected={selectedPage === '/classes'}
            disabled={false}
            targetPage={'/classes'}
            setSelectedPage={setSelectedPage}
            icon={<SchoolIcon />}
          />
          <DrawerMenuItem
            label={t('Teachers')}
            drawerOpen={open}
            selected={selectedPage === '/teachers'}
            disabled={false}
            targetPage={'/teachers'}
            setSelectedPage={setSelectedPage}
            icon={<CoPresent />}
          />
          <DrawerMenuItem
            label={t('Rooms')}
            drawerOpen={open}
            selected={selectedPage === '/rooms'}
            disabled={file?.roomsSetting === 0}
            disabledMessage={t('Rooms were disabled for this file')}
            targetPage={'/rooms'}
            setSelectedPage={setSelectedPage}
            icon={<MapsHomeWorkIcon />}
          />
          <DrawerMenuItem
            label={t('Subjects')}
            drawerOpen={open}
            selected={selectedPage === '/subjects'}
            disabled={false}
            targetPage={'/subjects'}
            setSelectedPage={setSelectedPage}
            icon={<MenuBookIcon />}
          />
          <DrawerMenuItem
            label={t('Lessons')}
            drawerOpen={open}
            selected={selectedPage === '/lessons'}
            disabled={false}
            targetPage={'/lessons'}
            setSelectedPage={setSelectedPage}
            icon={<CollectionsBookmark />}
          />
          <DrawerMenuItem
            label={t('Timetables')}
            drawerOpen={open}
            selected={selectedPage === '/solutions'}
            disabled={false}
            targetPage={'/solutions'}
            setSelectedPage={setSelectedPage}
            icon={<CalendarMonthIcon />}
          />
        </div>
        <DrawerMenuItem
          label={t('Settings')}
          drawerOpen={open}
          selected={selectedPage === '/settings'}
          disabled={false}
          targetPage={'/settings'}
          setSelectedPage={setSelectedPage}
          icon={<Settings />}
        />
        <List>
          {[t('Sign Out')].map((text, index) => (
            <Tooltip key={text} title={text} placement="right-end">
              <ListItem disablePadding sx={{ display: 'block' }} onClick={() => signOut()}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}

// <List className="file-elements" disablePadding>
//   {[
//     t('Files'),
//     file?.studentsSetting !== 0 ? t('Classes') : t('Students'),
//     t('Teachers'),
//     t('Rooms'),
//     t('Subjects'),
//     t('Lessons'),
//     t('Timetables'),
//   ].map((text, index) => (
//     <div key={text}>
//       <Tooltip title={text} placement="right-end">
//         <ListItem
//           className={'menu-' + text}
//           disablePadding
//           sx={{ display: 'block' }}
//           onClick={() => {
//             if (index === 3 && file?.roomsSetting === 0) toast.warning(t('Rooms were disabled for this file'));
//             else {
//               history.push(pages[index]);
//               setSelectedPage(pages[index]);
//             }
//           }}
//         >
//           <ListItemButton
//             disabled={index === 3 && file?.roomsSetting === 0}
//             selected={selectedPage === pages[index] || selectedPage === pagesSingle[index]}
//             sx={{
//               color:
//                 selectedPage === pages[index] || selectedPage === pagesSingle[index] ? '#1A73E8' : 'primary',
//               minHeight: 48,
//               justifyContent: open ? 'initial' : 'center',
//               px: 2.5,
//             }}
//           >
//             <ListItemIcon
//               sx={{
//                 color:
//                   selectedPage === pages[index] || selectedPage === pagesSingle[index] ? '#1A73E8' : 'primary',
//                 minWidth: 0,
//                 mr: open ? 3 : 'auto',
//                 justifyContent: 'center',
//               }}
//             >
//               {returnIcon(index)}
//             </ListItemIcon>
//             <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
//           </ListItemButton>
//         </ListItem>
//       </Tooltip>
//       {/* <Divider sx={{ height: addDivder[index] ? '1px' : '0' }}></Divider> */}
//       {addDivder[index] && <Divider />}
//     </div>
//   ))}
//   <Tooltip title={t('Settings')} placement="right-end">
//     <ListItem
//       disablePadding
//       sx={{ display: 'block' }}
//       onClick={() => toast.warning(t('Settings and customization comming soon'))}
//     >
//       <ListItemButton
//         sx={{
//           minHeight: 48,
//           justifyContent: open ? 'initial' : 'center',
//           px: 2.5,
//         }}
//       >
//         <ListItemIcon
//           sx={{
//             minWidth: 0,
//             mr: open ? 3 : 'auto',
//             justifyContent: 'center',
//           }}
//         >
//           <Settings />
//         </ListItemIcon>
//         <ListItemText primary={t('Settings')} sx={{ opacity: open ? 1 : 0 }} />
//       </ListItemButton>
//     </ListItem>
//   </Tooltip>
// </List>
// <Divider />
