import React, { useContext, useEffect, useState } from 'react';
import { Container, Grid, Typography, colors, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router';
import { doc, getDoc, updateDoc, writeBatch, collection, query, getDocs, Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SessionContext } from 'contexts';
import { db } from 'services';
import { IFile } from './File';
import DraggableDialog from 'components/DraggableDialog';
import FileCard from 'components/FileCard';
import PlanInfoDialog from 'components/PlanInfoDialog';
import SimpleBackdrop from 'components/SimpleBackdrop';
import FormDialog from 'components/FormDialog';
import useUserPlan from 'stripe/useUserPlan';
import { IResource } from 'pages/Resources/Resource';
import { ISubject } from 'pages/Subjects/Subject';
import { ILesson } from 'pages/Lessons/Lesson';
import { ISolution } from 'pages/Solutions/Solution';
import MiniDrawerFiles from 'components/MiniDrawerFiles';
import FileShareDialog from 'components/FileShareDialog';
import { SharedWithMe } from 'contexts/SessionContext/SessionContext';
import SharedFileCard from 'components/SharedFileCard';
import DemoProjectsDialog from 'components/DemoProjectsDialog';

export default function Files() {
  const { t } = useTranslation();
  const history = useHistory();
  const { authUser, user, files, sharedWithMe, setOwnerEmail, setShareMode } = useContext(SessionContext);
  const filesPath = `users/${authUser?.email}/files`;
  const userDoc = doc(db, `users/${authUser?.email}`);

  const [copyNameDialogOpen, setCopyNameDialogOpen] = useState(false);
  const [copyNameDialogConfirmed, setCopyNameDialogConfirmed] = useState(false);
  const [copyFileName, setCopyFileName] = useState('');
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const [showPlanLimitDialog, setShowPlanLimitDialog] = useState(false);
  const [clickedFileName, setClickedFileName] = React.useState('');
  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [seeDemoOpen, setSeeDemoOpen] = React.useState(false);
  const [isFileShared, setIsFileShared] = React.useState(false);
  const userPlan = useUserPlan(user);

  const handleCopyClick = (event: React.MouseEvent<unknown>, fileName: string) => {
    setIsFileShared(false);
    setClickedFileName(fileName);
    setCopyFileName(t('Copy of ') + fileName);
    setCopyNameDialogOpen(true);
  };

  const handleSharedCopyClick = (event: React.MouseEvent<unknown>, fileName: string) => {
    setClickedFileName(fileName);
    setIsFileShared(true);
    setCopyFileName(t('Copy of ') + fileName);
    setCopyNameDialogOpen(true);
  };

  const handleShareClick = (event: React.MouseEvent<unknown>, fileName: string) => {
    setIsFileShared(false);
    setClickedFileName(fileName);
    setShareDialogOpen(true);
  };

  const handleDeleteClick = (event: React.MouseEvent<unknown>, fileName: string) => {
    setIsFileShared(false);
    setClickedFileName(fileName);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleSharedDeleteClick = (event: React.MouseEvent<unknown>, fileName: string) => {
    setIsFileShared(true);
    setClickedFileName(fileName);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleCardClick = (event: React.MouseEvent<unknown>, fileName: string) => {
    setIsFileShared(false);
    if (fileName === '') {
      updateDoc(userDoc, {
        selectedFile: '',
      })
        .then(() => {
          user && setOwnerEmail(user?.email);
          history.push('/file');
        })
        .catch(() => {
          toast.error(t('An error has occurred'));
        });
      return;
    }
    const docRef = doc(db, filesPath, fileName);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const fileData = docSnap.data() as IFile;
        //Update selected file
        updateDoc(userDoc, {
          selectedFile: fileData.name,
        })
          .then(() => {
            user && setOwnerEmail(user?.email);
            toast.success(docSnap.data().name + t(' is selected'));
            history.push({ pathname: '/file', state: fileData });
          })
          .catch(() => {
            toast.error(t('An error has occurred'));
          });
      } else {
        toast.error(t('No such document'));
      }
    });
  };

  const handleSharedCardClick = (event: React.MouseEvent<unknown>, shared: SharedWithMe | undefined) => {
    if (!shared) return;
    const sharedFilePath = `users/${shared.user}/files`;
    const docRef = doc(db, sharedFilePath, shared.file);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const fileData = docSnap.data() as IFile;
        //Update selected file
        updateDoc(userDoc, {
          selectedFile: fileData.name,
        })
          .then(() => {
            setOwnerEmail(shared.user);
            setShareMode(shared.mode);
            toast.success(docSnap.data().name + t(' is selected'));
            history.push({ pathname: '/file', state: fileData });
          })
          .catch(() => {
            toast.error(t('An error has occurred'));
          });
      } else {
        toast.error(t('No such document'));
      }
    });
  };

  async function checkDeleteConfirmation() {
    if (deleteConfirmed) {
      setDeleteConfirmed(false);
      if (isFileShared) {
        //TODO logic to remove sharing
        const sharingsUserDoc = doc(db, `sharings/${user?.email}`);
        const sharedFileDoc = doc(
          db,
          `users/${sharedWithMe.find((shared) => shared.file === clickedFileName)?.user}/files/${clickedFileName}`
        );
        getDoc(sharedFileDoc).then((docSnap) => {
          if (docSnap.exists()) {
            const sharedFile = docSnap.data() as IFile;
            if (sharedFile) {
              const batch = writeBatch(db);
              batch.update(sharingsUserDoc, {
                sharedWithMe: sharedWithMe.filter((shared) => shared.file !== clickedFileName),
              });
              batch.update(sharedFileDoc, {
                sharedWith: sharedFile.sharedWith?.filter((shared) => shared.user !== user?.email),
              });
              batch
                .commit()
                .then(() => {
                  toast.success(clickedFileName + t(' unshared sucessfully'));
                })
                .catch(() => {
                  toast.error(t('An error has ocurred'));
                });
            } else {
              toast.error(t('An error has ocurred'));
            }
          }
        });
        setIsFileShared(false);
      } else {
        try {
          const batch = writeBatch(db);
          await Promise.all(
            ['resources', 'subjects', 'lessons', 'solutions'].map(async (collectionLabel) => {
              const querySnapshot = await getDocs(
                query(collection(db, `${filesPath}/${clickedFileName}/${collectionLabel}`))
              );
              querySnapshot.forEach((document: any) => {
                batch.delete(doc(db, `${filesPath}/${clickedFileName}/${collectionLabel}`, document.id));
              });
            })
          ).then(() => {
            batch.delete(doc(db, filesPath, clickedFileName));
            batch
              .commit()
              .then(() => {
                toast.success(clickedFileName + t(' deleted sucessfully'));
                history.push('/files');
              })
              .catch(() => {
                toast.error(t('An error has ocurred'));
              });
          });
        } catch (error: any) {
          toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
          console.error({ error });
        }
      }
    }
  }

  checkDeleteConfirmation();

  async function copy() {
    try {
      setCopyNameDialogConfirmed(false);
      //Validation and newName adjustments
      const newName: string = copyFileName;
      if (files.map((file) => file.name).includes(newName)) {
        toast.error(t('File ') + newName + t(' already registered!'));
        return;
      }
      setLoadingOpen(true);
      // Get file and its collections
      const originalFilesPath = isFileShared
        ? `users/${sharedWithMe.find((shared) => shared.file === clickedFileName)?.user}/files`
        : filesPath;
      setIsFileShared(false);
      const docRef = doc(db, originalFilesPath, clickedFileName);
      getDoc(docRef).then(async (docSnap) => {
        if (docSnap.exists()) {
          const file = docSnap.data() as IFile;
          const newFile = { ...file };
          const resourcesSnap = await getDocs(query(collection(db, `${originalFilesPath}/${file.name}/resources`)));
          const resources = resourcesSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as IResource[];
          const subjectsSnap = await getDocs(query(collection(db, `${originalFilesPath}/${file.name}/subjects`)));
          const subjects = subjectsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ISubject[];
          const lessonsSnap = await getDocs(query(collection(db, `${originalFilesPath}/${file.name}/lessons`)));
          const lessons = lessonsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ILesson[];
          const solutionsSnap = await getDocs(query(collection(db, `${originalFilesPath}/${file.name}/solutions`)));
          const solutions = solutionsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ISolution[];
          newFile.name = newName;
          newFile.createdAt = new Date();
          newFile.updatedAt = new Date();
          const batch = writeBatch(db);
          batch.set(doc(db, filesPath, newName), newFile);
          //Copy resources, subjects, lessons, and solutions
          const newFilePath = filesPath + '/' + newName;
          resources.forEach(async (resource: any) => {
            batch.set(doc(db, newFilePath + '/resources', resource.name), resource);
          });
          subjects.forEach(async (subject: any) => {
            batch.set(doc(db, newFilePath + '/subjects', subject.name), subject);
          });
          lessons.forEach(async (lesson: any) => {
            batch.set(doc(db, newFilePath + '/lessons', lesson.name), lesson);
          });
          solutions.forEach(async (solution: any) => {
            batch.set(doc(db, newFilePath + '/solutions', solution.name), solution);
          });
          //Update selected file
          batch.update(userDoc, {
            selectedFile: newName,
          });

          batch
            .commit()
            .then(() => {
              setLoadingOpen(false);
              toast.success(file?.name + t(' copied sucessfully'));
              history.push('/files');
            })
            .catch(() => {
              setLoadingOpen(false);
              toast.error(t('File is too large to be copied'));
              history.push('/files');
            });
        } else {
          toast.error(t('No such document'));
        }
      });
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  }

  copyNameDialogConfirmed && copy();

  const handleNavToUpgradePlan = () => {
    setShowPlanLimitDialog(false);
    history.push('/settings');
  };

  const [sharedFiles, setSharedFiles] = useState<IFile[]>([]);

  useEffect(() => {
    // Fetch shared files and set them in the state
    if (sharedWithMe) {
      const filesPromises = sharedWithMe.map((shared) => {
        const path = '/users/' + shared.user + '/files/' + shared.file;
        return getDoc(doc(db, path))
          .then((doc) => {
            const sharedFile = doc.data() as IFile;
            return sharedFile;
          })
          .catch((error) => {
            console.error(error);
            return null;
          });
      });

      Promise.all(filesPromises).then((sharedFilesData) => {
        const filteredFiles = sharedFilesData.filter((file) => file !== null) as IFile[];
        setSharedFiles(filteredFiles);
      });
    }
  }, [sharedWithMe]);

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '56px 8px 8px 65px',
        minHeight: '100vh',
        backgroundColor: colors.grey[50],
        // backgroundColor: `linear-gradient(to bottom, ${colors.red[100]} 30%, ${colors.green[800]} 30%)`,
      }}
    >
      {shareDialogOpen && (
        <FileShareDialog open={shareDialogOpen} setOpen={setShareDialogOpen} clickedFileName={clickedFileName} />
      )}
      {seeDemoOpen && (
        <DemoProjectsDialog open={seeDemoOpen} setOpen={setSeeDemoOpen} title={t('Demo projects')} message={''} />
      )}
      {copyNameDialogOpen && (
        <FormDialog
          open={copyNameDialogOpen}
          setOpen={setCopyNameDialogOpen}
          setConfirmed={setCopyNameDialogConfirmed}
          title={t('Copy File')}
          message={t('Choose a name to the copy of the file. This name cannot be changed later:')}
          fieldLabel={t('File Name')}
          inputText={copyFileName}
          setInputText={setCopyFileName}
        />
      )}
      {deleteConfirmationDialogOpen && (
        <DraggableDialog
          open={deleteConfirmationDialogOpen}
          setOpen={setDeleteConfirmationDialogOpen}
          setConfirmed={setDeleteConfirmed}
          confirmLabel={isFileShared ? t('Unshare') : t('Delete file')}
          title={t('Warning')}
          message={
            isFileShared
              ? clickedFileName + t(' will be no long shared with you')
              : clickedFileName + t('Delete file warning')
          }
        />
      )}
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      {showPlanLimitDialog && (
        <PlanInfoDialog
          open={showPlanLimitDialog}
          positiveLabel={t('Upgrade plan')}
          negativeLabel={t('Back')}
          positiveAction={handleNavToUpgradePlan}
          negativeAction={() => {
            setShowPlanLimitDialog(false);
          }}
          title={t('Plan limit reached')}
          message={t(
            'You have reached the limit of files for your plan! Please consider upgrading your plan to create more files.'
          )}
        />
      )}
      <MiniDrawerFiles />
      <Grid container justifyContent="center">
        <Grid
          container
          justifyContent="flex-start"
          maxWidth={'lg'}
          // sx={{ backgroundColor: 'red' }}
          alignItems="flex-start"
          paddingTop={1}
          paddingBottom={8}
          spacing={4}
        >
          <Grid item width={'100%'}>
            <Typography variant="h6" gutterBottom color={'primary'}>
              {t('My files')}
            </Typography>
          </Grid>

          <Grid item>
            <FileCard
              file={null}
              user={user}
              onClick={handleCardClick}
              onCopyClick={handleCopyClick}
              onShareClick={handleShareClick}
              onDeleteClick={handleDeleteClick}
              setSeeDemoOpen={setSeeDemoOpen}
            />
          </Grid>
          {files
            .sort(
              (file1, file2) =>
                (file2.updatedAt as unknown as Timestamp).toMillis() -
                (file1.updatedAt as unknown as Timestamp).toMillis()
            )
            .map((file, index) => {
              return (
                <Grid item key={index}>
                  <FileCard
                    file={file}
                    user={user}
                    onClick={handleCardClick}
                    onCopyClick={handleCopyClick}
                    onShareClick={handleShareClick}
                    onDeleteClick={handleDeleteClick}
                  />
                </Grid>
              );
            })}
          {sharedWithMe && (
            <Grid item width={'100%'}>
              <Typography variant="h6" gutterBottom color={'primary'}>
                {t('Shared with me')}
              </Typography>
            </Grid>
          )}
          {sharedFiles.map((sharedFile, index) => {
            return (
              <Grid item key={index}>
                <SharedFileCard
                  file={sharedFile}
                  user={user}
                  shared={
                    sharedWithMe
                      ? sharedWithMe.find((shared) => sharedFile && shared.file === sharedFile.name)
                      : undefined
                  }
                  onClick={handleSharedCardClick}
                  onCopyClick={handleSharedCopyClick}
                  onDeleteClick={handleSharedDeleteClick}
                />
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Container>
  );
}
