import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Grid, Stack, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IImportWarning } from '../pages/Lessons/components/ImportLessonsDialog';
import WarningIcon from '@mui/icons-material/Warning';
import DangerousIcon from '@mui/icons-material/Dangerous';
import { useTranslation } from 'react-i18next';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

interface WarningData {
  id: string;
  line: number;
  column: string;
  description: string;
  type: 'error' | 'warning';
}

function createData(
  id: string,
  line: number,
  column: string,
  description: string,
  type: 'error' | 'warning'
): WarningData {
  return {
    id,
    line,
    column,
    description,
    type,
  };
}

export default function ImportConfirmationDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const setImportOpen = props.setImportOpen;
  const setConfirmed = props.setConfirmed;
  const title = props.title;
  const message = props.message;

  const warnings: IImportWarning[] = JSON.parse(localStorage.getItem('warnings') as string);

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setConfirmed(true);
    setOpen(false);
    setImportOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('Id'),
      flex: 1,
      hide: true,
    },
    {
      field: 'line',
      type: 'number',
      headerName: t('Line'),
      flex: 2,
      minWidth: 50,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'column',
      headerName: t('Column'),
      flex: 4,
      minWidth: 80,
    },
    {
      field: 'description',
      headerName: t('Description'),
      flex: 20,
      minWidth: 150,
    },
    {
      field: 'type',
      headerName: t('Type'),
      flex: 2,
      minWidth: 50,
      renderCell: (params) => {
        if (params.value === 'warning') return <WarningIcon color="warning" />;
        else return <DangerousIcon color="error" />;
      },
    },
  ];

  let rows: any[] = [];
  warnings.map(function (warning: IImportWarning, index) {
    return rows.push(
      createData(
        warning.line + ' ' + warning.column + ' ' + warning.description,
        warning.line,
        warning.column,
        warning.description,
        warning.type
      )
    );
  });

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <Grid container spacing={1} paddingTop={2} sx={{ height: '60vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              rowsPerPageOptions={[20, 50, 100]}
              // headerHeight={0}
              // hideFooterPagination
              density={'compact'}
              // loading={rows.length === 0}
              // checkboxSelection
              disableSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: 'line', sort: 'asc' }],
                },
              }}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <HighlightAltIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('No WARNINGS were found \\o/')}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SearchOffIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Local FILTER returns no data')}
                    </Typography>
                  </Stack>
                ),
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleConfirm}>{t('Confirm')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
