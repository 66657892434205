import React from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { doc } from 'firebase/firestore';
import { setDoc } from '@firebase/firestore';
import { createUserWithEmailAndPassword, UserCredential } from 'firebase/auth';
import { toast } from 'react-toastify';

import * as S from './styles';
import { auth, db } from 'services';
import { IUser } from 'contexts/SessionContext/SessionContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const SignUpHome = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const usersPath = 'users';

  const formik = useFormik<IUser>({
    initialValues: {
      email: '',
      password: '',
      name: '',
      phone: '',
      photoURL: '',
      countryCode: 'US',
      selectedFile: '',
      uid: '',
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      email: Yup.string().required('Required field').email('Invalid email'),
      password: Yup.string().required('Required field').min(8, 'Must have at least 8 chars'),
      name: Yup.string().required('Required field'),
    }),
    onSubmit: async (values) => {
      const createdUser = await createUser();
      if (createdUser) {
        let userDoc: Partial<IUser> & { uid: string } = { ...values, uid: createdUser.user.uid };
        delete userDoc.password;
        // await addDoc(dadosCollectionRef, userDoc);
        await setDoc(doc(db, usersPath, userDoc.email as string), {
          email: userDoc.email,
          name: userDoc.name,
          phone: userDoc.phone,
          photoURL: userDoc.photoURL,
          countryCode: 'US',
          uid: userDoc.uid,
        });
        toast.success(t('User created sucessfully'));
        history.push('/files');
      } else {
        toast.error(t('An error has occurred'));
      }
    },
  });

  const createUser = async (): Promise<UserCredential | null> => {
    try {
      const createdUser = await createUserWithEmailAndPassword(auth, formik.values.email, formik.values.password);
      return createdUser;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const { getFieldProps } = formik;

  return (
    <Grid container padding={0}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center" style={{ padding: 8 }} spacing={2}>
          <Grid item>
            <img height={'auto'} width={60} src="/img/logo.png" alt="Optables logo"></img>
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <Grid item xs={12}>
              <TextField
                label={t('Name')}
                variant="standard"
                size="small"
                fullWidth
                {...getFieldProps('name')}
                error={!!formik.errors.name}
                helperText={formik.errors.name}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <Grid item xs={12}>
              <TextField
                label={t('Email')}
                type="email"
                variant="standard"
                size="small"
                fullWidth
                style={{ borderRadius: 18 }}
                {...getFieldProps('email')}
                error={!!formik.errors.email}
                helperText={formik.errors.email}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <Grid item xs={12}>
              <TextField
                type="password"
                label={t('Password')}
                variant="standard"
                size="small"
                fullWidth
                {...getFieldProps('password')}
                error={!!formik.errors.password}
                helperText={formik.errors.password}
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} justifyContent="center">
            <Grid item xs={12}>
              <S.TextSmall>
                {t('By signing up, you agree to our')}
                &nbsp;
                <Link to={'terms'} style={{ color: 'inherit' }}>
                  {t('Terms of Use')}
                </Link>
                &nbsp;{t('and')}&nbsp;
                <Link to={'privacy'} style={{ color: 'inherit' }}>
                  {t('Privacy Policy')}
                </Link>
              </S.TextSmall>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button variant="contained" color="primary" type="submit" fullWidth sx={{ marginTop: 1 }}>
              <Typography variant="subtitle2">{t('Sign Up')}</Typography>
            </Button>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export { SignUpHome };
