import { colors } from '@material-ui/core';

const priorityColors: Record<string, string> = {
  'Very low': colors.red[700],
  Low: colors.orange[700],
  Average: colors.grey[700],
  High: colors.lightGreen[700],
  'Very high': colors.green[700],
  'Muito baixo': colors.red[700],
  Baixo: colors.orange[700],
  Normal: colors.grey[700],
  Alto: colors.lightGreen[700],
  'Muito alto': colors.green[700],
};

export { priorityColors };
