import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { linearProgressClasses } from '@material-ui/core';
import { theme } from 'styles';
import { ISolution } from 'pages/Solutions/Solution';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

interface LinearWithValueLabelProps {
  afterSolve?: () => void;
  initTime: number;
  maxTime: number;
  solving: boolean;
  setSolving: (solving: boolean) => void;
}

export default function LinearWithValueLabel(props: LinearWithValueLabelProps) {
  const solving: boolean = props.solving;
  const maxTime: number = props.maxTime;
  const afterSolve = props.afterSolve;
  const [progress, setProgress] = React.useState(solving ? (props.initTime / maxTime) * 100 : 0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (progress >= 102 && afterSolve) {
        afterSolve();
      }
      solving ? setProgress((prevProgress: number) => prevProgress + (100 / maxTime) * 0.5) : setProgress(0);
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, [afterSolve, maxTime, progress, solving]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel
        value={progress > 100 ? 100 : progress}
        sx={{
          height: 12,
          borderRadius: 6,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 6,
            backgroundColor: theme.palette.mode === 'light' ? 'primary' : '#308fe8',
          },
        }}
      />
    </Box>
  );
}
