import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { doc, writeBatch } from '@firebase/firestore';
import { Grid } from '@material-ui/core';
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
} from '@mui/material/colors';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import ControlledSwitch from '../../../components/ControlledSwitch';
import ImportDataGrid from '../../../components/ImportDataGrid';
import BasicSelect from '../../../components/BasicSelect';
import { SessionContext } from 'contexts';
import { ILesson } from 'pages/Lessons/Lesson';
import ImportConfirmationDialog from '../../../components/ImportConfirmationDialog';
import SimpleBackdrop from 'components/SimpleBackdrop';
import { db } from 'services';
import OnboardingContent from 'components/OnboardingContent';
import { useContainerDimensions } from 'hooks/useContainerDimensions';
import Onboarding from 'components/Onboarding';
import ImportLessonsAccordion from './ImportLessonsAccordion';
import InfoDialog from 'components/InfoDialog';
import { Info } from '@material-ui/icons';
import useUserPlan from 'stripe/useUserPlan';
import { planFeatures } from 'configs/planFeatures';
import PlanInfoDialog from 'components/PlanInfoDialog';
import { useHistory } from 'react-router-dom';
import { ContentPaste } from '@mui/icons-material';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export interface IImportWarning {
  line: number;
  column: string;
  description: string;
  type: 'error' | 'warning';
}

export default function ImportLessonsDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const { user, file, resources, subjects, solutions, lessons, ownerEmail } = React.useContext(SessionContext);
  const resourcesPath = 'users/' + ownerEmail + '/files/' + file?.name + '/resources';
  const subjectsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/subjects';
  const lessonsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/lessons';
  const solutionsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/solutions';
  const [ignoreFirstLine, setIgnoreFirstLine] = React.useState<boolean>(true);
  const [showPlanLimitDialog, setShowPlanLimitDialog] = React.useState<boolean>(false);
  const history = useHistory();

  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];
  const priorityOptions = ['Very low', 'Low', 'Average', 'High', 'Very high'];

  const handleClose = () => {
    setOpen(false);
  };

  let warnings: IImportWarning[] = [];

  function validateSubjectNameInput(name: string, lineNum: number, column: string): boolean {
    // const subjectNames: string[] = subjects.map((sub) => sub.name);
    if (name.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty name for')} ${column} ${t('will be ignored')}`,
        type: 'warning',
      });
      return false;
    } else if (name.length > 50) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name having more than 50 chars for')} ${column} ${t('will be ignored')}`,
        type: 'warning',
      });
      return false;
    } else if (
      name.includes('/') ||
      name.includes('\\') ||
      name.includes('|') ||
      name.includes(';') ||
      name.includes('#') ||
      name.includes('=') ||
      name.includes(':') ||
      name.includes('_') ||
      name.includes('{') ||
      name.includes('}') ||
      name.includes('&')
    ) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name cannot contain: / \\ | ; # = : _ { } & for')} ${column}`,
        type: 'warning',
      });
      return false;
    } else return true;
  }

  function validateResourceNameInput(name: string, lineNum: number, column: string, type: string): boolean {
    //const resourceNames: string[] = resources.filter((resource) => resource.type === type).map((res) => res.name);
    if (name.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty name for')} ${column} ${t('will be ignored')}`,
        type: 'warning',
      });
      return false;
    } else if (name.length >= 40) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name having more than 40 chars for')} ${column} ${t('will be inogred')}`,
        type: 'warning',
      });
      return false;
    } else if (
      name.includes('/') ||
      name.includes('\\') ||
      name.includes('|') ||
      name.includes(';') ||
      name.includes('#') ||
      name.includes('=') ||
      name.includes(':') ||
      name.includes('_') ||
      name.includes('{') ||
      name.includes('}') ||
      name.includes('&')
    ) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name containing: / \\ | ; # = : _ { } & for')} ${column} ${t('will be ignored')}`,
        type: 'warning',
      });
      return false;
    } else return true;
  }

  function handleSubject(name: string, lineNum: number, column: string, subject: string[]) {
    if (validateSubjectNameInput(name.trim(), lineNum, column)) {
      subject.push(name.trim());
    }
  }

  function chooseLabel(type: string) {
    if (type === 'class') {
      return file?.studentsSetting !== 0 ? t('classes') : t('students');
    } else if (type === 'teacher') {
      return t('teachers');
    } else if (type === 'room') {
      return t('rooms');
    } else {
      return t('resources');
    }
  }

  function handleResource(
    name: string,
    lineNum: number,
    column: string,
    type: string,
    resources: string[],
    resourcesNum: number[]
  ) {
    const resourceNames = name.split('+');
    resourceNames.forEach((resourceName) => {
      if (validateResourceNameInput(resourceName.trim(), lineNum, column, type)) {
        resources.push(resourceName.trim());
      }
    });
    if (resources.length < resourcesNum[0]) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('The number requested joint')} ${chooseLabel(type)} ${t(
          'larger than the number of provided'
        )} ${column} ${t('and will be considered as')} ${resources.length}`,
        type: 'error',
      });
      resourcesNum = [resources.length];
    }
  }

  const MAX_TIMESLOTS = (file?.days.length as number) * (file?.times.length as number);

  function handleJointResourcesNum(
    inputText: string,
    lineNum: number,
    column: string,
    resourcesNum: number[],
    defaultValue: number
  ) {
    const inputAsNumber: number = +inputText.trim();
    if (inputText.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty')} ${column} ${t('will be considered as')} ${defaultValue}`,
        type: 'warning',
      });
      resourcesNum.push(defaultValue);
    } else if (!Number.isInteger(inputAsNumber)) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Decimal or non-numeric')} ${column} ${t('will be considered as')} ${defaultValue}`,
        type: 'warning',
      });
      resourcesNum.push(defaultValue);
    } else if (inputAsNumber < 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Negative value for')} ${column} ${t('will be considered as')} ${defaultValue}`,
        type: 'warning',
      });
      resourcesNum.push(defaultValue);
    } else {
      resourcesNum.push(inputAsNumber);
    }
  }

  function handleLessonsWeek(inputText: string, lineNum: number, column: string, lessonsWeek: number[]) {
    const inputAsNumber: number = +inputText.trim();
    if (inputText.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty')} ${column} ${t('will be considered as')} 1`,
        type: 'warning',
      });
      lessonsWeek.push(1);
    } else if (!Number.isInteger(inputAsNumber)) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Decimal or non-numeric')} ${column} ${t('will be considered as')} 1`,
        type: 'warning',
      });
      lessonsWeek.push(1);
    } else if (inputAsNumber > MAX_TIMESLOTS) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${column} ${t('is higher than the number of timeslots and will be considered as')} 1`,
        type: 'warning',
      });
      lessonsWeek.push(1);
    } else if (inputAsNumber < 1) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Negative or 0 value for')} ${column} ${t('will be considered as')} 1`,
        type: 'warning',
      });
      lessonsWeek.push(1);
    } else {
      lessonsWeek.push(inputAsNumber);
    }
  }

  function handleSplit(inputText: string, lineNum: number, column: string, split: string[], lessonsWeek: number) {
    let defaultSplit: string = '';
    Array.from({ length: lessonsWeek }).forEach((num) => {
      defaultSplit += '1-';
    });
    defaultSplit = defaultSplit.slice(0, -1);
    if (inputText.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty')} ${column} ${t('will be considered as')} ${defaultSplit}`,
        type: 'warning',
      });
      split.push(defaultSplit);
      return;
    }
    const inputSplit = inputText.split('+').map((text) => {
      return text.trim();
    });
    inputSplit.forEach((input) => {
      const splitOption = input.split('-').map((text) => {
        return +text.trim();
      });
      let splitSum = 0;
      let splitArray: number[] = [];
      let inputIsOk = true;
      splitOption.forEach((splitNum) => {
        if (inputIsOk) {
          if (!Number.isInteger(splitNum)) {
            warnings.push({
              line: lineNum + 1,
              column: column,
              description: `${t('Decimal or non-numeric')} ${column} ${t('will be considered as')} ${defaultSplit}`,
              type: 'warning',
            });
            inputIsOk = false;
          } else {
            splitSum += splitNum;
            splitArray.push(splitNum);
          }
        }
      });
      if (inputIsOk) {
        if (splitSum !== lessonsWeek) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${column} ${t('sum of')}: ${splitOption} ${t(
              'does not match the expected Times /Week'
            )} ${lessonsWeek} ${t('and will be considered as')} ${defaultSplit}`,
            type: 'warning',
          });
          !split.includes(defaultSplit) && split.push(defaultSplit);
        } else if (splitArray.find((splitNum) => splitNum > times.length)) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${column} ${t(
              'spreads over more times than existing and will be considered as'
            )} ${defaultSplit}`,
            type: 'warning',
          });
          !split.includes(defaultSplit) && split.push(defaultSplit);
        } else {
          split.push(input.replaceAll(' ', ''));
        }
      }
    });
  }

  function handleMinMaxConstraint(
    inputText: string,
    lineNum: number,
    column: string,
    constraintMinMax: number[],
    min: number,
    max: number
  ) {
    // let defaultValue: string = `${min}-${max}`;
    if (inputText.length === 0) {
      // warnings.push({
      //   line: lineNum + 1,
      //   column: column,
      //   description: `${t('Empty')} ${column} ${'will be considered as'} ${defaultValue}`,
      //   type: 'warning',
      // });
      constraintMinMax.push(min);
      constraintMinMax.push(max);
      return;
    }
    const inputSplit = inputText.split('-').map((text) => {
      return text.trim();
    });

    if (inputSplit.length === 2) {
      if (inputSplit[0] === '') inputSplit[0] = '' + min;
      if (inputSplit[1] === '') inputSplit[1] = '' + max;
      let inputMin: number = +inputSplit[0];
      let inputMax: number = +inputSplit[1];
      if (!Array.from({ length: max + 1 }, (x, i) => i).includes(inputMin)) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${t('Min value for')} ${column} ${t('is not in the allowed range')} (${min}, ${max}) ${t(
            'and will be ignored'
          )}`,
          type: 'warning',
        });
        inputMin = min;
      }
      if (!Array.from({ length: max + 1 }, (x, i) => i).includes(inputMax)) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${t('Max value for')} ${column} ${t('is not in the allowed range')} (${min}, ${max}) ${t(
            'and will be ignored'
          )}`,
          type: 'warning',
        });
        inputMax = max;
      }
      if (inputMin > max) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${t('Min')} (${inputMin}) ${t('is larger than max allowed value')} (${max}) ${t(
            'for'
          )} ${column} ${t('and will be ignored')}`,
          type: 'warning',
        });
        inputMin = min;
      } else if (inputMin > inputMax) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${t('Min')} (${inputMin}) ${t('larger than max')} (${inputMax}) ${t('for')} ${column} ${t(
            'will be ignored'
          )}`,
          type: 'warning',
        });
        inputMin = min;
      }
      constraintMinMax.push(inputMin);
      constraintMinMax.push(inputMax);
      return;
    } else {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${column} ${inputText} ${t('is not in the required format (min-max) and will be ignored')}`,
        type: 'warning',
      });
      constraintMinMax.push(min);
      constraintMinMax.push(max);
      return;
    }
  }

  function handleArrayOfTimes(inputText: string, lineNum: number, column: string, arrayOfTimes: string[]) {
    if (inputText.trim().length === 0) {
      // Empty cells are allowed for this field
      return;
    }
    const inputSplit = inputText.split('+').map((text) => {
      return text.trim();
    });

    inputSplit.forEach((input) => {
      let inputData = input.split(':');
      if (inputData.length < 2) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${column} ${inputData} ${t('is not in the required format (day:time) and will be ignored')}`,
          type: 'warning',
        });
      } else {
        const allDays = inputData[0].trim() === '*';
        const allTimes = inputData[1].trim() === '*';
        const day = Number(inputData[0].trim());
        const time = Number(inputData[1].trim());
        const dayIsValid = Array.from({ length: days.length + 1 }, (x, i) => i).includes(day) || day < 1 || allDays;
        const timeIsValid =
          Array.from({ length: times.length + 1 }, (x, i) => i).includes(time) || time < 1 || allTimes;
        if (!dayIsValid) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${t('Day')} ${day} ${t('in')} ${column} ${input} ${t('is not in the allowed range')} (1, ${
              days.length
            }) ${t('and will be ignored')}`,
            type: 'warning',
          });
        } else if (!timeIsValid) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${t('Time')} ${time} ${t('in')} ${column} ${input} ${t('is not in the allowed range')} (1, ${
              times.length
            }) ${t('and will be ignored')}`,
            type: 'warning',
          });
        } else {
          if (allDays && allTimes) {
            days.forEach((day, indexDay) => {
              times.forEach((time, indexTime) => {
                !arrayOfTimes.includes(indexDay + ':' + indexTime) && arrayOfTimes.push(indexDay + ':' + indexTime);
              });
            });
          } else if (allDays) {
            days.forEach((day, index) => {
              !arrayOfTimes.includes(index + ':' + (time - 1)) && arrayOfTimes.push(index + ':' + (time - 1));
            });
          } else if (allTimes) {
            times.forEach((time, index) => {
              !arrayOfTimes.includes(day - 1 + ':' + index) && arrayOfTimes.push(day - 1 + ':' + index);
            });
          } else {
            !arrayOfTimes.includes(day - 1 + ':' + (time - 1)) && arrayOfTimes.push(day - 1 + ':' + (time - 1));
          }
        }
      }
    });
  }

  var getInitials = function (string: string, maxLength: number) {
    if (string.length <= maxLength) {
      return string.toUpperCase();
    }
    var names = string.trim().split(' ');
    if (names.length === 1) {
      return names[0].substring(0, maxLength).toUpperCase();
    }
    var initials: string = '';
    var midChars: number = 0;
    for (let i = 0; i < names.length; i++) {
      if (i === 0 || i === names.length - 1) initials += names[i].charAt(0).toUpperCase();
      else if (names[i].charAt(0) === names[i].charAt(0).toUpperCase() && midChars + 2 < maxLength) {
        initials += names[i].substring(0, 1).toUpperCase();
        midChars++;
      }
    }
    return initials;
  };

  const colors: any[] = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    // yellow,
    amber,
    orange,
    deepOrange,
    brown,
    // grey,
    // blueGrey,
  ];
  const intensities: number[] = [400, 500, 600, 700, 800, 900];

  function getRandomColor() {
    return colors[Math.floor(Math.random() * colors.length)][
      intensities[Math.floor(Math.random() * intensities.length)]
    ];
  }

  function getDefaultSplit(lessonsWeek: number[]) {
    let lessons = 1;
    if (lessonsWeek.length > 0) {
      lessons = lessonsWeek[0];
    }
    let defaultSplit = '';
    Array.from({ length: lessons }).forEach((num) => {
      defaultSplit += '1-';
    });
    defaultSplit = defaultSplit.slice(0, -1);
    return [defaultSplit];
  }

  let lessonsToImport: ILesson[] = [];

  const handleProceed = () => {
    // Check for repeated columns
    const columns: string[] = [column1, column2, column3, column4, column5, column6, column7, column8];
    let ignoredColCount = 0;
    for (let i = 0; i < columns.length; ++i) {
      for (let j = i + 1; j < columns.length; ++j) {
        if (columns[i] === columns[j] && columns[i] !== t('Ignore column')) {
          toast.error(`${t('Cannot have more than one column with value')} ${columns[i]}`);
          return;
        }
      }
      columns[i] === t('Ignore column') && ignoredColCount++;
    }
    if (ignoredColCount === columns.length) {
      toast.error(t("At least one column should be marked as different than 'Ignore column'"));
      return;
    }
    lessonsToImport = [];
    navigator.clipboard
      .readText()
      .then((text) => {
        text.split('\n').forEach((line, index) => {
          //Ignoring (or not) the first line
          if (index === 0 && ignoreFirstLine) return;
          //Ignoring empty lines
          if (line === '') return;
          // Getting cell values
          const cells = line.split('\t');
          const columnVal1 = cells[0];
          const columnVal2 = cells.length > 1 ? cells[1] : '';
          const columnVal3 = cells.length > 2 ? cells[2] : '';
          const columnVal4 = cells.length > 3 ? cells[3] : '';
          const columnVal5 = cells.length > 4 ? cells[4] : '';
          const columnVal6 = cells.length > 5 ? cells[5] : '';
          const columnVal7 = cells.length > 6 ? cells[6] : '';
          const columnVal8 = cells.length > 7 ? cells[7] : '';
          // Process new or existing subject
          let subject: string[] = [];
          column1 === t('Subject') && handleSubject(columnVal1, index, column1, subject);
          column2 === t('Subject') && handleSubject(columnVal2, index, column2, subject);
          column3 === t('Subject') && handleSubject(columnVal3, index, column3, subject);
          column4 === t('Subject') && handleSubject(columnVal4, index, column4, subject);
          column5 === t('Subject') && handleSubject(columnVal5, index, column5, subject);
          column6 === t('Subject') && handleSubject(columnVal6, index, column6, subject);
          column7 === t('Subject') && handleSubject(columnVal7, index, column7, subject);
          column8 === t('Subject') && handleSubject(columnVal8, index, column8, subject);
          // Process joint classes
          let classNum: number[] = [];
          const defaultClassNum = 1;
          column1 === `${file?.studentsSetting !== 0 ? t('Num of Classes') : t('Num of Students')}` &&
            handleJointResourcesNum(columnVal1, index, column1, classNum, defaultClassNum);
          column2 === `${file?.studentsSetting !== 0 ? t('Num of Classes') : t('Num of Students')}` &&
            handleJointResourcesNum(columnVal2, index, column2, classNum, defaultClassNum);
          column3 === `${file?.studentsSetting !== 0 ? t('Num of Classes') : t('Num of Students')}` &&
            handleJointResourcesNum(columnVal3, index, column3, classNum, defaultClassNum);
          column4 === `${file?.studentsSetting !== 0 ? t('Num of Classes') : t('Num of Students')}` &&
            handleJointResourcesNum(columnVal4, index, column4, classNum, defaultClassNum);
          column5 === `${file?.studentsSetting !== 0 ? t('Num of Classes') : t('Num of Students')}` &&
            handleJointResourcesNum(columnVal5, index, column5, classNum, defaultClassNum);
          column6 === `${file?.studentsSetting !== 0 ? t('Num of Classes') : t('Num of Students')}` &&
            handleJointResourcesNum(columnVal6, index, column6, classNum, defaultClassNum);
          column7 === `${file?.studentsSetting !== 0 ? t('Num of Classes') : t('Num of Students')}` &&
            handleJointResourcesNum(columnVal7, index, column7, classNum, defaultClassNum);
          column8 === `${file?.studentsSetting !== 0 ? t('Num of Classes') : t('Num of Students')}` &&
            handleJointResourcesNum(columnVal8, index, column8, classNum, defaultClassNum);
          classNum.length === 0 && classNum.push(defaultClassNum);
          // Process new or existing class
          let classes: string[] = [];
          column1 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal1, index, column1, 'class', classes, classNum);
          column2 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal2, index, column2, 'class', classes, classNum);
          column3 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal3, index, column3, 'class', classes, classNum);
          column4 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal4, index, column4, 'class', classes, classNum);
          column5 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal5, index, column5, 'class', classes, classNum);
          column6 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal6, index, column6, 'class', classes, classNum);
          column7 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal7, index, column7, 'class', classes, classNum);
          column8 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal8, index, column8, 'class', classes, classNum);
          // Process joint teachers
          let teacherNum: number[] = [];
          const defaultTeacherNum = 1;
          column1 === t('Num of Teachers') &&
            handleJointResourcesNum(columnVal1, index, column1, teacherNum, defaultTeacherNum);
          column2 === t('Num of Teachers') &&
            handleJointResourcesNum(columnVal2, index, column2, teacherNum, defaultTeacherNum);
          column3 === t('Num of Teachers') &&
            handleJointResourcesNum(columnVal3, index, column3, teacherNum, defaultTeacherNum);
          column4 === t('Num of Teachers') &&
            handleJointResourcesNum(columnVal4, index, column4, teacherNum, defaultTeacherNum);
          column5 === t('Num of Teachers') &&
            handleJointResourcesNum(columnVal5, index, column5, teacherNum, defaultTeacherNum);
          column6 === t('Num of Teachers') &&
            handleJointResourcesNum(columnVal6, index, column6, teacherNum, defaultTeacherNum);
          column7 === t('Num of Teachers') &&
            handleJointResourcesNum(columnVal7, index, column7, teacherNum, defaultTeacherNum);
          column8 === t('Num of Teachers') &&
            handleJointResourcesNum(columnVal8, index, column8, teacherNum, defaultTeacherNum);
          teacherNum.length === 0 && teacherNum.push(defaultTeacherNum);
          // Process new or existing teacher
          let teachers: string[] = [];
          column2 === t('Teachers') && handleResource(columnVal2, index, column2, 'teacher', teachers, teacherNum);
          column1 === t('Teachers') && handleResource(columnVal1, index, column1, 'teacher', teachers, teacherNum);
          column3 === t('Teachers') && handleResource(columnVal3, index, column3, 'teacher', teachers, teacherNum);
          column4 === t('Teachers') && handleResource(columnVal4, index, column4, 'teacher', teachers, teacherNum);
          column5 === t('Teachers') && handleResource(columnVal5, index, column5, 'teacher', teachers, teacherNum);
          column6 === t('Teachers') && handleResource(columnVal6, index, column6, 'teacher', teachers, teacherNum);
          column7 === t('Teachers') && handleResource(columnVal7, index, column7, 'teacher', teachers, teacherNum);
          column8 === t('Teachers') && handleResource(columnVal8, index, column8, 'teacher', teachers, teacherNum);
          // Process joint teachers
          let roomNum: number[] = [];
          const defaultRoomNum = 0;
          column1 === t('Num of Rooms') && handleJointResourcesNum(columnVal1, index, column1, roomNum, defaultRoomNum);
          column2 === t('Num of Rooms') && handleJointResourcesNum(columnVal2, index, column2, roomNum, defaultRoomNum);
          column3 === t('Num of Rooms') && handleJointResourcesNum(columnVal3, index, column3, roomNum, defaultRoomNum);
          column4 === t('Num of Rooms') && handleJointResourcesNum(columnVal4, index, column4, roomNum, defaultRoomNum);
          column5 === t('Num of Rooms') && handleJointResourcesNum(columnVal5, index, column5, roomNum, defaultRoomNum);
          column6 === t('Num of Rooms') && handleJointResourcesNum(columnVal6, index, column6, roomNum, defaultRoomNum);
          column7 === t('Num of Rooms') && handleJointResourcesNum(columnVal7, index, column7, roomNum, defaultRoomNum);
          column8 === t('Num of Rooms') && handleJointResourcesNum(columnVal8, index, column8, roomNum, defaultRoomNum);
          roomNum.length === 0 && roomNum.push(defaultRoomNum);
          // Process new or existing room
          let rooms: string[] = [];
          column1 === t('Rooms') && handleResource(columnVal1, index, column1, 'room', rooms, roomNum);
          column2 === t('Rooms') && handleResource(columnVal2, index, column2, 'room', rooms, roomNum);
          column3 === t('Rooms') && handleResource(columnVal3, index, column3, 'room', rooms, roomNum);
          column4 === t('Rooms') && handleResource(columnVal4, index, column4, 'room', rooms, roomNum);
          column5 === t('Rooms') && handleResource(columnVal5, index, column5, 'room', rooms, roomNum);
          column6 === t('Rooms') && handleResource(columnVal6, index, column6, 'room', rooms, roomNum);
          column7 === t('Rooms') && handleResource(columnVal7, index, column7, 'room', rooms, roomNum);
          column8 === t('Rooms') && handleResource(columnVal8, index, column8, 'room', rooms, roomNum);
          // Process times /week
          let lessonsWeek: number[] = [];
          column1 === t('Times /Week') && handleLessonsWeek(columnVal1, index, column1, lessonsWeek);
          column2 === t('Times /Week') && handleLessonsWeek(columnVal2, index, column2, lessonsWeek);
          column3 === t('Times /Week') && handleLessonsWeek(columnVal3, index, column3, lessonsWeek);
          column4 === t('Times /Week') && handleLessonsWeek(columnVal4, index, column4, lessonsWeek);
          column5 === t('Times /Week') && handleLessonsWeek(columnVal5, index, column5, lessonsWeek);
          column6 === t('Times /Week') && handleLessonsWeek(columnVal6, index, column6, lessonsWeek);
          column7 === t('Times /Week') && handleLessonsWeek(columnVal7, index, column7, lessonsWeek);
          column8 === t('Times /Week') && handleLessonsWeek(columnVal8, index, column8, lessonsWeek);
          // Process split
          let split: string[] = [];
          column1 === t('Split') && handleSplit(columnVal1, index, column1, split, lessonsWeek[0]);
          column2 === t('Split') && handleSplit(columnVal2, index, column2, split, lessonsWeek[0]);
          column3 === t('Split') && handleSplit(columnVal3, index, column3, split, lessonsWeek[0]);
          column4 === t('Split') && handleSplit(columnVal4, index, column4, split, lessonsWeek[0]);
          column5 === t('Split') && handleSplit(columnVal5, index, column5, split, lessonsWeek[0]);
          column6 === t('Split') && handleSplit(columnVal6, index, column6, split, lessonsWeek[0]);
          column7 === t('Split') && handleSplit(columnVal7, index, column7, split, lessonsWeek[0]);
          column8 === t('Split') && handleSplit(columnVal8, index, column8, split, lessonsWeek[0]);
          // Process days between lessons
          let daysBetweenLessons: number[] = [];
          const minValidDaysBetweenLessons = 0;
          const maxValidDaysBetweenLessons = days.length - 1;
          column1 === t('Days between Lessons') &&
            handleMinMaxConstraint(
              columnVal1,
              index,
              column1,
              daysBetweenLessons,
              minValidDaysBetweenLessons,
              maxValidDaysBetweenLessons
            );
          column2 === t('Days between Lessons') &&
            handleMinMaxConstraint(
              columnVal2,
              index,
              column2,
              daysBetweenLessons,
              minValidDaysBetweenLessons,
              maxValidDaysBetweenLessons
            );
          column3 === t('Days between Lessons') &&
            handleMinMaxConstraint(
              columnVal3,
              index,
              column3,
              daysBetweenLessons,
              minValidDaysBetweenLessons,
              maxValidDaysBetweenLessons
            );
          column4 === t('Days between Lessons') &&
            handleMinMaxConstraint(
              columnVal4,
              index,
              column4,
              daysBetweenLessons,
              minValidDaysBetweenLessons,
              maxValidDaysBetweenLessons
            );
          column5 === t('Days between Lessons') &&
            handleMinMaxConstraint(
              columnVal5,
              index,
              column5,
              daysBetweenLessons,
              minValidDaysBetweenLessons,
              maxValidDaysBetweenLessons
            );
          column6 === t('Days between Lessons') &&
            handleMinMaxConstraint(
              columnVal6,
              index,
              column6,
              daysBetweenLessons,
              minValidDaysBetweenLessons,
              maxValidDaysBetweenLessons
            );
          column7 === t('Days between Lessons') &&
            handleMinMaxConstraint(
              columnVal7,
              index,
              column7,
              daysBetweenLessons,
              minValidDaysBetweenLessons,
              maxValidDaysBetweenLessons
            );
          column8 === t('Days between Lessons') &&
            handleMinMaxConstraint(
              columnVal8,
              index,
              column8,
              daysBetweenLessons,
              minValidDaysBetweenLessons,
              maxValidDaysBetweenLessons
            );
          // Process predefined times
          let predefinedTimes: string[] = [];
          column1 === t('Predefined Times') && handleArrayOfTimes(columnVal1, index, column1, predefinedTimes);
          column2 === t('Predefined Times') && handleArrayOfTimes(columnVal2, index, column2, predefinedTimes);
          column3 === t('Predefined Times') && handleArrayOfTimes(columnVal3, index, column3, predefinedTimes);
          column4 === t('Predefined Times') && handleArrayOfTimes(columnVal4, index, column4, predefinedTimes);
          column5 === t('Predefined Times') && handleArrayOfTimes(columnVal5, index, column5, predefinedTimes);
          column6 === t('Predefined Times') && handleArrayOfTimes(columnVal6, index, column6, predefinedTimes);
          column7 === t('Predefined Times') && handleArrayOfTimes(columnVal7, index, column7, predefinedTimes);
          column8 === t('Predefined Times') && handleArrayOfTimes(columnVal8, index, column8, predefinedTimes);

          const lessonToImport = {
            color: '',
            photoURL: '',
            name: subject + ' | ' + classes.toString(),
            short: '',

            subject: subject,
            lessonsWeek: lessonsWeek.length > 0 ? lessonsWeek[0] : 1,
            split: split.length > 0 ? split : getDefaultSplit(lessonsWeek),
            minGapLessons: daysBetweenLessons.length > 0 ? daysBetweenLessons[0] : 0,
            maxGapLessons: daysBetweenLessons.length > 1 ? daysBetweenLessons[1] : days.length - 1,

            classNum: classes.length === 0 ? 0 : classNum[0],
            classes: classes,
            teacherNum: teachers.length === 0 ? 0 : teacherNum[0],
            teachers: teachers,
            roomNum: rooms.length === 0 ? 0 : roomNum[0],
            rooms: rooms,

            priority: priorityOptions[2],
            predefinedTimes: predefinedTimes,
            simultaneousWith: [],
            notSimultaneousWith: [],
            occurBefore: [],
            occurBeforeMinDays: 0,

            createdAt: new Date(),
            updatedAt: new Date(),
          };
          let addLesson = true;
          if (lessonToImport.subject.length !== 1) {
            warnings.push({
              line: index + 1,
              column: t('Subject'),
              description: t('Lesson has an empty Subject and will be ignored'),
              type: 'error',
            });
            addLesson = false;
          }
          if (lessonToImport.classes.length === 0) {
            warnings.push({
              line: index + 1,
              column: t('Class'),
              description: t('Lesson has no class'),
              type: 'warning',
            });
          }
          if (lessonToImport.teachers.length === 0) {
            warnings.push({
              line: index + 1,
              column: t('Teachers'),
              description: t('Lesson has no teachers'),
              type: 'warning',
            });
          }
          // if (lessonToImport.rooms.length === 0) {
          //   warnings.push({
          //     line: index + 1,
          //     column: 'Rooms',
          //     description: `Lesson has no rooms`,
          //     type: 'warning',
          //   });
          // }
          if (lessons.find((lesson) => lesson.name === lessonToImport.name) !== undefined) {
            warnings.push({
              line: index + 1,
              column: t('Subject'),
              description: `${t('Lesson')} ${lessonToImport.name} ${t('already exists and will be ignored')}`,
              type: 'error',
            });
            addLesson = false;
          }
          addLesson && lessonsToImport.push(lessonToImport);
        });
        // See warnings, erros and confirm
        localStorage.setItem('warnings', JSON.stringify(warnings));
        localStorage.setItem('lessonsToImport', JSON.stringify(lessonsToImport));
        if (warnings.length > 0) setConfirmationOpen(true);
        else {
          setImportConfirmed(true);
          setOpen(false);
        }
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
    // setOpen(false);
  };

  const importFields = [
    t('Ignore column'),
    t('Subject'),
    t('Times /Week'),
    t('Split'),
    t('Days between Lessons'),
    `${file?.studentsSetting !== 0 ? t('Num of Classes') : t('Num of Students')}`,
    `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}`,
    t('Num of Teachers'),
    t('Teachers'),
    t('Num of Rooms'),
    t('Rooms'),
    t('Predefined Times'),
  ];
  const [column1, setColumn1] = React.useState<string>(importFields[0]);
  const [column2, setColumn2] = React.useState<string>(importFields[0]);
  const [column3, setColumn3] = React.useState<string>(importFields[0]);
  const [column4, setColumn4] = React.useState<string>(importFields[0]);
  const [column5, setColumn5] = React.useState<string>(importFields[0]);
  const [column6, setColumn6] = React.useState<string>(importFields[0]);
  const [column7, setColumn7] = React.useState<string>(importFields[0]);
  const [column8, setColumn8] = React.useState<string>(importFields[0]);

  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [importConfirmed, setImportConfirmed] = React.useState(false);
  const userPlan = useUserPlan(user);

  const checkImportConfirmation = () => {
    if (importConfirmed) {
      setLoadingOpen(true);
      setImportConfirmed(false);
      const batch = writeBatch(db);
      const lessonsToImport: ILesson[] = JSON.parse(localStorage.getItem('lessonsToImport') as string);

      if (userPlan === 'free' && lessonsToImport.length + lessons.length > planFeatures.free.maxLessons) {
        setShowPlanLimitDialog(true);
      } else if (userPlan === 'basic' && lessonsToImport.length + lessons.length > planFeatures.basic.maxLessons) {
        setShowPlanLimitDialog(true);
      } else {
        lessonsToImport.forEach((lessonToImport) => {
          // Add subject if it does not exists
          if (subjects.find((subject) => subject.name === lessonToImport.subject[0]) === undefined) {
            const subjectToImport = {
              color: getRandomColor(),
              photoURL: '',
              name: lessonToImport.subject[0],
              short: getInitials(lessonToImport.subject[0], 4),
              priority: priorityOptions[2],
              unavailableTimes: [],
              undesiredTimes: [],
              createdAt: new Date(),
              updatedAt: new Date(),
            };
            batch.set(doc(db, subjectsPath, subjectToImport.name), subjectToImport);
          }
          // Add class if it does not exists
          lessonToImport.classes.forEach((class_) => {
            if (resources.find((resource) => resource.name === class_) === undefined) {
              const resourceToImport = {
                color: getRandomColor(),
                photoURL: '',
                name: class_,
                short: getInitials(class_, 4),
                type: 'class',
                minWorkload: 0,
                maxWorkload: days.length * times.length,
                minWorkingDays: 0,
                maxWorkingDays: days.length,
                minIdleWindow: 0,
                maxIdleWindow: times.length - 2,
                minDailyWorkload: 0,
                maxDailyWorkload: times.length,
                minRestBetweenDays: 0,
                maxRestBetweenDays: times.length * 2 - 2,
                minRoomChangesDay: 0,
                maxRoomChangesDay: times.length - 1,
                minDistinctSubjects: 0,
                maxDistinctSubjects: days.length * times.length,
                minConsecutiveTimes: 0,
                maxConsecutiveTimes: times.length,
                travelTimeRooms: [],
                minTravelTime: 0,
                forbiddenCombination: [],
                priority: priorityOptions[2],
                unavailableTimes: [],
                undesiredTimes: [],
                createdAt: new Date(),
                updatedAt: new Date(),
              };
              batch.set(doc(db, resourcesPath, resourceToImport.name), resourceToImport);
            }
          });
          // Add teacher if it does not exists
          lessonToImport.teachers.forEach((teacher) => {
            if (resources.find((resource) => resource.name === teacher) === undefined) {
              const resourceToImport = {
                color: getRandomColor(),
                photoURL: '',
                name: teacher,
                short: getInitials(teacher, 4),
                type: 'teacher',
                minWorkload: 0,
                maxWorkload: days.length * times.length,
                minWorkingDays: 0,
                maxWorkingDays: days.length,
                minIdleWindow: 0,
                maxIdleWindow: times.length - 2,
                minDailyWorkload: 0,
                maxDailyWorkload: times.length,
                minRestBetweenDays: 0,
                maxRestBetweenDays: times.length * 2 - 2,
                minRoomChangesDay: 0,
                maxRoomChangesDay: times.length - 1,
                minDistinctSubjects: 0,
                maxDistinctSubjects: days.length * times.length,
                minConsecutiveTimes: 0,
                maxConsecutiveTimes: times.length,
                travelTimeRooms: [],
                minTravelTime: 0,
                priority: priorityOptions[2],
                forbiddenCombination: [],
                unavailableTimes: [],
                undesiredTimes: [],
                createdAt: new Date(),
                updatedAt: new Date(),
              };
              batch.set(doc(db, resourcesPath, resourceToImport.name), resourceToImport);
            }
          });
          // Add room if it does not exists
          lessonToImport.rooms.forEach((room) => {
            if (resources.find((resource) => resource.name === room) === undefined) {
              const resourceToImport = {
                color: getRandomColor(),
                photoURL: '',
                name: room,
                short: getInitials(room, 4),
                type: 'room',
                minWorkload: 0,
                maxWorkload: days.length * times.length,
                minWorkingDays: 0,
                maxWorkingDays: days.length,
                minIdleWindow: 0,
                maxIdleWindow: times.length - 2,
                minDailyWorkload: 0,
                maxDailyWorkload: times.length,
                minRestBetweenDays: 0,
                maxRestBetweenDays: times.length * 2 - 2,
                minRoomChangesDay: 0,
                maxRoomChangesDay: times.length - 1,
                minDistinctSubjects: 0,
                maxDistinctSubjects: days.length * times.length,
                minConsecutiveTimes: 0,
                maxConsecutiveTimes: times.length,
                travelTimeRooms: [],
                minTravelTime: 0,
                priority: priorityOptions[2],
                forbiddenCombination: [],
                unavailableTimes: [],
                undesiredTimes: [],
                createdAt: new Date(),
                updatedAt: new Date(),
              };

              batch.set(doc(db, resourcesPath, resourceToImport.name), resourceToImport);
            }
          });
          // Add lesson
          lessonToImport.createdAt = new Date();
          lessonToImport.updatedAt = new Date();
          lessonToImport.priority =
            subjects.find((sub) => sub.name === lessonToImport.subject[0])?.priority ?? priorityOptions[2];
          batch.set(doc(db, lessonsPath, lessonToImport.name), lessonToImport);
          //Set solutions to outdated
          solutions.forEach((solution) => {
            batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
              status: 'Outdated',
            });
          });
        });
        // Update file stats
        batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          updatedAt: new Date(),
        });
        batch
          .commit()
          .then(() => {
            setLoadingOpen(false);
            toast.success(`${lessonsToImport.length} ${t('lessons imported successfully')}`);
          })
          .catch(() => {
            setLoadingOpen(false);
            toast.warning(t('Data import limit per operation exceeded, please input less data at a time'));
          });
      }
    }
  };

  checkImportConfirmation();

  const [infoDialogOpen, setInfoDialogOpen] = React.useState(false);

  const gridRef = React.useRef<any>();
  let { width } = useContainerDimensions(gridRef);

  let maxSelectWidth = width !== 0 ? Math.floor(width / 8) : 172;

  const importStorageFlagKey = 'onboarding_import';
  const [runImportOnboarding, setRunImportOnboarding] = React.useState(false);

  React.useEffect(() => {
    // To ensure columns are resized on fisrt load
    if (open) {
      setLoadingOpen(true);
    } else {
      setLoadingOpen(false);
    }
    if (localStorage.getItem(importStorageFlagKey) !== 'true') {
      setRunImportOnboarding(true);
    } else {
      setRunImportOnboarding(false);
    }
  }, [open, runImportOnboarding, width]);

  const stepsImport = [
    {
      target: '.import-list',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('Content on CTRL+C')}
          message={t('The content in your clipboard (CTRL+C) is tabled and displayed here')}
        />
      ),
    },
    {
      target: '.columns-grid',
      content: (
        <OnboardingContent
          title={t('Columns')}
          message={t('You can select which field is informed in which column here')}
        />
      ),
    },
    {
      target: '.info-button',
      content: (
        <OnboardingContent
          title={t('Input Format')}
          message={t('You can see the required format for importing data for each field here')}
        />
      ),
    },
    {
      target: '.proceed-button',
      content: (
        <OnboardingContent title={t('Proceed')} message={t('When you are done, click here to import the data')} />
      ),
    },
  ];

  const handleNavToUpgradePlan = () => {
    setShowPlanLimitDialog(false);
    history.push('/settings');
  };

  const [clipboardText, setClipboardText] = React.useState<string>('');

  const handlePaste = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        setClipboardText(text);
        toast.success(t('Content successfully loaded from clipboard'));
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
  };

  return (
    <div>
      {infoDialogOpen && (
        <InfoDialog
          open={infoDialogOpen}
          setOpen={setInfoDialogOpen}
          title={t('Columns Data Format')}
          message={<ImportLessonsAccordion />}
        />
      )}
      {showPlanLimitDialog && (
        <PlanInfoDialog
          open={showPlanLimitDialog}
          positiveLabel={t('Upgrade plan')}
          negativeLabel={t('Back')}
          positiveAction={handleNavToUpgradePlan}
          negativeAction={() => {
            setShowPlanLimitDialog(false);
          }}
          title={t('Plan limit reached')}
          message={t(
            'You have reached the limit of lessons for this file in your plan! Please consider upgrading your plan to create more files.'
          )}
        />
      )}
      {confirmationOpen && (
        <ImportConfirmationDialog
          setImportOpen={setOpen}
          open={confirmationOpen}
          setOpen={setConfirmationOpen}
          setConfirmed={setImportConfirmed}
          title={t('Import confirmation')}
          message={t('Please check the warnings below. If you confirm, the subjects will be imported.')}
          warnings={warnings}
        />
      )}
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} justifyContent="space-between" alignItems={'center'}>
            <Grid item xs={12} md={4}>
              <DialogContentText>{message}</DialogContentText>
            </Grid>
            <Grid item xs={4} md={2}>
              <Button
                startIcon={<ContentPaste />}
                sx={{ width: '160px' }}
                variant={'outlined'}
                onClick={() => handlePaste()}
              >
                {t('Paste')}
              </Button>
            </Grid>
            <Grid item xs={4} md={3}>
              <ControlledSwitch
                checked={ignoreFirstLine}
                setChecked={setIgnoreFirstLine}
                label={t('Ignore first line')}
              ></ControlledSwitch>
            </Grid>
            <Grid item xs={4} md={3}>
              <Button
                className="info-button"
                startIcon={<Info />}
                variant="text"
                size="small"
                onClick={() => setInfoDialogOpen(true)}
                color="primary"
                fullWidth
              >
                {t('Column Format')}
              </Button>
            </Grid>
          </Grid>
          <Grid className="columns-grid" container spacing={0} paddingTop={2} ref={gridRef}>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column1}
                setCurrent={setColumn1}
                options={importFields}
                label={`${t('Column')} 1`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column2}
                setCurrent={setColumn2}
                options={importFields}
                label={`${t('Column')} 2`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column3}
                setCurrent={setColumn3}
                options={importFields}
                label={`${t('Column')} 3`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column4}
                setCurrent={setColumn4}
                options={importFields}
                label={`${t('Column')} 4`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column5}
                setCurrent={setColumn5}
                options={importFields}
                label={`${t('Column')} 5`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column6}
                setCurrent={setColumn6}
                options={importFields}
                label={`${t('Column')} 6`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column7}
                setCurrent={setColumn7}
                options={importFields}
                label={`${t('Column')} 7`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column8}
                setCurrent={setColumn8}
                options={importFields}
                label={`${t('Column')} 8`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
          </Grid>
          <Grid className="import-list" container spacing={1}>
            <ImportDataGrid
              clipboardText={clipboardText}
              setClipboardText={setClipboardText}
              ignoreFirstLine={ignoreFirstLine}
            ></ImportDataGrid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
          <Button className="proceed-button" onClick={handleProceed}>
            {t('Proceed')}
          </Button>
        </DialogActions>
        <Onboarding steps={stepsImport} run={runImportOnboarding} storageFlagKey={importStorageFlagKey} />
      </Dialog>
    </div>
  );
}
