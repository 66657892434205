import * as React from 'react';
import Switch from '@mui/material/Switch';
import { FormControlLabel, FormGroup } from '@material-ui/core';

export default function ControlledSwitch(props: any) {
  const checked: boolean = props.checked;
  const setChecked = props.setChecked;
  const label: string = props.label;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />}
        label={label}
      />
    </FormGroup>
  );
}
