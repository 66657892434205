import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import ControlledSwitch from './ControlledSwitch';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableShowConstraints(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const setConfirmed = props.setConfirmed;
  const type = props.type;

  const showWorkload = props.showWorkload;
  const showWorkingDays = props.showWorkingDays;
  const showIdleWindow = props.showIdleWindow;
  const showDailyWorkload = props.showDailyWorkload;
  const showRestBetweenDays = props.showRestBetweenDays;
  const showRoomChangesDay = props.showRoomChangesDay;
  const showConsecutiveTimes = props.showConsecutiveTimes;
  const showTravelTime = props.showTravelTime;
  const showDistinctSubjects = props.showDistinctSubjects;
  const showForbiddenCombination = props.showForbiddenCombination;
  const setShowWorkload = props.setShowWorkload;
  const setShowWorkingDays = props.setShowWorkingDays;
  const setShowIdleWindow = props.setShowIdleWindow;
  const setShowRestBetweenDays = props.setShowRestBetweenDays;
  const setShowRoomChangesDay = props.setShowRoomChangesDay;
  const setShowDailyWorkload = props.setShowDailyWorkload;
  const setShowConsecutiveTimes = props.setShowConsecutiveTimes;
  const setShowTravelTime = props.setShowTravelTime;
  const setShowDistinctSubjects = props.setShowDistinctSubjects;
  const setShowForbiddenCombination = props.setShowForbiddenCombination;

  const handleClose = () => {
    setConfirmed(true);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ControlledSwitch
                checked={showWorkload}
                setChecked={setShowWorkload}
                label={t('Working Times')}
              ></ControlledSwitch>
            </Grid>
            <Grid item xs={12}>
              <ControlledSwitch
                checked={showWorkingDays}
                setChecked={setShowWorkingDays}
                label={t('Working Days')}
              ></ControlledSwitch>
            </Grid>
            <Grid item xs={12}>
              <ControlledSwitch
                checked={showIdleWindow}
                setChecked={setShowIdleWindow}
                label={t('Idle Window')}
              ></ControlledSwitch>
            </Grid>
            <Grid item xs={12}>
              <ControlledSwitch
                checked={showDailyWorkload}
                setChecked={setShowDailyWorkload}
                label={t('Daily Workload')}
              ></ControlledSwitch>
            </Grid>
            {type !== 'room' && (
              <Grid item xs={12}>
                <ControlledSwitch
                  checked={showRestBetweenDays}
                  setChecked={setShowRestBetweenDays}
                  label={t('Rest between Days')}
                ></ControlledSwitch>
              </Grid>
            )}
            {type !== 'room' && (
              <Grid item xs={12}>
                <ControlledSwitch
                  checked={showRoomChangesDay}
                  setChecked={setShowRoomChangesDay}
                  label={t('Rooms per Day')}
                ></ControlledSwitch>
              </Grid>
            )}
            <Grid item xs={12}>
              <ControlledSwitch
                checked={showConsecutiveTimes}
                setChecked={setShowConsecutiveTimes}
                label={t('Consecutive Times')}
              ></ControlledSwitch>
            </Grid>
            {type === 'room' && (
              <Grid item xs={12}>
                <ControlledSwitch
                  checked={showTravelTime}
                  setChecked={setShowTravelTime}
                  label={t('Travel Time')}
                ></ControlledSwitch>
              </Grid>
            )}
            {type === 'teacher' && (
              <Grid item xs={12}>
                <ControlledSwitch
                  checked={showDistinctSubjects}
                  setChecked={setShowDistinctSubjects}
                  label={t('Distinct Subjects')}
                ></ControlledSwitch>
              </Grid>
            )}
            {type !== 'room' && (
              <Grid item xs={12}>
                <ControlledSwitch
                  checked={showForbiddenCombination}
                  setChecked={setShowForbiddenCombination}
                  label={t('Forbidden Combination')}
                ></ControlledSwitch>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
