import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { doc, setDoc } from '@firebase/firestore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SessionContext } from 'contexts';
import { toast } from 'react-toastify';
import InfoIcon from '@mui/icons-material/Info';
import EmailIcon from '@mui/icons-material/Email';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import SendIcon from '@mui/icons-material/Send';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { db } from 'services';
import { blue, grey } from '@mui/material/colors';
import ActionAreaCard from './components/ActionAreaCard';
import MiniDrawerHome from 'components/MiniDrawerHome';
import BasicStandardSelect from 'components/BasicStandardSelect';
import { useTranslation } from 'react-i18next';
import AuthDialog from 'components/AuthDialog';
import SwipeableTextMobileStepper from 'components/SwipeableTextMobileStepper';
import i18n from 'i18n';
import { planFeatures } from 'configs/planFeatures';
import { Link } from 'react-router-dom';
import VideoPlayerDialog from 'components/VideoPlayerDialog';

interface Contact {
  name: string;
  email: string;
  category: string;
  message: string;
  sentAt: Date;
}

export default function Home() {
  const { t } = useTranslation();
  const history = useHistory();
  const { authUser } = useContext(SessionContext);
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const currentDate: Date = new Date();

  // Redirect if user is logged in
  authUser?.email !== undefined && history.push('/files');

  function handleGetStarted() {
    if (authUser?.email === undefined) {
      setAuthOpen(true);
      // history.push('/signin');
    } else {
      history.push('/files');
    }
  }

  function handleContact() {
    const name = (document?.getElementById('name') as HTMLInputElement)?.value;
    const email = (document?.getElementById('email') as HTMLInputElement)?.value;
    const message = (document?.getElementById('message') as HTMLInputElement)?.value;
    const sentAt = new Date();
    const contactData: Contact = { name: name, email: email, category: category, message: message, sentAt: sentAt };
    setDoc(doc(db, 'messages', sentAt.toUTCString() + ' ' + email), contactData);
    toast.success(t('Message sent successfully'));
  }

  const [category, setCategory] = useState<string>('');
  const categoryOptions = [
    t('Testimonial'),
    t('Sales and pricing'),
    t('Support'),
    t('Feature request'),
    t('Bug report'),
    t('Other'),
  ];

  const [authOpen, setAuthOpen] = useState(false);

  const languageOptions = [
    { code: 'BR', label: 'PT', description: 'Português (PT)' },
    { code: 'US', label: 'EN', description: 'English (US)' },
    { code: 'ES', label: 'ES', description: 'Español (ES)' },
    { code: 'DE', label: 'DE', description: 'Deutsch (DE)' },
  ];

  const [currentCountryCode, setCurrentCountryCode] = React.useState<string | undefined>(
    languageOptions.find((option) => option.label === i18n.language.toUpperCase())
      ? languageOptions.find((option) => option.label === i18n.language.toUpperCase())?.code
      : 'US'
  );

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  return (
    <Container
      maxWidth={false}
      style={{
        // background: 'linear-gradient(180deg, rgba(16,56,112,1) 0%, rgba(25,103,211,1) 50%, rgba(0,212,255,1) 100%)',
        // backgroundColor: '#1967d3',
        background: 'rgba(16,56,112,1)',
        backgroundColor: 'rgba(16,56,112,1)',
        padding: '0px',
      }}
    >
      <AuthDialog open={authOpen} setOpen={setAuthOpen} title={''} message={''} />
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_demo')}
        ></VideoPlayerDialog>
      )}
      <MiniDrawerHome
        currentCountryCode={currentCountryCode}
        setCurrentCountryCode={setCurrentCountryCode}
        languageOptions={languageOptions}
      />
      <Grid container padding={4} paddingTop={widthLessThan600 ? 7 : 14} alignItems="center">
        <Grid item lg={1}></Grid>
        <Grid item xs={12} lg={4}>
          <Grid container item xs={12} padding={4} justifyContent="flex-start">
            <Typography variant="h3" fontWeight="bold" color="white" component="span" display="inline">
              {t('Catch phrase 1')}&nbsp;
            </Typography>
            <Typography variant="h3" fontWeight="bold" color="#ffcb2b" component="span" display="inline">
              {t('Catch phrase 2')}&nbsp;
            </Typography>
            <Typography variant="h3" fontWeight="bold" color="white" component="span" display="inline">
              {t('Catch phrase aux')}
            </Typography>
            <Typography variant="h3" fontWeight="bold" color="white" component="span" display="inline">
              {t('Catch phrase 3')}
            </Typography>
          </Grid>
          <Grid container item xs={12} padding={4} justifyContent="center">
            <Typography variant="subtitle1" fontSize={24} lineHeight={1.2} color="white">
              {t('Optables description')}
            </Typography>
          </Grid>
          <Grid container item spacing={4} xs={12} padding={4}>
            <Grid item container xs={12} sm={6} justifyContent="center">
              <Button fullWidth size="large" variant="contained" color="secondary" onClick={handleGetStarted}>
                {t('Get started')}
              </Button>
            </Grid>
            <Grid item container xs={12} sm={6} justifyContent="center">
              <Button
                fullWidth
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => setVideoPlayerDialogOpen(true)}
              >
                {t('Watch demo')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={1}></Grid>
        <Grid item xs={12} lg={5} padding={2} sx={{ backgroundColor: '#ffffff', borderRadius: 1 }}>
          <SwipeableTextMobileStepper></SwipeableTextMobileStepper>
        </Grid>
        <Grid item lg={1}></Grid>
      </Grid>
      <section id="features">
        <Grid container item xs={12} justifyContent="center" paddingTop={widthLessThan600 ? 4 : 4}>
          <Paper elevation={3} sx={{ width: '80vw', backgroundColor: '#ffffff', borderRadius: 1 }}>
            <Grid container spacing={4} padding={4} justifyContent="space-around">
              <Grid container item xs={12} paddingTop={4} justifyContent="center">
                <Typography variant="h4" color={grey[700]} align="center" display="inline">
                  {t('Main Features')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <ActionAreaCard
                  imgPath="/img/features/awardWinning.jpg"
                  title={t('Award Winner')}
                  message={t('Award Winner detail')}
                ></ActionAreaCard>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <ActionAreaCard
                  imgPath="/img/features/free.jpg"
                  title={t('Free of Charge')}
                  message={t('Free of Charge detail')}
                ></ActionAreaCard>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <ActionAreaCard
                  imgPath="/img/features/flexible.jpg"
                  title={t('Flexible')}
                  message={t('Flexible detail')}
                ></ActionAreaCard>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <ActionAreaCard
                  imgPath="/img/features/easyToUse.jpg"
                  title={t('Easy to Use')}
                  message={t('Easy to Use detail')}
                ></ActionAreaCard>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </section>
      <section id="testmonials">
        <Grid container item xs={12} justifyContent="center" paddingTop={8}>
          <Paper elevation={5} sx={{ width: '80vw', backgroundColor: '#ffffff', borderRadius: 1 }}>
            <Grid container spacing={4} padding={4} justifyContent="space-around">
              <Grid container item xs={12} paddingTop={4} justifyContent="center">
                <Typography variant="h4" color={grey[700]} align="center" display="inline">
                  {t('Trusted')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={3} sx={{ backgroundColor: grey[700], borderRadius: 1 }}>
                  <CardActionArea>
                    <CardContent>
                      <Grid container item xs={12} padding={2}>
                        <Grid container item xs={12} md={3} lg={2} justifyContent="center">
                          <img
                            height="140"
                            width="140"
                            src="/img/testmonials/welbert_rodrigues_deelt.jpg"
                            alt="testmonial pearson 1"
                            style={{ borderRadius: 100 }}
                          />
                        </Grid>
                        <Grid container item xs={12} md={9} lg={10} justifyContent="center">
                          <Grid item xs={12}>
                            <Typography variant="h5" component="div" sx={{ color: grey[50] }}>
                              Welbert Rodrigues
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" sx={{ color: grey[50] }}>
                              {t('Head of the Eletrical Engineering Department - UFOP')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" fontStyle="italic" sx={{ color: grey[200] }}>
                              {`"${t('Depo 1')}"`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={3} sx={{ backgroundColor: grey[700], borderRadius: 1 }}>
                  <CardActionArea>
                    <CardContent>
                      <Grid container item xs={12} padding={2}>
                        <Grid container item xs={12} md={3} lg={2} justifyContent="center">
                          <img
                            height="140"
                            width="140"
                            src="/img/testmonials/mateus_satler_decsi.jpeg"
                            alt="testmonial pearson 2"
                            style={{ borderRadius: 100 }}
                          />
                        </Grid>
                        <Grid container item xs={12} md={9} lg={10} justifyContent="center">
                          <Grid item xs={12}>
                            <Typography variant="h5" component="div" sx={{ color: grey[50] }}>
                              Mateus Satler
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" sx={{ color: grey[50] }}>
                              {t('Head of the Computing and Systems Department - UFOP')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" fontStyle="italic" sx={{ color: grey[200] }}>
                              {`"${t('Depo 2')}"`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card elevation={3} sx={{ backgroundColor: grey[700], borderRadius: 1 }}>
                  <CardActionArea>
                    <CardContent>
                      <Grid container item xs={12} padding={2}>
                        <Grid container item xs={12} md={3} lg={2} justifyContent="center">
                          <img
                            height="140"
                            width="140"
                            src="/img/testmonials/felipe_cota_deelt.jpeg"
                            alt="testmonial pearson 3"
                            style={{ borderRadius: 100 }}
                          />
                        </Grid>
                        <Grid container item xs={12} md={9} lg={10} justifyContent="center">
                          <Grid item xs={12}>
                            <Typography variant="h5" component="div" sx={{ color: grey[50] }}>
                              Felipe Cota
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" sx={{ color: grey[50] }}>
                              {t('Professor at Eletrical Engineering Department - UFOP')}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" fontStyle="italic" sx={{ color: grey[200] }}>
                              {`"${t('Depo 3')}"`}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </section>
      <section id="pricing">
        <Grid container item xs={12} justifyContent="center" paddingTop={8}>
          <Paper elevation={3} sx={{ width: '80vw', backgroundColor: '#ffffff', borderRadius: 1 }}>
            <Grid container spacing={4} padding={4} justifyContent="space-around">
              <Grid container item xs={12} paddingTop={4} justifyContent="center">
                <Typography variant="h4" color={grey[700]} align="center" component="div">
                  {t('Pricing plans')}
                </Typography>
              </Grid>
              <Grid container item xs={12} md={4}>
                <Card sx={{ width: '100%', backgroundColor: grey[700], borderRadius: 1, justifyContent: 'center' }}>
                  {/* <CardActionArea> */}
                  <CardContent>
                    <Grid container item justifyContent="center" sx={{ color: grey[50] }}>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="h5" component="div" sx={{ color: grey[50] }}>
                          {t('Free')}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <div style={{ height: 20 }}></div>
                      </Grid>
                      {currentCountryCode === 'BR' ? (
                        <Grid container item xs={12} justifyContent="center" alignItems={'baseline'}>
                          <Typography component="div">R$</Typography>
                          <Typography paddingLeft={1} variant="h2" component="div">
                            0,00
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid container item xs={12} justifyContent="center" alignItems={'baseline'}>
                          <Typography component="div">USD</Typography>
                          <Typography paddingLeft={1} variant="h2" component="div">
                            0.00
                          </Typography>
                        </Grid>
                      )}
                      <Grid container item xs={12} justifyContent="center">
                        <div style={{ height: 20 }}></div>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Access to all features')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            2 {t('files')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Up to 100 lessons / file')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Up to 1 minute of running time')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Export formats: None')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} paddingTop={4}>
                        <Button fullWidth color="primary" variant="contained" onClick={handleGetStarted}>
                          {t('Get started')}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  {/* </CardActionArea> */}
                </Card>
              </Grid>
              <Grid container item xs={12} md={4}>
                <Card sx={{ width: '100%', backgroundColor: grey[700], borderRadius: 1, justifyContent: 'center' }}>
                  {/* <CardActionArea> */}
                  <CardContent>
                    <Grid container item justifyContent="center" sx={{ color: grey[50] }}>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="h5" component="div" sx={{ color: grey[50] }}>
                          {t('Basic')}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="body2" component="div" sx={{ textDecoration: 'line-through' }}>
                          {currentCountryCode === 'BR'
                            ? `R$ ${planFeatures.basic.fullPriceBRL}`
                            : `USD ${planFeatures.basic.fullPriceUSD}`}
                        </Typography>
                      </Grid>
                      {currentCountryCode === 'BR' ? (
                        <Grid container item xs={12} justifyContent="center" alignItems={'baseline'}>
                          <Typography component="div">R$</Typography>
                          <Typography paddingLeft={1} variant="h2" component="div">
                            {planFeatures.basic.priceBRL}
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid container item xs={12} justifyContent="center" alignItems={'baseline'}>
                          <Typography component="div">USD</Typography>
                          <Typography paddingLeft={1} variant="h2" component="div">
                            {planFeatures.basic.priceUSD}
                          </Typography>
                        </Grid>
                      )}
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="body2" component="div" sx={{ color: grey[50] }}>
                          {t('/ month')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Access to all features')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            10 {t('files')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Up to 300 lessons / file')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Up to 30 minutes of running time')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Export formats: .xlsx')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} paddingTop={4}>
                        <Button
                          fullWidth
                          color="secondary"
                          variant="contained"
                          // sx={{ backgroundColor: grey[200] }}
                          onClick={() => {
                            toast.warning(t('Please sign in and go to settings to subscribe'));
                            handleGetStarted();
                          }}
                        >
                          {t('Select plan')}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  {/* </CardActionArea> */}
                </Card>
              </Grid>
              <Grid container item xs={12} md={4}>
                <Card sx={{ width: '100%', backgroundColor: grey[700], borderRadius: 1, justifyContent: 'center' }}>
                  {/* <CardActionArea> */}
                  <CardContent>
                    <Grid container item justifyContent="center" sx={{ color: grey[50] }}>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="h5" component="div" sx={{ color: grey[50] }}>
                          {t('Pro')}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="body2" component="div" sx={{ textDecoration: 'line-through' }}>
                          {currentCountryCode === 'BR'
                            ? `R$ ${planFeatures.premium.fullPriceBRL}`
                            : `USD ${planFeatures.premium.fullPriceUSD}`}
                        </Typography>
                      </Grid>
                      {currentCountryCode === 'BR' ? (
                        <Grid container item xs={12} justifyContent="center" alignItems={'baseline'}>
                          <Typography component="div">R$</Typography>
                          <Typography paddingLeft={1} variant="h2" component="div">
                            {planFeatures.premium.priceBRL}
                          </Typography>
                        </Grid>
                      ) : (
                        <Grid container item xs={12} justifyContent="center" alignItems={'baseline'}>
                          <Typography component="div">USD</Typography>
                          <Typography paddingLeft={1} variant="h2" component="div">
                            {planFeatures.premium.priceUSD}
                          </Typography>
                        </Grid>
                      )}
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="body2" component="div" sx={{ color: grey[50] }}>
                          {t('/ month')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Access to all features')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Illimited files')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Illimited lessons / file')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Up to 1 hour of running time')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                          <CheckCircleIcon color="primary"></CheckCircleIcon>
                          <Typography variant="body2" sx={{ color: grey[200] }}>
                            {t('Export formats: .xslx .json .xml')}
                          </Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} paddingTop={4}>
                        <Button
                          fullWidth
                          color="secondary"
                          variant="contained"
                          // sx={{ backgroundColor: grey[200] }}
                          onClick={() => {
                            toast.warning(t('Please sign in and go to settings to subscribe'));
                            handleGetStarted();
                          }}
                        >
                          {t('Select plan')}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                  {/* </CardActionArea> */}
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </section>
      <section id="contact">
        <Grid container item xs={12} justifyContent="center" paddingTop={8}>
          <Paper elevation={3} sx={{ width: '80vw', backgroundColor: '#ffffff', borderRadius: 1 }}>
            <Grid container spacing={4} padding={4} justifyContent="space-around">
              <Grid container item xs={12} paddingTop={4} justifyContent="center">
                <Typography variant="h4" color={grey[700]} align="center" display="inline">
                  {t('Contact')}
                </Typography>
              </Grid>
              <Grid container item xs={12} md={6}>
                <Card sx={{ width: '100%', backgroundColor: grey[700], borderRadius: 1, justifyContent: 'center' }}>
                  <CardContent>
                    <Grid container item justifyContent="center">
                      <Grid container item xs={12} justifyContent="center">
                        <Typography variant="body2" component="div" sx={{ color: grey[50] }}>
                          {t('Contact detail')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} paddingTop={2}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <AssignmentIndIcon sx={{ color: grey[50], mr: 2, my: 0.5 }} />
                          <TextField
                            translate="no"
                            fullWidth
                            sx={{ input: { color: grey[50] }, label: { color: grey[50] } }}
                            id="name"
                            label={t('Name')}
                            color="secondary"
                            variant="standard"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} paddingTop={2}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <EmailIcon sx={{ color: grey[50], mr: 2, my: 0.5 }} />
                          <TextField
                            translate="no"
                            fullWidth
                            sx={{ input: { color: grey[50] }, label: { color: grey[50] } }}
                            id="email"
                            label={t('Email')}
                            color="secondary"
                            variant="standard"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} paddingTop={2}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                          <InfoIcon sx={{ color: grey[50], mr: 2, my: 0.5 }} />
                          <BasicStandardSelect
                            current={category}
                            setCurrent={setCategory}
                            options={categoryOptions}
                            label={t('Contact reason')}
                          ></BasicStandardSelect>
                        </Box>
                      </Grid>
                      <Grid item xs={12} paddingTop={2}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <InsertCommentIcon sx={{ color: grey[50], mr: 2, my: 0.5 }} />
                          <TextField
                            translate="no"
                            fullWidth
                            multiline
                            rows={5}
                            InputProps={{
                              style: { color: grey[50] },
                            }}
                            InputLabelProps={{
                              style: { color: grey[50] },
                            }}
                            label={t('Message')}
                            color="secondary"
                            variant="standard"
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} paddingTop={2}>
                        <Button
                          startIcon={<SendIcon />}
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={handleContact}
                        >
                          {t('Send')}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </section>
      <Grid container item xs={12} justifyContent="center" alignContent={'center'} paddingTop={8}>
        <Paper sx={{ width: '100vw', backgroundColor: grey[700] }}>
          <Grid container spacing={1} padding={2} justifyContent="space-around" alignContent={'center'}>
            <Grid item xs={4}>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: 'initial',
                    // px: 2.5,
                    color: grey[50],
                  }}
                  onClick={() => history.push('/')}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                    }}
                  >
                    {<img height={20} width={20} src="/img/logoWhite.png" alt="Optables logo" />}
                  </ListItemIcon>
                  <ListItemText primary={'Optables'} sx={{ paddingLeft: 1 }} />
                </ListItemButton>
              </ListItem>
            </Grid>
            <Grid container item xs={4} spacing={1} justifyContent="center" alignContent={'center'}>
              <Grid item xs={4} sm={3} md={2} lg={1}>
                <IconButton
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: blue[500],
                    borderRadius: 100,
                    '&:hover': {
                      backgroundColor: blue[800],
                    },
                  }}
                  onClick={() =>
                    window.open(
                      'https://www.youtube.com/watch?v=BYyh4jxCgAo&list=PLsfh2zkrGs9mbQTzUeGFqkSyfIsjz4BIx&index=1&ab_channel=GeorgeFonseca'
                    )
                  }
                >
                  <YouTubeIcon sx={{ color: grey[200] }} />
                </IconButton>
              </Grid>
              <Grid item xs={4} sm={3} md={2} lg={1}>
                <IconButton
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: blue[500],
                    borderRadius: 100,
                    '&:hover': {
                      backgroundColor: blue[800],
                    },
                  }}
                >
                  <InstagramIcon sx={{ color: grey[200] }} />
                </IconButton>
              </Grid>
              <Grid item xs={4} sm={3} md={2} lg={1}>
                <IconButton
                  sx={{
                    width: 32,
                    height: 32,
                    backgroundColor: blue[500],
                    borderRadius: 100,
                    '&:hover': {
                      backgroundColor: blue[800],
                    },
                  }}
                >
                  <FacebookIcon sx={{ color: grey[200] }} />
                </IconButton>
              </Grid>
              {!widthLessThan600 && (
                <Grid item xs={4} sm={3} md={2} lg={1}>
                  <IconButton
                    sx={{
                      width: 32,
                      height: 32,
                      backgroundColor: blue[500],
                      borderRadius: 100,
                      '&:hover': {
                        backgroundColor: blue[800],
                      },
                    }}
                  >
                    <LinkedInIcon sx={{ color: grey[200] }} />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <Grid container item xs={4} justifyContent="center">
              <Grid container item xs={12} justifyContent="flex-end">
                <Typography variant="body2" sx={{ color: grey[200] }}>
                  {`© ${currentDate.getFullYear()} Optables`}
                </Typography>
              </Grid>
              <Grid container item xs={12} justifyContent="flex-end">
                <Typography variant="body2" sx={{ color: grey[200] }}>
                  <Link to={'terms'} style={{ color: 'inherit' }}>
                    {t('Terms')}
                  </Link>
                  &nbsp;|&nbsp;
                  <Link to={'privacy'} style={{ color: 'inherit' }}>
                    {t('Privacy')}
                  </Link>
                  {/* {t('Terms | Privacy')} */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
}
