import { DialogContentText, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';

export default function AccordionTableDescription(props: any) {
  const data = props.data;
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
        <TableBody>
          {data.map((row: any) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                <DialogContentText fontWeight={'bold'}>{row.name}</DialogContentText>
              </TableCell>
              <TableCell component="th" scope="row">
                <DialogContentText>{row.description}</DialogContentText>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
