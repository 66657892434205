import {
  Avatar,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  IconButton,
  Input,
  Link,
  Paper,
  Stack,
  Typography,
  colors,
} from '@material-ui/core';
import { Alarm, InsertDriveFile } from '@material-ui/icons';
import FormTitle from 'components/FormTitle';
import MiniDrawer from 'components/MiniDrawer';
import { SessionContext } from 'contexts';
import { ChangeEventHandler, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createCheckoutSession } from '../../stripe/createCheckoutSession';
import useUserPlan from '../../stripe/useUserPlan';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { grey } from '@mui/material/colors';
import { theme } from 'styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { planFeatures } from 'configs/planFeatures';
import UserAvatarFile from './UserAvatarFile';

export default function Settings() {
  const { user } = useContext(SessionContext);
  const userPlan = useUserPlan(user);
  const [loadingPro, setLoadingPro] = useState(false);
  const [loadingPremium, setLoadingPremium] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    // Handle file upload logic here
    if (selectedFile) {
      // Perform additional logic or API calls for file upload
    }
  };

  function delay(seconds: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000); // Multiply by 1000 to convert seconds to milliseconds
    });
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={() => {}}>
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/material-ui/getting-started/installation/"
      onClick={() => {}}
    >
      Profile
    </Link>,
  ];

  const { t } = useTranslation();
  return (
    <Container
      maxWidth={false}
      style={{ padding: '56px 8px 8px 65px', minHeight: '100vh', backgroundColor: colors.grey[50] }}
    >
      <MiniDrawer />
      {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs> */}
      <Grid container justifyContent="center">
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
          <Grid container spacing={1}>
            <Grid
              className="file-data-form"
              container
              item
              sm={12}
              // md={6}
              spacing={1}
              alignItems={'start'}
              alignContent={'start'}
            >
              {/* <Paper elevation={3} sx={{ padding: '8px', marginLeft: '8px', marginTop: '8px' }}>
            <Grid container spacing={1}> */}
              <Grid item xs={12}>
                <Grid
                  container
                  item
                  xs={12}
                  justifyContent={'space-between'}
                  padding={2}
                  spacing={1}
                  flexDirection={'row'}
                >
                  <Grid item container xs={12} spacing={1} padding={1}>
                    <Grid item flexGrow={1}>
                      <Typography variant="body1" gutterBottom sx={{ color: '#1976D2' }}>
                        {t('Profile')}
                      </Typography>
                      <Divider color="#1976D2" />
                    </Grid>
                  </Grid>
                </Grid>
                {/* <FormTitle title={t('Profile')} icon={<InsertDriveFile />} /> */}
                <Grid item container justifyContent={'center'} alignItems={'center'}>
                  <UserAvatarFile user={user} />
                  {/* <IconButton size="small" aria-label="account of current user" color="inherit">
                  <Avatar sx={{ width: 96, height: 96 }} alt={user?.name} src={user?.photoURL} />
                </IconButton> */}
                </Grid>
                <Grid item container justifyContent={'center'} alignItems={'center'}>
                  <Typography variant="h4" color={grey[700]}>
                    {user?.name}
                  </Typography>
                </Grid>
                <Grid item container justifyContent={'center'} alignItems={'center'}>
                  <Typography fontSize={14}>{user?.email}</Typography>
                </Grid>
                <Grid container spacing={4} paddingTop={4} justifyContent="space-evenly">
                  <Grid container item xs={12} justifyContent="center">
                    {/* <FormTitle title={t('Pricing plans')} icon={<InsertDriveFile />} /> */}
                    {/* <Typography variant="h4" color={grey[700]} align="center" component="div">
                    {t('Pricing plans')}
                  </Typography> */}
                  </Grid>
                  <Grid container marginLeft={4} marginRight={4} paddingY={4} spacing={4} justifyContent="center">
                    <Grid container item xs={12} md={4} xl={3}>
                      <Card
                        sx={{
                          width: '100%',
                          // backgroundColor: grey[700],
                          borderRadius: 1,
                          border:
                            userPlan === 'free' ? `4px solid ${theme.palette.primary.main}` : `1px solid ${grey[400]}`,
                          justifyContent: 'center',
                        }}
                      >
                        {/* <CardActionArea> */}
                        <CardContent>
                          <Grid container item justifyContent="center" sx={{ color: grey[700] }}>
                            <Grid container item xs={12} justifyContent="center">
                              <Typography variant="h5" component="div">
                                {t('Free')}
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center">
                              <div style={{ height: 20 }}></div>
                            </Grid>
                            {user?.countryCode === 'BR' ? (
                              <Grid
                                container
                                item
                                xs={12}
                                justifyContent="center"
                                alignItems={'baseline'}
                                sx={{ color: grey[900] }}
                              >
                                <Typography component="div">R$</Typography>
                                <Typography paddingLeft={1} variant="h2" component="div">
                                  0,00
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                item
                                xs={12}
                                justifyContent="center"
                                alignItems={'baseline'}
                                sx={{ color: grey[900] }}
                              >
                                <Typography component="div">USD</Typography>
                                <Typography paddingLeft={1} variant="h2" component="div">
                                  0.00
                                </Typography>
                              </Grid>
                            )}
                            <Grid container item xs={12} justifyContent="center">
                              <div style={{ height: 20 }}></div>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2">{t('Access to all features')}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2">2 {t('files')}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2">{t('Up to 100 lessons / file')}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2">{t('Up to 1 minute of running time')}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2">{t('Export formats: None')}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} paddingTop={4}>
                              {userPlan === 'free' ? (
                                <Button fullWidth color="primary">
                                  {t('Current plan')}
                                </Button>
                              ) : (
                                <Button
                                  fullWidth
                                  color="primary"
                                  variant={'outlined'}
                                  onClick={() => {
                                    window.open('https://billing.stripe.com/p/login/00g2aUadP7tA7qUfYY', '_blank');
                                  }}
                                >
                                  {t('Downgrade to free')}
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                        {/* </CardActionArea> */}
                      </Card>
                    </Grid>
                    <Grid container item xs={12} md={4} xl={3}>
                      <Card
                        sx={{
                          width: '100%',
                          borderRadius: 1,
                          border:
                            userPlan === 'basic' ? `4px solid ${theme.palette.primary.main}` : `1px solid ${grey[400]}`,
                          justifyContent: 'center',
                        }}
                      >
                        {/* <CardActionArea> */}
                        <CardContent>
                          <Grid container item justifyContent="center" sx={{ color: grey[700] }}>
                            <Grid container item xs={12} justifyContent="center">
                              <Typography variant="h5" component="div">
                                {t('Basic')}
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center">
                              <Typography variant="body2" component="div" sx={{ textDecoration: 'line-through' }}>
                                {user?.countryCode === 'BR'
                                  ? `R$ ${planFeatures.basic.fullPriceBRL}`
                                  : `USD ${planFeatures.basic.fullPriceUSD}`}
                              </Typography>
                            </Grid>
                            {user?.countryCode === 'BR' ? (
                              <Grid
                                container
                                item
                                xs={12}
                                justifyContent="center"
                                alignItems={'baseline'}
                                sx={{ color: grey[900] }}
                              >
                                <Typography component="div">R$</Typography>
                                <Typography paddingLeft={1} variant="h2" component="div">
                                  {planFeatures.basic.priceBRL}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                item
                                xs={12}
                                justifyContent="center"
                                alignItems={'baseline'}
                                sx={{ color: grey[900] }}
                              >
                                <Typography component="div">USD</Typography>
                                <Typography paddingLeft={1} variant="h2" component="div">
                                  {planFeatures.basic.priceUSD}
                                </Typography>
                              </Grid>
                            )}
                            <Grid container item xs={12} justifyContent="center">
                              <Typography variant="body2" component="div">
                                {t('/ month')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2">{t('Access to all features')}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2">10 {t('files')}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2">{t('Up to 300 lessons / file')}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2">{t('Up to 30 minutes of running time')}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2">{t('Export formats: .xlsx')}</Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} paddingTop={4}>
                              {userPlan === 'basic' ? (
                                <Button
                                  fullWidth
                                  // color="secondary"
                                  variant="contained"
                                  // sx={{ backgroundColor: grey[200] }}
                                  onClick={() =>
                                    window.open('https://billing.stripe.com/p/login/00g2aUadP7tA7qUfYY', '_blank')
                                  }
                                >
                                  {t('Manage subscription')}
                                </Button>
                              ) : userPlan === 'free' ? (
                                <LoadingButton
                                  loading={loadingPro}
                                  loadingPosition="start"
                                  startIcon={null}
                                  fullWidth
                                  onClick={async () => {
                                    setLoadingPro(true);
                                    createCheckoutSession(user?.uid as string, 'basic');
                                    await delay(10);
                                    setLoadingPro(false);
                                  }}
                                  variant="outlined"
                                >
                                  {loadingPro ? t('Redirecting') : t('Upgrade to basic')}
                                </LoadingButton>
                              ) : (
                                <Button
                                  fullWidth
                                  color="primary"
                                  variant={'outlined'}
                                  onClick={() => {
                                    window.open('https://billing.stripe.com/p/login/00g2aUadP7tA7qUfYY', '_blank');
                                  }}
                                >
                                  {t('Downgrade to basic')}
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                        {/* </CardActionArea> */}
                      </Card>
                    </Grid>
                    <Grid container item xs={12} md={4} xl={3}>
                      <Card
                        sx={{
                          width: '100%',
                          borderRadius: 1,
                          border:
                            userPlan === 'premium'
                              ? `4px solid ${theme.palette.primary.main}`
                              : `1px solid ${grey[400]}`,
                          justifyContent: 'center',
                        }}
                      >
                        {/* <CardActionArea> */}
                        <CardContent>
                          <Grid container item justifyContent="center" sx={{ color: grey[700] }}>
                            <Grid container item xs={12} justifyContent="center">
                              <Typography variant="h5" component="div">
                                {t('Premium')}
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent="center">
                              <Typography variant="body2" component="div" sx={{ textDecoration: 'line-through' }}>
                                {user?.countryCode === 'BR'
                                  ? `R$ ${planFeatures.premium.fullPriceBRL}`
                                  : `USD ${planFeatures.premium.fullPriceUSD}`}
                              </Typography>
                            </Grid>
                            {user?.countryCode === 'BR' ? (
                              <Grid
                                container
                                item
                                xs={12}
                                justifyContent="center"
                                alignItems={'baseline'}
                                sx={{ color: grey[900] }}
                              >
                                <Typography component="div">R$</Typography>
                                <Typography paddingLeft={1} variant="h2" component="div">
                                  {planFeatures.premium.priceBRL}
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid
                                container
                                item
                                xs={12}
                                justifyContent="center"
                                alignItems={'baseline'}
                                sx={{ color: grey[900] }}
                              >
                                <Typography component="div">USD</Typography>
                                <Typography paddingLeft={1} variant="h2" component="div">
                                  {planFeatures.premium.priceUSD}
                                </Typography>
                              </Grid>
                            )}
                            <Grid container item xs={12} justifyContent="center">
                              <Typography variant="body2" component="div">
                                {t('/ month')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2" sx={{ color: grey[700] }}>
                                  {t('Access to all features')}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2" sx={{ color: grey[700] }}>
                                  {t('Illimited files')}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2" sx={{ color: grey[700] }}>
                                  {t('Illimited lessons / file')}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2" sx={{ color: grey[700] }}>
                                  {t('Up to 1 hour of running time')}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1} paddingTop={2} direction={'row'} alignItems="center">
                                <CheckCircleIcon color="primary"></CheckCircleIcon>
                                <Typography variant="body2" sx={{ color: grey[700] }}>
                                  {t('Export formats: .xslx .json .xml')}
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} paddingTop={4}>
                              {userPlan === 'premium' ? (
                                <Button
                                  fullWidth
                                  // color="secondary"
                                  variant="contained"
                                  // sx={{ backgroundColor: grey[200] }}
                                  onClick={() =>
                                    window.open('https://billing.stripe.com/p/login/00g2aUadP7tA7qUfYY', '_blank')
                                  }
                                >
                                  {t('Manage subscription')}
                                </Button>
                              ) : (
                                <LoadingButton
                                  loading={loadingPremium}
                                  loadingPosition="start"
                                  startIcon={null}
                                  fullWidth
                                  onClick={async () => {
                                    setLoadingPremium(true);
                                    createCheckoutSession(user?.uid as string, 'premium');
                                    await delay(10);
                                    setLoadingPremium(false);
                                  }}
                                  variant="outlined"
                                >
                                  {loadingPremium ? t('Redirecting') : t('Upgrade to premium')}
                                </LoadingButton>
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                        {/* </CardActionArea> */}
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid
            className="file-days-times-form"
            container
            item
            sm={12}
            md={6}
            spacing={1}
            alignItems={'start'}
            alignContent={'start'}
          >
            <Grid item xs={12}>
              <FormTitle title={t('Appearence')} icon={<Alarm />} />
            </Grid>
          </Grid> */}
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
}
