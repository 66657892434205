import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { grey } from '@material-ui/core/colors';

export default function ActionAreaCard(props: any) {
  const imgPath = props.imgPath;
  const title = props.title;
  const message = props.message;
  return (
    <Card sx={{ backgroundColor: grey[700] }}>
      <CardActionArea>
        <CardMedia component="img" height="140" image={imgPath} alt="green iguana" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div" sx={{ color: grey[50] }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ color: grey[200] }}>
            {message}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
