import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  Typography,
  ToggleButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Card,
  Box,
  Tabs,
  Tab,
  Tooltip,
  Stack,
  IconButton,
  colors,
  Divider,
  Accordion,
  AccordionSummary,
  DialogContentText,
  AccordionDetails,
  CardContent,
  useMediaQuery,
} from '@material-ui/core';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { toast } from 'react-toastify';
import { doc, setDoc, deleteDoc, updateDoc, getDoc } from '@firebase/firestore';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { red, grey, green, orange } from '@mui/material/colors';
import { renderToStaticMarkup } from 'react-dom/server';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import SchoolIcon from '@mui/icons-material/School';
import { CoPresent } from '@mui/icons-material';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';

import { db } from 'services';
import { SessionContext } from 'contexts';
import MiniDrawer from 'components/MiniDrawer';
import DraggableDialog from 'components/DraggableDialog';
import LinearWithValueLabel from 'components/LinearWithValueLabel';
import DefectsDialog from './components/DefectsDialog';
import ConfigsDialog, {
  DisplayTypesMap,
  PriorityOptionsMap,
  RunningTimeSecondsMap,
  SolverTypesMap,
} from './components/ConfigsDialog';
import ResourcesSoln from './components/ResourcesSoln';
import OnboardingContent from 'components/OnboardingContent';
import Onboarding from 'components/Onboarding';
import FormTitle from 'components/FormTitle';
import ExportDialog from './components/ExportDialog';
import ImportAssignmentsDialog from './components/ImportAssignmentsDialog';
import useUserPlan from 'stripe/useUserPlan';
import PlanInfoDialog from 'components/PlanInfoDialog';
import { planFeatures } from 'configs/planFeatures';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { ArrowBack, Error, Help } from '@material-ui/icons';
import VideoPlayerDialog from 'components/VideoPlayerDialog';

export interface IPublicSolution {
  fileName: string;
  solutionUrl: string;
  url: string;
}

export interface IAssignment {
  lessonRef: string;
  resources: string[];
  timeslots: string[];
  // timeslotsLocked: string[];
}

export interface IDefect {
  appliesTo: string;
  description: string;
  type: string;
  times: number;
  weight: number;
}

export interface ISolution {
  assignments: IAssignment[];
  lockedAssignments: string[];
  defects: IDefect[];

  cost: number;
  creator: string;
  randomSeed: number;
  remarks: string;
  runningTime: number;
  solver: string;
  threads: number;
  warmStartCost: number;
  status: string; // Optimal - Up to Date - Processing - Outdated - Unsolved
  lowerBound: number; // Model proven minimum possible cost
  numConstraints: number;
  display: string;

  name: string;
  createdAt: Date;
  updatedAt: Date;

  clashesClasses: string;
  workloadClasses: string;
  workingDaysClasses: string;
  idleWindowClasses: string;
  dailyWorkloadClasses: string;
  distinctSubjectsClasses: string;
  forbiddenCombinationClasses: string;
  unavailableTimesClasses: string;
  undesiredTimesClasses: string;

  clashesTeachers: string;
  workloadTeachers: string;
  workingDaysTeachers: string;
  idleWindowTeachers: string;
  dailyWorkloadTeachers: string;
  distinctSubjectsTeachers: string;
  forbiddenCombinationTeachers: string;
  unavailableTimesTeachers: string;
  undesiredTimesTeachers: string;

  clashesRooms: string;
  workloadRooms: string;
  workingDaysRooms: string;
  idleWindowRooms: string;
  dailyWorkloadRooms: string;
  distinctSubjectsRooms: string;
  forbiddenCombinationRooms: string;
  unavailableTimesRooms: string;
  undesiredTimesRooms: string;

  unavailableTimesSubjects: string;
  undesiredTimesSubjects: string;
  assignTimes: string;
  assignResources: string;
  split: string;
  daysBetweenLessons: string;
  predefinedTimes: string;
  simultaneousWith: string;
  notSimultaneousWith: string;
  occurBefore: string;

  restBetweenDaysClasses: string;
  roomChangesDayClasses: string;
  consecutiveTimesClasses: string;
  restBetweenDaysTeachers: string;
  roomChangesDayTeachers: string;
  consecutiveTimesTeachers: string;
  consecutiveTimesRooms: string;
  travelTimeRooms: string;
}

export default function Solution(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, file, resources, subjects, lessons, solutions, ownerEmail, shareMode } = useContext(SessionContext);
  const userPlan = useUserPlan(user);
  const [showPlanLimitDialog, setShowPlanLimitDialog] = useState(false);
  const [planLimitDialogShown, setPlanLimitDialogShown] = useState(false);
  const widthLessThan1500 = useMediaQuery('(max-width:1500px)');

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  if (file?.name === undefined) {
    history.push('/files');
    toast.warning(t('Select a file before creating timetables'));
  } else if (resources === undefined || resources.filter((res) => res.type === 'class').length === 0) {
    history.push('/classes');
    toast.warning(t('Setup classes before creating timetables'));
  } else if (lessons === undefined || lessons.length === 0) {
    history.push('/lessons');
    toast.warning(t('Setup lessons before creating timetables'));
  }

  const solutionsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/solutions';

  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];

  const priorityOptionsMap: PriorityOptionsMap = {};
  priorityOptionsMap['Disabled'] = 'Disabled';
  priorityOptionsMap['Very low'] = 'Very low';
  priorityOptionsMap['Very Low'] = 'Very low';
  priorityOptionsMap['Low'] = 'Low';
  priorityOptionsMap['Average'] = 'Average';
  priorityOptionsMap['High'] = 'High';
  priorityOptionsMap['Very high'] = 'Very high';
  priorityOptionsMap['Very High'] = 'Very high';
  priorityOptionsMap['Deshabilitado'] = 'Disabled';
  priorityOptionsMap['Muito baixo'] = 'Very low';
  priorityOptionsMap['Baixo'] = 'Low';
  priorityOptionsMap['Normal'] = 'Average';
  priorityOptionsMap['Alto'] = 'High';
  priorityOptionsMap['Muito alto'] = 'Very high';

  const priorityOptions = [t('Disabled'), t('Very low'), t('Low'), t('Average'), t('High'), t('Very high')];

  const [selectedRes, setSelectedRes] = React.useState<string>(
    resources === undefined || resources.filter((res) => res.type === 'class').length === 0
      ? ''
      : resources
          .filter((res) => res.type === 'class')
          .sort((a, b) => {
            return a.name > b.name ? 1 : -1;
          })[0].name
  );

  const [previousName, setPreviousName] = React.useState<string>(
    history.location.state !== undefined
      ? (JSON.parse(history.location.state as string) as ISolution).name
      : `${t('Auto')} ${
          user?.countryCode === 'BR'
            ? dayjs(new Date().toString()).locale(ptBR).format('DD [de] MMMM [de] YYYY [às] HH:mm[h]')
            : dayjs(new Date().toString()).format('MMMM DD,YY[ at ]hh:mma')
        }`
  );

  const getRuntimeSuggestion = () => {
    if (lessons.length < 50) {
      return 60;
    } else if (lessons.length >= 50 && lessons.length < 200) {
      return 300;
    } else {
      return 3600;
    }
  };

  const runtimeSuggestion = getRuntimeSuggestion();

  const checkRuntimeSuggestion = () => {
    if (userPlan === 'courtesy') return;
    if (
      history.location.state === undefined &&
      !showPlanLimitDialog &&
      !planLimitDialogShown &&
      ((userPlan === 'free' && runtimeSuggestion > planFeatures.free.maxRuntime) ||
        (userPlan === 'basic' && runtimeSuggestion > planFeatures.basic.maxRuntime))
    ) {
      setShowPlanLimitDialog(true);
    }
  };

  checkRuntimeSuggestion();

  function getPlanRuntimeSuggestion() {
    if (userPlan === 'free' && runtimeSuggestion > planFeatures.free.maxRuntime) return planFeatures.free.maxRuntime;
    else if (userPlan === 'basic' && runtimeSuggestion > planFeatures.basic.maxRuntime)
      return planFeatures.basic.maxRuntime;
    else return getRuntimeSuggestion();
  }

  const [solution, setSolution] = React.useState<ISolution>(
    history.location.state !== undefined
      ? (JSON.parse(history.location.state as string) as ISolution)
      : {
          assignments: [],
          lockedAssignments: [],
          defects: [],

          cost: -1,
          creator: 'optables',
          randomSeed: -1,
          remarks: '',
          runningTime: getPlanRuntimeSuggestion(),
          solver: 'Fast',
          threads: -1,
          warmStartCost: -1,
          status: 'Empty',
          lowerBound: 0,
          numConstraints: -1,
          display: 'Compact',

          name: previousName,
          createdAt: new Date(),
          updatedAt: new Date(),

          clashesClasses: priorityOptionsMap[priorityOptions[5]],
          workloadClasses: priorityOptionsMap[priorityOptions[5]],
          workingDaysClasses: priorityOptionsMap[priorityOptions[3]],
          idleWindowClasses: priorityOptionsMap[priorityOptions[3]],
          dailyWorkloadClasses: priorityOptionsMap[priorityOptions[3]],
          distinctSubjectsClasses: priorityOptionsMap[priorityOptions[3]],
          forbiddenCombinationClasses: priorityOptionsMap[priorityOptions[3]],
          unavailableTimesClasses: priorityOptionsMap[priorityOptions[5]],
          undesiredTimesClasses: priorityOptionsMap[priorityOptions[1]],
          clashesTeachers: priorityOptionsMap[priorityOptions[5]],
          workloadTeachers: priorityOptionsMap[priorityOptions[5]],
          workingDaysTeachers: priorityOptionsMap[priorityOptions[1]],
          idleWindowTeachers: priorityOptionsMap[priorityOptions[1]],
          dailyWorkloadTeachers: priorityOptionsMap[priorityOptions[1]],
          distinctSubjectsTeachers: priorityOptionsMap[priorityOptions[1]],
          forbiddenCombinationTeachers: priorityOptionsMap[priorityOptions[1]],
          unavailableTimesTeachers: priorityOptionsMap[priorityOptions[5]],
          undesiredTimesTeachers: priorityOptionsMap[priorityOptions[1]],
          clashesRooms: priorityOptionsMap[priorityOptions[5]],
          workloadRooms: priorityOptionsMap[priorityOptions[5]],
          workingDaysRooms: priorityOptionsMap[priorityOptions[3]],
          idleWindowRooms: priorityOptionsMap[priorityOptions[3]],
          dailyWorkloadRooms: priorityOptionsMap[priorityOptions[3]],
          distinctSubjectsRooms: priorityOptionsMap[priorityOptions[3]],
          forbiddenCombinationRooms: priorityOptionsMap[priorityOptions[3]],
          unavailableTimesRooms: priorityOptionsMap[priorityOptions[5]],
          undesiredTimesRooms: priorityOptionsMap[priorityOptions[1]],
          unavailableTimesSubjects: priorityOptionsMap[priorityOptions[5]],
          undesiredTimesSubjects: priorityOptionsMap[priorityOptions[1]],
          assignTimes: priorityOptionsMap[priorityOptions[5]],
          assignResources: priorityOptionsMap[priorityOptions[5]],
          split: priorityOptionsMap[priorityOptions[5]],
          daysBetweenLessons: priorityOptionsMap[priorityOptions[5]],
          predefinedTimes: priorityOptionsMap[priorityOptions[5]],
          simultaneousWith: priorityOptionsMap[priorityOptions[5]],
          notSimultaneousWith: priorityOptionsMap[priorityOptions[5]],
          occurBefore: priorityOptionsMap[priorityOptions[5]],

          restBetweenDaysClasses: priorityOptionsMap[priorityOptions[1]],
          roomChangesDayClasses: priorityOptionsMap[priorityOptions[1]],
          consecutiveTimesClasses: priorityOptionsMap[priorityOptions[1]],
          restBetweenDaysTeachers: priorityOptionsMap[priorityOptions[1]],
          roomChangesDayTeachers: priorityOptionsMap[priorityOptions[1]],
          consecutiveTimesTeachers: priorityOptionsMap[priorityOptions[1]],
          consecutiveTimesRooms: priorityOptionsMap[priorityOptions[1]],
          travelTimeRooms: priorityOptionsMap[priorityOptions[1]],
        }
  );

  let solverTypesMap: SolverTypesMap = {};
  solverTypesMap[t('Optimal')] = 'Optimal';
  solverTypesMap[t('Fast')] = 'Fast';

  let displayTypesMap: DisplayTypesMap = {};
  displayTypesMap[t('Compact')] = 'Compact';
  displayTypesMap[t('Individual tables')] = 'Individual Tables';

  async function submitForm(redirect: boolean) {
    if (!validateInput()) return;
    try {
      solution.name = (document.getElementById('name') as HTMLInputElement).value;
      const newSolution: ISolution = { ...solution };
      newSolution.status = redirect ? status : 'Processing';
      newSolution.createdAt = new Date();
      newSolution.updatedAt = new Date();
      newSolution.lockedAssignments = selectedCells;
      setDoc(doc(db, solutionsPath, solution.name), newSolution).then(() => {
        if (redirect) {
          history.location.state !== undefined
            ? toast.success(solution.name + t(' updated sucessfully'))
            : toast.success(solution.name + t(' created sucessfully'));
          history.push('/solutions');
        }
      });
      // Update file stats
      updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      //Delete old record and update references if name has changed
      if (previousName !== '' && previousName !== solution.name) {
        deleteDoc(doc(db, solutionsPath, previousName));
      }
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  }

  function afterSolve() {
    setSolving(false);
    const docRef = doc(db, solutionsPath, solution.name);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        setSolution(docSnap.data() as ISolution);
        setStatus(docSnap.data().status);
      } else {
        toast.error(t('No such document'));
      }
    });
  }

  async function runSolver() {
    const path = (solutionsPath + '/' + solution.name).replaceAll(' ', '_');
    const axios = require('axios').default;
    axios
      .get(`https://optables.com.br:3001/?path=${path}`)
      .then(function (response: any) {
        // handle success
        afterSolve();
      })
      .catch(function (error: any) {
        // handle error
        // afterSolve('ERROR');
      })
      .then(function () {
        // always executed
        afterSolve();
      });
  }

  let mapRunningTimeSecondsToLabels: RunningTimeSecondsMap = {};
  mapRunningTimeSecondsToLabels[60] = t('1 minute');
  mapRunningTimeSecondsToLabels[300] = t('5 minutes');
  mapRunningTimeSecondsToLabels[1800] = t('30 minutes');
  mapRunningTimeSecondsToLabels[3600] = t('1 hour');
  mapRunningTimeSecondsToLabels[7200] = t('2 hours');
  mapRunningTimeSecondsToLabels[36000] = t('10 hours');

  const solve = () => {
    try {
      setStatus('Processing');
      submitForm(false).then(() => {
        localStorage.setItem(solution.name, new Date().getTime() + '');
        runSolver();
        toast.success(
          t('Timetable will be displayed within') + ' ' + mapRunningTimeSecondsToLabels[solution.runningTime]
        );
      });
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  };

  const [solving, setSolving] = React.useState<boolean>(solution.status === 'Processing');
  const [status, setStatus] = React.useState<string>(solution.status);

  const copy = () => {
    try {
      //Validation and newName adjustments
      const name = (document.getElementById('name') as HTMLInputElement).value;
      const newName: string = t('Copy of ') + name;
      if (solutions.map((solution) => solution.name).includes(newName)) {
        toast.error(newName + t(' already registered'));
        return;
      }
      let newSolution: ISolution = { ...solution };
      newSolution.createdAt = new Date();
      newSolution.updatedAt = new Date();
      newSolution.name = newName;
      newSolution.lockedAssignments = selectedCells;

      setDoc(doc(db, solutionsPath, newName), newSolution).then(() => {
        toast.success(name + t(' copied sucessfully'));
        history.push('/solutions');
      });
      // Update file stats
      updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  };

  //#region States and Tables

  const [selectedCells, setSelectedCells] = React.useState<string[]>(solution.lockedAssignments);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let contSelected = 0;
  let contSelectedAtDay: number[] = [];
  days.forEach((element) => {
    contSelectedAtDay.push(0);
  });
  let contSelectedAtTime: number[] = [];
  times.forEach((element) => {
    contSelectedAtTime.push(0);
  });

  let cells: any[] = [];
  resources.forEach((res, r_index) => {
    if (solution.display === 'Compact') {
      times.forEach((time, t_index) => {
        days.forEach((day, d_index) => {
          cells.push(res.name + ':' + d_index + ':' + t_index);
        });
      });
    } else {
      if (res.name === selectedRes) {
        times.forEach((time, t_index) => {
          days.forEach((day, d_index) => {
            cells.push(res.name + ':' + d_index + ':' + t_index);
          });
        });
      }
    }
  });

  const isCellSelected = (name: string) => selectedCells.indexOf(name) !== -1;

  const handleSelectAllDayTimeClick = (event: React.MouseEvent<unknown>, resDayTime: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellDay = element.split(':')[1];
      let nameDay = resDayTime.split(':')[1];
      let cellTime = element.split(':')[2];
      let nameTime = resDayTime.split(':')[2];
      if (cellDay === nameDay && cellTime === nameTime) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtDay[nameDay as unknown as number]++;
          contSelectedAtTime[nameTime as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtDay[nameDay as unknown as number]--;
          contSelectedAtTime[nameTime as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllDayClick = (event: React.MouseEvent<unknown>, resDayTime: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellDay = element.split(':')[1];
      let nameDay = resDayTime.split(':')[1];
      if (cellDay === nameDay) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtDay[nameDay as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtDay[nameDay as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllTimeClick = (event: React.MouseEvent<unknown>, resDayTime: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellTime = element.split(':')[2];
      let nameTime = resDayTime.split(':')[2];
      if (cellTime === nameTime) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtTime[nameTime as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtTime[nameTime as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllResClick = (event: React.MouseEvent<unknown>, resDayTime: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellRes = element.split(':')[0];
      let nameRes = resDayTime.split(':')[0];
      if (cellRes === nameRes) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          // contSelectedAtTime[nameRes as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          // contSelectedAtTime[nameRes as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllClick = () => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellTime = element.split(':')[2];
      let cellDay = element.split(':')[1];
      const selectedIndex = selectedCells.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
        contSelectedAtDay[cellDay as unknown as number]++;
        contSelectedAtTime[cellTime as unknown as number]++;
        contSelected++;
      } else {
        newSelected = newSelected.filter((item) => item !== element);
        contSelectedAtDay[cellDay as unknown as number]--;
        contSelectedAtTime[cellTime as unknown as number]--;
        contSelected--;
      }
    });
    setSelectedCells(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedCells.indexOf(name);
    let newSelected: string[] = [];
    let nameDay = name.split(':')[1];
    let nameTime = name.split(':')[2];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCells, name);
      contSelectedAtDay[nameDay as unknown as number]++;
      contSelectedAtTime[nameTime as unknown as number]++;
      contSelected++;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCells.slice(1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex === selectedCells.length - 1) {
      newSelected = newSelected.concat(selectedCells.slice(0, -1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedCells.slice(0, selectedIndex), selectedCells.slice(selectedIndex + 1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    }
    setSelectedCells(newSelected);
  };

  cells.forEach((element) => {
    let cellDay = element.split(':')[0];
    let cellTime = element.split(':')[1];
    const selectedIndex = selectedCells.indexOf(element);
    if (selectedIndex !== -1) {
      contSelectedAtDay[cellDay as unknown as number]++;
      contSelectedAtTime[cellTime as unknown as number]++;
      contSelected++;
    }
  });

  //#endregion

  const [configsOpen, setConfigsOpen] = useState(false);
  const [configsConfirmed, setConfigsConfirmed] = useState(false);

  const checkConfigsConfirmation = () => {
    if (configsConfirmed) {
      setConfigsConfirmed(false);
      if (!validateInput()) return;
      solution.name = (document.getElementById('name') as HTMLInputElement).value;
      setDoc(doc(db, solutionsPath, solution.name), solution).then(() => {
        updateDoc(doc(db, solutionsPath + '/' + solution.name), {
          updatedAt: new Date(),
        }).then(() => {
          //Delete old record and update references if name has changed
          if (previousName !== '' && previousName !== solution.name) {
            deleteDoc(doc(db, solutionsPath, previousName));
          }
          // Update file stats
          // Update file stats
          updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
            updatedAt: new Date(),
          });
          setPreviousName(solution.name);
          toast.success(t('Configs set sucessfully'));
        });
      });
    }
  };

  checkConfigsConfirmation();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      deleteDoc(doc(db, solutionsPath, solution.name));
      setDeleteConfirmed(false);
      toast.success(solution.name + t(' deleted sucessfully'));
      history.push('/solutions');
      // Update file stats
      updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
    }
  };

  checkDeleteConfirmation();

  const [lockConfirmationOpen, setLockConfirmationOpen] = useState(false);
  const [lockConfirmed, setLockConfirmed] = useState(false);

  const checkLockConfirmation = () => {
    if (lockConfirmed) {
      handleSelectAllClick();
      // Add all current assignments to lockedAssignments
      // let allCells: string[] = [];
      // solution.assignments.forEach((assignment) =>
      //   assignment.timeslots.forEach((timeslot) => {
      //     assignment.resources.forEach((resource) => {
      //       allCells.push(resource + ':' + timeslot);
      //     });
      //   })
      // );
      // setSelectedCells(allCells);
      setLockConfirmed(false);
      toast.success(t('All assignments locked'));
    }
  };

  checkLockConfirmation();

  const [defectsOpen, setDefectsOpen] = useState(false);

  let resourcesDict: string[] = [];

  function preprocessSolution() {
    // Initialize with empty strings
    let preprocessedSolution: string[][][] = [];
    // Auxiliary structure to reduce time-complexity
    resources.forEach((resource, r_index) => {
      resourcesDict.push(resource.name);
      let timetable: string[][] = [];
      days.forEach((day, d_index) => {
        let daySchedule: string[] = [];
        times.forEach((time, t_index) => {
          const dayTime: string = d_index + ':' + t_index;
          // # for unavailable time
          // = for undesired time
          // . for available time
          resource.unavailableTimes.includes(dayTime)
            ? daySchedule.push('#')
            : resource.undesiredTimes.includes(dayTime)
            ? daySchedule.push('=')
            : daySchedule.push('.');
        });
        timetable.push(daySchedule);
      });
      preprocessedSolution.push(timetable);
    });
    // Fill according to the assignments
    solution.assignments.forEach((assignment, a_index) => {
      const lessonRef: string = assignment.lessonRef;
      assignment.resources.forEach((resource, r_index) => {
        assignment.timeslots.forEach((timeslot, t_index) => {
          // Process a single assignment
          if (timeslot.split(':').length > 1) {
            const dayIdx: number = timeslot.split(':')[0] as unknown as number;
            const timeIdx: number = timeslot.split(':')[1] as unknown as number;
            const resIdx: number = resourcesDict.indexOf(resource);
            if (resIdx >= 0) preprocessedSolution[resIdx][dayIdx][timeIdx] = lessonRef;
          }
        });
      });
    });
    return preprocessedSolution;
  }

  const preprocessedSolution: string[][][] = preprocessSolution();

  function getAssignmentCellTextColor(resourceName: string, cell: string): string[] {
    const res: string[] = [];
    const day = cell.split(':')[0] as unknown as number;
    const time = cell.split(':')[1] as unknown as number;
    var cellText = preprocessedSolution[resourcesDict.indexOf(resourceName)][day][time];

    // To print all resources assigned to a lesson in each cell
    solution.assignments
      .filter((assignment) => assignment.lessonRef === cellText)
      .forEach((assignment, a_index) => {
        assignment.resources.forEach((resource, r_index) => {
          if (resource === resourceName) {
            assignment.resources.forEach((resource2, r2_index) => {
              if (!cellText.includes(resource2)) cellText += ' | ' + resource2;
            });
          }
        });
      });

    res.push(cellText);
    if (cellText === '' || cellText === '.' || cellText === '=' || cellText === '#') {
      res.push('');
      return res;
    }
    const cellColor = subjects.filter((subject) => subject.name === cellText.split(' | ')[0])[0].color;
    res.push(cellColor);
    return res;
  }

  function validateInput(): boolean {
    const solutionNames: string[] = solutions.map((solution) => solution.name);
    const name = (document.getElementById('name') as HTMLInputElement).value;
    if (name.length === 0) {
      toast.error(t('Notes cannot be empty'));
      return false;
    } else if (name.length >= 50) {
      toast.error(t('Notes cannot have more than 50 chars'));
      return false;
    } else if (previousName === `${t('Auto')} ${solutions.length}` && solutionNames.includes(name)) {
      toast.error(t('Unique notes already registered'));
      return false;
    } else if (
      name.includes('/') ||
      name.includes('\\') ||
      name.includes('|') ||
      name.includes(';') ||
      name.includes('.') ||
      name.includes('#') ||
      name.includes('=') ||
      // name.includes(':') ||
      name.includes('_') ||
      name.includes('{') ||
      name.includes('}') ||
      name.includes('&')
    ) {
      toast.error(t('Notes cannot contain any of these chars: / \\ | ; . # = _ { } &'));
      return false;
    } else return true;
  }

  function chooseIcon(type: string) {
    if (type === 'class') {
      return <SchoolIcon />;
    } else if (type === 'teacher') {
      return <CoPresent />;
    } else if (type === 'room') {
      return <MapsHomeWorkIcon />;
    } else {
      return <SchoolIcon />;
    }
  }

  //Tabs
  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        key={`simple-tabpanel-${index}`}
        id={`simple-tabpanel-${index}`}
        style={{ maxWidth: '100%', width: '100%' }}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ paddingRight: 0 }}>{children}</Box>}
      </div>
    );
  }

  const [selectedTab, setSelectedTab] = React.useState(0);
  const [pageClass, setPageClass] = React.useState(0);
  const [pageTeacher, setPageTeacher] = React.useState(0);
  const [pageRoom, setPageRoom] = React.useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  function individualTable2Html(resource: string) {
    return (
      <table id="toExcel">
        <thead>
          <tr>
            <th
              align="center"
              colSpan={1 + days.length}
              style={{
                minWidth: 80 + days.length * 160,
                backgroundColor: '#1A73E8',
                color: grey[50],
                border: '1px solid black',
              }}
            >
              {resource}
            </th>
          </tr>
          <tr>
            <th align="center" style={{ minWidth: 80, color: '#1A73E8' }}>
              {t('TIME')}
            </th>
            {days.map((day, d_index) => {
              return (
                <th key={`day_${d_index}`} align="center" style={{ minWidth: 160, color: '#1A73E8' }}>
                  {days[d_index]}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {times.map((time, t_index) => {
            return (
              <tr key={`time_${t_index}`}>
                <th style={{ height: 72, minWidth: 80, color: '#1A73E8' }}>{times[t_index]}</th>

                {days.map((day, d_index) => {
                  const cell = getAssignmentCellTextColor(resource, d_index + ':' + t_index);
                  const cellText = cell[0];
                  const cellColor = cell[1];
                  return (
                    <th
                      key={`cell_${d_index}_${t_index}`}
                      align="center"
                      style={{
                        height: 72,
                        minWidth: 160,
                        border: '1px solid black',
                        padding: 1,
                        backgroundColor: cellText === '#' || cellText === '=' ? grey[200] : cellColor,
                        color: cellText === '#' || cellText === '=' ? grey[600] : grey[50],
                      }}
                    >
                      {cellText === '#'
                        ? ` X `
                        : cellText === '='
                        ? ` - `
                        : cellText === '.'
                        ? ` `
                        : cellText.replace(' | ' + resource, '').replaceAll(' | ', ' • ')}
                      {/* : cellText.split(' | ')[0]} */}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        <tr></tr>
      </table>
    );
  }

  function resourceTypeTable2Html(resourceType: string) {
    return (
      <table id="toExcel">
        <thead>
          <tr>
            <th
              align="center"
              colSpan={1 + days.length * times.length}
              style={{
                minWidth: 80 + days.length * 160,
                backgroundColor: '#1A73E8',
                color: grey[50],
                border: '1px solid black',
              }}
            >
              {t(resourceType).toUpperCase()}
            </th>
          </tr>
          <tr>
            <th align="center" style={{ minWidth: 32, borderRight: `2px solid ${grey[300]}` }}>
              {''}
            </th>
            {days.map((day, d_index) => {
              return (
                <th
                  translate="no"
                  key={`header${d_index}`}
                  align="center"
                  style={{ minWidth: 32 * times.length, borderRight: `2px solid ${grey[300]}`, color: '#1A73E8' }}
                  colSpan={times.length}
                >
                  {days[d_index]}
                </th>
              );
            })}
          </tr>
          <tr>
            <th align="center" style={{ minWidth: 32, borderRight: `2px solid ${grey[300]}`, color: '#1A73E8' }}>
              {t(resourceType).toUpperCase()}
            </th>
            {days.map((day, d_index) => {
              return times.map((time, t_index) => {
                return (
                  <th
                    key={`header${d_index}_${t_index}`}
                    align="center"
                    style={{
                      minWidth: 32,
                      borderRight: t_index + 1 === times.length ? `2px solid ${grey[300]}` : '',
                      color: '#1A73E8',
                    }}
                  >
                    {times[t_index]}
                  </th>
                );
              });
            })}
          </tr>
        </thead>
        <tbody>
          {resources
            .filter((res) => res.type === resourceType)
            .map((res, r_index) => {
              return (
                <tr key={`res_${r_index}`}>
                  <th
                    style={{
                      // height: 32,
                      minWidth: 32,
                      borderRight: `2px solid ${grey[300]}`,
                      color: '#1A73E8',
                    }}
                  >
                    {res.short}
                  </th>
                  {days.map((day, d_index) => {
                    return times.map((time, t_index) => {
                      const cell = getAssignmentCellTextColor(res.name, d_index + ':' + t_index);
                      const cellText = cell[0];
                      const cellColor = cell[1];
                      return (
                        <th
                          key={`cell_${d_index}_${t_index}`}
                          align="center"
                          style={{
                            // height: 32,
                            minWidth: 160,
                            border: '1px solid black',
                            padding: 1,
                            backgroundColor: cellText === '#' || cellText === '=' ? grey[200] : cellColor,
                            color: cellText === '#' || cellText === '=' ? grey[600] : grey[50],
                          }}
                        >
                          {cellText === '#'
                            ? ` X `
                            : cellText === '='
                            ? ` - `
                            : cellText === '.'
                            ? ` `
                            : subjects.find((sub) => sub.name === cellText.split(' | ')[0])?.short}
                          {/* : cellText.split(' | ')[0]} */}
                        </th>
                      );
                    });
                  })}
                </tr>
              );
            })}
          <tr></tr>
        </tbody>
      </table>
    );
  }

  function handleExportXls() {
    // Excel - working with file warning
    const name = (document.getElementById('name') as HTMLInputElement).value;
    var uri = 'data:application/vnd.ms-excel;base64,',
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s: any) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s: any, c: any) {
        return s.replace(/{(\w+)}/g, function (m: any, p: any) {
          return c[p];
        });
      };

    var htmlString = '';
    if (solution.display !== 'Compact') {
      let resourceTypes: string[] = ['class', 'teacher', 'room'];
      resourceTypes.forEach((resourceType) => {
        resources
          .filter((res) => res.type === resourceType)
          .forEach((res) => {
            htmlString += renderToStaticMarkup(individualTable2Html(res.name));
          });
      });
    } else {
      let resourceTypes: string[] = ['class', 'teacher'];
      resources.find((res) => res.type === 'room') !== undefined && resourceTypes.push('room');
      resourceTypes.forEach((resType) => {
        htmlString += renderToStaticMarkup(resourceTypeTable2Html(resType));
      });
    }

    var toExcel = `<div>${htmlString}</div>`;
    var ctx = {
      worksheet: name || '',
      table: toExcel,
    };
    var link = document.createElement('a');
    link.download = `${file?.name} ${solution.name}.xls`;
    link.href = uri + base64(format(template, ctx));
    link.click();
  }

  const [exportOpen, setExportOpen] = useState(false);
  const [exportConfirmed, setExportConfirmed] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState<string>(t('MS Excel'));
  const [publishTimetable, setPublishTimetable] = useState(false);

  const autoDownloadFile = (text: string, extension: string) => {
    var textFile: any = null,
      makeTextFile = function (text: any) {
        var data = new Blob([text], { type: 'text/plain' });
        // If we are replacing a previously generated file we need to
        // manually revoke the object URL to avoid memory leaks.
        if (textFile !== null) {
          window.URL.revokeObjectURL(textFile);
        }
        textFile = window.URL.createObjectURL(data);
        // returns a URL you can use as a href
        return textFile;
      };

    var create = document.createElement(extension);
    // var create = document.getElementById('name');
    create?.addEventListener('click', function () {
      var link = document.createElement('a');
      link.setAttribute('download', solution.name + '.' + extension);
      link.href = makeTextFile(text);
      document.body.appendChild(link);

      // wait for the link to be added to the document
      window.requestAnimationFrame(function () {
        var event = new MouseEvent('click');
        link.dispatchEvent(event);
        document.body.removeChild(link);
      });
    });
    create.click();
  };

  const handleExportJson = async () => {
    const json = JSON.stringify(solution, null, 2);
    autoDownloadFile(json, 'json');
  };

  const handleExportCsv = async () => {
    var content = `${t('Subject')},${t('Class')},${t('Teachers')},${t('Rooms')},${t('Timeslots')}\n`;
    solution.assignments.forEach((assignment) => {
      content += assignment.lessonRef.split(' | ')[0] + ',';
      let assignmentClasses: string[] = [];
      let assignmentTeachers: string[] = [];
      let assignmentRooms: string[] = [];
      assignment.resources.forEach((assignmentResource) => {
        resources.find((resource) => resource.name === assignmentResource)?.type === 'class' &&
          assignmentClasses.push(assignmentResource);
        resources.find((resource) => resource.name === assignmentResource)?.type === 'teacher' &&
          assignmentTeachers.push(assignmentResource);
        resources.find((resource) => resource.name === assignmentResource)?.type === 'room' &&
          assignmentRooms.push(assignmentResource);
      });
      content += assignmentClasses.join('+') + ',';
      content += assignmentTeachers.join('+') + ',';
      content += assignmentRooms.join('+') + ',';
      content += assignment.timeslots
        .map((assignmentTimeslot) => {
          const day = +assignmentTimeslot.split(':')[0] + 1;
          const time = +assignmentTimeslot.split(':')[1] + 1;
          return '' + day + ':' + time;
        })
        .join('+');
      content += '\n';
    });
    autoDownloadFile(content, 'csv');
  };

  const publishTimetableToApp = async () => {
    await setDoc(doc(db, 'publicSolutions', ownerEmail + ' | ' + file?.name), {
      filename: file?.name,
      solutionUrl: solutionsPath + '/' + solution.name,
      url: solutionsPath.replace('/solutions', ''),
    });
    toast.success(t('Your timetable has been published to the Optables app'));
  };

  const withdrawTimetableFromApp = async () => {
    const publicSolutionDoc = await getDoc(doc(db, 'publicSolutions', ownerEmail + ' | ' + file?.name));
    if (publicSolutionDoc.exists()) {
      await deleteDoc(doc(db, 'publicSolutions', ownerEmail + ' | ' + file?.name));
      toast.success(t('Your timetable has been withdrawn from Optables app'));
    }
  };

  const checkExportConfirmation = () => {
    if (exportConfirmed) {
      setExportConfirmed(false);
      if (selectedFormat === t('MS Excel')) handleExportXls();
      else if (selectedFormat === t('Json')) handleExportJson();
      else if (selectedFormat === t('Csv')) handleExportCsv();
      if (publishTimetable) {
        publishTimetableToApp();
      } else {
        withdrawTimetableFromApp();
      }
    }
  };

  checkExportConfirmation();

  const [runSolutionCreation, setRunSolutionCreation] = useState(false);
  const solutionCreationStorageFlagKey = 'onboarding-solution-creation';
  useEffect(() => {
    if (localStorage.getItem(solutionCreationStorageFlagKey) !== 'true') {
      setRunSolutionCreation(true);
    }
  }, []);

  const steps = [
    // {
    //   target: '.quality-metrics',
    //   disableBeacon: true,
    //   content: (
    //     <OnboardingContent
    //       title={t('Timetable quality metrics')}
    //       message={t(
    //         'You can see several quality metrics, like how many assignments were performed and how many defects this timetable has. Hover on a field to see its full description'
    //       )}
    //     />
    //   ),
    // },
    // {
    //   target: '.weighted-cost-button',
    //   content: (
    //     <OnboardingContent
    //       title={t('Weighted cost')}
    //       message={t(
    //         'Each requirement has a priority (weight). The weighted sum of defects is displayed here. The smaller, the better the timetable is'
    //       )}
    //     />
    //   ),
    // },
    {
      target: '.compact-timetable',
      content: (
        <OnboardingContent
          title={t('Timetable display')}
          message={t(
            'By default, timetables are displayed having times in the columns and classes in the rows. You can change it to view individual timetables in the CONFIGS menu'
          )}
        />
      ),
    },
    {
      target: '.resource-switch',
      content: (
        <OnboardingContent
          title={t('Class/teacher display')}
          message={
            file?.roomsSetting !== 0
              ? t('Here you can switch to view timetables by classes (default), teachers, or rooms')
              : t('Here you can switch to view timetables by classes (default) or teachers')
          }
        />
      ),
    },
    {
      target: '.defects-button',
      content: (
        <OnboardingContent
          title={t('Defects')}
          message={t('Here you can see the requirements that were not met in this timetable')}
        />
      ),
    },
    {
      target: '.lock-button',
      content: (
        <OnboardingContent
          title={t('Lock/unlock assignments')}
          message={t(
            "You can click on an assignment to lock it, ensuring that it won't change, even when a new timetable is generated. Here you can lock all the assignments"
          )}
        />
      ),
    },
    {
      target: '.configs-button',
      content: (
        <OnboardingContent
          title={t('Configs')}
          message={t(
            'You can change the priority of each requirement, the timetable display type, and the time limit to build it'
          )}
        />
      ),
    },
    {
      target: '.generate-button',
      content: <OnboardingContent title={t('Generate')} message={t('Click here to generate a new timetable')} />,
    },
  ];

  const [importOpen, setImportOpen] = useState(false);

  const handleNavToUpgradePlan = () => {
    setShowPlanLimitDialog(false);
    setPlanLimitDialogShown(true);
    history.push('/settings');
  };

  const defectCount = (resource: string) => {
    return solution.defects.filter((defect) => defect.appliesTo === resource).length;
  };

  const now = new Date().getTime();
  const updatedAt = (solution.updatedAt as any).seconds ? (solution.updatedAt as any).seconds * 1000 : now;

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '56px 8px 8px 65px',
        minHeight: '100vh',
        backgroundColor: colors.grey[50],
        height: '100vh',
        overflow: 'auto',
      }}
    >
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_solution')}
        ></VideoPlayerDialog>
      )}
      {showPlanLimitDialog && (
        <PlanInfoDialog
          open={showPlanLimitDialog}
          positiveLabel={t('Upgrade plan')}
          negativeLabel={t('Continue anyway')}
          positiveAction={handleNavToUpgradePlan}
          negativeAction={() => {
            setShowPlanLimitDialog(false);
            setPlanLimitDialogShown(true);
          }}
          title={t('Runtime recommendation')}
          message={t(
            `Your file have a lot of resources and constraints. Optables suggests at least ${
              runtimeSuggestion / 60
            } minutes of runtime to build timetables for this file. We recommend you to upgrade your Optables plan to have a better experience.`
          )}
        />
      )}
      <ImportAssignmentsDialog
        open={importOpen}
        setOpen={setImportOpen}
        title={`${t('Import solution')}`}
        message={t('Import message')}
        solution={solution}
      />
      <ExportDialog
        open={exportOpen}
        setOpen={setExportOpen}
        title={t('Export Options')}
        message={t('Select the export file format:')}
        setConfirmed={setExportConfirmed}
        selectedFormat={selectedFormat}
        setSelectedFormat={setSelectedFormat}
        publishTimetable={publishTimetable}
        setPublishTimetable={setPublishTimetable}
      />
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={'Warning'}
        message={solution.name + t(' will be permanently deleted from this file. Are you sure?')}
      />
      <ConfigsDialog
        open={configsOpen}
        setOpen={setConfigsOpen}
        setConfirmed={setConfigsConfirmed}
        title={t('Configurations')}
        message={t('Select the priority for each requirement:')}
        solution={solution}
        setSolution={setSolution}
        priorityOptions={priorityOptions}
        setStatus={setStatus}
        userPlan={userPlan}
        runtimeSuggestion={getPlanRuntimeSuggestion()}
      />
      <DraggableDialog
        open={lockConfirmationOpen}
        setOpen={setLockConfirmationOpen}
        setConfirmed={setLockConfirmed}
        title={t('Warning')}
        message={t('Lock warning')}
      />
      <DefectsDialog
        open={defectsOpen}
        setOpen={setDefectsOpen}
        title={t('Defects')}
        selectedResource={selectedRes}
        solution={solution}
        message={t('The constraints below were not attended in this timetable:')}
      />
      <MiniDrawer />
      <Grid container justifyContent="center" paddingBottom={8}>
        <Paper elevation={3} sx={{ width: '100%', maxWidth: widthLessThan1500 ? 'lg' : '80vw' }}>
          <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
            <Grid item container xs={12} spacing={1} padding={1}>
              <Grid item flexGrow={1}>
                <Typography variant="body1" gutterBottom sx={{ color: '#1976D2' }}>
                  <Tooltip title={t('Back')}>
                    <IconButton
                      style={{ height: 24, width: 24, marginRight: 4 }}
                      color="primary"
                      onClick={() => history.goBack()}
                    >
                      <ArrowBack style={{ height: 20, width: 20 }} />
                    </IconButton>
                  </Tooltip>
                  {history.location.state !== undefined ? t('Update ') + t('timetable') : t('New ') + t('timetable')}
                </Typography>
                <Divider color="#1976D2" />
              </Grid>
              <Grid item alignSelf={'center'}>
                <Tooltip title={t('Watch a helpful video')}>
                  <IconButton
                    style={{ height: 24, width: 24 }}
                    color="primary"
                    onClick={() => setVideoPlayerDialogOpen(true)}
                  >
                    <Help style={{ height: 24, width: 24 }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              {history.location.state !== undefined && (
                <>
                  <Grid item xs={3} md={2}>
                    <Button
                      startIcon={<DeleteIcon />}
                      variant="outlined"
                      size="small"
                      disabled={readOnly}
                      onClick={() => setDeleteConfirmationOpen(true)}
                      color="error"
                      fullWidth
                    >
                      {t('Delete')}
                    </Button>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Button
                      startIcon={<FileCopyIcon />}
                      variant="outlined"
                      size="small"
                      disabled={readOnly}
                      onClick={() => copy()}
                      color="primary"
                      fullWidth
                    >
                      {t('Copy')}
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <DialogContentText>{t('Timetable Info')}</DialogContentText>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    className="quality-metrics"
                    container
                    spacing={1}
                    item
                    xs={12}
                    justifyContent="space-between"
                    paddingTop={1}
                  >
                    {/* Notes */}
                    <Grid item xs={12} sm={9} md={8}>
                      <TextField
                        id="name"
                        required
                        fullWidth
                        size="small"
                        label={t('Notes')}
                        variant="outlined"
                        translate="no"
                        // multiline
                        // rows={3}
                        defaultValue={solution.name}
                        // onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    {/* Status */}
                    <Grid item xs={12} sm={3} md={4}>
                      <Tooltip
                        placement="left-end"
                        title={
                          <div>
                            {t('Possible status:')}
                            <hr />⦿ {t('Empty - Timetable has not been generated yet')}
                            <hr />⦿ {t('Outdated description')}
                            <hr />⦿{' '}
                            {t(
                              'Processing - Optables is generating the timetable - you can leave the page in this meantime'
                            )}
                            <hr />⦿ {t('Up to date description')}
                            <hr />⦿ {t('Optimal description')}
                          </div>
                        }
                      >
                        <TextField
                          id="cost"
                          fullWidth
                          size="small"
                          label={t('Status')}
                          variant="outlined"
                          InputProps={{
                            readOnly: true,
                          }}
                          translate="no"
                          sx={{
                            input: {
                              color:
                                status === 'Empty'
                                  ? red[900]
                                  : status === 'Outdated' || status === 'Processing'
                                  ? orange[900]
                                  : green[900],
                            },
                            '& .MuiOutlinedInput-root': {
                              background:
                                status === 'Empty'
                                  ? red[100]
                                  : status === 'Outdated' || status === 'Processing'
                                  ? orange[100]
                                  : green[100],
                            },
                          }}
                          value={t(status)}
                        />
                      </Tooltip>
                    </Grid>
                    {/* Assignments */}
                    <Grid item xs={12} md={4}>
                      <Card>
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {t('Assignments')}
                          </Typography>
                          <PieChart
                            margin={{ top: 0, bottom: 0, left: 0, right: 250 }}
                            slotProps={{
                              legend: {
                                padding: 50,
                              },
                            }}
                            series={[
                              {
                                data: [
                                  {
                                    id: 0,
                                    value: solution.assignments.length,
                                    color: colors.green[200],
                                    label: `Alocadas (${(
                                      100 -
                                      ((lessons.length - solution.assignments.length) / lessons.length) * 100
                                    ).toFixed(1)}%)`,
                                  },
                                  {
                                    id: 1,
                                    value: lessons.length - solution.assignments.length,
                                    color: colors.red[200],
                                    label: `Não alocadas (${(
                                      ((lessons.length - solution.assignments.length) / lessons.length) *
                                      100
                                    ).toFixed(1)}%)`,
                                  },
                                ],
                                innerRadius: 20,
                                outerRadius: 50,
                              },
                            ]}
                            width={400}
                            height={150}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Card>
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {t('Constraints')}
                          </Typography>
                          <PieChart
                            margin={{ top: 0, bottom: 0, left: 0, right: 250 }}
                            slotProps={{
                              legend: {
                                padding: 50,
                              },
                            }}
                            series={[
                              {
                                data: [
                                  {
                                    id: 0,
                                    value:
                                      solution.assignments.length === 0
                                        ? 0
                                        : solution.numConstraints - solution.defects.length,
                                    color: colors.green[200],
                                    label: `Atendidas (${
                                      solution.assignments.length === 0
                                        ? '0.0'
                                        : (
                                            ((solution.numConstraints - solution.defects.length) /
                                              solution.numConstraints) *
                                            100
                                          ).toFixed(1)
                                    }%)`,
                                  },
                                  {
                                    id: 1,
                                    value: solution.assignments.length === 0 ? 100 : solution.defects.length,
                                    color: colors.red[200],
                                    label: `Não atendidas (${
                                      solution.assignments.length === 0
                                        ? '100.0'
                                        : ((solution.defects.length / solution.numConstraints) * 100).toFixed(1)
                                    }%)`,
                                  },
                                ],
                                innerRadius: 20,
                                outerRadius: 50,
                              },
                            ]}
                            width={400}
                            height={150}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Card>
                        <CardContent>
                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {t('Optimum closeness')}
                          </Typography>
                          <PieChart
                            margin={{ top: 0, bottom: 0, left: 0, right: 250 }}
                            slotProps={{
                              legend: {
                                padding: 50,
                              },
                            }}
                            series={[
                              {
                                data: [
                                  {
                                    id: 0,
                                    value: solution.assignments.length === 0 ? 0 : solution.lowerBound,
                                    color: colors.green[200],
                                    label: `Min. possível (${(solution.cost === 0
                                      ? 100
                                      : (solution.lowerBound / solution.cost) * 100
                                    ).toFixed(1)}%)`,
                                    // label: `Mínimo possível (${solution.lowerBound})`,
                                  },
                                  {
                                    id: 1,
                                    value:
                                      solution.assignments.length === 0 ? 100 : solution.cost - solution.lowerBound,
                                    color: colors.red[200],
                                    label: `Acima do min. (${(
                                      (solution.cost === 0
                                        ? 0
                                        : (solution.cost - solution.lowerBound) / solution.cost) * 100
                                    ).toFixed(1)}%)`,
                                  },
                                ],
                                innerRadius: 20,
                                outerRadius: 50,
                              },
                            ]}
                            width={400}
                            height={150}
                          />
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid container item spacing={1} xs={12} alignItems="center" paddingTop={1}>
              {/* Progress and Status */}
              <Grid item xs={12} md={10}>
                <LinearWithValueLabel
                  afterSolve={afterSolve}
                  initTime={(now - updatedAt) / 1000}
                  maxTime={solution.runningTime}
                  solving={solving}
                  setSolving={setSolving}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button
                  className="generate-button"
                  startIcon={<PlayCircleIcon />}
                  variant="outlined"
                  size="small"
                  disabled={solving || readOnly}
                  fullWidth
                  onClick={() => {
                    if (!validateInput()) return;
                    setSolving(true);
                    solve();
                  }}
                  color="primary"
                >
                  {solving ? t('Generating...') : t('Generate')}
                </Button>
                {/* )} */}
              </Grid>
            </Grid>
            {solution.display !== 'Compact' ? (
              <Grid container paddingTop={1} spacing={1}>
                <Grid item xs={12} flexGrow={1}>
                  <Typography variant="body1" gutterBottom sx={{ color: '#1976D2', textAlign: 'center' }}>
                    {t('Timetable to ') + selectedRes}
                  </Typography>
                  <Divider color="#1976D2" />
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  md={3}
                  alignItems={'stretch'}
                  alignContent={'start'}
                  sx={{ paddingTop: '0px !important' }}
                >
                  <Paper elevation={3} sx={{ width: '100%' }}>
                    <Grid item xs={12} marginTop={0} paddingTop={0}>
                      <Box sx={{ width: '100%' }}>
                        <Tabs
                          value={selectedTab}
                          onChange={handleTabChange}
                          variant="scrollable"
                          allowScrollButtonsMobile
                          aria-label="wrapped label tabs example"
                        >
                          <Tab label={file?.studentsSetting !== 0 ? t('Classes') : t('Students')} />
                          <Tab label={t('Teachers')} />
                          <Tab label={t('Rooms')} />
                        </Tabs>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TabPanel value={selectedTab} index={0}>
                        <ResourcesSoln
                          type={'class'}
                          selected={selectedRes}
                          setSelected={setSelectedRes}
                          solution={solution}
                          page={pageClass}
                          setPage={setPageClass}
                          numTimes={times.length}
                          defects={solution.defects}
                        />
                      </TabPanel>
                      <TabPanel value={selectedTab} index={1}>
                        <ResourcesSoln
                          type={'teacher'}
                          selected={selectedRes}
                          setSelected={setSelectedRes}
                          solution={solution}
                          page={pageTeacher}
                          setPage={setPageTeacher}
                          numTimes={times.length}
                          defects={solution.defects}
                        />
                      </TabPanel>
                      <TabPanel value={selectedTab} index={2}>
                        <ResourcesSoln
                          type={'room'}
                          selected={selectedRes}
                          setSelected={setSelectedRes}
                          solution={solution}
                          page={pageRoom}
                          setPage={setPageRoom}
                          numTimes={times.length}
                          defects={solution.defects}
                        />
                      </TabPanel>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  md={9}
                  spacing={1}
                  alignItems={'stretch'}
                  justifyContent={'space-evenly'}
                  alignContent={'start'}
                  sx={{ paddingTop: '0px !important' }}
                >
                  <Grid item xs={12} paddingTop={0}>
                    <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden' }}>
                      <TableContainer sx={{ maxHeight: '55vh' }}>
                        <Table id="ind_table" size="small" padding="none" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" sx={{ minWidth: 80 }}>
                                <Button
                                  id={`check_${0}_${0}`}
                                  fullWidth={true}
                                  size="small"
                                  color="primary"
                                  onClick={(event) => handleSelectAllClick()}
                                >
                                  {t('Time')}
                                </Button>
                              </TableCell>
                              {days.map((day, d_index) => {
                                return (
                                  <TableCell
                                    translate="no"
                                    key={`day_${d_index}`}
                                    align="center"
                                    sx={{ minWidth: 160 }}
                                  >
                                    <Button
                                      value="check"
                                      id={`check_${d_index}:0`}
                                      translate="no"
                                      fullWidth
                                      size="small"
                                      sx={{ color: 'primary' }}
                                      // selected={rowCount > 0 && contSelectedAtDay[d_index] === rowCount}
                                      onClick={(event) =>
                                        handleSelectAllDayClick(event, selectedRes + ':' + d_index + ':*')
                                      }
                                    >
                                      {days[d_index]}
                                    </Button>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {times.map((time, t_index) => {
                              return (
                                <TableRow
                                  key={`time_${t_index}`}
                                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                  <TableCell sx={{ height: 72, minWidth: 80 }}>
                                    <Button
                                      id={`check_${0}:${t_index}`}
                                      translate="no"
                                      fullWidth
                                      size="small"
                                      color="primary"
                                      sx={{
                                        height: '100%',
                                      }}
                                      onClick={(event) =>
                                        handleSelectAllTimeClick(event, selectedRes + ':*:' + t_index)
                                      }
                                    >
                                      {times[t_index]}
                                    </Button>
                                  </TableCell>

                                  {days.map((day, d_index) => {
                                    const isItemSelected = isCellSelected(selectedRes + ':' + d_index + ':' + t_index);
                                    const cell = getAssignmentCellTextColor(selectedRes, d_index + ':' + t_index);
                                    const cellText = cell[0];
                                    const cellColor = cell[1];
                                    return (
                                      <TableCell
                                        key={`cell_${d_index}_${t_index}`}
                                        align="center"
                                        style={{ maxHeight: '100px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                      >
                                        <Card sx={{ height: 72, minWidth: 160 }}>
                                          {cellText === '#' ? (
                                            <ToggleButton
                                              value="check"
                                              id={`check_${d_index}_${t_index}`}
                                              fullWidth
                                              size="small"
                                              sx={{
                                                pointerEvents: 'none',
                                                height: '100%',
                                                backgroundColor: grey[200],
                                              }}
                                              // onClick={(event) => handleClick(event, d_index + ':' + t_index)}
                                              selected
                                            >
                                              <CloseIcon sx={{ color: grey[600] }}></CloseIcon>
                                            </ToggleButton>
                                          ) : cellText === '=' ? (
                                            <ToggleButton
                                              value="check"
                                              id={`check_${d_index}_${t_index}`}
                                              fullWidth
                                              size="small"
                                              sx={{
                                                pointerEvents: 'none',
                                                height: '100%',
                                                backgroundColor: grey[200],
                                              }}
                                              // onClick={(event) => handleClick(event, d_index + ':' + t_index)}
                                              selected
                                            >
                                              <RemoveIcon sx={{ color: grey[600] }}></RemoveIcon>
                                            </ToggleButton>
                                          ) : cellText === '.' ? (
                                            <ToggleButton
                                              value="check"
                                              id={`check_${d_index}_${t_index}`}
                                              fullWidth
                                              translate="no"
                                              size="small"
                                              sx={{
                                                pointerEvents: 'none',
                                                textTransform: 'none',
                                                fontWeight: 'normal',
                                                height: '100%',
                                                color: '#ffffff',
                                                backgroundColor: '#ffffff',
                                              }}
                                              selected={false}
                                            ></ToggleButton>
                                          ) : (
                                            // <Tooltip title={`${cellText.split(' | ')[1]}`}>
                                            <ToggleButton
                                              value="check"
                                              id={`check_${d_index}_${t_index}`}
                                              fullWidth
                                              translate="no"
                                              size="small"
                                              sx={{
                                                textTransform: 'none',
                                                fontWeight: 'normal',
                                                // fontSize: 12,
                                                height: '100%',
                                                color: '#ffffff',
                                                backgroundColor: cellColor === '' ? 'primary' : cellColor,
                                              }}
                                              onClick={(event) =>
                                                handleClick(event, selectedRes + ':' + d_index + ':' + t_index)
                                              }
                                              selected={isItemSelected}
                                            >
                                              <Typography fontSize={12} translate="no" style={{ whiteSpace: 'normal' }}>
                                                {/* {cellText.split(' | ')[0]} */}
                                                {cellText.replace(' | ' + selectedRes, '').replaceAll(' | ', ' • ')}
                                              </Typography>
                                              {isItemSelected ? (
                                                <LockIcon
                                                  sx={{
                                                    paddingLeft: 1,
                                                    justifyContent: 'flex-end',
                                                    alignSelf: 'flex-end',
                                                  }}
                                                ></LockIcon>
                                              ) : (
                                                <LockOpenIcon
                                                  sx={{
                                                    paddingLeft: 1,
                                                    justifyContent: 'flex-end',
                                                    alignSelf: 'flex-end',
                                                  }}
                                                ></LockOpenIcon>
                                              )}
                                            </ToggleButton>
                                            // </Tooltip>
                                          )}
                                        </Card>
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                    <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end" paddingTop={1}>
                      <Grid item xs={6} md={2}>
                        <Button
                          className="defects-button"
                          startIcon={<Error />}
                          variant="text"
                          size="small"
                          onClick={() => setDefectsOpen(true)}
                          color={solution.defects.length !== 0 ? 'error' : 'primary'}
                          fullWidth
                        >
                          {`${t('Defects')}${solution.defects.length !== 0 ? ` (${solution.defects.length})` : ''}`}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          className="lock-button"
                          startIcon={<LockIcon />}
                          variant="text"
                          size="small"
                          disabled={readOnly}
                          onClick={() => setLockConfirmationOpen(true)}
                          color="primary"
                          fullWidth
                        >
                          {t('Lock') + (selectedCells.length === 0 ? '' : ` (${selectedCells.length})`)}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          startIcon={<LockOpenIcon />}
                          variant="text"
                          size="small"
                          disabled={readOnly}
                          onClick={() => {
                            if (selectedCells.length === 0) toast.warning(t('All assignments are already unlocked'));
                            else toast.success(t('All assignments unlocked'));
                            setSelectedCells([]);
                          }}
                          color="primary"
                          fullWidth
                        >
                          {t('Unlock')}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          startIcon={<FileUploadIcon />}
                          variant="text"
                          size="small"
                          disabled={readOnly}
                          onClick={() => setImportOpen(true)}
                          color="primary"
                          fullWidth
                        >
                          {t('Import')}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          startIcon={<FileDownloadIcon />}
                          variant="text"
                          size="small"
                          disabled={readOnly}
                          onClick={() => setExportOpen(true)}
                          color="primary"
                          fullWidth
                        >
                          {t('Export')}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          className="configs-button"
                          startIcon={<DisplaySettingsIcon />}
                          variant="text"
                          size="small"
                          disabled={readOnly}
                          onClick={() => setConfigsOpen(true)}
                          color="primary"
                          fullWidth
                        >
                          {t('Configs')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid container paddingTop={1} spacing={1}>
                <Grid item xs={12} flexGrow={1}>
                  <Typography variant="body1" gutterBottom sx={{ color: '#1976D2', textAlign: 'center' }}>
                    {`${
                      selectedTab === 0 && file?.studentsSetting !== 0
                        ? t('Timetable to Classes')
                        : selectedTab === 0 && file?.studentsSetting === 0
                        ? t('Timetable to Students')
                        : selectedTab === 1
                        ? t('Timetable to Teachers')
                        : t('Timetable to Rooms')
                    }`}
                  </Typography>
                  <Divider color="#1976D2" />
                </Grid>

                <Grid
                  id={'table'}
                  container
                  item
                  sm={12}
                  justifyContent={'space-evenly'}
                  alignContent={'start'}
                  sx={{ paddingTop: '0px !important' }}
                >
                  <Grid item xs={12}>
                    <Paper elevation={3} sx={{ width: '100%', overflow: 'hidden', overflowX: 'auto' }}>
                      <TableContainer sx={{ maxHeight: '55vh' }}>
                        <Table size="small" padding="none" stickyHeader>
                          <TableHead className="compact-timetable">
                            <TableRow>
                              <TableCell align="center" sx={{ minWidth: 32, borderRight: `2px solid ${grey[300]}` }}>
                                <Stack className="resource-switch" direction={'row'} justifyContent="space-evenly">
                                  <IconButton
                                    id={`prev`}
                                    sx={{ width: 15, height: 31 }}
                                    size="small"
                                    color="primary"
                                    onClick={(event) => {
                                      setSelectedTab(selectedTab - 1 < 0 ? 2 : selectedTab - 1);
                                    }}
                                  >
                                    <ChevronLeftIcon />
                                  </IconButton>
                                  <IconButton
                                    id={`next`}
                                    sx={{ width: 15, height: 31 }}
                                    size="small"
                                    color="primary"
                                    onClick={(event) => {
                                      setSelectedTab(selectedTab + 1 > 2 ? 0 : selectedTab + 1);
                                    }}
                                  >
                                    <ChevronRightIcon />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                              {days.map((day, d_index) => {
                                return (
                                  <TableCell
                                    translate="no"
                                    key={`header${d_index}`}
                                    align="center"
                                    sx={{ minWidth: 32 * times.length, borderRight: `2px solid ${grey[300]}` }}
                                    colSpan={times.length}
                                  >
                                    <Button
                                      value="check"
                                      id={`check_${d_index}:0`}
                                      fullWidth={true}
                                      size="small"
                                      sx={{ color: 'primary' }}
                                      // selected={rowCount > 0 && contSelectedAtDay[d_index] === rowCount}
                                      onClick={(event) => handleSelectAllDayClick(event, '*:' + d_index + ':*')}
                                    >
                                      {days[d_index]}
                                    </Button>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                            <TableRow>
                              <TableCell align="center" sx={{ minWidth: 32, borderRight: `2px solid ${grey[300]}` }}>
                                <IconButton
                                  size="small"
                                  sx={{ width: 31, height: 31 }}
                                  color="primary"
                                  onClick={(event) => handleSelectAllClick()}
                                >
                                  {chooseIcon(selectedTab === 0 ? 'class' : selectedTab === 1 ? 'teacher' : 'room')}
                                </IconButton>
                              </TableCell>
                              {days.map((day, d_index) => {
                                return times.map((time, t_index) => {
                                  return (
                                    <TableCell
                                      key={`header${d_index}_${t_index}`}
                                      align="center"
                                      sx={{
                                        minWidth: 32,
                                        borderRight: t_index + 1 === times.length ? `2px solid ${grey[300]}` : '',
                                      }}
                                    >
                                      <Button
                                        value="check"
                                        id={`check_${d_index}:0`}
                                        translate="no"
                                        fullWidth={true}
                                        size="small"
                                        sx={{ color: 'primary' }}
                                        // selected={rowCount > 0 && contSelectedAtDay[d_index] === rowCount}
                                        onClick={(event) =>
                                          handleSelectAllDayTimeClick(event, '*:' + d_index + ':' + t_index)
                                        }
                                      >
                                        {times[t_index]}
                                      </Button>
                                    </TableCell>
                                  );
                                });
                              })}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {resources
                              .filter((res) =>
                                selectedTab === 0
                                  ? res.type === 'class'
                                  : selectedTab === 1
                                  ? res.type === 'teacher'
                                  : res.type === 'room'
                              )
                              .map((res, r_index) => {
                                return (
                                  <TableRow
                                    key={`res_${r_index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell
                                      // style={{ position: 'sticky', left: 0, background: "white" }}
                                      sx={{
                                        height: 32,
                                        minWidth: 32,
                                        borderRight: `2px solid ${grey[300]}`,
                                      }}
                                    >
                                      <Tooltip
                                        title={`${res.name}${
                                          defectCount(res.name) > 0 ? ` (${defectCount(res.name)} ${t('defects')})` : ''
                                        }`}
                                      >
                                        <Button
                                          id={`check_${0}:${r_index}`}
                                          translate="no"
                                          fullWidth={true}
                                          size="small"
                                          color="primary"
                                          sx={{
                                            height: '100%',
                                            background: `${defectCount(res.name) ? colors.red[50] : ''}`,
                                          }}
                                          onClick={(event) => handleSelectAllResClick(event, res.name + ':*:*')}
                                        >
                                          {res.short}
                                        </Button>
                                      </Tooltip>
                                    </TableCell>

                                    {days.map((day, d_index) => {
                                      return times.map((time, t_index) => {
                                        const isItemSelected = isCellSelected(res.name + ':' + d_index + ':' + t_index);
                                        const cell = getAssignmentCellTextColor(res.name, d_index + ':' + t_index);
                                        const cellText = cell[0];
                                        const cellColor = cell[1];
                                        return (
                                          <TableCell
                                            key={`cell_${d_index}_${t_index}`}
                                            align="center"
                                            sx={{
                                              borderRight: t_index + 1 === times.length ? `2px solid ${grey[300]}` : '',
                                            }}
                                          >
                                            <Card sx={{ height: 32, minWidth: 32 }} elevation={0}>
                                              {cellText === '#' ? (
                                                <ToggleButton
                                                  value="check"
                                                  id={`check_${d_index}_${t_index}`}
                                                  fullWidth
                                                  size="small"
                                                  sx={{
                                                    pointerEvents: 'none',
                                                    height: '100%',
                                                    backgroundColor: grey[200],
                                                  }}
                                                  // onClick={(event) => handleClick(event, d_index + ':' + t_index)}
                                                  selected
                                                >
                                                  <CloseIcon sx={{ color: grey[600] }}></CloseIcon>
                                                </ToggleButton>
                                              ) : cellText === '=' ? (
                                                <ToggleButton
                                                  value="check"
                                                  id={`check_${d_index}_${t_index}`}
                                                  fullWidth
                                                  size="small"
                                                  sx={{
                                                    pointerEvents: 'none',
                                                    height: '100%',
                                                    backgroundColor: grey[200],
                                                  }}
                                                  // onClick={(event) => handleClick(event, d_index + ':' + t_index)}
                                                  selected
                                                >
                                                  <RemoveIcon sx={{ color: grey[600] }}></RemoveIcon>
                                                </ToggleButton>
                                              ) : cellText === '.' ? (
                                                <ToggleButton
                                                  value="check"
                                                  id={`check_${d_index}_${t_index}`}
                                                  fullWidth
                                                  translate="no"
                                                  size="small"
                                                  sx={{
                                                    pointerEvents: 'none',
                                                    textTransform: 'none',
                                                    fontWeight: 'normal',
                                                    height: '100%',
                                                    color: '#ffffff',
                                                    backgroundColor: '#ffffff',
                                                  }}
                                                  selected={false}
                                                ></ToggleButton>
                                              ) : (
                                                <Tooltip title={cellText.replaceAll(' | ', ' • ')}>
                                                  <ToggleButton
                                                    value="check"
                                                    id={`check_${d_index}_${t_index}`}
                                                    fullWidth
                                                    translate="no"
                                                    size="small"
                                                    sx={{
                                                      textTransform: 'none',
                                                      fontWeight: 'normal',
                                                      // fontSize: 12,
                                                      height: '100%',
                                                      color: '#ffffff',
                                                      backgroundColor: cellColor === '' ? 'primary' : cellColor,
                                                    }}
                                                    onClick={(event: any) =>
                                                      handleClick(event, res.name + ':' + d_index + ':' + t_index)
                                                    }
                                                    selected={isItemSelected}
                                                  >
                                                    <Typography fontSize={12} translate="no" noWrap>
                                                      {
                                                        subjects.find((sub) => sub.name === cellText.split(' | ')[0])
                                                          ?.short
                                                      }
                                                    </Typography>
                                                    {isItemSelected && (
                                                      <LockIcon
                                                        sx={{
                                                          paddingLeft: 1,
                                                          justifyContent: 'flex-end',
                                                          alignSelf: 'flex-end',
                                                        }}
                                                      ></LockIcon>
                                                    )}
                                                  </ToggleButton>
                                                </Tooltip>
                                              )}
                                            </Card>
                                          </TableCell>
                                        );
                                      });
                                    })}
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>

                    <Grid container spacing={1} justifyContent="flex-end" alignItems="flex-end" paddingTop={1}>
                      <Grid item xs={6} md={2}>
                        <Button
                          className="defects-button"
                          startIcon={<Error />}
                          variant="text"
                          size="small"
                          onClick={() => setDefectsOpen(true)}
                          color={solution.defects.length !== 0 ? 'error' : 'primary'}
                          fullWidth
                        >
                          {`${t('Defects')}${solution.defects.length !== 0 ? ` (${solution.defects.length})` : ''}`}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          className="lock-button"
                          startIcon={<LockIcon />}
                          variant="text"
                          size="small"
                          disabled={readOnly}
                          onClick={() => setLockConfirmationOpen(true)}
                          color="primary"
                          fullWidth
                        >
                          {t('Lock') + (selectedCells.length === 0 ? '' : ` (${selectedCells.length})`)}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          startIcon={<LockOpenIcon />}
                          variant="text"
                          size="small"
                          disabled={readOnly}
                          onClick={() => {
                            if (selectedCells.length === 0) toast.warning(t('All assignments are already unlocked'));
                            else toast.success(t('All assignments unlocked'));
                            setSelectedCells([]);
                          }}
                          color="primary"
                          fullWidth
                        >
                          {t('Unlock')}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          startIcon={<FileUploadIcon />}
                          variant="text"
                          size="small"
                          disabled={readOnly}
                          onClick={() => setImportOpen(true)}
                          color="primary"
                          fullWidth
                        >
                          {t('Import')}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          startIcon={<FileDownloadIcon />}
                          variant="text"
                          size="small"
                          disabled={readOnly}
                          onClick={() => setExportOpen(true)}
                          color="primary"
                          fullWidth
                        >
                          {t('Export')}
                        </Button>
                      </Grid>
                      <Grid item xs={6} md={2}>
                        <Button
                          className="configs-button"
                          startIcon={<DisplaySettingsIcon />}
                          variant="text"
                          size="small"
                          disabled={readOnly}
                          onClick={() => setConfigsOpen(true)}
                          color="primary"
                          fullWidth
                        >
                          {t('Configs')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      {/* <Onboarding run={runSolutionCreation} steps={steps} storageFlagKey={solutionCreationStorageFlagKey} /> */}
    </Container>
  );
}
