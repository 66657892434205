import File from 'pages/Files/File';
import Files from 'pages/Files/Files';
import Home from 'pages/Home/Home';
import Lesson from 'pages/Lessons/Lesson';
import Lessons from 'pages/Lessons/Lessons';
import { Resource } from 'pages/Resources';
import Resources from 'pages/Resources/Resources';
import Settings from 'pages/Settings/Settings';
import { Solution } from 'pages/Solutions';
import Solutions from 'pages/Solutions/Solutions';
import { Subject } from 'pages/Subjects';
import Subjects from 'pages/Subjects/Subjects';
import { Switch, Route } from 'react-router-dom';

import TermsOfUse from 'pages/TermsAndPrivacy/TermsOfUse';
import PrivacyPolicy from 'pages/TermsAndPrivacy/PrivacyPolicy';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/terms" component={TermsOfUse} />
      <Route exact path="/privacy" component={PrivacyPolicy} />
      <Route exact path="/file" component={File} />
      <Route exact path="/files" component={Files} />
      <Route exact path="/class">
        <Resource type={'class'} />
      </Route>
      <Route exact path="/teacher">
        <Resource type={'teacher'} />
      </Route>
      <Route exact path="/room">
        <Resource type={'room'} />
      </Route>
      <Route exact path="/classes">
        <Resources type={'class'} />
      </Route>
      <Route exact path="/teachers">
        <Resources type={'teacher'} />
      </Route>
      <Route exact path="/rooms">
        <Resources type={'room'} />
      </Route>
      <Route exact path="/subject" component={Subject} />
      <Route exact path="/subjects" component={Subjects} />
      <Route exact path="/lesson" component={Lesson} />
      <Route exact path="/lessons" component={Lessons} />
      <Route exact path="/solution" component={Solution} />
      <Route exact path="/solutions" component={Solutions} />
      <Route exact path="/settings" component={Settings} />
    </Switch>
  );
};

export { Routes };
