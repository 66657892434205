import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Grid, Typography } from '@material-ui/core';
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@mui/material/colors';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableAvatarPicker(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const resource = props.resource;
  const setSelectedColor = props.setSelectedColor;
  // const setSelectedPhotoURL = props.setSelectedPhotoURL;

  const colors: any[] = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown,
    grey,
    blueGrey,
  ];

  const intensities: number[] = [100, 200, 300, 400, 500, 600, 700, 800, 900];

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeColor = (color: string) => {
    setSelectedColor(color);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('Avatar Color')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('Select the avatar color for')} {resource.name}
          </DialogContentText>
        </DialogContent>
        <Grid container spacing={1} padding={6} paddingTop={2} justifyContent="center" alignItems="center">
          {intensities.map((intensity, index) => {
            return (
              <Box key={`color_${index}_0`}>
                {colors.map((color, index2) => {
                  return (
                    <Grid key={`color_${index}_${index2}`} item xs={1} style={{ margin: '0px', padding: '0px' }}>
                      {color[intensity] === resource.color ? (
                        <Box
                          onClick={() => handleChangeColor(color[intensity])}
                          sx={{
                            width: 40,
                            height: 20,
                            border: '2px solid #444444',
                            boxSizing: 'border-box',
                            backgroundColor: color[intensity],
                            '&:hover': {
                              border: '2px solid #444444',
                              boxSizing: 'border-box',
                              backgroundColor: color[intensity],
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                        ></Box>
                      ) : (
                        <Box
                          onClick={() => handleChangeColor(color[intensity])}
                          sx={{
                            width: 40,
                            height: 20,
                            backgroundColor: color[intensity],
                            '&:hover': {
                              border: '2px solid #444444',
                              boxSizing: 'border-box',
                              backgroundColor: color[intensity],
                              opacity: [0.9, 0.8, 0.7],
                            },
                          }}
                        ></Box>
                      )}
                    </Grid>
                  );
                })}
              </Box>
            );
          })}
        </Grid>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
}
