import { Stripe, loadStripe } from '@stripe/stripe-js';

let stripePromisse: Stripe | null;

const initializeStripe = async () => {
  if (!stripePromisse) {
    stripePromisse = await loadStripe(
      'pk_live_51NDVaPHd2WlRmyn4PY1sBVgQ6RJhfY5TXxgpEakK65IeLLRh5ttr7ymwT6fEMPPMNjvaAk23iQ9Xed49BoCBAOTr008E0XNIgw'
      // 'pk_test_51NDVaPHd2WlRmyn4WLVsCR6hjeUERAnU8pbij1PywnjIwshWlgOriVGwdD1wXyxq1dgG5hI5UyOK4gkgIM1qiULe00TrqezWq3'
    );
  }
  return stripePromisse;
};

export default initializeStripe;
