import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from 'firebase/auth';

import { auth, db } from 'services';
import { collection, onSnapshot, doc } from 'firebase/firestore';
import { IFile } from 'pages/Files/File';
import { IResource } from 'pages/Resources/Resource';
import { ISubject } from 'pages/Subjects/Subject';
import { ILesson } from 'pages/Lessons/Lesson';
import { ISolution } from 'pages/Solutions/Solution';

export interface ISingIn {
  email: string;
  password: string;
}

export interface SharedWithMe {
  user: string;
  file: string;
  mode: string;
}

export interface IUser {
  email: string;
  password: string;
  name: string;
  phone: string;
  photoURL: string;
  countryCode: string;
  selectedFile: string;
  uid?: string;
  sharedWithMe?: SharedWithMe[];
}

interface ISessionContext {
  authUser: User | null;
  user: IUser | null;
  file: IFile | null;
  files: IFile[];
  resources: IResource[];
  subjects: ISubject[];
  lessons: ILesson[];
  solutions: ISolution[];
  sharedWithMe: SharedWithMe[];
  configs: IConfigs | undefined;
  ownerEmail: string;
  shareMode: string;
  setOwnerEmail: React.Dispatch<React.SetStateAction<string>>;
  setShareMode: React.Dispatch<React.SetStateAction<string>>;
  windowDimensions: { width: number; height: number };
}

interface IConfigs {
  courtesyPremium: string[];
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const SessionContext = React.createContext({} as ISessionContext);

const SessionProvider = ({ children }: { children?: React.ReactNode }) => {
  const history = useHistory();
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const [file, setFile] = useState<IFile | null>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [resources, setResources] = useState<IResource[]>([]);
  const [subjects, setSubjects] = useState<ISubject[]>([]);
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [solutions, setSolutions] = useState<ISolution[]>([]);
  const [sharedWithMe, setSharedWithMe] = useState<SharedWithMe[]>([]);
  const [configs, setConfigs] = useState<IConfigs | undefined>();
  const [ownerEmail, setOwnerEmail] = useState<string>('');
  const [shareMode, setShareMode] = useState<string>('');

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  //User auth effect
  useEffect(() => {
    auth.onAuthStateChanged((userData) => {
      if (userData) {
        setAuthUser(userData);
        history.push('/');
      } else {
        setAuthUser(null);
        history.push('/');
      }
    });
  }, [history]);

  //User info effect
  useEffect(() => {
    onSnapshot(collection(db, 'users'), (snapshot) => {
      const users = snapshot.docs.map((doc) => {
        return Object.assign({ ...doc.data() }, { id: doc.id });
      }) as unknown as IUser[];
      users.forEach((s) => {
        if (s.email === authUser?.email) {
          setUser(s);
        }
      });
    });
  }, [authUser?.email]);

  //Select file effect
  useEffect(() => {
    if (user?.selectedFile === '') return;
    try {
      const userStr = ownerEmail === '' ? user?.email : ownerEmail;
      onSnapshot(doc(db, 'users/' + userStr + '/files/' + user?.selectedFile), (snapshot) => {
        const dataFile = snapshot.data() as unknown as IFile;
        setFile(dataFile);
      });
    } catch (error) {
      console.error(error);
    }
  }, [ownerEmail, user?.email, user?.selectedFile]);

  //Select files effect
  useEffect(() => {
    try {
      onSnapshot(collection(db, 'users/' + authUser?.email + '/files'), (snapshot) => {
        const dataFiles = snapshot.docs.map((doc) => {
          return Object.assign({ ...doc.data() }, { id: doc.id });
        }) as unknown as IFile[];
        setFiles(dataFiles);
      });
    } catch (error) {
      console.error(error);
    }
  }, [authUser?.email]);

  //Select resources effect
  useEffect(() => {
    if (user?.selectedFile === '') return;
    const userStr = ownerEmail === '' ? user?.email : ownerEmail;
    const path = '/users/' + userStr + '/files/' + user?.selectedFile + '/resources';
    onSnapshot(collection(db, path), (snapshot) => {
      const dataResources = snapshot.docs.map((doc) => {
        return Object.assign({ ...doc.data() }, { id: doc.id });
      }) as unknown as IResource[];
      setResources(dataResources);
    });
  }, [ownerEmail, user?.email, user?.selectedFile]);

  //Select subjects effect
  useEffect(() => {
    if (user?.selectedFile === '') return;
    const userStr = ownerEmail === '' ? user?.email : ownerEmail;
    const path = '/users/' + userStr + '/files/' + user?.selectedFile + '/subjects';
    onSnapshot(collection(db, path), (snapshot) => {
      const dataSubjects = snapshot.docs.map((doc) => {
        return Object.assign({ ...doc.data() }, { id: doc.id });
      }) as unknown as ISubject[];
      setSubjects(dataSubjects);
    });
  }, [ownerEmail, user?.email, user?.selectedFile]);

  //Select lessons effect
  useEffect(() => {
    if (user?.selectedFile === '') return;
    const userStr = ownerEmail === '' ? user?.email : ownerEmail;
    const path = '/users/' + userStr + '/files/' + user?.selectedFile + '/lessons';
    onSnapshot(collection(db, path), (snapshot) => {
      const dataLessons = snapshot.docs.map((doc) => {
        return Object.assign({ ...doc.data() }, { id: doc.id });
      }) as unknown as ILesson[];
      setLessons(dataLessons);
    });
  }, [ownerEmail, user?.email, user?.selectedFile]);

  //Select solutions effect
  useEffect(() => {
    if (user?.selectedFile === '') return;
    const userStr = ownerEmail === '' ? user?.email : ownerEmail;
    const path = '/users/' + userStr + '/files/' + user?.selectedFile + '/solutions';
    onSnapshot(collection(db, path), (snapshot) => {
      const dataSolutions = snapshot.docs.map((doc) => {
        return Object.assign({ ...doc.data() }, { id: doc.id });
      }) as unknown as ISolution[];
      setSolutions(dataSolutions);
    });
  }, [ownerEmail, user?.email, user?.selectedFile]);

  //Select configs premium users
  useEffect(() => {
    // const path = '/configs/v1';
    onSnapshot(doc(db, 'configs', 'v1'), (snapshot) => {
      const dataConfigs = snapshot.data() as IConfigs;
      setConfigs(dataConfigs);
    });
  }, []);

  //Select sharings effect
  useEffect(() => {
    const path = '/sharings/' + user?.email;
    onSnapshot(doc(db, path), (snapshot) => {
      const sharedWithMe = snapshot.data()?.sharedWithMe as SharedWithMe[];
      // const sharedWithMe = snapshot.docs.map((doc) => {
      //   return Object.assign({ ...doc.data().sharedWithMe }, { id: doc.id });
      // }) as unknown as SharedWithMe[];
      setSharedWithMe(sharedWithMe);
    });
  }, [user?.email]);

  return (
    <SessionContext.Provider
      value={{
        authUser: authUser,
        user: user,
        file: file,
        files,
        resources,
        subjects,
        lessons,
        solutions,
        sharedWithMe,
        configs,
        ownerEmail,
        shareMode,
        setOwnerEmail,
        setShareMode,
        windowDimensions,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
