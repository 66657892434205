import React, { useRef, ChangeEvent } from 'react';
import { Button, IconButton, Input } from '@mui/material';
import { Avatar, CardMedia, colors } from '@material-ui/core';
import { db, storage } from 'services';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { IUser } from 'contexts/SessionContext/SessionContext';
import { IFile } from './File';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LoadingIndicator from 'components/LoadingIndicator';

export default function FileAvatarFile(props: any) {
  const user: IUser = props.user;
  const photoURL: string = props.photoURL;
  const setPhotoURL = props.setPhotoURL;
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const handleFileClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const selectedFile = files[0];
      // Handle the selected file here

      try {
        // const randomFileName = uuidv4();
        setIsLoading(true);
        const storageRef = ref(storage, `images/${user.uid}/profile/${uuidv4()}`);
        await uploadBytes(storageRef, selectedFile);

        const downloadUrl = await getDownloadURL(storageRef);

        setPhotoURL(downloadUrl);
        // updateDoc(fileDoc, {
        //   photoURL: downloadUrl,
        // }).then(() => {
        //   setAvatarUrl(downloadUrl);
        // });
        setIsLoading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".jpg,.jpeg,.png"
        onChange={handleFileChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      <IconButton
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '0px',
          padding: '0px',
          // marginLeft: '0px',
        }}
        onClick={() => {
          handleFileClick();
        }}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : photoURL ? (
          <CardMedia component="img" height="160px" image={photoURL} alt="Photo" />
        ) : (
          <AccountBalanceIcon sx={{ fontSize: 60, height: '160px' }} htmlColor={colors.grey[600]} />
        )}
      </IconButton>

      {/* <IconButton onClick={handleFileClick}>
        <Avatar sx={{ width: 96, height: 96 }} alt={user?.name} src={user?.photoURL} />
      </IconButton> */}
    </div>
  );
}
