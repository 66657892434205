import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function ValueSelectConfirmDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const setConfirmed = props.setConfirmed;
  const title = props.title;
  const message = props.message;

  const valueOptions: number[] = props.valueOptions;
  const value: string = props.value;
  const setValue = props.setValue;

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    localStorage.setItem('copyClass', value);
    setConfirmed(true);
    setOpen(false);
  };

  const handleChangeValue = (event: SelectChangeEvent) => {
    setValue(event.target.value as unknown as string);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <Grid item sx={{ padding: 4 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="days-select-label">{title}</InputLabel>
              <Select
                size="small"
                labelId="value-select-label"
                id="value-select"
                defaultValue={'' + value}
                onChange={handleChangeValue}
                label={title}
              >
                {valueOptions.map((valueOption, index) => {
                  return (
                    <MenuItem key={`lessonsWeek_${index}`} value={valueOption}>
                      {valueOption}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleConfirm}>{t('Confirm')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
