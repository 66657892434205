import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { grey } from '@material-ui/core/colors';
import { Chip } from '@material-ui/core';

export default function BasicStandardSelect(props: any) {
  const current: string = props.current;
  const setCurrent = props.setCurrent;
  const options: string[] = props.options;
  const label: string = props.label;

  const handleChange = (event: SelectChangeEvent) => {
    setCurrent(event.target.value);
  };

  return (
    <FormControl variant="standard" fullWidth size="small">
      <InputLabel
        sx={{ color: grey[50], input: { color: grey[50] }, label: { color: grey[50] } }}
        id="demo-simple-select-label"
      >
        {label}
      </InputLabel>
      <Select
        translate="no"
        sx={{ input: { color: grey[50] }, label: { color: grey[50] } }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={'' + current}
        label={label}
        onChange={handleChange}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            <Chip translate="no" key={selected} label={selected} sx={{ backgroundColor: grey[200] }} />
          </Box>
        )}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={`${label}_${index}`} value={option}>
              {option}
            </MenuItem>
          );
        })}
        {/* <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem> */}
      </Select>
    </FormControl>
  );
}
