import getStripe from './initializeStripe';
import { db } from '../services';
import { addDoc, collection, DocumentData, onSnapshot } from '@firebase/firestore';

const BASIC_PRICE_ID = 'price_1NFO1UHd2WlRmyn4Iu5AhfBr';
const PREMIUM_PRICE_ID = 'price_1NFNz0Hd2WlRmyn4STiPQUyU';
// const BASIC_PRICE_ANNUAL_ID = 'price_1NEkwYHd2WlRmyn4gDb45wJr';
// const PREMIUM_PRICE_ANNUAL_ID = 'price_1NEkyUHd2WlRmyn42aYeUA9J';
// const BASIC_PRICE_ID = 'price_1NEaCFHd2WlRmyn4AopEkj06';
// const PREMIUM_PRICE_ID = 'price_1NDZ33Hd2WlRmyn4rzkqO0UG';

export async function createCheckoutSession(uid: string, product: string) {
  //Create a new checkout session in the subcollection inside this users document
  const checkoutSessionRef = await addDoc(collection(db, `customers/${uid}/checkout_sessions`), {
    price: product === 'basic' ? BASIC_PRICE_ID : PREMIUM_PRICE_ID,
    success_url: `${window.location.origin}/settings`,
    cancel_url: `${window.location.origin}/settings`,
  });

  // Wait for the checkoutSession to get attached by the extension
  onSnapshot(checkoutSessionRef, async (snapshot) => {
    const { sessionId } = snapshot.data() as DocumentData;
    if (sessionId) {
      // We have a session let's redirect to checkout
      // Init stripe
      const stripe = await getStripe();
      stripe?.redirectToCheckout({ sessionId });
    }
  });
}
