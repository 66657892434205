import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  colors,
  useMediaQuery,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MiniDrawer from 'components/MiniDrawer';
import { DataGrid, GridColDef, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { SessionContext } from 'contexts';
import { doc, getDoc, Timestamp, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { db } from 'services';
import DraggableDialog from 'components/DraggableDialog';
import { red, green, amber, grey } from '@mui/material/colors';
import { ILesson } from 'pages/Lessons/Lesson';
import ImportSubjectsDialog from './components/ImportSubjectsDialog';
import SimpleBackdrop from 'components/SimpleBackdrop';
import { useTranslation } from 'react-i18next';
import OnboardingContent from 'components/OnboardingContent';
import dayjs from 'dayjs';
import InfoDialog from 'components/InfoDialog';
import { ContentPaste } from '@mui/icons-material';
import { priorityColors } from 'styles/priorityColors';
import { setRandomColor } from 'styles/randomColor';
import { Help } from '@material-ui/icons';
import VideoPlayerDialog from 'components/VideoPlayerDialog';

interface SubjectData {
  short: string;
  color: string;
  photoURL: string;
  id: string;
  priority: string;
  numlinkedLessons: number;
  sumLessonsWeek: number;
  availability: number;
  unavailableTimes: string[];
  undesiredTimes: string[];
  updatedAt: Date;
}

function createData(
  short: string,
  color: string,
  photoURL: string,
  id: string,
  priority: string,
  numlinkedLessons: number,
  sumLessonsWeek: number,
  availability: number,
  unavailableTimes: string[],
  undesiredTimes: string[],
  updatedAt: Date
): SubjectData {
  return {
    short,
    color,
    photoURL,
    id,
    priority,
    numlinkedLessons,
    sumLessonsWeek,
    availability,
    unavailableTimes,
    undesiredTimes,
    updatedAt,
  };
}

export default function Subjects(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { subjects, lessons, solutions, file, user, ownerEmail, shareMode } = useContext(SessionContext);
  const [pageSize, setPageSize] = React.useState<number>(100);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  if (file?.name === undefined) {
    history.push('/files');
    toast.warning(t('Select a file first'));
  }

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const subjectsPath = `users/${ownerEmail}/files/${file?.name}/subjects`;
  const lessonsPath = `users/${ownerEmail}/files/${file?.name}/lessons`;
  const solutionsPath = `users/${ownerEmail}/files/${file?.name}/solutions`;
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];

  const columns: GridColDef[] = [
    {
      field: 'short',
      headerName: ' ',
      minWidth: 32,
      flex: 0.0001,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      hide: true,
      renderCell: (params) => {
        return (
          <Grid item container alignItems="center" justifyContent="center">
            <Avatar
              translate="no"
              sx={{
                width: 28,
                height: 28,
                fontSize: params.row.short.length > 3 ? '8px' : '12px',
                bgcolor: params.row.color,
              }}
              alt={params.value}
              src={params.row.photoURL}
            >
              {params.row.short}
            </Avatar>
          </Grid>
        );
      },
    },
    {
      field: 'id',
      headerName: t('Name'),
      flex: 3,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            translate="no"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: params.row.color, color: 'white', border: '0px' }}
            label={params.value}
          />
        );
      },
    },
    {
      field: 'priority',
      headerName: t('Priority'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900,
      renderCell: (params) => {
        // return t(params.value);
        return (
          <Chip
            translate="no"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: priorityColors[params.value], color: 'white', border: '0px' }}
            label={t(params.value)}
          />
        );
      },
    },
    {
      field: 'numlinkedLessons',
      headerName: t('Lessons'),
      flex: 1,
      type: 'number',
      minWidth: 100,
      hide: widthLessThan900,
    },
    {
      field: 'sumLessonsWeek',
      headerName: t('Times'),
      flex: 1,
      type: 'number',
      minWidth: 100,
      hide: widthLessThan900,
    },
    {
      field: 'availability',
      headerName: t('Availability'),
      flex: 1,
      minWidth: 100,
      // hide: widthLessThan600,
      renderCell: (params) => {
        const cellHeight = 28 / times.length;
        return (
          <TableContainer>
            <Table translate="no" size="small" padding="none">
              <TableBody>
                {times.map((time, t_index) => {
                  return (
                    <TableRow key={`time_${t_index}`}>
                      {days.map((day, d_index) => {
                        return (
                          <TableCell
                            key={`cell_${d_index}_${t_index}`}
                            sx={{
                              border: 0.5,
                              borderColor: grey[600],
                              height: `${cellHeight}px`,
                              backgroundColor: `${
                                params.row.unavailableTimes.includes('' + d_index + ':' + t_index)
                                  ? red[200]
                                  : params.row.undesiredTimes.includes('' + d_index + ':' + t_index)
                                  ? amber[200]
                                  : green[200]
                              }`,
                            }}
                            align="center"
                          ></TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: t('Updated At'),
      flex: 1,
      minWidth: 130,
      headerAlign: 'right',
      align: 'right',
      hide: widthLessThan600,
      renderCell: (params) => {
        return (
          <Typography fontSize={14}>
            {user?.countryCode === 'BR'
              ? dayjs(params.row.updatedAt).format('DD/MM/YY HH:mm[h]')
              : dayjs(params.row.updatedAt).format('MM-DD-YY hh:mma')}
          </Typography>
        );
      },
    },
  ];

  function getNumTimesWeek(lessons: ILesson[], subjectName: string) {
    let numtimesWeek: number = 0;
    lessons
      .filter((lesson) => lesson.subject[0] === subjectName)
      .forEach((lesson) => (numtimesWeek += +lesson.lessonsWeek));
    return numtimesWeek;
  }
  let rows: any[] = [];
  subjects.map(function (subject, index) {
    return rows.push(
      createData(
        subject.short,
        subject.color,
        subject.photoURL,
        subject.name,
        subject.priority,
        lessons.filter((lesson) => lesson.subject[0] === subject.name).length,
        getNumTimesWeek(lessons, subject.name),
        subject.unavailableTimes.length * 100 + subject.undesiredTimes.length,
        subject.unavailableTimes,
        subject.undesiredTimes,
        (subject.updatedAt as unknown as Timestamp).toDate()
      )
    );
  });

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const docRef = doc(db, subjectsPath, name);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        // Redirect to resource page
        const subjectData = { ...docSnap.data() };
        history.push({ pathname: '/subject', state: subjectData });
      } else {
        // doc.data() will be undefined in this case
        toast.error(t('No such document!'));
        console.error('No such document!');
      }
    });
  };

  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      setLoadingOpen(true);
      setDeleteConfirmed(false);
      const batch = writeBatch(db);
      selectionModel.forEach((selectedId) => {
        batch.delete(doc(db, subjectsPath, selectedId.toString()));
        //Delete related lessons
        lessons
          .filter((lesson) => lesson.subject[0] === selectedId.toString())
          .forEach((lesson) => batch.delete(doc(db, lessonsPath, lesson.name)));
        //Set solutions to outdated
        solutions.forEach((solution) => {
          batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
            status: 'Outdated',
          });
        });
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch.commit().then(() => {
        setLoadingOpen(false);
        toast.success(selectionModel.length + t(' subjects deleted sucessfully'));
      });
    }
  };

  checkDeleteConfirmation();

  const [importOpen, setImportOpen] = useState(false);

  const [runEmptySubjects, setRunEmptySubjects] = useState(false);
  const emptySubjectsStorageFlagKey = 'onboarding_empty_subjects';

  useEffect(() => {
    if (localStorage.getItem(emptySubjectsStorageFlagKey) !== 'true') {
      setRunEmptySubjects(true);
    }
  }, []);

  const stepsEmptySubjects = [
    {
      target: '.subjects-list',
      disableBeacon: true,
      content: (
        <OnboardingContent title={t('List of subjects')} message={t('All subjects of your file are displayed here')} />
      ),
    },
    {
      target: '.import-button',
      content: (
        <OnboardingContent
          title={t('Import subjects')}
          message={t(
            'You can import subjects from any spreasheet software, just head to your existing spreadsheet, select the data, hit CTRL+C, then click this button'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: (
        <OnboardingContent title={t('Add subject')} message={t('To add a subject manually, click CREATE button')} />
      ),
    },
  ];

  const [clipboardRequestInfoOpen, setClipboardRequestInfoOpen] = useState(false);
  const [clipboardDeniedInfoOpen, setClipboardDeniedInfoOpen] = useState(false);

  const handleImportClick = async () => {
    setImportOpen(true);
    const permission = await navigator.permissions.query({ name: 'clipboard-read' } as any);
    if (permission.state === 'denied') {
      setClipboardDeniedInfoOpen(true);
    }
    if (permission.state === 'prompt') {
      setClipboardRequestInfoOpen(true);
    }
  };

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  return (
    <Container
      maxWidth={false}
      style={{ padding: '56px 8px 8px 65px', minHeight: '100vh', backgroundColor: colors.grey[50] }}
    >
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={
          selectionModel.length + t(' subjects and their linked lessons will be permanently deleted. Are you sure?')
        }
      />
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_subjects')}
        ></VideoPlayerDialog>
      )}
      <ImportSubjectsDialog
        open={importOpen}
        setOpen={setImportOpen}
        title={t('Import subjects')}
        message={t('Import message')}
      />
      {clipboardRequestInfoOpen && (
        <InfoDialog
          open={clipboardRequestInfoOpen}
          setOpen={setClipboardRequestInfoOpen}
          title={'Permissão necessária'}
          message={
            <>
              <Grid container justifyContent="center" padding={4}>
                <Grid item>
                  <ContentPaste
                    fontSize="large"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  />
                </Grid>
              </Grid>
              <Typography>{t('Precisamos acessar seu clipboard (CTRL+C) para importar os dados!')}</Typography>
              <Typography>{t('Por favor conceda a permissão solicitada no canto superior da tela.')}</Typography>
            </>
          }
        />
      )}
      {clipboardDeniedInfoOpen && (
        <InfoDialog
          open={clipboardDeniedInfoOpen}
          setOpen={setClipboardDeniedInfoOpen}
          title={'Permissão necessária'}
          message={
            <>
              <Grid container justifyContent="center" padding={4}>
                <Grid item>
                  <ContentPaste
                    fontSize="large"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  />
                </Grid>
              </Grid>
              <Typography>{t('Parece que você negou a permissão de acesso ao seu clipboard (CTRL+C) =(')}</Typography>
              <Typography>
                {t('Por favor conceda a permissão nas configurações do seu navegador e tente novamente.')}
              </Typography>
            </>
          }
        />
      )}
      <MiniDrawer />
      <Grid container justifyContent="center">
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
          <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
            <Grid item flexGrow={1}>
              <Typography variant="body1" gutterBottom sx={{ color: '#1976D2' }}>
                {/* <Typography variant="h6" gutterBottom color={'primary'}> */}
                {'5. ' + t('Subjects')}
              </Typography>
              <Divider color="#1976D2" />
            </Grid>
            <Grid item alignSelf={'center'}>
              <Tooltip title={t('Watch a helpful video')}>
                <IconButton
                  style={{ height: 24, width: 24 }}
                  color="primary"
                  onClick={() => setVideoPlayerDialogOpen(true)}
                >
                  <Help style={{ height: 24, width: 24 }} />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <Grid item container flexDirection={'row'} spacing={1}> */}
            {selectionModel.length !== 0 ? (
              <Grid item xs={3} md={2}>
                <Button
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => setDeleteConfirmationOpen(true)}
                  color="error"
                >
                  {`${t('Delete')} (${selectionModel.length})`}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={3} md={2}>
                <Button
                  className="import-button"
                  startIcon={<FileDownloadIcon />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => handleImportClick()}
                  color="primary"
                >
                  {t('Import')}
                </Button>
              </Grid>
            )}
            <Grid item xs={3} md={2}>
              <Button
                className="create-button"
                startIcon={<AddCircleIcon />}
                variant="outlined"
                size="small"
                fullWidth
                disabled={readOnly}
                onClick={() => {
                  setRandomColor();
                  history.push('/subject');
                }}
                color="primary"
              >
                {t('Create')}
              </Button>
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Grid className={'subjects-list'} item xs={12} sx={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              // autoPageSize
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[20, 50, 100]}
              // hideFooterPagination
              density={'compact'}
              // loading={rows.length === 0}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'id', sort: 'asc' }],
                },
              }}
              localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
              onRowClick={(params, event) => handleClick(event, params.id.toString())}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <HighlightAltIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Click on IMPORT or CREATE to add subjects')}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SearchOffIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Local FILTER returns no data')}
                    </Typography>
                  </Stack>
                ),
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
            <Grid item xs={2}>
              <Button
                className="create-button"
                // startIcon={<AddCircleIcon />}
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => (file?.roomsSetting !== 0 ? history.push('/rooms') : history.push('/teachers'))}
                color="primary"
              >
                {t('Back')}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className="import-button"
                // startIcon={<FileDownloadIcon />}
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => history.push('/lessons')}
                color="primary"
              >
                {t('Next')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Onboarding steps={stepsEmptySubjects} run={runEmptySubjects} storageFlagKey={emptySubjectsStorageFlagKey} /> */}
    </Container>
  );
}
