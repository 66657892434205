export const planFeatures = {
  free: {
    fullPriceBRL: '0,00',
    priceBRL: '0,00',
    fullPriceUSD: '0.00',
    priceUSD: '0.00',
    maxFiles: 2,
    maxLessons: 100,
    maxRuntime: 300,
    exportFormats: [],
  },
  basic: {
    fullPriceBRL: '49,99',
    priceBRL: '29,99',
    fullPriceUSD: '19.99',
    priceUSD: '9.99',
    maxFiles: 10,
    maxLessons: 300,
    maxRuntime: 1800,
    exportFormats: ['xlsx'],
  },
  premium: {
    fullPriceBRL: '79,99',
    priceBRL: '49,99',
    fullPriceUSD: '29.99',
    priceUSD: '19.99',
    maxFiles: Number.POSITIVE_INFINITY,
    maxLessons: Number.POSITIVE_INFINITY,
    maxRuntime: 3600,
    exportFormats: ['xlsx', 'json', 'xml'],
  },
};
