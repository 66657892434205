import { useState, useEffect, useContext } from 'react';
import getUserPlan from './getUserPlan';
import { SessionContext } from 'contexts';

export default function useUserPlan(user: any) {
  const { configs } = useContext(SessionContext);
  //TODO set default plan to free
  const [userPlan, setUserPlan] = useState<string>('coutersy');

  useEffect(() => {
    if (user) {
      const checkUserPlanStatus = async function () {
        if (configs?.courtesyPremium.includes('' + user.email)) {
          setUserPlan('courtesy');
        } else {
          setUserPlan(await getUserPlan());
        }
      };
      checkUserPlanStatus();
    }
  }, [configs?.courtesyPremium, user]);

  return userPlan;
}
