import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  Tooltip,
  Typography,
  colors,
  useMediaQuery,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid, GridColDef, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { doc, deleteDoc, getDoc, Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { grey } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';

import MiniDrawer from 'components/MiniDrawer';
import { SessionContext } from 'contexts';
import { db } from 'services';
import DraggableDialog from 'components/DraggableDialog';
import OnboardingContent from 'components/OnboardingContent';
import Onboarding from 'components/Onboarding';
import FormTitle from 'components/FormTitle';
import dayjs from 'dayjs';
import LinearWithValueLabel from 'components/LinearWithValueLabel';
import VideoPlayerDialog from 'components/VideoPlayerDialog';
import { Help } from '@material-ui/icons';

interface FileData {
  id: string;
  status: string;
  cost: string;
  numAssignments: string;
  defects: string;
  runningTime: number;
  updatedAt: Date;
}

function createData(
  id: string,
  status: string,
  cost: string,
  numAssignments: string,
  defects: string,
  runningTime: number,
  updatedAt: Date
): FileData {
  return {
    id,
    status,
    cost,
    numAssignments,
    defects,
    runningTime,
    updatedAt,
  };
}

export default function Solutions() {
  const { t } = useTranslation();
  const history = useHistory();
  const { solutions, lessons, user, file, ownerEmail, shareMode } = useContext(SessionContext);
  const [pageSize, setPageSize] = React.useState<number>(100);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  if (file?.name === undefined) {
    history.push('/files');
    toast.warning(t('Select a file first'));
  }

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const solutionsPath = 'users/' + ownerEmail + '/files/' + user?.selectedFile + '/solutions';
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const widthLessThan600 = useMediaQuery('(max-width:600px)');

  const [solving, setSolving] = React.useState<boolean>(true);

  const recoverProgressTime = async (name: string) => {
    const ans = (await localStorage.getItem(name)) as unknown as number;
    return ans;
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('Notes'),
      flex: 7,
      minWidth: 120,
      renderCell: (params) => {
        return <Chip translate="no" variant="outlined" size="small" label={params.value} />;
      },
    },
    {
      field: 'status',
      headerName: t('Status'),
      flex: 2,
      minWidth: 100,
      renderCell: (params) => {
        const now = new Date().getTime();
        const updatedAt = localStorage.getItem(params.row.id) as unknown as number;
        return params.row.status === 'Processing' ? (
          <LinearWithValueLabel
            initTime={(now - updatedAt) / 1000}
            maxTime={params.row.runningTime}
            solving={solving}
            setSolving={setSolving}
          />
        ) : (
          <Chip
            translate="no"
            size="small"
            variant="outlined"
            color={
              params.row.status === 'Empty'
                ? 'error'
                : params.row.status === 'Outdated' || params.row.status === 'Processing'
                ? 'warning'
                : 'success'
            }
            label={t(params.row.status)}
          />
        );
      },
    },
    {
      field: 'numAssignments',
      headerName: t('Assignments'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'defects',
      headerName: t('Constraints met'),
      flex: 1,
      minWidth: 150,
      hide: widthLessThan900,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'cost',
      headerName: t('Opt closeness'),
      flex: 1,
      minWidth: 150,
      hide: widthLessThan600,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'updatedAt',
      headerName: t('Updated At'),
      flex: 2,
      minWidth: 130,
      headerAlign: 'right',
      align: 'right',
      hide: widthLessThan900,
      renderCell: (params) => {
        return (
          <Typography fontSize={14}>
            {user?.countryCode === 'BR'
              ? dayjs(params.row.updatedAt).format('DD/MM/YY HH:mm[h]')
              : dayjs(params.row.updatedAt).format('MM-DD-YY hh:mma')}
          </Typography>
        );
      },
    },
  ];

  let rows: any[] = [];
  solutions.map(function (solution, index) {
    return rows.push(
      createData(
        solution.name,
        solution.status,
        (solution.cost === 0 ? 100 : (solution.lowerBound / solution.cost) * 100).toFixed(1) + '%',
        ((solution.assignments.length / lessons.length) * 100).toFixed(1) + '%',
        (solution.assignments.length === 0
          ? 0
          : ((solution.numConstraints - solution.defects.length) / solution.numConstraints) * 100
        ).toFixed(1) + '%',
        solution.runningTime,
        (solution.updatedAt as unknown as Timestamp).toDate()
      )
    );
  });

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const docRef = doc(db, solutionsPath, name);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        let solutionData = { ...docSnap.data() };
        history.push({
          pathname: '/solution',
          state: JSON.stringify(solutionData),
        });
      } else {
        // doc.data() will be undefined in this case
        toast.error(t('No such document'));
        console.error('No such document!');
      }
    });
  };

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      selectionModel.forEach((selectedId) => {
        deleteDoc(doc(db, solutionsPath, selectedId.toString()));
      });
      toast.success(selectionModel.length + t(' timetables deleted sucessfully'));
      setDeleteConfirmed(false);
    }
  };

  checkDeleteConfirmation();

  const [runEmptySolutions, setRunEmptySolutions] = useState(false);
  const emptySolutionsStorageFlagKey = 'onboarding_empty_solutions';

  useEffect(() => {
    if (localStorage.getItem(emptySolutionsStorageFlagKey) !== 'true') {
      setRunEmptySolutions(true);
    }
  }, []);

  const stepsEmptySolutions = [
    {
      target: '.solutions-list',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('List of timetables')}
          message={t(
            'You can generate several timetables to the same file. All timetables of your file are displayed here'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: (
        <OnboardingContent
          title={t('Create timetable')}
          message={t('To create a new timetable, click CREATE button')}
        />
      ),
    },
  ];

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '56px 8px 8px 65px',
        minHeight: '100vh',
        backgroundColor: colors.grey[50],
      }}
    >
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_solutions')}
        ></VideoPlayerDialog>
      )}
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={selectionModel.length + t(' timetables will be permanently deleted. Are you sure?')}
      />
      <MiniDrawer />
      <Grid container justifyContent="center">
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
          <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
            <Grid item flexGrow={1}>
              <Typography variant="body1" gutterBottom sx={{ color: '#1976D2' }}>
                {/* <Typography variant="h6" gutterBottom color={'primary'}> */}
                {'7. ' + t('Timetables')}
              </Typography>
              <Divider color="#1976D2" />
            </Grid>
            <Grid item alignSelf={'center'}>
              <Tooltip title={t('Watch a helpful video')}>
                <IconButton
                  style={{ height: 24, width: 24 }}
                  color="primary"
                  onClick={() => setVideoPlayerDialogOpen(true)}
                >
                  <Help style={{ height: 24, width: 24 }} />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <Grid item container flexDirection={'row'} spacing={1}> */}
            {selectionModel.length !== 0 && (
              <Grid item xs={3} md={2}>
                <Button
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => setDeleteConfirmationOpen(true)}
                  color="error"
                >
                  {`${t('Delete')} (${selectionModel.length})`}
                </Button>
              </Grid>
            )}
            <Grid item xs={3} md={2}>
              <Button
                className="create-button"
                startIcon={<AddCircleIcon />}
                variant="outlined"
                size="small"
                fullWidth
                disabled={readOnly}
                onClick={() => {
                  history.push('/solution');
                }}
                color="primary"
              >
                {t('Create')}
              </Button>
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Grid className="solutions-list" item xs={12} sx={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              // autoPageSize
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[20, 50, 100]}
              // hideFooterPagination
              density={'compact'}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'updatedAt', sort: 'desc' }],
                },
              }}
              localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
              onRowClick={(params, event) => handleClick(event, params.id.toString())}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <HighlightAltIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Click on CREATE to add a new timetable')}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SearchOffIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Local FILTER returns no data')}
                    </Typography>
                  </Stack>
                ),
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
            <Grid item xs={2}>
              <Button
                className="create-button"
                // startIcon={<AddCircleIcon />}
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => history.push('/lessons')}
                color="primary"
              >
                {t('Back')}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className="import-button"
                style={{ visibility: 'hidden' }}
                // startIcon={<FileDownloadIcon />}
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => history.push('/settings')}
                color="primary"
              >
                {t('Next')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Onboarding steps={stepsEmptySolutions} run={runEmptySolutions} storageFlagKey={emptySolutionsStorageFlagKey} /> */}
    </Container>
  );
}
