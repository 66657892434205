import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { grey } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SwipeableTextMobileStepper() {
  const { t } = useTranslation();
  const images = [
    {
      label: t('1. Create a file'),
      imgPath: `/img/screenshots/${t('en')}/screenshot1.png`,
    },
    {
      label: t('2. Provide the input data'),
      imgPath: `/img/screenshots/${t('en')}/screenshot2.png`,
    },
    {
      label: t('3. Optionally, import the data'),
      imgPath: `/img/screenshots/${t('en')}/screenshot3.png`,
    },
    {
      label: t('4. Visualize the input'),
      imgPath: `/img/screenshots/${t('en')}/screenshot4.png`,
    },
    {
      label: t('5. Set the priority of requirements'),
      imgPath: `/img/screenshots/${t('en')}/screenshot5.png`,
    },
    {
      label: t('6. Generate the timetable'),
      imgPath: `/img/screenshots/${t('en')}/screenshot6.png`,
    },
    {
      label: t('7. Export the timetable'),
      imgPath: `/img/screenshots/${t('en')}/screenshot7.png`,
    },
  ];

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
      <Paper
        square
        elevation={0}
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 50,
          pl: 2,
          bgcolor: 'background.default',
        }}
      >
        <Typography color={grey[700]}>{images[activeStep].label}</Typography>
      </Paper>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  border: '1pt solid black',
                  height: 'auto',
                  display: 'block',
                  // maxWidth: 400,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
            {t('Next')}
            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            {t('Back')}
          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;
