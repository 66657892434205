import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Chip,
  colors,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import MiniDrawer from 'components/MiniDrawer';
import { DataGrid, GridColDef, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { SessionContext } from 'contexts';
import { doc, getDoc, Timestamp, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import SchoolIcon from '@mui/icons-material/School';
import { CoPresent, ContentPaste } from '@mui/icons-material';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useLocation } from 'react-router-dom';

import { db } from 'services';
import DraggableDialog from 'components/DraggableDialog';
import ImportResourcesDialog from './components/ImportResourcesDialog';
import SimpleBackdrop from 'components/SimpleBackdrop';
import { useTranslation } from 'react-i18next';
import Onboarding from 'components/Onboarding';
import OnboardingContent from 'components/OnboardingContent';
import FormTitle from 'components/FormTitle';
import dayjs from 'dayjs';
import InfoDialog from 'components/InfoDialog';
import { priorityColors } from 'styles/priorityColors';
import { setRandomColor } from 'styles/randomColor';
import { amber, green, grey, red } from '@material-ui/core/colors';
import VideoPlayerDialog from 'components/VideoPlayerDialog';
import { Help } from '@material-ui/icons';

interface ResourceData {
  short: string;
  color: string;
  photoURL: string;
  id: string;
  priority: string;
  workload: string;
  workingDays: string;
  idleWindow: string;
  dailyWorkload: string;
  restBetweenDays: string;
  roomChangesDay: string;
  consecutiveTimes: string;
  distinctSubjects: string;
  travelTime: string[];
  forbiddenCombination: string[];
  availability: number;
  unavailableTimes: string[];
  undesiredTimes: string[];
  updatedAt: Date;
  type: string;
}

function createData(
  short: string,
  color: string,
  photoURL: string,
  id: string,
  priority: string,
  workload: string,
  workingDays: string,
  idleWindow: string,
  dailyWorkload: string,
  restBetweenDays: string,
  roomChangesDay: string,
  consecutiveTimes: string,
  distinctSubjects: string,
  travelTime: string[],
  forbiddenCombination: string[],
  availability: number,
  unavailableTimes: string[],
  undesiredTimes: string[],
  updatedAt: Date,
  type: string
): ResourceData {
  return {
    short,
    color,
    photoURL,
    id,
    priority,
    workload,
    workingDays,
    idleWindow,
    restBetweenDays,
    roomChangesDay,
    dailyWorkload,
    consecutiveTimes,
    distinctSubjects,
    travelTime,
    forbiddenCombination,
    availability,
    unavailableTimes,
    undesiredTimes,
    updatedAt,
    type,
  };
}

export default function Resources(props: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const type = props.type;
  const history = useHistory();
  const { resources, file, user, lessons, solutions, ownerEmail, shareMode } = useContext(SessionContext);
  const [pageSize, setPageSize] = React.useState<number>(100);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  if (file?.name === undefined) {
    //TODO use a onboarding
    history.push('/files');
    toast.warning(t('Select a file first'));
  }

  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const resourcesPath = 'users/' + ownerEmail + '/files/' + file?.name + '/resources';
  const lessonsPath = `users/${ownerEmail}/files/${file?.name}/lessons`;
  const solutionsPath = `users/${ownerEmail}/files/${file?.name}/solutions`;
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];

  function showConstraint(constrType: string) {
    if (type === 'class') {
      if (constrType === 'Workload') return file?.showWorkloadClasses;
      else if (constrType === 'Working Days') return file?.showWorkingDaysClasses;
      else if (constrType === 'Idle Window') return file?.showIdleWindowClasses;
      else if (constrType === 'Daily Workload') return file?.showDailyWorkloadClasses;
      else if (constrType === 'Rest between Days') return file?.showRestBetweenDaysClasses;
      else if (constrType === 'Room Changes Day') return file?.showRoomChangesDayClasses;
      else if (constrType === 'Consecutive Times') return file?.showConsecutiveTimesClasses;
      else if (constrType === 'Distinct Subjects') return file?.showDistinctSubjectsClasses;
      else if (constrType === 'Forbidden Combination') return file?.showForbiddenCombinationClasses;
    } else if (type === 'teacher') {
      if (constrType === 'Workload') return file?.showWorkloadTeachers;
      else if (constrType === 'Working Days') return file?.showWorkingDaysTeachers;
      else if (constrType === 'Idle Window') return file?.showIdleWindowTeachers;
      else if (constrType === 'Daily Workload') return file?.showDailyWorkloadTeachers;
      else if (constrType === 'Rest between Days') return file?.showRestBetweenDaysTeachers;
      else if (constrType === 'Room Changes Day') return file?.showRoomChangesDayTeachers;
      else if (constrType === 'Consecutive Times') return file?.showConsecutiveTimesTeachers;
      else if (constrType === 'Distinct Subjects') return file?.showDistinctSubjectsTeachers;
      else if (constrType === 'Forbidden Combination') return file?.showForbiddenCombinationTeachers;
    } else if (type === 'room') {
      if (constrType === 'Workload') return file?.showWorkloadRooms;
      else if (constrType === 'Working Days') return file?.showWorkingDaysRooms;
      else if (constrType === 'Idle Window') return file?.showIdleWindowRooms;
      else if (constrType === 'Daily Workload') return file?.showDailyWorkloadRooms;
      else if (constrType === 'Rest between Days') return file?.showRestBetweenDaysRooms;
      else if (constrType === 'Room Changes Day') return file?.showRoomChangesDayRooms;
      else if (constrType === 'Consecutive Times') return file?.showConsecutiveTimesRooms;
      else if (constrType === 'Travel Time') return file?.showTravelTimeRooms;
      else if (constrType === 'Distinct Subjects') return file?.showDistinctSubjectsRooms;
      else if (constrType === 'Forbidden Combination') return file?.showForbiddenCombinationRooms;
    }
    return false;
  }

  const columns: GridColDef[] = [
    {
      field: 'short',
      headerName: ' ',
      minWidth: 32,
      flex: 0.0001,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      hide: true,
      renderCell: (params) => {
        return (
          <Grid item container alignItems="center" justifyContent="center">
            <Avatar
              translate="no"
              sx={{
                width: 28,
                height: 28,
                fontSize: params.row.short.length > 3 ? '8px' : '12px',
                bgcolor: params.row.color,
              }}
              alt={params.value}
              src={params.row.photoURL}
            >
              {params.row.short}
            </Avatar>
          </Grid>
        );
      },
    },
    {
      field: 'id',
      headerName: t('Name'),
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Chip
            translate="no"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: params.row.color, color: 'white', border: '0px' }}
            label={params.value}
          />
        );
      },
    },
    {
      field: 'priority',
      headerName: t('Priority'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900,
      renderCell: (params) => {
        // return t(params.value);
        return (
          <Chip
            translate="no"
            variant="outlined"
            size="small"
            sx={{ backgroundColor: priorityColors[params.value], color: 'white', border: '0px' }}
            label={t(params.value)}
          />
        );
      },
    },
    {
      field: 'workload',
      headerName: t('Working Times'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || !showConstraint('Workload'),
    },
    {
      field: 'workingDays',
      headerName: t('Working Days'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || !showConstraint('Working Days'),
    },
    {
      field: 'idleWindow',
      headerName: t('Idle Window'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || !showConstraint('Idle Window'),
    },
    {
      field: 'dailyWorkload',
      headerName: t('Daily Workload'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || !showConstraint('Daily Workload'),
    },
    {
      field: 'restBetweenDays',
      headerName: t('Rest between Days'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || !showConstraint('Rest between Days'),
      hideable: type !== 'room',
    },
    {
      field: 'roomChangesDay',
      headerName: t('Rooms per Day'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || !showConstraint('Room Changes Day'),
      hideable: type !== 'room',
    },
    {
      field: 'consecutiveTimes',
      headerName: t('Consecutive Times'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || !showConstraint('Consecutive Times'),
    },
    {
      field: 'distinctSubjects',
      headerName: t('Distinct Subjects'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || !showConstraint('Distinct Subjects'),
      hideable: type === 'teacher',
    },
    {
      field: 'travelTime',
      headerName: t('Travel Time'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan900 || !showConstraint('Travel Time'),
      hideable: type === 'room',
      renderCell: (params) => {
        return params.row.travelTime.map((room: string) => {
          return <Chip translate="no" key={room} variant="outlined" label={room} />;
        });
      },
    },
    {
      field: 'forbiddenCombination',
      headerName: t('Forbidden Combination'),
      flex: 2,
      minWidth: 150,
      hide: widthLessThan900 || !showConstraint('Forbidden Combination'),
      hideable: type !== 'room',
      renderCell: (params) => {
        return params.row.forbiddenCombination.map((day: string) => {
          return <Chip translate="no" key={day} variant="outlined" size="small" label={day} />;
        });
      },
    },
    {
      field: 'availability',
      headerName: t('Availability'),
      flex: 1,
      minWidth: 100,
      // hide: widthLessThan600,
      renderCell: (params) => {
        const cellHeight = 28 / times.length;
        return (
          <TableContainer>
            <Table size="small" aria-label="Unavialable Times" padding="none">
              <TableBody>
                {times.map((time, t_index) => {
                  return (
                    <TableRow key={`time_${t_index}`}>
                      {days.map((day, d_index) => {
                        return (
                          <TableCell
                            key={`cell_${d_index}_${t_index}`}
                            sx={{
                              border: 0.5,
                              borderColor: grey[600],
                              height: `${cellHeight}px`,
                              backgroundColor: `${
                                params.row.unavailableTimes.includes('' + d_index + ':' + t_index)
                                  ? red[200]
                                  : params.row.undesiredTimes.includes('' + d_index + ':' + t_index)
                                  ? amber[200]
                                  : green[200]
                              }`,
                            }}
                            align="center"
                          ></TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: t('Updated At'),
      flex: 1,
      minWidth: 130,
      headerAlign: 'right',
      align: 'right',
      hide: widthLessThan600,
      renderCell: (params) => {
        return (
          <Typography fontSize={14}>
            {user?.countryCode === 'BR'
              ? dayjs(params.row.updatedAt).format('DD/MM/YY HH:mm[h]')
              : dayjs(params.row.updatedAt).format('MM/DD/YY hh:mma')}
          </Typography>
        );
      },
    },
  ];

  let rows: any[] = [];
  resources
    .filter((res) => res.type === type)
    .map(function (resource, index) {
      return rows.push(
        createData(
          resource.short,
          resource.color,
          resource.photoURL,
          resource.name,
          resource.priority,
          (resource.minWorkload === 0 ? '↓' : resource.minWorkload) +
            ' - ' +
            (resource.maxWorkload === days.length * times.length ? '↑' : resource.maxWorkload),
          (resource.minWorkingDays === 0 ? '↓' : resource.minWorkingDays) +
            ' - ' +
            (resource.maxWorkingDays === days.length ? '↑' : resource.maxWorkingDays),
          (resource.minIdleWindow === 0 ? '↓' : resource.minIdleWindow) +
            ' - ' +
            (resource.maxIdleWindow === times.length - 2 ? '↑' : resource.maxIdleWindow),
          (resource.minDailyWorkload === 0 ? '↓' : resource.minDailyWorkload) +
            ' - ' +
            (resource.maxDailyWorkload === times.length ? '↑' : resource.maxDailyWorkload),

          (resource.minRestBetweenDays === 0 ? '↓' : resource.minRestBetweenDays) +
            ' - ' +
            (resource.maxRestBetweenDays === times.length * 2 - 2 ? '↑' : resource.maxRestBetweenDays),

          (resource.minRoomChangesDay === 0 ? '↓' : resource.minRoomChangesDay) +
            ' - ' +
            (resource.maxRoomChangesDay === times.length - 1 ? '↑' : resource.maxRoomChangesDay),

          (resource.minConsecutiveTimes === 0 ? '↓' : resource.minConsecutiveTimes) +
            ' - ' +
            (resource.maxConsecutiveTimes === times.length ? '↑' : resource.maxConsecutiveTimes),

          (resource.minDistinctSubjects === 0 ? '↓' : resource.minDistinctSubjects) +
            ' - ' +
            (resource.maxDistinctSubjects === days.length * times.length ? '↑' : resource.maxDistinctSubjects),
          resource.travelTimeRooms,
          resource.forbiddenCombination.map((dayNum) => {
            return days[dayNum];
          }),
          resource.unavailableTimes.length * 100 + resource.undesiredTimes.length,
          resource.unavailableTimes,
          resource.undesiredTimes,
          (resource.updatedAt as unknown as Timestamp).toDate(),
          resource.type
        )
      );
    });

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const docRef = doc(db, resourcesPath, name);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        // Redirect to resource page
        const resourceData = { ...docSnap.data() };
        history.push({ pathname: '/' + type, state: resourceData });
      } else {
        // doc.data() will be undefined in this case
        toast.error('No such document');
      }
    });
  };

  function chooseIcon() {
    if (type === 'class') {
      return <SchoolIcon />;
    } else if (type === 'teacher') {
      return <CoPresent />;
    } else if (type === 'room') {
      return <MapsHomeWorkIcon />;
    } else {
      return <AddCircleIcon />;
    }
  }

  function chooseLabel() {
    if (type === 'class') {
      return file?.studentsSetting !== 0 ? t('Classes') : t('Students');
    } else if (type === 'teacher') {
      return t('Teachers');
    } else if (type === 'room') {
      return t('Rooms');
    } else {
      return t('Resources');
    }
  }

  function getNumberFromType() {
    if (type === 'class') {
      return '2. ';
    } else if (type === 'teacher') {
      return '3. ';
    } else if (type === 'room') {
      return '4. ';
    } else {
      return '';
    }
  }

  const [loadingOpen, setLoadingOpen] = React.useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      setLoadingOpen(true);
      setDeleteConfirmed(false);
      const batch = writeBatch(db);
      selectionModel.forEach((selectedId) => {
        batch.delete(doc(db, resourcesPath, selectedId.toString()));
        //Delete related lessons
        lessons
          .filter(
            (lesson) =>
              lesson.classes.includes(selectedId.toString()) ||
              lesson.teachers.includes(selectedId.toString()) ||
              lesson.rooms.includes(selectedId.toString())
          )
          .forEach((lesson) => batch.delete(doc(db, lessonsPath, lesson.name)));
        //Set all solutions to outdated
        solutions.forEach((solution) => {
          batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
            status: 'Outdated',
          });
        });
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch.commit().then(() => {
        setLoadingOpen(false);
        toast.success(selectionModel.length + ' ' + chooseLabel() + t(' deleted sucessfully'));
      });
    }
  };

  checkDeleteConfirmation();

  const [importOpen, setImportOpen] = useState(false);

  const [runEmptyClasses, setRunEmptyClasses] = useState(false);
  const emptyClassesStorageFlagKey = 'onboarding_empty_classes';

  const stepsEmptyClasses = [
    {
      target: '.resources-list',
      disableBeacon: true,
      content: (
        <OnboardingContent title={t('List of classes')} message={t('All classes of your file are displayed here')} />
      ),
    },
    {
      target: '.import-button',
      content: (
        <OnboardingContent
          title={t('Import classes')}
          message={t(
            'You can import classes from any spreasheet software, just head to your existing spreadsheet, select the data, hit CTRL+C, then click this button'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: <OnboardingContent title={t('Add class')} message={t('To add a class manually, click CREATE button')} />,
    },
  ];

  const [runEmptyTeachers, setRunEmptyTeachers] = useState(false);
  const emptyTeachersStorageFlagKey = 'onboarding_empty_teachers';

  const stepsEmptyTeachers = [
    {
      target: '.resources-list',
      disableBeacon: true,
      content: (
        <OnboardingContent title={t('List of teachers')} message={t('All teachers of your file are displayed here')} />
      ),
    },
    {
      target: '.import-button',
      content: (
        <OnboardingContent
          title={t('Import teachers')}
          message={t(
            'You can import teachers from any spreasheet software, just head to your existing spreadsheet, select the data, hit CTRL+C, then click this button'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: (
        <OnboardingContent title={t('Add teacher')} message={t('To add a teacher manually, click CREATE button')} />
      ),
    },
  ];

  const [runEmptyRooms, setRunEmptyRooms] = useState(false);
  const emptyRoomsStorageFlagKey = 'onboarding_empty_rooms';

  useEffect(() => {
    if (localStorage.getItem(emptyClassesStorageFlagKey) !== 'true') {
      setRunEmptyClasses(true);
    }
    if (localStorage.getItem(emptyTeachersStorageFlagKey) !== 'true') {
      setRunEmptyTeachers(true);
    }
    if (localStorage.getItem(emptyRoomsStorageFlagKey) !== 'true') {
      setRunEmptyRooms(true);
    }
  }, []);

  const stepsEmptyRooms = [
    {
      target: '.resources-list',
      disableBeacon: true,
      content: (
        <OnboardingContent title={t('List of rooms')} message={t('All rooms of your file are displayed here')} />
      ),
    },
    {
      target: '.import-button',
      content: (
        <OnboardingContent
          title={t('Import rooms')}
          message={t(
            'You can import rooms from any spreasheet software, just head to your existing spreadsheet, select the data, hit CTRL+C, then click this button'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: <OnboardingContent title={t('Add room')} message={t('To add a room manually, click CREATE button')} />,
    },
  ];

  const navigateBackwards = () => {
    if (type === 'class') {
      history.push({ pathname: '/file', state: file });
    } else if (type === 'teacher') {
      history.push('/classes');
    } else if (type === 'room') {
      history.push('/teachers');
    }
  };

  const navigateForward = () => {
    if (type === 'class') {
      history.push('/teachers');
    } else if (type === 'teacher') {
      if (file?.roomsSetting !== 0) {
        history.push('/rooms');
      } else history.push('/subjects');
    } else if (type === 'room') {
      history.push('/subjects');
    }
  };

  const [clipboardRequestInfoOpen, setClipboardRequestInfoOpen] = useState(false);
  const [clipboardDeniedInfoOpen, setClipboardDeniedInfoOpen] = useState(false);

  const handleImportClick = async () => {
    setImportOpen(true);
    const permission = await navigator.permissions.query({ name: 'clipboard-read' } as any);
    if (permission.state === 'denied') {
      setClipboardDeniedInfoOpen(true);
    }
    if (permission.state === 'prompt') {
      setClipboardRequestInfoOpen(true);
    }
  };

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  return (
    <Container
      maxWidth={false}
      style={{ padding: '56px 8px 8px 65px', minHeight: '100vh', backgroundColor: colors.grey[50] }}
    >
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={'Warning'}
        message={selectionModel.length + ' ' + chooseLabel() + t('Delete resource warning')}
      />
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_resources')}
        ></VideoPlayerDialog>
      )}
      <ImportResourcesDialog
        open={importOpen}
        setOpen={setImportOpen}
        title={`${t('Import')} ${chooseLabel()}`}
        message={t('Import message')}
        type={type}
      />
      {clipboardRequestInfoOpen && (
        <InfoDialog
          open={clipboardRequestInfoOpen}
          setOpen={setClipboardRequestInfoOpen}
          title={'Permissão necessária'}
          message={
            <>
              <Grid container justifyContent="center" padding={4}>
                <Grid item>
                  <ContentPaste
                    fontSize="large"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  />
                </Grid>
              </Grid>
              <Typography>{t('Precisamos acessar seu clipboard (CTRL+C) para importar os dados!')}</Typography>
              <Typography>{t('Por favor conceda a permissão solicitada no canto superior da tela.')}</Typography>
            </>
          }
        />
      )}
      {clipboardDeniedInfoOpen && (
        <InfoDialog
          open={clipboardDeniedInfoOpen}
          setOpen={setClipboardDeniedInfoOpen}
          title={'Permissão necessária'}
          message={
            <>
              <Grid container justifyContent="center" padding={4}>
                <Grid item>
                  <ContentPaste
                    fontSize="large"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  />
                </Grid>
              </Grid>
              <Typography>{t('Parece que você negou a permissão de acesso ao seu clipboard (CTRL+C) =(')}</Typography>
              <Typography>
                {t('Por favor conceda a permissão nas configurações do seu navegador e tente novamente.')}
              </Typography>
            </>
          }
        />
      )}
      <MiniDrawer currentPage={location.pathname} />
      <Grid container justifyContent="center">
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
          <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
            <Grid item flexGrow={1}>
              <Typography variant="body1" gutterBottom sx={{ color: '#1976D2' }}>
                {/* <Typography variant="h6" gutterBottom color={'primary'}> */}
                {getNumberFromType() + chooseLabel()}
              </Typography>
              <Divider color="#1976D2" />
            </Grid>
            <Grid item alignSelf={'center'}>
              <Tooltip title={t('Watch a helpful video')}>
                <IconButton
                  style={{ height: 24, width: 24 }}
                  color="primary"
                  onClick={() => setVideoPlayerDialogOpen(true)}
                >
                  <Help style={{ height: 24, width: 24 }} />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <Grid item container flexDirection={'row'} spacing={1}> */}
            {selectionModel.length !== 0 ? (
              <Grid item xs={3} md={2}>
                <Button
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => setDeleteConfirmationOpen(true)}
                  color="error"
                >
                  {`${t('Delete')} (${selectionModel.length})`}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={3} md={2}>
                <Button
                  className="import-button"
                  startIcon={<FileDownloadIcon />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => handleImportClick()}
                  color="primary"
                >
                  {t('Import')}
                </Button>
              </Grid>
            )}
            <Grid item xs={3} md={2}>
              <Button
                className="create-button"
                startIcon={<AddCircleIcon />}
                variant="outlined"
                size="small"
                fullWidth
                disabled={readOnly}
                onClick={() => {
                  setRandomColor();
                  history.push('/' + type);
                }}
                color="primary"
              >
                {t('Create')}
              </Button>
            </Grid>
            {/* </Grid> */}
          </Grid>
          {/* <Grid item xs={12}>
            <FormTitle title={chooseLabel()} icon={chooseIcon()} />
          </Grid> */}
          <Grid className="resources-list" item xs={12} sx={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              // autoPageSize
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[20, 50, 100]}
              // hideFooterPagination
              density={'compact'}
              // loading={rows.length === 0}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'id', sort: 'asc' }],
                },
              }}
              localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
              onRowClick={(params, event) => handleClick(event, params.id.toString())}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <HighlightAltIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {`${t('Click on IMPORT or CREATE to add')} ${chooseLabel()}`}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SearchOffIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Local FILTER returns no data')}
                    </Typography>
                  </Stack>
                ),
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
            <Grid item xs={2}>
              <Button
                className="create-button"
                // startIcon={<AddCircleIcon />}
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => navigateBackwards()}
                color="primary"
              >
                {t('Back')}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className="import-button"
                // startIcon={<FileDownloadIcon />}
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => navigateForward()}
                color="primary"
              >
                {t('Next')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* {type === 'class' ? (
        <Onboarding steps={stepsEmptyClasses} run={runEmptyClasses} storageFlagKey={emptyClassesStorageFlagKey} />
      ) : type === 'teacher' ? (
        <Onboarding steps={stepsEmptyTeachers} run={runEmptyTeachers} storageFlagKey={emptyTeachersStorageFlagKey} />
      ) : (
        <Onboarding steps={stepsEmptyRooms} run={runEmptyRooms} storageFlagKey={emptyRoomsStorageFlagKey} />
      )} */}
    </Container>
  );
}
