import React, { useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  colors,
  useMediaQuery,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { DataGrid, GridColDef, GridSelectionModel, ptBR } from '@mui/x-data-grid';
import { doc, getDoc, Timestamp, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { blue, green, grey } from '@material-ui/core/colors';
import { CollectionsBookmark, Help } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { SessionContext } from 'contexts';
import MiniDrawer from 'components/MiniDrawer';
import { db } from 'services';
import DraggableDialog from 'components/DraggableDialog';
import ImportLessonsDialog from 'pages/Lessons/components/ImportLessonsDialog';
import SimpleBackdrop from 'components/SimpleBackdrop';
import { ISubject } from 'pages/Subjects/Subject';
import OnboardingContent from 'components/OnboardingContent';
import Onboarding from 'components/Onboarding';
import FormTitle from 'components/FormTitle';
import { ContentPaste } from '@mui/icons-material';
import InfoDialog from 'components/InfoDialog';
import VideoPlayerDialog from 'components/VideoPlayerDialog';

interface LessonData {
  short: string;
  color: string;
  photoURL: string;

  id: string;
  subject: string[];
  lessonsWeek: number;
  split: string[];
  gapLessons: string;

  classNum: number;
  classes: string[];
  teacherNum: number;
  teachers: string[];
  roomNum: number;
  rooms: string[];

  availability: number;
  predefinedTimes: string[];
  simulatenousWith: string[];
  notSimulatenousWith: string[];
  occurBefore: string[];
  updatedAt: Date;
}

function createData(
  short: string,
  color: string,
  photoURL: string,

  id: string,
  subject: string[],
  lessonsWeek: number,
  split: string[],
  gapLessons: string,

  classNum: number,
  classes: string[],
  teacherNum: number,
  teachers: string[],
  roomNum: number,
  rooms: string[],

  availability: number,
  predefinedTimes: string[],
  simulatenousWith: string[],
  notSimulatenousWith: string[],
  occurBefore: string[],
  updatedAt: Date
): LessonData {
  return {
    short,
    color,
    photoURL,

    id,
    subject,
    lessonsWeek,
    split,
    gapLessons,

    classNum,
    classes,
    teacherNum,
    teachers,
    roomNum,
    rooms,

    availability,
    predefinedTimes,
    simulatenousWith,
    notSimulatenousWith,
    occurBefore,
    updatedAt,
  };
}

export default function Lessons(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const { lessons, subjects, file, user, ownerEmail, shareMode } = useContext(SessionContext);
  const [selectionModel, setSelectionModel] = React.useState<GridSelectionModel>([]);
  const lessonsPath = 'users/' + ownerEmail + '/files/' + user?.selectedFile + '/lessons';
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];
  const [pageSize, setPageSize] = React.useState<number>(100);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const strArrayComparator: any = (v1: string[], v2: string[]) => {
    if (v1.length !== v2.length) {
      return v1.length - v2.length;
    } else {
      return ('' + v1[0]).localeCompare('' + v2[0]);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'short',
      headerName: ' ',
      minWidth: 32,
      flex: 0.0001,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      hide: true,
      renderCell: (params) => {
        return (
          <Grid item container alignItems="center" justifyContent="center">
            <Avatar
              translate="no"
              sx={{
                width: 28,
                height: 28,
                fontSize: params.row.short.length > 3 ? '8px' : '12px',
                bgcolor: params.row.color,
              }}
              alt={params.value}
              src={params.row.photoURL}
            >
              {params.row.short}
            </Avatar>
          </Grid>
        );
      },
    },
    {
      field: 'id',
      headerName: t('Name'),
      flex: 1,
      hide: true,
      renderCell: (params) => {
        return <Chip translate="no" variant="outlined" size="small" label={params.value} />;
      },
    },
    {
      field: 'subject',
      headerName: t('Subject'),
      flex: 4,
      minWidth: 150,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        return params.row.subject.map((sub: string) => {
          return (
            <Chip
              translate="no"
              key={sub}
              variant="outlined"
              size="small"
              sx={{ backgroundColor: params.row.color, color: 'white', border: '0px' }}
              label={sub}
            />
          );
        });
      },
    },
    {
      field: 'lessonsWeek',
      headerName: t('Times'),
      flex: 1,
      minWidth: 100,
      hide: widthLessThan600,
    },
    {
      field: 'split',
      headerName: t('Split'),
      flex: 2,
      minWidth: 100,
      hide: true,
      renderCell: (params) => {
        return params.row.split.map((split: string, index: number) => {
          return <Chip key={index} variant="outlined" size="small" label={params.row.split[index]} />;
        });
      },
    },
    {
      field: 'gapLessons',
      headerName: t('Days between Lessons'),
      flex: 2,
      minWidth: 50,
      hide: true,
    },
    {
      field: 'classes',
      headerName: file?.studentsSetting !== 0 ? t('Class') : t('Students'),
      flex: 2,
      minWidth: 150,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        return params.row.classes.map((class_: string, index: number) => {
          if (index === 0) {
            return (
              <Stack translate="no" key={index} direction="row" spacing={0} alignItems={'center'}>
                <Typography fontSize={14}>{params.row.classNum}×</Typography>
                <Chip variant="outlined" size="small" label={class_} />
              </Stack>
            );
          } else return <Chip translate="no" key={index} variant="outlined" size="small" label={class_} />;
        });
      },
    },
    {
      field: 'teachers',
      headerName: t('Teachers'),
      flex: 4,
      minWidth: 150,
      hide: widthLessThan900,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        return params.row.teachers.map((teacher: string, index: number) => {
          if (index === 0) {
            return (
              <Stack translate="no" key={index} direction="row" spacing={0} alignItems={'center'}>
                <Typography fontSize={14}>{params.row.teacherNum}×</Typography>
                <Chip variant="outlined" size="small" label={teacher} />
              </Stack>
            );
          } else return <Chip translate="no" key={index} variant="outlined" size="small" label={teacher} />;
        });
      },
    },
    {
      field: 'rooms',
      headerName: t('Rooms'),
      flex: 2,
      minWidth: 100,
      hide: widthLessThan900 || file?.roomsSetting === 0,
      sortComparator: strArrayComparator,
      renderCell: (params) => {
        if (params.row.rooms.length === 0) {
          return (
            <Stack translate="no" direction="row" spacing={0} alignItems={'center'}>
              <Typography fontSize={14}>{params.row.roomNum}×</Typography>
            </Stack>
          );
        } else {
          return params.row.rooms.map((room: string, index: number) => {
            if (index === 0) {
              return (
                <Stack translate="no" key={index} direction="row" spacing={0} alignItems={'center'}>
                  <Typography fontSize={14}>{params.row.roomNum}×</Typography>
                  <Chip variant="outlined" size="small" label={room} />
                </Stack>
              );
            } else return <Chip translate="no" key={index} variant="outlined" size="small" label={room} />;
          });
        }
        // return <Chip variant="filled" label={room} />;
      },
    },
    {
      field: 'availability',
      headerName: t('Predefined Times'),
      flex: 1,
      minWidth: 160,
      hide: widthLessThan900,
      renderCell: (params) => {
        const cellHeight = 28 / times.length;
        return (
          <TableContainer>
            <Table translate="no" size="small" padding="none">
              <TableBody>
                {times.map((time, t_index) => {
                  return (
                    <TableRow key={`time_${t_index}`}>
                      {days.map((day, d_index) => {
                        return (
                          <TableCell
                            key={`cell_${d_index}_${t_index}`}
                            sx={{
                              border: 0.5,
                              borderColor: grey[600],
                              height: `${cellHeight}px`,
                              backgroundColor: `${
                                params.row.predefinedTimes.includes('' + d_index + ':' + t_index)
                                  ? blue[200]
                                  : green[200]
                              }`,
                            }}
                            align="center"
                          ></TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        );
      },
    },
    {
      field: 'updatedAt',
      headerName: t('Updated At'),
      flex: 1,
      minWidth: 130,
      headerAlign: 'right',
      align: 'right',
      hide: true,
      renderCell: (params) => {
        return (
          <Typography fontSize={14}>
            {user?.countryCode === 'BR'
              ? dayjs(params.row.updatedAt).format('DD/MM/YY HH:mm[h]')
              : dayjs(params.row.updatedAt).format('MM-DD-YY hh:mma')}
          </Typography>
        );
      },
    },
  ];

  let rows: any[] = [];
  lessons.map(function (lesson, index) {
    const subject: ISubject[] = subjects.filter((subject) => subject.name === lesson.subject[0]);
    if (subject.length === 0) return 0;
    return rows.push(
      createData(
        subject[0].short,
        subject[0].color,
        subject[0].photoURL,

        lesson.name,
        lesson.subject,
        lesson.lessonsWeek,
        lesson.split,
        (lesson.minGapLessons === 0 ? '↓' : lesson.minGapLessons) +
          ' - ' +
          (lesson.maxGapLessons === days.length - 1 ? '↑' : lesson.maxGapLessons),

        lesson.classNum,
        lesson.classes,
        lesson.teacherNum,
        lesson.teachers,
        lesson.roomNum,
        lesson.rooms,

        lesson.predefinedTimes.length,
        lesson.predefinedTimes,
        lesson.simultaneousWith,
        lesson.notSimultaneousWith,
        lesson.occurBefore,
        (lesson.updatedAt as unknown as Timestamp).toDate()
      )
    );
  });

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const docRef = doc(db, lessonsPath, name);
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const lessonData = { ...docSnap.data() };
        history.push({ pathname: '/lesson', state: lessonData });
      } else {
        // doc.data() will be undefined in this case
        toast.error(t('No such document'));
      }
    });
  };

  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      setLoadingOpen(true);
      setDeleteConfirmed(false);
      const batch = writeBatch(db);
      selectionModel.forEach((selectedId) => {
        batch.delete(doc(db, lessonsPath, selectedId.toString()));
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch.commit().then(() => {
        setLoadingOpen(false);
        toast.success(selectionModel.length + t(' lessons deleted sucessfully'));
      });
    }
  };

  checkDeleteConfirmation();

  const [importOpen, setImportOpen] = useState(false);

  const [runEmptyLessons, setRunEmptyLessons] = useState(false);
  const emptyLessonsStorageFlagKey = 'onboarding_empty_lessons';

  useEffect(() => {
    if (localStorage.getItem(emptyLessonsStorageFlagKey) !== 'true') {
      setRunEmptyLessons(true);
    }
  }, []);

  const stepsEmptyLessons = [
    {
      target: '.lessons-list',
      disableBeacon: true,
      content: (
        <OnboardingContent title={t('List of lessons')} message={t('All lessons of your file are displayed here')} />
      ),
    },
    {
      target: '.import-button',
      content: (
        <OnboardingContent
          title={t('Import lessons')}
          message={t(
            'You can import lessons from any spreasheet software, just head to your existing spreadsheet, select the data, hit CTRL+C, then click this button'
          )}
        />
      ),
    },
    {
      target: '.create-button',
      content: (
        <OnboardingContent title={t('Add lesson')} message={t('To add a lesson manually, click CREATE button')} />
      ),
    },
  ];

  const [clipboardRequestInfoOpen, setClipboardRequestInfoOpen] = useState(false);
  const [clipboardDeniedInfoOpen, setClipboardDeniedInfoOpen] = useState(false);

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  const handleImportClick = async () => {
    setImportOpen(true);
    const permission = await navigator.permissions.query({ name: 'clipboard-read' } as any);
    if (permission.state === 'denied') {
      setClipboardDeniedInfoOpen(true);
    }
    if (permission.state === 'prompt') {
      setClipboardRequestInfoOpen(true);
    }
  };

  return (
    <Container
      maxWidth={false}
      style={{ padding: '56px 8px 8px 65px', minHeight: '100vh', backgroundColor: colors.grey[50] }}
    >
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={selectionModel.length + t(' lessons will be permanently deleted. Are you sure?')}
      />
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_lessons')}
        ></VideoPlayerDialog>
      )}
      <ImportLessonsDialog
        open={importOpen}
        setOpen={setImportOpen}
        title={t('Import lessons')}
        message={t('Import message')}
      />
      {clipboardRequestInfoOpen && (
        <InfoDialog
          open={clipboardRequestInfoOpen}
          setOpen={setClipboardRequestInfoOpen}
          title={'Permissão necessária'}
          message={
            <>
              <Grid container justifyContent="center" padding={4}>
                <Grid item>
                  <ContentPaste
                    fontSize="large"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  />
                </Grid>
              </Grid>
              <Typography>{t('Precisamos acessar seu clipboard (CTRL+C) para importar os dados!')}</Typography>
              <Typography>{t('Por favor conceda a permissão solicitada no canto superior da tela.')}</Typography>
            </>
          }
        />
      )}
      {clipboardDeniedInfoOpen && (
        <InfoDialog
          open={clipboardDeniedInfoOpen}
          setOpen={setClipboardDeniedInfoOpen}
          title={'Permissão necessária'}
          message={
            <>
              <Grid container justifyContent="center" padding={4}>
                <Grid item>
                  <ContentPaste
                    fontSize="large"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  />
                </Grid>
              </Grid>
              <Typography>{t('Parece que você negou a permissão de acesso ao seu clipboard (CTRL+C) =(')}</Typography>
              <Typography>
                {t('Por favor conceda a permissão nas configurações do seu navegador e tente novamente.')}
              </Typography>
            </>
          }
        />
      )}
      <MiniDrawer />
      <Grid container justifyContent="center">
        <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
          <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
            <Grid item flexGrow={1}>
              <Typography variant="body1" gutterBottom sx={{ color: '#1976D2' }}>
                {/* <Typography variant="h6" gutterBottom color={'primary'}> */}
                {'6. ' + t('Lessons')}
              </Typography>
              <Divider color="#1976D2" />
            </Grid>
            <Grid item alignSelf={'center'}>
              <Tooltip title={t('Watch a helpful video')}>
                <IconButton
                  style={{ height: 24, width: 24 }}
                  color="primary"
                  onClick={() => setVideoPlayerDialogOpen(true)}
                >
                  <Help style={{ height: 24, width: 24 }} />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <Grid item container flexDirection={'row'} spacing={1}> */}
            {selectionModel.length !== 0 ? (
              <Grid item xs={3} md={2}>
                <Button
                  startIcon={<DeleteIcon />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => setDeleteConfirmationOpen(true)}
                  color="error"
                >
                  {`${t('Delete')} (${selectionModel.length})`}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={3} md={2}>
                <Button
                  className="import-button"
                  startIcon={<FileDownloadIcon />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  disabled={readOnly}
                  onClick={() => handleImportClick()}
                  color="primary"
                >
                  {t('Import')}
                </Button>
              </Grid>
            )}
            <Grid item xs={3} md={2}>
              <Button
                className="create-button"
                startIcon={<AddCircleIcon />}
                variant="outlined"
                size="small"
                fullWidth
                disabled={readOnly}
                onClick={() => {
                  history.push('/lesson');
                }}
                color="primary"
              >
                {t('Create')}
              </Button>
            </Grid>
            {/* </Grid> */}
          </Grid>
          <Grid className="lessons-list" item xs={12} sx={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              // autoPageSize
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[20, 50, 100]}
              // getRowHeight={() => 'auto'}
              // hideFooterPagination
              density={'compact'}
              // loading={rows.length === 0}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(ids) => {
                setSelectionModel(ids);
              }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'subject', sort: 'asc' }],
                },
              }}
              localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
              onRowClick={(params, event) => handleClick(event, params.id.toString())}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <HighlightAltIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Click on IMPORT or CREATE to add lessons')}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SearchOffIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Local FILTER returns no data')}
                    </Typography>
                  </Stack>
                ),
              }}
            />
          </Grid>
          <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
            <Grid item xs={2}>
              <Button
                className="create-button"
                // startIcon={<AddCircleIcon />}
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => history.push('/subjects')}
                color="primary"
              >
                {t('Back')}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className="import-button"
                // startIcon={<FileDownloadIcon />}
                variant="outlined"
                size="small"
                fullWidth
                onClick={() => history.push('/solutions')}
                color="primary"
              >
                {t('Next')}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {/* <Onboarding steps={stepsEmptyLessons} run={runEmptyLessons} storageFlagKey={emptyLessonsStorageFlagKey} /> */}
    </Container>
  );
}
