import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

function LoadingIndicator() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '160px' }}>
      <CircularProgress color="primary" />
    </div>
  );
}

export default LoadingIndicator;
