import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@material-ui/core';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function ValueSelectDialog(props: any) {
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;

  const valueOptions: number[] = props.valueOptions;
  const value: number = props.value;
  const setValue = props.setValue;

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeValue = (event: SelectChangeEvent) => {
    setValue(event.target.value as unknown as number);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <Grid item sx={{ padding: 4 }}>
            <FormControl fullWidth size="small">
              <InputLabel id="days-select-label">{title}</InputLabel>
              <Select
                size="small"
                labelId="value-select-label"
                id="value-select"
                defaultValue={'' + value}
                onChange={handleChangeValue}
                label={title}
              >
                {valueOptions.map((valueOption, index) => {
                  return (
                    <MenuItem key={`lessonsWeek_${index}`} value={index}>
                      {valueOption}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
