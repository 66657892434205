import { createTheme } from '@material-ui/core';

import { colors } from './colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue,
      contrastText: colors.whiteDark,
    },
    secondary: {
      main: colors.whiteDark,
      contrastText: colors.blue,
    },
  },
});

export { theme, colors };
