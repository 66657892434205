import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  Container,
  ButtonGroup,
  Paper,
  Typography,
  Tooltip,
  useMediaQuery,
  colors,
  IconButton,
  CardMedia,
  Divider,
  Accordion,
  AccordionSummary,
  DialogContentText,
  AccordionDetails,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { doc, updateDoc } from 'firebase/firestore';
import { setDoc, writeBatch, collection, query, getDocs } from '@firebase/firestore';
import { Help, InsertDriveFile, Save } from '@material-ui/icons';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router';
import AlarmIcon from '@mui/icons-material/Alarm';
import { TextField as PickerTextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { Prompt } from 'react-router';

import { db } from 'services';
import { SessionContext } from 'contexts';
import CountrySelect from './components/CountrySelect';
import MiniDrawer from 'components/MiniDrawer';
import DraggableDialog from 'components/DraggableDialog';
import { LocalizationProvider, MobileTimePicker, TimePicker } from '@mui/x-date-pickers';
import SimpleBackdrop from 'components/SimpleBackdrop';
import { useTranslation } from 'react-i18next';
import FormDialog from 'components/FormDialog';
import Onboarding from 'components/Onboarding';
import OnboardingContent from 'components/OnboardingContent';
import useUserPlan from 'stripe/useUserPlan';
import { planFeatures } from 'configs/planFeatures';
import PlanInfoDialog from 'components/PlanInfoDialog';
import FileAvatarFile from './FileAvatarFile';
import { grey } from '@material-ui/core/colors';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import VideoPlayerDialog from 'components/VideoPlayerDialog';

export interface ShareUser {
  user: string;
  mode: string;
}
export interface IFile {
  name: string;
  institution: string;
  year: number;
  country: string;
  educationLevels: string[];
  countryCode: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  days: string[];
  times: string[];
  photoURL?: string;

  numClasses: number;
  numTeachers: number;
  numRooms: number;
  numSubjects: number;
  numLessons: number;
  numConstraints: number;
  numSolutions: number;

  timeslotsSet: boolean;

  showWorkloadClasses: boolean;
  showWorkloadTeachers: boolean;
  showWorkloadRooms: boolean;
  showWorkingDaysClasses: boolean;
  showWorkingDaysTeachers: boolean;
  showWorkingDaysRooms: boolean;
  showIdleWindowClasses: boolean;
  showIdleWindowTeachers: boolean;
  showIdleWindowRooms: boolean;
  showDailyWorkloadClasses: boolean;
  showDailyWorkloadTeachers: boolean;
  showDailyWorkloadRooms: boolean;
  showRestBetweenDaysClasses: boolean;
  showRestBetweenDaysTeachers: boolean;
  showRestBetweenDaysRooms: boolean;
  showRoomChangesDayClasses: boolean;
  showRoomChangesDayTeachers: boolean;
  showRoomChangesDayRooms: boolean;
  showConsecutiveTimesClasses: boolean;
  showConsecutiveTimesTeachers: boolean;
  showConsecutiveTimesRooms: boolean;
  showTravelTimeClasses: boolean;
  showTravelTimeTeachers: boolean;
  showTravelTimeRooms: boolean;
  showDistinctSubjectsClasses: boolean;
  showDistinctSubjectsTeachers: boolean;
  showDistinctSubjectsRooms: boolean;
  showForbiddenCombinationClasses: boolean;
  showForbiddenCombinationTeachers: boolean;
  showForbiddenCombinationRooms: boolean;

  studentsSetting: number;
  teachersSetting: number;
  roomsSetting: number;

  sharedWith?: ShareUser[];
}

export default function File() {
  const { t } = useTranslation();
  const {
    user,
    files,
    file: dbFile,
    resources,
    subjects,
    lessons,
    solutions,
    ownerEmail,
    shareMode,
  } = useContext(SessionContext);
  const [run, setRun] = useState(false);
  const [showPlanLimitDialog, setShowPlanLimitDialog] = useState(false);
  const userPlan = useUserPlan(user);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const userDoc = doc(db, 'users/' + user?.email);
  const filesPath = 'users/' + ownerEmail + '/files';
  const history = useHistory();
  const defaultDays: string[] = [t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday')];
  const defaultTimes: string[] = ['07:00', '08:00', '09:00', '10:00', '11:00'];
  const widthLessThan1200 = useMediaQuery('(max-width:1200px)');

  const previousName: string =
    user?.selectedFile === ''
      ? ''
      : dbFile
      ? dbFile.name
      : history.location.state !== undefined
      ? (history.location.state as IFile).name
      : (files.find((file) => file.name === user?.selectedFile)?.name as string);

  const emptyFile = {
    name: '',
    institution: '',
    year: new Date().getFullYear(),
    educationLevels: ['University'],
    country: 'Brazil',
    countryCode: 'BR',
    description: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    days: defaultDays,
    times: defaultTimes,
    photoURL: '',

    numClasses: 0,
    numTeachers: 0,
    numRooms: 0,
    numSubjects: 0,
    numLessons: 0,
    numConstraints: 0,
    numSolutions: 0,

    timeslotsSet: false,

    showWorkloadClasses: false,
    showWorkloadTeachers: true,
    showWorkloadRooms: true,

    showWorkingDaysClasses: false,
    showWorkingDaysTeachers: true,
    showWorkingDaysRooms: false,

    showIdleWindowClasses: true,
    showIdleWindowTeachers: true,
    showIdleWindowRooms: true,

    showDailyWorkloadClasses: true,
    showDailyWorkloadTeachers: true,
    showDailyWorkloadRooms: false,

    showRestBetweenDaysClasses: false,
    showRestBetweenDaysTeachers: false,
    showRestBetweenDaysRooms: false,

    showRoomChangesDayClasses: false,
    showRoomChangesDayTeachers: false,
    showRoomChangesDayRooms: false,

    showConsecutiveTimesClasses: false,
    showConsecutiveTimesTeachers: false,
    showConsecutiveTimesRooms: false,

    showTravelTimeClasses: false,
    showTravelTimeTeachers: false,
    showTravelTimeRooms: false,

    showDistinctSubjectsClasses: false,
    showDistinctSubjectsTeachers: false,
    showDistinctSubjectsRooms: false,

    showForbiddenCombinationClasses: false,
    showForbiddenCombinationTeachers: false,
    showForbiddenCombinationRooms: false,

    studentsSetting: 1,
    teachersSetting: 1,
    roomsSetting: 1,

    sharedWith: [],
  };

  const file =
    user?.selectedFile === ''
      ? emptyFile
      : dbFile
      ? dbFile
      : history.location.state !== undefined
      ? (history.location.state as IFile)
      : (files.find((file) => file.name === user?.selectedFile) as IFile);

  function planValidation(): boolean {
    if (userPlan === 'courtesy') return true;
    if (userPlan === 'free' && files.length >= planFeatures.free.maxFiles + 1) {
      setShowPlanLimitDialog(true);
      return false;
    } else if (userPlan === 'basic' && files.length >= planFeatures.basic.maxFiles + 1) {
      setShowPlanLimitDialog(true);
      return false;
    } else if (userPlan === 'premium' && files.length >= planFeatures.premium.maxFiles + 1) {
      setShowPlanLimitDialog(true);
      return false;
    }
    return true;
  }

  const [photoURL, setPhotoURL] = useState<string | undefined>(file?.photoURL);

  async function submitForm(navigate: boolean = true) {
    if (!planValidation()) return false;
    try {
      for (let i = 0; i < numDays; i++) {
        daysStr.push((document?.getElementById(`day${i + 1}`) as HTMLInputElement)?.value);
      }
      for (let i = 0; i < numTimes; i++) {
        const d: Date | null = timeValues[i];
        let h = addZero(d?.getHours());
        let m = addZero(d?.getMinutes());
        let timeStr = h + ':' + m;
        timesStr.push(timeStr);
      }

      const fileToSave = {
        ...file,
        name: name,
        institution: (document?.getElementById('institution') as HTMLInputElement)?.value ?? '',
        year: year,
        educationLevels: educationLevels.map((level) => educationalLevelsMap[level]),
        description: (document?.getElementById('description') as HTMLInputElement)?.value ?? '',
        country: country,
        countryCode: countryCode,
        photoURL: photoURL ? photoURL : '',
        updatedAt: new Date(),
        days: daysStr,
        timeslotsSet: true,
        times: timesStr,
        studentsSetting: studentsSetting,
        teachersSetting: teachersSetting,
        roomsSetting: roomsSetting,
      };

      await setDoc(doc(db, filesPath, name), fileToSave);

      // history.location.state !== undefined
      //   ? toast.success(name + t(' updated sucessfully'))
      //   : toast.success(name + t(' created sucessfully'));

      //Update selected file
      updateDoc(userDoc, {
        selectedFile: name,
      })
        .then(() => {
          navigate ? history.push('/classes') : toast.success(t('File data sucessfully updated'));
        })
        .catch(() => {
          toast.error(t('An error has occurred'));
        });
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  }

  interface EducationalLevelMap {
    [key: string]: string;
  }

  const educationalLevelsMap: EducationalLevelMap = {};
  educationalLevelsMap[t('Elementary School')] = 'School';
  educationalLevelsMap[t('High School')] = 'High School';
  educationalLevelsMap[t('College')] = 'College';
  educationalLevelsMap[t('University')] = 'University';
  educationalLevelsMap[t('Other')] = 'Other';

  const [name, setName] = React.useState<string>(file.name as string);
  const [educationLevels, setEducationLevels] = React.useState<string[]>(file.educationLevels.map((level) => t(level)));
  const [country, setCountry] = React.useState<string>(file.country);
  const [countryCode, setCountryCode] = React.useState<string>(file.countryCode);

  const [year, setYear] = React.useState<number>(file.year);
  const handleChangeYear = (event: SelectChangeEvent) => {
    setYear(event.target.value as unknown as number);
  };

  const [studentsSetting, setStudentsSetting] = React.useState<number>(file.studentsSetting);
  const [teachersSetting, setTeachersSetting] = React.useState<number>(file.teachersSetting);
  const [roomsSetting, setRoomsSetting] = React.useState<number>(file.roomsSetting);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  async function checkDeleteConfirmed() {
    if (deleteConfirmed) {
      setDeleteConfirmed(false);
      const batch = writeBatch(db);
      await Promise.all(
        ['resources', 'subjects', 'lessons', 'solutions'].map(async (collectionLabel) => {
          const querySnapshot = await getDocs(query(collection(db, `${filesPath}/${file.name}/${collectionLabel}`)));
          querySnapshot.forEach((document: any) => {
            batch.delete(doc(db, `${filesPath}/${file.name}/${collectionLabel}`, document.id));
          });
        })
      ).then(() => {
        batch.delete(doc(db, filesPath, file.name));
        batch
          .commit()
          .then(() => {
            toast.success(file.name + t(' deleted sucessfully'));
            history.push('/files');
          })
          .catch(() => {
            toast.error(t('An error has ocurred'));
          });
      });
    }
  }

  checkDeleteConfirmed();

  const [copyNameDialogOpen, setCopyNameDialogOpen] = useState(false);
  const [copyNameDialogConfirmed, setCopyNameDialogConfirmed] = useState(false);
  const [copyFileName, setCopyFileName] = useState(t('Copy of ') + name);

  function validateInput(): boolean {
    const fileNames: string[] = files.map((file) => file.name);
    if (name.length === 0) {
      toast.error(t('Name cannot be empty'));
      return false;
    } else if (name.length >= 40) {
      toast.error(t('Name cannot have more than 40 chars!'));
      return false;
    } else if (previousName.length === 0 && fileNames.includes(name)) {
      toast.error(t('Unique name already registered!'));
      return false;
    } else if (
      name.includes('/') ||
      name.includes('\\') ||
      name.includes('|') ||
      name.includes(';') ||
      name.includes('#') ||
      name.includes('=') ||
      name.includes(':') ||
      name.includes('_') ||
      name.includes('{') ||
      name.includes('}') ||
      name.includes('&')
    ) {
      toast.error(t('Name cannot contain: / \\ | ; # = : _ { } &'));
      return false;
      // } else if ((document?.getElementById('institution') as HTMLInputElement)?.value === '') {
      //   toast.error('Provide the name of the institution');
      //   return false;
    } else if (educationLevels.length === 0) {
      toast.error(t('Select at least one option for Education Levels'));
      return false;
    } else return true;
  }

  const [loadingOpen, setLoadingOpen] = React.useState(false);

  function copy() {
    try {
      setCopyNameDialogConfirmed(false);
      //Validation and newName adjustments
      const newName: string = copyFileName;
      if (files.map((file) => file.name).includes(newName)) {
        toast.error(t('File ') + newName + t(' already registered!'));
        return;
      }
      setLoadingOpen(true);
      const newFile = { ...file };
      newFile.name = newName;
      newFile.createdAt = new Date();
      newFile.updatedAt = new Date();
      const batch = writeBatch(db);
      batch.set(doc(db, filesPath, newName), newFile);
      //Copy resources, subjects, lessons, and solutions
      const newFilePath = filesPath + '/' + newName;
      resources.forEach(async (resource) => {
        batch.set(doc(db, newFilePath + '/resources', resource.name), resource);
      });
      subjects.forEach(async (subject) => {
        batch.set(doc(db, newFilePath + '/subjects', subject.name), subject);
      });
      lessons.forEach(async (lesson) => {
        batch.set(doc(db, newFilePath + '/lessons', lesson.name), lesson);
      });
      solutions.forEach(async (solution) => {
        batch.set(doc(db, newFilePath + '/solutions', solution.name), solution);
      });
      //Update selected file
      batch.update(userDoc, {
        selectedFile: newName,
      });

      batch
        .commit()
        .then(() => {
          setLoadingOpen(false);
          toast.success(name + t(' copied sucessfully'));
          history.push('/files');
        })
        .catch(() => {
          setLoadingOpen(false);
          toast.error(t('File is too large to be copied'));
          history.push('/files');
        });
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
  }

  copyNameDialogConfirmed && copy();

  function addZero(i: any) {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  }

  const [value, setValue] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 0
      ? new Date('2022-01-01T' + (file ? file.times[0] : '07:00'))
      : new Date('2022-01-01T07:00:00')
  );

  const [value2, setValue2] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 1
      ? new Date('2022-01-01T' + (file ? file.times[1] : '08:00'))
      : new Date('2022-01-01T08:00:00')
  );

  const [value3, setValue3] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 2
      ? new Date('2022-01-01T' + (file ? file.times[2] : '09:00'))
      : new Date('2022-01-01T09:00:00')
  );

  const [value4, setValue4] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 3
      ? new Date('2022-01-01T' + (file ? file.times[3] : '10:00'))
      : new Date('2022-01-01T10:00:00')
  );

  const [value5, setValue5] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 4
      ? new Date('2022-01-01T' + (file ? file.times[4] : '11:00'))
      : new Date('2022-01-01T11:00:00')
  );

  const [value6, setValue6] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 5
      ? new Date('2022-01-01T' + (file ? file.times[5] : '12:00'))
      : new Date('2022-01-01T12:00:00')
  );

  const [value7, setValue7] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 6
      ? new Date('2022-01-01T' + (file ? file.times[6] : '13:00'))
      : new Date('2022-01-01T13:00:00')
  );

  const [value8, setValue8] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 7
      ? new Date('2022-01-01T' + (file ? file.times[7] : '14:00'))
      : new Date('2022-01-01T14:00:00')
  );

  const [value9, setValue9] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 8
      ? new Date('2022-01-01T' + (file ? file.times[8] : '15:00'))
      : new Date('2022-01-01T15:00:00')
  );

  const [value10, setValue10] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 9
      ? new Date('2022-01-01T' + (file ? file.times[9] : '16:00'))
      : new Date('2022-01-01T16:00:00')
  );

  const [value11, setValue11] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 10
      ? new Date('2022-01-01T' + (file ? file.times[10] : '17:00'))
      : new Date('2022-01-01T17:00:00')
  );

  const [value12, setValue12] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 11
      ? new Date('2022-01-01T' + (file ? file.times[11] : '18:00'))
      : new Date('2022-01-01T18:00:00')
  );

  const [value13, setValue13] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 12
      ? new Date('2022-01-01T' + (file ? file.times[12] : '19:00'))
      : new Date('2022-01-01T19:00:00')
  );

  const [value14, setValue14] = React.useState<Date | null>(
    (file ? file.times.length : 0) > 13
      ? new Date('2022-01-01T' + (file ? file.times[13] : '20:00'))
      : new Date('2022-01-01T20:00:00')
  );
  const handleChange = (newValue: Date | null) => {
    setValue(newValue);
  };
  const handleChange2 = (newValue2: Date | null) => {
    setValue2(newValue2);
  };
  const handleChange3 = (newValue3: Date | null) => {
    setValue3(newValue3);
  };
  const handleChange4 = (newValue4: Date | null) => {
    setValue4(newValue4);
  };
  const handleChange5 = (newValue5: Date | null) => {
    setValue5(newValue5);
  };
  const handleChange6 = (newValue6: Date | null) => {
    setValue6(newValue6);
  };
  const handleChange7 = (newValue7: Date | null) => {
    setValue7(newValue7);
  };
  const handleChange8 = (newValue8: Date | null) => {
    setValue8(newValue8);
  };
  const handleChange9 = (newValue9: Date | null) => {
    setValue9(newValue9);
  };
  const handleChange10 = (newValue10: Date | null) => {
    setValue10(newValue10);
  };
  const handleChange11 = (newValue11: Date | null) => {
    setValue11(newValue11);
  };
  const handleChange12 = (newValue12: Date | null) => {
    setValue12(newValue12);
  };
  const handleChange13 = (newValue13: Date | null) => {
    setValue13(newValue13);
  };
  const handleChange14 = (newValue14: Date | null) => {
    setValue14(newValue14);
  };
  const [numDays, setNumDays] = React.useState<number>(file.days.length);
  const handleChangeDays = (event: SelectChangeEvent) => {
    setNumDays(event.target.value as unknown as number);
  };
  let daysStr: string[] = [];

  const [numTimes, setNumTimes] = React.useState<number>(file.times.length);
  const handleChangeTimes = (event: SelectChangeEvent) => {
    setNumTimes(event.target.value as unknown as number);
  };
  const changeHandler = [
    handleChange,
    handleChange2,
    handleChange3,
    handleChange4,
    handleChange5,
    handleChange6,
    handleChange7,
    handleChange8,
    handleChange9,
    handleChange10,
    handleChange11,
    handleChange12,
    handleChange13,
    handleChange14,
  ];
  const timeValues = [
    value,
    value2,
    value3,
    value4,
    value5,
    value6,
    value7,
    value8,
    value9,
    value10,
    value11,
    value12,
    value13,
    value14,
  ];
  let timesStr: string[] = [];

  const handleNavToUpgradePlan = () => {
    setShowPlanLimitDialog(false);
    history.push('/settings');
  };

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [confirmed, setConfirmed] = useState(false);

  const checkConfirmation = () => {
    if (confirmed) {
      submitForm();
      history.push('/classes');
    }
  };

  const currentDate: Date = new Date();

  checkConfirmation();

  const fileCreationStorageFlagKey = 'onboarding-file-creation';
  useEffect(() => {
    if (localStorage.getItem(fileCreationStorageFlagKey) !== 'true') {
      setRun(true);
    }
    // return () => {
    //   if (!validateInput()) return;
    //   submitForm();
    // };
  }, []);

  const steps = [
    {
      target: '.file-data-form',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('Data and settings')}
          message={t('Setup the data and overall settings of the new file')}
        />
      ),
    },
    {
      target: '.file-days-times-form',
      content: (
        <OnboardingContent
          title={t('Days and times')}
          message={t('Setup the number of days and the number of times per day in this file')}
        />
      ),
    },
    {
      target: '.file-add-button',
      content: (
        <OnboardingContent title={t('Save file')} message={t("When you're done, click SAVE to create the file")} />
      ),
    },
  ];

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  return (
    <>
      {/* <Prompt when={true} message={t('You have unsaved changes, are you sure you want to leave?')} /> */}
      <Container
        maxWidth={false}
        style={{ padding: '56px 8px 8px 65px', minHeight: '100vh', backgroundColor: colors.grey[50] }}
      >
        <FormDialog
          open={copyNameDialogOpen}
          setOpen={setCopyNameDialogOpen}
          setConfirmed={setCopyNameDialogConfirmed}
          title={t('Copy File')}
          message={t('Choose a name to the copy of the file. This name cannot be changed later:')}
          fieldLabel={t('File Name')}
          inputText={copyFileName}
          setInputText={setCopyFileName}
        />
        <DraggableDialog
          open={deleteConfirmationOpen}
          setOpen={setDeleteConfirmationOpen}
          setConfirmed={setDeleteConfirmed}
          title={t('Warning')}
          message={file.name + t('Delete file warning')}
        />
        {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
        {videoPlayerDialogOpen && (
          <VideoPlayerDialog
            open={videoPlayerDialogOpen}
            setOpen={setVideoPlayerDialogOpen}
            videoURL={t('video_url_help_file')}
          ></VideoPlayerDialog>
        )}
        <DraggableDialog
          open={confirmationOpen}
          setOpen={setConfirmationOpen}
          setConfirmed={setConfirmed}
          title={t('Warning')}
          message={t('Times confirmation')}
        />
        {showPlanLimitDialog && (
          <PlanInfoDialog
            open={showPlanLimitDialog}
            positiveLabel={t('Upgrade plan')}
            negativeLabel={t('Back')}
            positiveAction={handleNavToUpgradePlan}
            negativeAction={() => {
              setShowPlanLimitDialog(false);
            }}
            title={t('Plan limit reached')}
            message={t(
              'You have reached the limit of files for your plan! Please consider upgrading your plan to create more files.'
            )}
          />
        )}
        <MiniDrawer />
        <Grid container justifyContent="center">
          <Paper elevation={3} sx={{ padding: '8px', maxWidth: 'lg' }}>
            <Grid container spacing={1} padding={2}>
              <Grid container item xs={12} justifyContent={'space-between'} paddingBottom={2} spacing={1}>
                <Grid item flexGrow={1}>
                  {/* <Typography variant="body1" gutterBottom sx={{ color: '#0D47A1' }}> */}
                  {/* <Typography variant="h6" gutterBottom color={'primary'}> */}
                  <Typography variant="body1" gutterBottom sx={{ color: '#1976D2' }}>
                    {'1. ' + t('File data')}
                  </Typography>
                  <Divider color="#1976D2" />
                </Grid>
                <Grid item alignSelf={'center'}>
                  <Tooltip title={t('Watch a helpful video')}>
                    <IconButton
                      style={{ height: 24, width: 24 }}
                      color="primary"
                      onClick={() => setVideoPlayerDialogOpen(true)}
                    >
                      <Help style={{ height: 24, width: 24 }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={3} md={2}>
                  <Button
                    className={'save-button'}
                    startIcon={<Save />}
                    variant="outlined"
                    size="small"
                    disabled={readOnly}
                    onClick={() => {
                      if (!validateInput()) return;
                      submitForm(false);
                    }}
                    color="primary"
                    fullWidth
                  >
                    {t('Save')}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                className="file-data-form"
                container
                item
                sm={12}
                // md={6}
                // maxWidth={'lg'}
                spacing={3}
                alignItems={'start'}
                alignContent={'start'}
              >
                <Grid item container sm={12} md={6} spacing={1}>
                  <Grid
                    item
                    xs={12}
                    height={'160px'}
                    width={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    sx={{
                      backgroundColor: colors.grey[100],
                      padding: '0 !important',
                    }}
                  >
                    <FileAvatarFile photoURL={photoURL} setPhotoURL={setPhotoURL} user={user} />
                  </Grid>
                  {/* Name and Year */}
                  <Grid item xs={12} sm={8}>
                    <TextField
                      translate="no"
                      id="name"
                      fullWidth
                      size="small"
                      label={t('Name')}
                      disabled={history.location.state !== undefined || previousName !== '' || readOnly}
                      variant="standard"
                      onChange={(e) => setName(e.target.value)}
                      defaultValue={file.name}
                      InputProps={{
                        autoFocus: true, // Use the autoFocus attribute
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="days-select-label" variant="standard">
                        {t('Year')}
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="year-select-label"
                        id="year"
                        variant="standard"
                        disabled={readOnly}
                        defaultValue={'' + file.year}
                        onChange={handleChangeYear}
                        label={t('Year')}
                      >
                        {Array.from({ length: 15 }).map((_, index) => {
                          return (
                            <MenuItem
                              key={`${currentDate.getFullYear() + 1 - index}`}
                              value={`${currentDate.getFullYear() + 1 - index}`}
                            >
                              {`${currentDate.getFullYear() + 1 - index}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* Institution and Country */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      translate="no"
                      id="institution"
                      fullWidth
                      size="small"
                      disabled={readOnly}
                      label={t('Institution (optional)')}
                      variant="standard"
                      defaultValue={file.institution}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CountrySelect
                      disabled={readOnly}
                      label={t('Country')}
                      country={file.country}
                      setCountry={setCountry}
                      setCountryCode={setCountryCode}
                    />
                  </Grid>
                  {/* Description */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      translate="no"
                      id="description"
                      fullWidth
                      label={t('Comments (optional)')}
                      size="small"
                      variant="standard"
                      rows={1}
                      disabled={readOnly}
                      defaultValue={file.description}
                    />
                  </Grid>
                </Grid>
                <Grid
                  className="file-days-times-form"
                  container
                  item
                  sm={12}
                  md={6}
                  spacing={1}
                  alignItems={'start'}
                  alignContent={'start'}
                >
                  <Grid container item sm={6} spacing={1}>
                    {/* DAYS */}
                    <Grid container item sm={12} spacing={1} alignItems={'start'} alignContent={'start'}>
                      <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="days-select-label" variant="standard">
                            {t('Days per week')}
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="days-select-label"
                            id="days-select"
                            variant="standard"
                            label={t('Days per week')}
                            disabled={file.timeslotsSet || readOnly}
                            defaultValue={'' + file.days.length}
                            onChange={handleChangeDays}
                          >
                            {Array.from({ length: 14 }).map((_, index) => {
                              return (
                                <MenuItem key={`day_${index + 1}`} value={`${index + 1}`}>
                                  {index + 1}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      {Array.from({ length: numDays }).map((_, index) => {
                        return (
                          <Grid key={`day2_${index + 1}`} item xs={12} sm={12}>
                            <TextField
                              id={`day${index + 1}`}
                              fullWidth
                              size="small"
                              disabled={readOnly}
                              label={`${t('Day')} ${index + 1}`}
                              variant="standard"
                              defaultValue={file?.days[index]}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  {/* TIMES */}
                  <Grid container item sm={6} spacing={1}>
                    <Grid container item sm={12} spacing={1} alignItems={'start'} alignContent={'start'}>
                      <Grid item xs={12}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="times-select-label" variant="standard">
                            {t('Times per day')}
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="times-select-label"
                            id="times-select"
                            variant="standard"
                            disabled={file.timeslotsSet || readOnly}
                            defaultValue={file.times.length ? '' + file.times.length : '4'}
                            label={t('Times per day')}
                            onChange={handleChangeTimes}
                          >
                            {Array.from({ length: 14 }).map((_, index) => {
                              return (
                                <MenuItem key={`time_${index + 1}`} value={`${index + 1}`}>
                                  {index + 1}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      {Array.from({ length: numTimes }).map((_, index) => {
                        return (
                          <Grid key={`timePicker2_${index + 1}`} item xs={12} sm={12}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <MobileTimePicker
                                // @ts-ignore
                                minutesStep={5}
                                label={`${t('Time')} ${index + 1}`}
                                value={timeValues[index]}
                                ampm={false}
                                disabled={readOnly}
                                onChange={changeHandler[index]}
                                renderInput={(params) => (
                                  <PickerTextField size="small" variant="standard" fullWidth {...params} />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>

                  {/* </Grid>
          </Paper> */}
                </Grid>

                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<GridExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <DialogContentText>{t('Advanced settings')}</DialogContentText>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid container item alignItems="center">
                          <Grid
                            xs={12}
                            lg={6}
                            item
                            container
                            paddingRight={1}
                            justifyContent={widthLessThan1200 ? 'center' : 'right'}
                          >
                            <Typography fontSize={14} color={grey[600]}>
                              {t('Students setting')}
                            </Typography>
                          </Grid>
                          <Grid xs={12} lg={6} item>
                            <ButtonGroup fullWidth size="small" aria-label="outlined primary button group">
                              <Tooltip placement="top-end" title={t('Students setting no')}>
                                <Button
                                  variant={studentsSetting === 0 ? 'contained' : 'outlined'}
                                  onClick={() => setStudentsSetting(0)}
                                >
                                  {t('No')}
                                </Button>
                              </Tooltip>
                              <Tooltip placement="top-end" title={t('Students setting not sure')}>
                                <Button
                                  variant={studentsSetting === 1 ? 'contained' : 'outlined'}
                                  onClick={() => setStudentsSetting(1)}
                                >
                                  {t('Not Sure')}
                                </Button>
                              </Tooltip>
                              <Tooltip placement="top-end" title={t('Students setting yes')}>
                                <Button
                                  variant={studentsSetting === 2 ? 'contained' : 'outlined'}
                                  onClick={() => setStudentsSetting(2)}
                                >
                                  {t('Yes')}
                                </Button>
                              </Tooltip>
                            </ButtonGroup>
                          </Grid>
                        </Grid>

                        <Grid container item alignItems="center">
                          <Grid
                            xs={12}
                            lg={6}
                            item
                            container
                            paddingRight={1}
                            justifyContent={widthLessThan1200 ? 'center' : 'right'}
                          >
                            <Typography fontSize={14} color={grey[600]}>
                              {t('Teachers setting')}
                            </Typography>
                          </Grid>
                          <Grid xs={12} lg={6} item>
                            <ButtonGroup fullWidth size="small" aria-label="outlined primary button group">
                              <Tooltip placement="left" title={t('Teachers setting no')}>
                                <Button
                                  variant={teachersSetting === 0 ? 'contained' : 'outlined'}
                                  onClick={() => setTeachersSetting(0)}
                                >
                                  {t('No')}
                                </Button>
                              </Tooltip>
                              <Tooltip title={t('Teachers setting not sure')}>
                                <Button
                                  variant={teachersSetting === 1 ? 'contained' : 'outlined'}
                                  onClick={() => setTeachersSetting(1)}
                                >
                                  {t('Not Sure')}
                                </Button>
                              </Tooltip>
                              <Tooltip placement="right" title={t('Teachers setting yes')}>
                                <Button
                                  variant={teachersSetting === 2 ? 'contained' : 'outlined'}
                                  onClick={() => setTeachersSetting(2)}
                                >
                                  {t('Yes')}
                                </Button>
                              </Tooltip>
                            </ButtonGroup>
                          </Grid>
                        </Grid>

                        <Grid container item alignItems="center">
                          <Grid
                            xs={12}
                            lg={6}
                            item
                            container
                            paddingRight={1}
                            justifyContent={widthLessThan1200 ? 'center' : 'right'}
                          >
                            <Typography fontSize={14} color={grey[600]}>
                              {t('Rooms setting')}
                            </Typography>
                          </Grid>
                          <Grid xs={12} lg={6} item>
                            <ButtonGroup fullWidth size="small">
                              <ButtonGroup fullWidth size="small">
                                <Tooltip title={t('Rooms setting no')}>
                                  <Button
                                    variant={roomsSetting === 0 ? 'contained' : 'outlined'}
                                    onClick={() =>
                                      history.location.state === undefined
                                        ? setRoomsSetting(0)
                                        : resources.find((res) => res.type === 'room') === undefined
                                        ? setRoomsSetting(0)
                                        : toast.warning(t('Need to delete rooms'))
                                    }
                                  >
                                    {t('No')}
                                  </Button>
                                </Tooltip>
                                <Tooltip title={t('Rooms setting not sure')}>
                                  <Button
                                    variant={roomsSetting === 1 ? 'contained' : 'outlined'}
                                    onClick={() => setRoomsSetting(1)}
                                  >
                                    {t('Not Sure')}
                                  </Button>
                                </Tooltip>
                                <Tooltip title={t('Rooms setting yes')}>
                                  <Button
                                    variant={roomsSetting === 2 ? 'contained' : 'outlined'}
                                    onClick={() => setRoomsSetting(2)}
                                  >
                                    {t('Yes')}
                                  </Button>
                                </Tooltip>
                              </ButtonGroup>
                            </ButtonGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1} justifyContent={'center'} paddingY={1}>
              <Grid item xs={2}>
                <Button
                  className="create-button"
                  // startIcon={<AddCircleIcon />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={() => history.push('/files')}
                  color="primary"
                >
                  {t('Back')}
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button
                  className="import-button"
                  // startIcon={<FileDownloadIcon />}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={() => {
                    if (!validateInput()) return;
                    submitForm();
                  }}
                  color="primary"
                >
                  {t('Next')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* <Onboarding run={run} steps={steps} storageFlagKey={fileCreationStorageFlagKey} /> */}
      </Container>
    </>
  );
}
