import { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { useTranslation } from 'react-i18next';
import { db } from 'services';
import { Timestamp, collection, doc, getDoc, getDocs, query, writeBatch } from 'firebase/firestore';
import { IFile } from 'pages/Files/File';
import { Grid } from '@material-ui/core';
import FileCard from './FileCard';
import { SessionContext } from 'contexts';
import DemoFileCard from './DemoFileCard';
import { SharedWithMe } from 'contexts/SessionContext/SessionContext';
import { toast } from 'react-toastify';
import FormDialog from './FormDialog';
import SimpleBackdrop from './SimpleBackdrop';
import { IResource } from 'pages/Resources/Resource';
import { ISubject } from 'pages/Subjects/Subject';
import { ILesson } from 'pages/Lessons/Lesson';
import { ISolution } from 'pages/Solutions/Solution';
import { useHistory } from 'react-router-dom';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DemoProjectsDialog(props: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;

  const { user, files, authUser } = useContext(SessionContext);
  const filesPath = `users/${authUser?.email}/files`;
  const userDoc = doc(db, `users/${authUser?.email}`);

  const [loadingOpen, setLoadingOpen] = useState(false);
  const [demoFiles, setDemoFiles] = useState<IFile[]>([]);
  const demoUser = 'george@ufop.edu.br';

  useEffect(() => {
    const filesPath = '/users/' + demoUser + '/files/';

    const fetchDemoFiles = async () => {
      const q = query(collection(db, filesPath));
      const querySnapshot = await getDocs(q);
      const fetchedDemoFiles: IFile[] = [];
      querySnapshot.forEach((doc) => {
        fetchedDemoFiles.push(doc.data() as IFile);
      });
      setDemoFiles(fetchedDemoFiles);
    };

    fetchDemoFiles();
  }, []);

  // const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [copyNameDialogOpen, setCopyNameDialogOpen] = useState(false);
  const [copyNameDialogConfirmed, setCopyNameDialogConfirmed] = useState(false);
  const [clickedFileName, setClickedFileName] = useState('');
  const [copyFileName, setCopyFileName] = useState('');

  const handleCopyClick = (event: React.MouseEvent<unknown>, fileName: string) => {
    setClickedFileName(fileName);
    setCopyFileName(t('Copy of ') + fileName);
    setCopyNameDialogOpen(true);
  };

  async function copy() {
    try {
      setCopyNameDialogConfirmed(false);
      //Validation and newName adjustments
      const newName: string = copyFileName;
      if (files.map((file) => file.name).includes(newName)) {
        toast.error(t('File ') + newName + t(' already registered!'));
        return;
      }
      setLoadingOpen(true);
      // Get file and its collections
      const demoFilePath = `users/${demoUser}/files`;
      const docRef = doc(db, demoFilePath, clickedFileName);
      getDoc(docRef).then(async (docSnap) => {
        if (docSnap.exists()) {
          const file = docSnap.data() as IFile;
          const newFile = { ...file };
          const resourcesSnap = await getDocs(query(collection(db, `${demoFilePath}/${clickedFileName}/resources`)));
          const resources = resourcesSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as IResource[];
          const subjectsSnap = await getDocs(query(collection(db, `${demoFilePath}/${clickedFileName}/subjects`)));
          const subjects = subjectsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ISubject[];
          const lessonsSnap = await getDocs(query(collection(db, `${demoFilePath}/${clickedFileName}/lessons`)));
          const lessons = lessonsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ILesson[];
          const solutionsSnap = await getDocs(query(collection(db, `${demoFilePath}/${clickedFileName}/solutions`)));
          const solutions = solutionsSnap.docs.map((doc) => {
            return Object.assign({ ...doc.data() }, { id: doc.id });
          }) as unknown as ISolution[];
          newFile.name = newName;
          newFile.createdAt = new Date();
          newFile.updatedAt = new Date();
          const batch = writeBatch(db);
          batch.set(doc(db, filesPath, newName), newFile);
          //Copy resources, subjects, lessons, and solutions
          const newFilePath = filesPath + '/' + newName;
          resources.forEach(async (resource: any) => {
            batch.set(doc(db, newFilePath + '/resources', resource.name), resource);
          });
          subjects.forEach(async (subject: any) => {
            batch.set(doc(db, newFilePath + '/subjects', subject.name), subject);
          });
          lessons.forEach(async (lesson: any) => {
            batch.set(doc(db, newFilePath + '/lessons', lesson.name), lesson);
          });
          solutions.forEach(async (solution: any) => {
            batch.set(doc(db, newFilePath + '/solutions', solution.name), solution);
          });
          //Update selected file
          batch.update(userDoc, {
            selectedFile: newName,
          });

          batch
            .commit()
            .then(() => {
              setLoadingOpen(false);
              toast.success(file?.name + t(' copied sucessfully'));
              history.push('/files');
            })
            .catch(() => {
              setLoadingOpen(false);
              toast.error(t('File is too large to be copied'));
              history.push('/files');
            });
        } else {
          toast.error(t('No such document'));
        }
      });
    } catch (error: any) {
      toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
      console.error({ error });
    }
    setOpen(false);
  }

  copyNameDialogConfirmed && copy();

  return (
    <div>
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      {copyNameDialogOpen && (
        <FormDialog
          open={copyNameDialogOpen}
          setOpen={setCopyNameDialogOpen}
          setConfirmed={setCopyNameDialogConfirmed}
          title={t('Copy File')}
          message={t('Choose a name to the copy of the file. This name cannot be changed later:')}
          fieldLabel={t('File Name')}
          inputText={copyFileName}
          setInputText={setCopyFileName}
        />
      )}
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} color={'primary'} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <Grid container justifyContent="center">
            <Grid
              container
              justifyContent="flex-start"
              maxWidth={'lg'}
              // sx={{ backgroundColor: 'red' }}
              alignItems="flex-start"
              paddingTop={1}
              paddingBottom={8}
              spacing={4}
            >
              {demoFiles
                .sort(
                  (file1, file2) =>
                    (file2.updatedAt as unknown as Timestamp).toMillis() -
                    (file1.updatedAt as unknown as Timestamp).toMillis()
                )
                .map((file, index) => {
                  return (
                    <Grid item key={index}>
                      <DemoFileCard
                        file={file}
                        user={user}
                        shared={{ user: 'george@ufop.edu.br', file: file.name, mode: 'read' }}
                        onCopyClick={handleCopyClick}
                      />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
