import { DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';

export default function OnboardingContent(props: any) {
  const title = props.title;
  const message = props.message;

  return (
    <React.Fragment>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
    </React.Fragment>
  );
}
