import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { IFile } from 'pages/Files/File';
import { IUser, SharedWithMe } from 'contexts/SessionContext/SessionContext';
import dayjs from 'dayjs';
import ptBR from 'dayjs/locale/pt-br';
import { Timestamp } from 'firebase/firestore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { CardActionArea, Divider, Grid, Menu, MenuItem, Tooltip, colors } from '@material-ui/core';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useTranslation } from 'react-i18next';

export default function DemoFileCard(props: {
  file: IFile | null;
  user: IUser | null;
  shared: SharedWithMe | undefined;
  onCopyClick: (event: React.MouseEvent<unknown>, fileName: string) => void;
}) {
  const { t } = useTranslation();
  const file = props.file;
  const user = props.user;
  const onCopyClick = props.onCopyClick;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card sx={{ width: 320 }} elevation={4}>
      {file !== null && (
        <>
          <CardActionArea onClick={(event) => onCopyClick(event, file.name)}>
            <CardHeader
              avatar={
                <Avatar
                  sx={{ borderRadius: '0px', height: '24px', width: '32px' }}
                  src={`https://flagcdn.com/h60/${file.countryCode.toLowerCase()}.png`}
                  aria-label="recipe"
                >
                  R
                </Avatar>
              }
              title={file.name}
              subheader={
                user?.countryCode === 'BR'
                  ? dayjs((file.updatedAt as unknown as Timestamp).toDate())
                      .locale(ptBR)
                      .format('DD [de] MMMM [de] YYYY [às] HH:mm[h]')
                  : dayjs((file.updatedAt as unknown as Timestamp).toDate()).format('MMMM DD,YY[ at ]hh:mma')
              }
            />
            <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleOptionsClose}>
              <MenuItem onClick={handleOptionsClose}>Profile</MenuItem>
              <MenuItem onClick={handleOptionsClose}>My account</MenuItem>
              <MenuItem onClick={handleOptionsClose}>Logout</MenuItem>
            </Menu>
            {file.photoURL ? (
              <CardMedia component="img" height="160px" image={file.photoURL} alt="Photo" />
            ) : (
              <Grid
                container
                minHeight={'160px'}
                width={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{ backgroundColor: colors.grey[100] }}
              >
                <AccountBalanceIcon sx={{ fontSize: 60 }} htmlColor={colors.grey[600]} />
              </Grid>
            )}
            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {`${file.institution} • ${file.year}`}
              </Typography>
              {file.description ? (
                <>
                  {file.description.split('\\n').map((i, key) => {
                    return (
                      <Typography key={key} variant="body2" color="text.secondary">
                        {i}
                        <br />
                      </Typography>
                    );
                  })}
                </>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  &nbsp;
                </Typography>
              )}
            </CardContent>
          </CardActionArea>
          <Divider />
          {/* <CardActions>
            <Button size="small" onClick={(event) => onCopyClick(event, file.name)}>
              {t('Clone project')}
            </Button>
          </CardActions> */}
          <CardActions disableSpacing>
            <Tooltip title={t('Copy file')}>
              <IconButton aria-label="copy file" onClick={(event) => onCopyClick(event, file.name)}>
                <FileCopyIcon />
              </IconButton>
            </Tooltip>
          </CardActions>
        </>
      )}
    </Card>
  );
}
