import React, { useContext, useState } from 'react';
import { Box, Grid, Stack, Typography } from '@material-ui/core';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { grey } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';
import { SessionContext } from 'contexts';
import { darken, lighten, styled } from '@mui/material/styles';
interface ImportData {
  id: string;
  id0: string;
  id1: string;
  id2: string;
  id3: string;
  id4: string;
  id5: string;
  id6: string;
  id7: string;
  status?: string;
}

function createData(
  id: string,
  id0: string,
  id1: string,
  id2: string,
  id3: string,
  id4: string,
  id5: string,
  id6: string,
  id7: string,
  status: string = 'None'
): ImportData {
  return {
    id,
    id0,
    id1,
    id2,
    id3,
    id4,
    id5,
    id6,
    id7,
    status,
  };
}

interface ImportDataGridProps {
  clipboardText: string;
  setClipboardText: (clipboardText: string) => void;
  ignoreFirstLine?: boolean;
}

export default function ImportDataGrid({
  clipboardText,
  setClipboardText,
  ignoreFirstLine = true,
}: ImportDataGridProps) {
  const { t } = useTranslation();
  const { user } = useContext(SessionContext);
  const [pageSize, setPageSize] = React.useState<number>(100);
  const columnNames = [
    `${t('Id')}`,
    `${t('Column')} 1`,
    `${t('Column')} 2`,
    `${t('Column')} 3`,
    `${t('Column')} 4`,
    `${t('Column')} 5`,
    `${t('Column')} 6`,
    `${t('Column')} 7`,
    `${t('Column')} 8`,
  ];

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('Id'),
      hide: true,
    },
    {
      field: 'id0',
      headerName: columnNames[0],
      flex: 1,
      // renderCell: (params) => {
      //   return (
      //     <div
      //       style={{
      //         backgroundColor: params.api.getRowIndex(params.id) === 0 ? grey[100] : 'transparent',
      //         width: '100%',
      //         height: '100%',
      //         display: 'flex',
      //         alignItems: 'center',
      //       }}
      //     >
      //       {params.row.id0}
      //     </div>
      //   );
      // },
    },
    {
      field: 'id1',
      headerName: columnNames[1],
      flex: 1,
    },
    {
      field: 'id2',
      headerName: columnNames[2],
      flex: 1,
    },
    {
      field: 'id3',
      headerName: columnNames[3],
      flex: 1,
    },
    {
      field: 'id4',
      headerName: columnNames[4],
      flex: 1,
    },
    {
      field: 'id5',
      headerName: columnNames[5],
      flex: 1,
    },
    {
      field: 'id6',
      headerName: columnNames[6],
      flex: 1,
    },
    {
      field: 'id7',
      headerName: columnNames[7],
      flex: 1,
    },
  ];

  // const [clipboardText, setClipboardText] = useState<string>('');

  let rows: ImportData[] = [];
  navigator.clipboard
    .readText()
    .then((text) => {
      setClipboardText(text);
      // handleClipboardInput();
    })
    .catch((err) => {
      console.error('Failed to read clipboard contents: ', err);
    });

  clipboardText.split('\n').map(function (clipboardLine, index) {
    const cells = clipboardLine.split('\t');

    return rows.push(
      createData(
        '' + index,
        cells[0],
        cells.length > 1 ? cells[1] : '',
        cells.length > 2 ? cells[2] : '',
        cells.length > 3 ? cells[3] : '',
        cells.length > 4 ? cells[4] : '',
        cells.length > 5 ? cells[5] : '',
        cells.length > 6 ? cells[6] : '',
        cells.length > 7 ? cells[7] : '',
        index === 0 && ignoreFirstLine ? 'Rejected' : 'None'
      )
    );
  });

  const getBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

  const getHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

  const getSelectedBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

  const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .super-app-theme--Rejected': {
      backgroundColor: getBackgroundColor(grey[500], theme.palette.mode),
      '&:hover': {
        backgroundColor: getBackgroundColor(grey[500], theme.palette.mode),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(grey[500], theme.palette.mode),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(grey[500], theme.palette.mode),
        },
      },
    },
  }));

  return (
    <Grid item xs={12} sx={{ height: '60vh', width: '100%' }}>
      <StyledDataGrid
        rows={rows}
        columns={columns}
        // autoPageSize
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[20, 50, 100]}
        headerHeight={0}
        // hideFooterPagination
        density={'compact'}
        // loading={rows.length === 0}
        // checkboxSelection
        disableSelectionOnClick
        // onSelectionModelChange={(ids) => {
        //   setSelectionModel(ids);
        // }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'updatedAt', sort: 'desc' }],
          },
        }}
        scrollbarSize={4}
        getRowClassName={(params) => `super-app-theme--${params.row.status}`}
        // onRowClick={(params, event) => handleClick(event, params.id.toString())}
        localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <HighlightAltIcon style={{ color: grey[500], textAlign: 'center' }} />
              <Typography color={grey[500]} textAlign="center">
                {t('There is not data in the CLIPBOARD')}
              </Typography>
            </Stack>
          ),
          NoResultsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <SearchOffIcon style={{ color: grey[500], textAlign: 'center' }} />
              <Typography color={grey[500]} textAlign="center">
                {t('Local FILTER returns no data')}
              </Typography>
            </Stack>
          ),
        }}
      />
    </Grid>
  );
}
