/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  ToggleButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  Container,
  Avatar,
  Tooltip,
  useMediaQuery,
  Divider,
  colors,
  Typography,
  Accordion,
  AccordionSummary,
  DialogContentText,
  AccordionDetails,
  IconButton,
} from '@material-ui/core';
import { ArrowBack, Help, Save } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { doc, deleteDoc, updateDoc, writeBatch } from '@firebase/firestore';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import RemoveIcon from '@mui/icons-material/Remove';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@material-ui/system';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import { blue } from '@mui/material/colors';

import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import SchoolIcon from '@mui/icons-material/School';
import { CoPresent } from '@mui/icons-material';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HotelIcon from '@mui/icons-material/Hotel';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ChairIcon from '@mui/icons-material/Chair';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import MoreTimeIcon from '@mui/icons-material/MoreTime';

import { db } from 'services';
import { SessionContext } from 'contexts';
import MiniDrawer from 'components/MiniDrawer';
import MultipleSelectChip from 'components/MultipleSelectChip';
import { useHistory } from 'react-router-dom';
import DraggableDialog from 'components/DraggableDialog';
import DraggableAvatarPicker from 'components/DraggableAvatarPicker';
import { ILesson } from 'pages/Lessons/Lesson';
import DraggableShowConstraints from 'components/DraggableShowConstraints';
import { IAssignment } from 'pages/Solutions/Solution';
import { useTranslation } from 'react-i18next';
import OnboardingContent from 'components/OnboardingContent';
import Onboarding from 'components/Onboarding';
import FormTitle from 'components/FormTitle';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import MinMaxConstraint from 'components/MinMaxConstraint';
import MinMaxConstraintSlider from 'components/MinMaxConstraintSlider';
import { PriorityOptionsMap } from 'pages/Solutions/components/ConfigsDialog';
import { priorityColors } from 'styles/priorityColors';
import VideoPlayerDialog from 'components/VideoPlayerDialog';

export interface IResource {
  color: string;
  photoURL: string;
  name: string;
  short: string;
  type: string;
  minWorkload: number;
  maxWorkload: number;
  minWorkingDays: number;
  maxWorkingDays: number;
  minIdleWindow: number;
  maxIdleWindow: number;
  minDailyWorkload: number;
  maxDailyWorkload: number;
  minRestBetweenDays: number;
  maxRestBetweenDays: number;
  minRoomChangesDay: number;
  maxRoomChangesDay: number;
  minDistinctSubjects: number;
  maxDistinctSubjects: number;
  minConsecutiveTimes: number;
  maxConsecutiveTimes: number;
  travelTimeRooms: string[];
  minTravelTime: number;
  priority: string;
  forbiddenCombination: number[];
  unavailableTimes: string[];
  undesiredTimes: string[];
  createdAt: Date;
  updatedAt: Date;
}

export default function Resource(props: any) {
  const { t } = useTranslation();
  const type = props.type;
  const { user, file, lessons, resources, solutions, ownerEmail, shareMode } = useContext(SessionContext);
  const resourcesPath = 'users/' + ownerEmail + '/files/' + file?.name + '/resources';
  const lessonsPath = `users/${ownerEmail}/files/${file?.name}/lessons`;
  const solutionsPath = `users/${ownerEmail}/files/${file?.name}/solutions`;
  const history = useHistory();
  const updateResource = history.location.state !== undefined;
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];
  const priorityOptions = [t('Very low'), t('Low'), t('Average'), t('High'), t('Very high')];
  const priorityOptionsMap: PriorityOptionsMap = {
    Disabled: 'Disabled',
    'Very low': 'Very low',
    'Very Low': 'Very low',
    Low: 'Low',
    Average: 'Average',
    High: 'High',
    'Very high': 'Very high',
    'Very High': 'Very high',
    Desabilitado: 'Disabled',
    'Muito baixo': 'Very low',
    Baixo: 'Low',
    Normal: 'Average',
    Alto: 'High',
    'Muito alto': 'Very high',
  };

  function chooseIcon() {
    if (type === 'class') {
      return <SchoolIcon />;
    } else if (type === 'teacher') {
      return <CoPresent />;
    } else if (type === 'room') {
      return <MapsHomeWorkIcon />;
    } else {
      return <AddCircleIcon />;
    }
  }

  function redirectLabel() {
    if (type === 'class') {
      return 'classes';
    } else if (type === 'teacher') {
      return 'teachers';
    } else if (type === 'room') {
      return 'rooms';
    } else {
      return 'resources';
    }
  }

  function getLabelFromType() {
    if (type === 'class') {
      return file?.studentsSetting !== 0 ? t('classes') : t('students');
    } else if (type === 'teacher') {
      return t('teachers');
    } else if (type === 'room') {
      return t('rooms');
    } else {
      return t('resources');
    }
  }

  async function updateFileStats() {
    await updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
      updatedAt: new Date(),
    });
  }

  const defaultColor: string = (
    localStorage.getItem('randomColor') ? localStorage.getItem('randomColor') : blue[900]
  ) as string;
  const previousName: string = updateResource ? (history.location.state as IResource).name : '';

  const resource: IResource = updateResource
    ? (history.location.state as IResource)
    : {
        color: defaultColor,
        photoURL: '',
        name: '',
        short: '',
        type: type,
        minWorkload: 0,
        maxWorkload: days.length * times.length,
        minWorkingDays: 0,
        maxWorkingDays: days.length,
        minIdleWindow: 0,
        maxIdleWindow: times.length - 2,
        minDailyWorkload: 0,
        maxDailyWorkload: times.length,
        minRestBetweenDays: 0,
        maxRestBetweenDays: times.length * 2 - 2,
        minRoomChangesDay: 0,
        maxRoomChangesDay: times.length - 1,
        minDistinctSubjects: 0,
        maxDistinctSubjects: days.length * times.length,
        minConsecutiveTimes: 0,
        maxConsecutiveTimes: times.length,
        travelTimeRooms: [],
        minTravelTime: 0,
        priority: priorityOptionsMap[priorityOptions[2]],
        forbiddenCombination: [],
        unavailableTimes: [],
        undesiredTimes: [],
        createdAt: new Date('2022-01-01T07:00:00'),
        updatedAt: new Date('2022-01-01T07:00:00'),
      };

  async function submitForm() {
    try {
      let unavailableTimes: string[] = [];
      for (let i = 0; i < selectedUnavailable.length; i++) {
        unavailableTimes.push(selectedUnavailable[i]);
      }
      let undesiredTimes: string[] = [];
      for (let i = 0; i < selectedUndesired.length; i++) {
        undesiredTimes.push(selectedUndesired[i]);
      }
      let forbiddenCombination: number[] = [];
      for (let i = 0; i < selectedDays.length; i++) {
        forbiddenCombination.push(days.indexOf(selectedDays[i]));
      }
      const newResource: IResource = {
        color: selectedColor,
        photoURL: selectedPhotoURL,
        name: name.trim(),
        short: short,
        type: type,
        minWorkload: +selectedMinWorkload,
        maxWorkload: +selectedMaxWorkload,
        minWorkingDays: +selectedMinWorkingDays,
        maxWorkingDays: +selectedMaxWorkingDays,
        minIdleWindow: +selectedMinIdleWindow,
        maxIdleWindow: +selectedMaxIdleWindow,
        minDailyWorkload: +selectedMinDailyWorkload,
        maxDailyWorkload: +selectedMaxDailyWorkload,
        minRestBetweenDays: +selectedMinRestBetweenDays,
        maxRestBetweenDays: +selectedMaxRestBetweenDays,
        minRoomChangesDay: +selectedMinRoomChangesDay,
        maxRoomChangesDay: +selectedMaxRoomChangesDay,
        minDistinctSubjects: +selectedMinDistinctSubjects,
        maxDistinctSubjects: +selectedMaxDistinctSubjects,
        minConsecutiveTimes: +selectedMinConsecutiveTimes,
        maxConsecutiveTimes: +selectedMaxConsecutiveTimes,
        travelTimeRooms: selectedTravelTimeRooms,
        minTravelTime: +selectedMinTravelTime,
        priority: priorityOptionsMap[selectedPriority],
        forbiddenCombination: forbiddenCombination,
        unavailableTimes: unavailableTimes,
        undesiredTimes: undesiredTimes,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      const batch = writeBatch(db);
      batch.set(doc(db, resourcesPath, name.trim()), newResource);
      //Set all solutions to outdated
      solutions.forEach((solution) => {
        batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });

      //Check if Constraints Apply to All
      const resourcesOfType = resources.filter((res) => res.type === type);
      if (selectedWorkload) {
        resourcesOfType.forEach((resType) =>
          batch.update(doc(db, resourcesPath + '/' + resType.name), {
            minWorkload: +selectedMinWorkload,
            maxWorkload: +selectedMaxWorkload,
            updatedAt: new Date(),
          })
        );
      }
      if (selectedWorkingDays) {
        resourcesOfType.forEach((resType) =>
          batch.update(doc(db, resourcesPath + '/' + resType.name), {
            minWorkingDays: +selectedMinWorkingDays,
            maxWorkingDays: +selectedMaxWorkingDays,
            updatedAt: new Date(),
          })
        );
      }
      if (selectedIdleWindow) {
        resourcesOfType.forEach((resType) =>
          batch.update(doc(db, resourcesPath + '/' + resType.name), {
            minIdleWindow: +selectedMinIdleWindow,
            maxIdleWindow: +selectedMaxIdleWindow,
            updatedAt: new Date(),
          })
        );
      }
      if (selectedDailyWorkload) {
        resourcesOfType.forEach((resType) =>
          batch.update(doc(db, resourcesPath + '/' + resType.name), {
            minDailyWorkload: +selectedMinDailyWorkload,
            maxDailyWorkload: +selectedMaxDailyWorkload,
            updatedAt: new Date(),
          })
        );
      }
      if (selectedRestBetweenDays) {
        resourcesOfType.forEach((resType) =>
          batch.update(doc(db, resourcesPath + '/' + resType.name), {
            minRestBetweenDays: +selectedMinRestBetweenDays,
            maxRestBetweenDays: +selectedMaxRestBetweenDays,
            updatedAt: new Date(),
          })
        );
      }
      if (selectedRoomChangesDay) {
        resourcesOfType.forEach((resType) =>
          batch.update(doc(db, resourcesPath + '/' + resType.name), {
            minRoomChangesDay: +selectedMinRoomChangesDay,
            maxRoomChangesDay: +selectedMaxRoomChangesDay,
            updatedAt: new Date(),
          })
        );
      }
      if (selectedConsecutiveTimes) {
        resourcesOfType.forEach((resType) =>
          batch.update(doc(db, resourcesPath + '/' + resType.name), {
            minConsecutiveTimes: +selectedMinConsecutiveTimes,
            maxConsecutiveTimes: +selectedMaxConsecutiveTimes,
            updatedAt: new Date(),
          })
        );
      }
      if (selectedDistinctSubjects) {
        resourcesOfType.forEach((resType) =>
          batch.update(doc(db, resourcesPath + '/' + resType.name), {
            minDistinctSubjects: +selectedMinDistinctSubjects,
            maxDistinctSubjects: +selectedMaxDistinctSubjects,
            updatedAt: new Date(),
          })
        );
      }
      if (selectedTravelTime) {
        resourcesOfType.forEach((resType) =>
          batch.update(doc(db, resourcesPath + '/' + resType.name), {
            travelTimeRooms: selectedTravelTimeRooms,
            minTravelTime: +selectedMinTravelTime,
            updatedAt: new Date(),
          })
        );
      }
      if (selectedForbiddenCombination) {
        resourcesOfType.forEach((resType) =>
          batch.update(doc(db, resourcesPath + '/' + resType.name), {
            forbiddenCombination: forbiddenCombination,
            updatedAt: new Date(),
          })
        );
      }
      // Delete old record and update references if name has changed
      if (previousName !== '' && previousName !== name.trim()) {
        batch.delete(doc(db, resourcesPath, previousName));
        //Update lesson references
        const lessonsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/lessons';
        if (type === 'class') {
          let lessonsToUpdate: ILesson[] = [];
          lessons.forEach((lesson) => {
            lesson.classes.forEach((class_) => {
              if (class_ === previousName) {
                lessonsToUpdate.push(lesson);
              }
            });
          });
          lessonsToUpdate.forEach((lessonToUpdate) => {
            batch.delete(doc(db, lessonsPath, lessonToUpdate.name));
            lessonToUpdate.classes.splice(lessonToUpdate.classes.indexOf(previousName), 1);
            lessonToUpdate.classes.push(name.trim());
            const newLessonName =
              lessonToUpdate.subject[0] + ' | ' + lessonToUpdate.classes.map((class_) => class_).toString();
            lessonToUpdate.name = newLessonName;
            batch.set(doc(db, lessonsPath, newLessonName), lessonToUpdate);
          });
          // Update name on simultaneous, non-simultaneous, and ordered lessons
          lessons.forEach((lesson) => {
            let newSimLessons: string[] = lesson.simultaneousWith;
            lesson.simultaneousWith.forEach((simLesson) => {
              if (simLesson.split(' | ')[1] === previousName) {
                const newLessonName = simLesson.split(' | ')[0] + ' | ' + name.trim();
                newSimLessons = newSimLessons.filter((simLesson) => simLesson.split(' | ')[1] !== previousName);
                newSimLessons.push(newLessonName);
                batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                  simultaneousWith: newSimLessons,
                });
              }
            });
            let newNotSimLessons: string[] = lesson.notSimultaneousWith;
            lesson.notSimultaneousWith.forEach((simLesson) => {
              if (simLesson.split(' | ')[1] === previousName) {
                const newLessonName = simLesson.split(' | ')[0] + ' | ' + name.trim();
                newNotSimLessons = newNotSimLessons.filter((simLesson) => simLesson.split(' | ')[1] !== previousName);
                newNotSimLessons.push(newLessonName);
                batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                  notSimultaneousWith: newNotSimLessons,
                });
              }
            });
            let newOccurBeforeLessons: string[] = lesson.occurBefore;
            lesson.occurBefore.forEach((simLesson) => {
              if (simLesson.split(' | ')[1] === previousName) {
                const newLessonName = simLesson.split(' | ')[0] + ' | ' + name.trim();
                newOccurBeforeLessons = newOccurBeforeLessons.filter(
                  (simLesson) => simLesson.split(' | ')[1] !== previousName
                );
                newOccurBeforeLessons.push(newLessonName);
                batch.update(doc(db, lessonsPath + '/' + lesson.name), {
                  occurBefore: newOccurBeforeLessons,
                });
              }
            });
          });
        } else if (type === 'teacher') {
          let lessonsToUpdate: ILesson[] = [];
          lessons.forEach((lesson) => {
            lesson.teachers.forEach((teacher) => {
              if (teacher === previousName) {
                lessonsToUpdate.push(lesson);
              }
            });
          });
          lessonsToUpdate.forEach((lessonToUpdate) => {
            lessonToUpdate.teachers.splice(lessonToUpdate.teachers.indexOf(previousName), 1);
            lessonToUpdate.teachers.push(name.trim());
            batch.set(doc(db, lessonsPath, lessonToUpdate.name), lessonToUpdate);
          });
        } else if (type === 'room') {
          let lessonsToUpdate: ILesson[] = [];
          lessons.forEach((lesson) => {
            lesson.rooms.forEach((room) => {
              if (room === previousName) {
                lessonsToUpdate.push(lesson);
              }
            });
          });
          lessonsToUpdate.forEach((lessonToUpdate) => {
            lessonToUpdate.rooms.splice(lessonToUpdate.rooms.indexOf(previousName), 1);
            lessonToUpdate.rooms.push(name.trim());
            batch.set(doc(db, lessonsPath, lessonToUpdate.name), lessonToUpdate);
          });
        }
        //Update solution references
        const solutionsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/solutions';
        solutions.forEach((solution) => {
          let updatedAssignments: IAssignment[] = solution.assignments;
          let needsUpdate = false;
          solution.assignments.forEach((assignment, index) => {
            let splitAssignment = assignment.lessonRef.split(' | ');
            let assigmentSubject = splitAssignment[0];
            let assigmentClasses = splitAssignment.length > 1 ? splitAssignment[1] : '';
            if (assigmentClasses.includes(previousName)) {
              const newLessonRef = assigmentSubject + ' | ' + assigmentClasses.replace(previousName, name.trim());
              updatedAssignments[index].lessonRef = newLessonRef;
              updatedAssignments[index].resources.splice(updatedAssignments[index].resources.indexOf(previousName), 1);
              updatedAssignments[index].resources.push(name.trim());
              needsUpdate = true;
            } else {
              if (assignment.resources.includes(previousName)) {
                updatedAssignments[index].resources.splice(
                  updatedAssignments[index].resources.indexOf(previousName),
                  1
                );
                updatedAssignments[index].resources.push(name.trim());
                needsUpdate = true;
              }
            }
          });
          if (needsUpdate) {
            solution.assignments = updatedAssignments;
            batch.update(doc(db, solutionsPath + '/' + solution.name), {
              assignments: updatedAssignments,
            });
          }
        });
      }
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      // Everything went ok!
      batch
        .commit()
        .then(() => {
          updateResource
            ? toast.success(name.trim() + t(' updated sucessfully'))
            : toast.success(name.trim() + t(' created sucessfully'));
          history.push('/' + redirectLabel());
        })
        .catch(() => {
          toast.error(t('An error has ocurred'));
          history.push('/' + redirectLabel());
        });
    } catch (error: any) {
      toast.error(error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has ocurred'));
      console.error({ error });
    }
  }

  const formik = useFormik<IResource>({
    initialValues: resource,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // name: Yup.string().required('Required field'),
      // short: Yup.string().required('Required field'),
    }),
    onSubmit: async (values) => {
      submitForm();
    },
  });

  function dayIdxToStr(indexes: number[]) {
    return indexes.map((index) => days[index]);
  }

  const [name, setName] = React.useState<string>(formik.initialValues.name);
  const [short, setShort] = React.useState<string>(formik.initialValues.short);
  const [selectedColor, setSelectedColor] = React.useState<string>(formik.initialValues.color);
  const [selectedPhotoURL, setSelectedPhotoURL] = React.useState<string>(formik.initialValues.photoURL);
  const [selectedDays, setSelectedDays] = React.useState<string[]>(
    dayIdxToStr(formik.initialValues.forbiddenCombination)
  );

  const [showWorkload, setShowWorkload] = React.useState<boolean>(
    resource.minWorkload !== 0 || resource.maxWorkload !== days.length * times.length
      ? true
      : type === 'class'
      ? (file?.showWorkloadClasses as boolean)
      : type === 'teacher'
      ? (file?.showWorkloadTeachers as boolean)
      : type === 'room'
      ? (file?.showWorkloadRooms as boolean)
      : false
  );
  const [showWorkingDays, setShowWorkingDays] = React.useState<boolean>(
    resource.minWorkingDays !== 0 || resource.maxWorkingDays !== days.length
      ? true
      : type === 'class'
      ? (file?.showWorkingDaysClasses as boolean)
      : type === 'teacher'
      ? (file?.showWorkingDaysTeachers as boolean)
      : type === 'room'
      ? (file?.showWorkingDaysRooms as boolean)
      : false
  );
  const [showIdleWindow, setShowIdleWindow] = React.useState<boolean>(
    resource.minIdleWindow !== 0 || resource.maxIdleWindow !== times.length - 2
      ? true
      : type === 'class'
      ? (file?.showIdleWindowClasses as boolean)
      : type === 'teacher'
      ? (file?.showIdleWindowTeachers as boolean)
      : type === 'room'
      ? (file?.showIdleWindowRooms as boolean)
      : false
  );
  const [showDailyWorkload, setShowDailyWorkload] = React.useState<boolean>(
    resource.minDailyWorkload !== 0 || resource.maxDailyWorkload !== times.length
      ? true
      : type === 'class'
      ? (file?.showDailyWorkloadClasses as boolean)
      : type === 'teacher'
      ? (file?.showDailyWorkloadTeachers as boolean)
      : type === 'room'
      ? (file?.showDailyWorkloadRooms as boolean)
      : false
  );
  const [showRestBetweenDays, setShowRestBetweenDays] = React.useState<boolean>(
    resource.minRestBetweenDays !== 0 || resource.maxRestBetweenDays !== times.length * 2 - 2
      ? true
      : type === 'class'
      ? (file?.showRestBetweenDaysClasses as boolean)
      : type === 'teacher'
      ? (file?.showRestBetweenDaysTeachers as boolean)
      : type === 'room'
      ? (file?.showRestBetweenDaysRooms as boolean)
      : false
  );
  const [showRoomChangesDay, setShowRoomChangesDay] = React.useState<boolean>(
    resource.minRoomChangesDay !== 0 || resource.maxRoomChangesDay !== times.length - 1
      ? true
      : type === 'class'
      ? (file?.showRoomChangesDayClasses as boolean)
      : type === 'teacher'
      ? (file?.showRoomChangesDayTeachers as boolean)
      : type === 'room'
      ? (file?.showRoomChangesDayRooms as boolean)
      : false
  );
  const [showConsecutiveTimes, setShowConsecutiveTimes] = React.useState<boolean>(
    resource.minConsecutiveTimes !== 0 || resource.maxConsecutiveTimes !== times.length
      ? true
      : type === 'class'
      ? (file?.showConsecutiveTimesClasses as boolean)
      : type === 'teacher'
      ? (file?.showConsecutiveTimesTeachers as boolean)
      : type === 'room'
      ? (file?.showConsecutiveTimesRooms as boolean)
      : false
  );
  const [showDistinctSubjects, setShowDistinctSubjects] = React.useState<boolean>(
    resource.minDistinctSubjects !== 0 || resource.maxDistinctSubjects !== days.length * times.length
      ? true
      : type === 'class'
      ? (file?.showDistinctSubjectsClasses as boolean)
      : type === 'teacher'
      ? (file?.showDistinctSubjectsTeachers as boolean)
      : type === 'room'
      ? (file?.showDistinctSubjectsRooms as boolean)
      : false
  );
  const [showTravelTime, setShowTravelTime] = React.useState<boolean>(
    resource.travelTimeRooms.length !== 0 && resource.minTravelTime !== 0
      ? true
      : type === 'room'
      ? (file?.showTravelTimeRooms as boolean)
      : false
  );

  const [showForbiddenCombination, setShowForbiddenCombination] = React.useState<boolean>(
    resource.forbiddenCombination.length !== 0
      ? true
      : type === 'class'
      ? (file?.showForbiddenCombinationClasses as boolean)
      : type === 'teacher'
      ? (file?.showForbiddenCombinationTeachers as boolean)
      : type === 'room'
      ? (file?.showForbiddenCombinationRooms as boolean)
      : false
  );

  const copy = () => {
    try {
      //Validation and newName adjustments
      const newName: string = t('Copy of ') + name.trim();
      if (resources.map((resource) => resource.name).includes(newName)) {
        toast.error(newName + t(' already registered'));
        return;
      }
      const newResource = { ...resource };
      newResource.name = newName;
      newResource.createdAt = new Date();
      newResource.updatedAt = new Date();
      const batch = writeBatch(db);
      batch.set(doc(db, resourcesPath, newName), newResource);
      //Set all solutions to outdated
      solutions.forEach((solution) => {
        batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch.commit().then(() => {
        toast.success(name.trim() + t(' copied sucessfully'));
        history.push('/' + redirectLabel());
      });
    } catch (error: any) {
      toast.error(error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred'));
      console.error({ error });
    }
  };

  const [selectedPriority, setSelectedPriority] = React.useState<string>(t(formik.initialValues.priority));
  const handleChangePriority = (event: SelectChangeEvent) => {
    setSelectedPriority(event.target.value as unknown as string);
  };

  const [selectedWorkload, setSelectedWorkload] = React.useState(false);
  const [selectedMinWorkload, setSelectedMinWorkload] = React.useState<number>(formik.initialValues.minWorkload);
  const [selectedMaxWorkload, setSelectedMaxWorkload] = React.useState<number>(formik.initialValues.maxWorkload);

  const [selectedWorkingDays, setSelectedWorkingDays] = React.useState(false);
  const [selectedMinWorkingDays, setSelectedMinWorkingDays] = React.useState<number>(
    formik.initialValues.minWorkingDays
  );
  const [selectedMaxWorkingDays, setSelectedMaxWorkingDays] = React.useState<number>(
    formik.initialValues.maxWorkingDays
  );

  const [selectedIdleWindow, setSelectedIdleWindow] = React.useState(false);
  const [selectedMinIdleWindow, setSelectedMinIdleWindow] = React.useState<number>(formik.initialValues.minIdleWindow);
  const [selectedMaxIdleWindow, setSelectedMaxIdleWindow] = React.useState<number>(formik.initialValues.maxIdleWindow);

  const [selectedDailyWorkload, setSelectedDailyWorkload] = React.useState(false);
  const [selectedMinDailyWorkload, setSelectedMinDailyWorkload] = React.useState<number>(
    formik.initialValues.minDailyWorkload
  );
  const [selectedMaxDailyWorkload, setSelectedMaxDailyWorkload] = React.useState<number>(
    formik.initialValues.maxDailyWorkload
  );

  const [selectedRestBetweenDays, setSelectedRestBetweenDays] = React.useState(false);
  const [selectedMinRestBetweenDays, setSelectedMinRestBetweenDays] = React.useState<number>(
    formik.initialValues.minRestBetweenDays
  );
  const [selectedMaxRestBetweenDays, setSelectedMaxRestBetweenDays] = React.useState<number>(
    formik.initialValues.maxRestBetweenDays
  );

  const [selectedRoomChangesDay, setSelectedRoomChangesDay] = React.useState(false);
  const [selectedMinRoomChangesDay, setSelectedMinRoomChangesDay] = React.useState<number>(
    formik.initialValues.minRoomChangesDay
  );
  const [selectedMaxRoomChangesDay, setSelectedMaxRoomChangesDay] = React.useState<number>(
    formik.initialValues.maxRoomChangesDay
  );

  const [selectedConsecutiveTimes, setSelectedConsecutiveTimes] = React.useState(false);
  const [selectedMinConsecutiveTimes, setSelectedMinConsecutiveTimes] = React.useState<number>(
    formik.initialValues.minConsecutiveTimes
  );
  const [selectedMaxConsecutiveTimes, setSelectedMaxConsecutiveTimes] = React.useState<number>(
    formik.initialValues.maxConsecutiveTimes
  );

  const [selectedTravelTime, setSelectedTravelTime] = React.useState(false);
  const [selectedTravelTimeRooms, setSelectedTravelTimeRooms] = React.useState<string[]>(
    formik.initialValues.travelTimeRooms
  );
  const [selectedMinTravelTime, setSelectedMinTravelTime] = React.useState<number>(formik.initialValues.minTravelTime);
  const handleChangeMinTravelTime = (event: SelectChangeEvent) => {
    setSelectedMinTravelTime(event.target.value as unknown as number);
  };

  const [selectedDistinctSubjects, setSelectedDistinctSubjects] = React.useState(false);
  const [selectedMinDistinctSubjects, setSelectedMinDistinctSubjects] = React.useState<number>(
    formik.initialValues.minDistinctSubjects
  );
  const [selectedMaxDistinctSubjects, setSelectedMaxDistinctSubjects] = React.useState<number>(
    formik.initialValues.maxDistinctSubjects
  );

  const [selectedForbiddenCombination, setSelectedForbiddenCombination] = React.useState(false);

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        key={`simple-tabpanel-${index}`}
        id={`simple-tabpanel-${index}`}
        style={{ maxWidth: '100%', width: '100%' }}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //#region States for and Tables

  const [selectedUnavailable, setSelectedUnavailable] = React.useState<readonly string[]>(
    formik.initialValues.unavailableTimes
  );
  const [selectedUndesired, setSelectedUndesired] = React.useState<readonly string[]>(
    formik.initialValues.undesiredTimes
  );

  let cells: any[] = [];
  times.forEach((time, t_index) => {
    days.forEach((day, d_index) => {
      cells.push(d_index + ':' + t_index);
    });
  });

  const isSelected = (name: string) => selectedUnavailable.indexOf(name) !== -1;
  const isSelected2 = (name: string) => selectedUndesired.indexOf(name) !== -1;

  const handleSelectAllDayClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUnavailable];
    cells.forEach((element) => {
      let cellDay = element.split(':')[0];
      let nameDay = name.split(':')[0];
      if (cellDay === nameDay) {
        const selectedIndex = selectedUnavailable.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtDay[nameDay as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtDay[nameDay as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedUnavailable(newSelected);
  };

  const handleSelectAllDayClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUndesired];
    cells.forEach((element) => {
      let cellDay = element.split(':')[0];
      let nameDay = name.split(':')[0];
      if (cellDay === nameDay) {
        const selectedIndex = selectedUndesired.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtDay2[nameDay as unknown as number]++;
          contSelected2++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtDay2[nameDay as unknown as number]--;
          contSelected2--;
        }
      }
    });
    setSelectedUndesired(newSelected);
  };

  const handleSelectAllTimeClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUnavailable];
    cells.forEach((element) => {
      let cellTime = element.split(':')[1];
      let nameTime = name.split(':')[1];
      if (cellTime === nameTime) {
        const selectedIndex = selectedUnavailable.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtTime[nameTime as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtTime[nameTime as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedUnavailable(newSelected);
  };

  const handleSelectAllTimeClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUndesired];
    cells.forEach((element) => {
      let cellTime = element.split(':')[1];
      let nameTime = name.split(':')[1];
      if (cellTime === nameTime) {
        const selectedIndex = selectedUndesired.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtTime2[nameTime as unknown as number]++;
          contSelected2++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtTime2[nameTime as unknown as number]--;
          contSelected2--;
        }
      }
    });
    setSelectedUndesired(newSelected);
  };

  const handleSelectAllClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUnavailable];
    cells.forEach((element) => {
      let cellTime = element.split(':')[1];
      let cellDay = element.split(':')[0];
      const selectedIndex = selectedUnavailable.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
        contSelectedAtDay[cellDay as unknown as number]++;
        contSelectedAtTime[cellTime as unknown as number]++;
        contSelected++;
      } else {
        newSelected = newSelected.filter((item) => item !== element);
        contSelectedAtDay[cellDay as unknown as number]--;
        contSelectedAtTime[cellTime as unknown as number]--;
        contSelected--;
      }
    });
    setSelectedUnavailable(newSelected);
  };

  const handleSelectAllClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedUndesired];
    cells.forEach((element) => {
      let cellTime = element.split(':')[1];
      let cellDay = element.split(':')[0];
      const selectedIndex = selectedUndesired.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
        contSelectedAtDay2[cellDay as unknown as number]++;
        contSelectedAtTime2[cellTime as unknown as number]++;
        contSelected2++;
      } else {
        newSelected = newSelected.filter((item) => item !== element);
        contSelectedAtDay2[cellDay as unknown as number]--;
        contSelectedAtTime2[cellTime as unknown as number]--;
        contSelected2--;
      }
    });
    setSelectedUndesired(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedUnavailable.indexOf(name);
    let newSelected: readonly string[] = [];
    let nameDay = name.split(':')[0];
    let nameTime = name.split(':')[1];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUnavailable, name);
      contSelectedAtDay[nameDay as unknown as number]++;
      contSelectedAtTime[nameTime as unknown as number]++;
      contSelected++;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUnavailable.slice(1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex === selectedUnavailable.length - 1) {
      newSelected = newSelected.concat(selectedUnavailable.slice(0, -1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUnavailable.slice(0, selectedIndex),
        selectedUnavailable.slice(selectedIndex + 1)
      );
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    }
    setSelectedUnavailable(newSelected);
  };

  const handleClick2 = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedUndesired.indexOf(name);
    let newSelected: readonly string[] = [];
    let nameDay = name.split(':')[0];
    let nameTime = name.split(':')[1];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedUndesired, name);
      contSelectedAtDay2[nameDay as unknown as number]++;
      contSelectedAtTime2[nameTime as unknown as number]++;
      contSelected2++;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedUndesired.slice(1));
      contSelectedAtDay2[nameDay as unknown as number]--;
      contSelectedAtTime2[nameTime as unknown as number]--;
      contSelected2--;
    } else if (selectedIndex === selectedUndesired.length - 1) {
      newSelected = newSelected.concat(selectedUndesired.slice(0, -1));
      contSelectedAtDay2[nameDay as unknown as number]--;
      contSelectedAtTime2[nameTime as unknown as number]--;
      contSelected2--;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedUndesired.slice(0, selectedIndex),
        selectedUndesired.slice(selectedIndex + 1)
      );
      contSelectedAtDay2[nameDay as unknown as number]--;
      contSelectedAtTime2[nameTime as unknown as number]--;
      contSelected2--;
    }
    setSelectedUndesired(newSelected);
  };

  let contSelectedAtDay: number[] = [];
  days.forEach((element) => {
    contSelectedAtDay.push(0);
  });
  let contSelectedAtTime: number[] = [];
  times.forEach((element) => {
    contSelectedAtTime.push(0);
  });
  let contSelected: number = 0;

  let contSelectedAtDay2: number[] = [];
  days.forEach((element) => {
    contSelectedAtDay2.push(0);
  });
  let contSelectedAtTime2: number[] = [];
  times.forEach((element) => {
    contSelectedAtTime2.push(0);
  });
  let contSelected2: number = 0;

  cells.forEach((element) => {
    let cellDay = element.split(':')[0];
    let cellTime = element.split(':')[1];
    const selectedIndex = selectedUnavailable.indexOf(element);
    if (selectedIndex !== -1) {
      contSelectedAtDay[cellDay as unknown as number]++;
      contSelectedAtTime[cellTime as unknown as number]++;
      contSelected++;
    }
  });

  cells.forEach((element) => {
    let cellDay = element.split(':')[0];
    let cellTime = element.split(':')[1];
    const selectedIndex = selectedUndesired.indexOf(element);
    if (selectedIndex !== -1) {
      contSelectedAtDay2[cellDay as unknown as number]++;
      contSelectedAtTime2[cellTime as unknown as number]++;
      contSelected2++;
    }
  });

  const rowCount: number = times.length;
  const colCount: number = days.length;
  const allCount: number = days.length * times.length;

  //#endregion

  const [avatarPickerOpen, setAvatarPickerOpen] = useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const checkDeleteConfirmation = () => {
    if (deleteConfirmed) {
      setDeleteConfirmed(false);
      deleteDoc(doc(db, resourcesPath, resource.name));
      //Delete related lessons
      lessons
        .filter(
          (lesson) =>
            lesson.classes.includes(resource.name) ||
            lesson.teachers.includes(resource.name) ||
            lesson.rooms.includes(resource.name)
        )
        .forEach((lesson) => deleteDoc(doc(db, lessonsPath, lesson.name)));
      //Set all solutions to outdated
      solutions.forEach((solution) => {
        updateDoc(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });

      toast.success(resource.name + ' deleted sucessfully');
      history.push('/' + redirectLabel());
      updateFileStats();
    }
  };
  checkDeleteConfirmation();

  const [showConstraintsOpen, setShowConstraintsOpen] = useState(false);
  const [showConstraintsConfirmed, setShowConstraintsConfirmed] = useState(false);

  const checkShowConstraintsConfirmation = () => {
    if (showConstraintsConfirmed) {
      setShowConstraintsConfirmed(false);
      if (type === 'class')
        updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          showWorkloadClasses: showWorkload,
          showWorkingDaysClasses: showWorkingDays,
          showIdleWindowClasses: showIdleWindow,
          showDailyWorkloadClasses: showDailyWorkload,
          showRestBetweenDaysClasses: showRestBetweenDays,
          showRoomChangesDayClasses: showRoomChangesDay,
          showConsecutiveTimesClasses: showConsecutiveTimes,
          showTravelTimeClasses: showTravelTime,
          showDistinctSubjectsClasses: showDistinctSubjects,
          showForbiddenCombinationClasses: showForbiddenCombination,
        });
      else if (type === 'teacher')
        updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          showWorkloadTeachers: showWorkload,
          showWorkingDaysTeachers: showWorkingDays,
          showIdleWindowTeachers: showIdleWindow,
          showDailyWorkloadTeachers: showDailyWorkload,
          showRestBetweenDaysTeachers: showRestBetweenDays,
          showRoomChangesDayTeachers: showRoomChangesDay,
          showConsecutiveTimesTeachers: showConsecutiveTimes,
          showTravelTimeTeachers: showTravelTime,
          showDistinctSubjectsTeachers: showDistinctSubjects,
          showForbiddenCombinationTeachers: showForbiddenCombination,
        });
      else if (type === 'room')
        updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          showWorkloadRooms: showWorkload,
          showWorkingDaysRooms: showWorkingDays,
          showIdleWindowRooms: showIdleWindow,
          showDailyWorkloadRooms: showDailyWorkload,
          showRestBetweenDaysRooms: showRestBetweenDays,
          showRoomChangesDayRooms: showRoomChangesDay,
          showConsecutiveTimesRooms: showConsecutiveTimes,
          showTravelTimeRooms: showTravelTime,
          showDistinctSubjectsRooms: showDistinctSubjects,
          showForbiddenCombinationRooms: showForbiddenCombination,
        });
    }
  };
  checkShowConstraintsConfirmation();

  const [toAllConfirmationOpen, setToAllConfirmationOpen] = useState(false);
  const [toAllConfirmed, setToAllConfirmed] = useState(false);

  const checkToAllConfirmation = () => {
    if (toAllConfirmed) {
      setToAllConfirmed(false);
      submitForm();
    }
  };
  checkToAllConfirmation();

  var getInitials = function (string: string, maxLength: number) {
    if (string.length <= maxLength) {
      return string.toUpperCase();
    }
    var names = string.trim().split(' ');
    if (names.length === 1) {
      return names[0].substring(0, maxLength).toUpperCase();
    }
    var initials: string = '';
    var midChars: number = 0;
    for (let i = 0; i < names.length; i++) {
      if (i === 0 || i === names.length - 1) initials += names[i].charAt(0).toUpperCase();
      else if (names[i].charAt(0) === names[i].charAt(0).toUpperCase() && midChars + 2 < maxLength) {
        initials += names[i].substring(0, 1).toUpperCase();
        midChars++;
      }
    }
    return initials;
  };

  const handleNameChange = (newName: string) => {
    setName(newName);
    setShort(getInitials(newName, 4));
  };

  // const StyledButton = styled(Button)`
  //   pointer-events: none;
  //   &:hover {
  //     cursor: default;
  //     background-color: primary;
  //   }
  // `;

  function validateInput(): boolean {
    const resourceNames: string[] = resources.map((res) => res.name);
    if (name.length === 0) {
      toast.error(t('Name cannot be empty'));
      return false;
    } else if (name.length >= 40) {
      toast.error(t('Name cannot have more than 40 chars'));
      return false;
    } else if (previousName.length === 0 && resourceNames.includes(name)) {
      toast.error(t('Unique name already registered'));
      return false;
    } else if (
      name.includes('/') ||
      name.includes('\\') ||
      name.includes('|') ||
      name.includes(';') ||
      name.includes('#') ||
      name.includes('=') ||
      name.includes(':') ||
      name.includes('_') ||
      name.includes('{') ||
      name.includes('}') ||
      name.includes('&')
    ) {
      toast.error(t('Name cannot contain: / \\ | ; # = : _ { } &'));
      return false;
    } else return true;
  }

  const linkedLessonsCount = lessons.filter(
    (lesson) =>
      lesson.classes.includes(resource.name) ||
      lesson.teachers.includes(resource.name) ||
      lesson.rooms.includes(resource.name)
  ).length;

  const [runResourceCreation, setRunResourceCreation] = useState(false);
  const resourceCreationStorageFlagKey = 'onboarding-resource-creation';
  useEffect(() => {
    if (localStorage.getItem(resourceCreationStorageFlagKey) !== 'true') {
      setRunResourceCreation(true);
    }
  }, []);

  const steps = [
    {
      target: '.availability-form',
      content: (
        <OnboardingContent
          title={t('Availability')}
          message={
            t('You can check the slots in which this ') +
            t(type) +
            t(' is unavailable, forbidding assignments at these times')
          }
        />
      ),
    },
    {
      target: '.undisired-times-tab',
      content: (
        <OnboardingContent
          title={t('Undesired Times')}
          message={t(
            'You can also mark slots as undesired, which is similar unavailable times, but with lesser priority (these times might be used if needed)'
          )}
        />
      ),
    },
    {
      target: '.constraints-form',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('Constraints')}
          message={t('You can setup several types of constraints to this ') + t(type) + t(' according to your needs')}
        />
      ),
    },
    {
      target: '.empty-field',
      content: (
        <OnboardingContent
          title={t('Empty fields')}
          message={t('Empty fields mean that no constraint of this type is being considered for this ') + t(type)}
        />
      ),
    },
    {
      target: '.constraint-description',
      content: (
        <OnboardingContent
          title={t('Constraint description')}
          message={t(
            'Hover the mouse (press and hold on mobile) on a constraint name to see its description and an example'
          )}
        />
      ),
    },
    {
      target: updateResource ? '.update-button' : '.save-button',
      content: (
        <OnboardingContent
          title={(updateResource ? t('Update ') : t('Save ')) + t(type)}
          message={
            (updateResource
              ? t("When you're done, click UPDATE to update the ")
              : t("When you're done, click SAVE to create the ")) + t(type)
          }
        />
      ),
    },
  ];

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '56px 8px 8px 65px',
        minHeight: '100vh',
        backgroundColor: colors.grey[50],
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={`${resource.name} ${
          linkedLessonsCount === 0 ? '' : `${t('and')} ${linkedLessonsCount} ${t('linked lessons')}`
        } ${t('will be permanently deleted from this file. Are you sure?')}`}
      />
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_subjects')}
        ></VideoPlayerDialog>
      )}
      <DraggableShowConstraints
        open={showConstraintsOpen}
        setOpen={setShowConstraintsOpen}
        title={t('Show/hide Constraints')}
        message={`${t('Choose the constraints to show for')} ${getLabelFromType()}:`}
        setConfirmed={setShowConstraintsConfirmed}
        type={type}
        showWorkload={showWorkload}
        showWorkingDays={showWorkingDays}
        showIdleWindow={showIdleWindow}
        showDailyWorkload={showDailyWorkload}
        showRestBetweenDays={showRestBetweenDays}
        showRoomChangesDay={showRoomChangesDay}
        showConsecutiveTimes={showConsecutiveTimes}
        showTravelTime={showTravelTime}
        showDistinctSubjects={showDistinctSubjects}
        showForbiddenCombination={showForbiddenCombination}
        setShowWorkload={setShowWorkload}
        setShowWorkingDays={setShowWorkingDays}
        setShowIdleWindow={setShowIdleWindow}
        setShowDailyWorkload={setShowDailyWorkload}
        setShowRestBetweenDays={setShowRestBetweenDays}
        setShowRoomChangesDay={setShowRoomChangesDay}
        setShowConsecutiveTimes={setShowConsecutiveTimes}
        setShowTravelTime={setShowTravelTime}
        setShowDistinctSubjects={setShowDistinctSubjects}
        setShowForbiddenCombination={setShowForbiddenCombination}
      />
      <DraggableDialog
        open={toAllConfirmationOpen}
        setOpen={setToAllConfirmationOpen}
        setConfirmed={setToAllConfirmed}
        title={t('Warning')}
        message={`${t('Constraints regarding')} \n${selectedWorkload ? `(${t('Working Times')}) \n` : ''}${
          selectedWorkingDays ? `(${t('Working Days')}) \n` : ''
        }${selectedIdleWindow ? `(${t('Idle Window')}) \n` : ''}${
          selectedRestBetweenDays ? `(${t('Rest between Days')}) \n` : ''
        }${selectedRoomChangesDay ? `(${t('Rooms per Day')}) \n` : ''}${
          selectedConsecutiveTimes ? `(${t('Consecutive Times')}) \n` : ''
        }${selectedDailyWorkload ? `(${t('Daily Workload')}) \n` : ''}${
          selectedDistinctSubjects ? `(${t('Distinct Subjects')}) \n` : ''
        }${selectedTravelTime ? `(${t('Travel Time')}) \n` : ''}${
          selectedForbiddenCombination ? `(${t('Forbidden Combination')}) \n` : ''
        } ${t('will be applied to all')} ${getLabelFromType()}. ${t('Are you sure?')}`}
      />
      <DraggableAvatarPicker
        open={avatarPickerOpen}
        setOpen={setAvatarPickerOpen}
        selectedColor={selectedColor}
        selectedPhotoURL={selectedPhotoURL}
        setSelectedColor={setSelectedColor}
        setSelectedPhotoURL={setSelectedPhotoURL}
        resource={resource}
      />
      <MiniDrawer />
      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center" paddingBottom={8}>
          <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
            <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
              <Grid item container xs={12} spacing={1} padding={1}>
                <Grid item flexGrow={1}>
                  <Typography variant="body1" gutterBottom sx={{ color: '#1976D2' }}>
                    <Tooltip title={t('Back')}>
                      <IconButton
                        style={{ height: 24, width: 24, marginRight: 4 }}
                        color="primary"
                        onClick={() => history.goBack()}
                      >
                        <ArrowBack style={{ height: 20, width: 20 }} />
                      </IconButton>
                    </Tooltip>
                    {updateResource ? t('Update ') + t(type) : t(`New ${type}`)}
                  </Typography>
                  <Divider color="#1976D2" />
                </Grid>
                <Grid item alignSelf={'center'}>
                  <Tooltip title={t('Watch a helpful video')}>
                    <IconButton
                      style={{ height: 24, width: 24 }}
                      color="primary"
                      onClick={() => setVideoPlayerDialogOpen(true)}
                    >
                      <Help style={{ height: 24, width: 24 }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {updateResource ? (
                  <>
                    <Grid item xs={3} md={2}>
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={() => setDeleteConfirmationOpen(true)}
                        color="error"
                        fullWidth
                      >
                        {t('Delete')}
                      </Button>
                    </Grid>
                    <Grid item xs={3} md={2}>
                      <Button
                        startIcon={<FileCopyIcon />}
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={() => copy()}
                        color="primary"
                        fullWidth
                      >
                        {t('Copy')}
                      </Button>
                    </Grid>
                    <Grid item xs={3} md={2}>
                      <Button
                        className={'update-button'}
                        startIcon={<Save />}
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        // type="submit"
                        onClick={() => {
                          if (!validateInput()) return;
                          selectedWorkload ||
                          selectedWorkingDays ||
                          selectedIdleWindow ||
                          selectedIdleWindow ||
                          selectedRestBetweenDays ||
                          selectedRoomChangesDay ||
                          selectedConsecutiveTimes ||
                          selectedTravelTime ||
                          selectedDistinctSubjects ||
                          selectedForbiddenCombination
                            ? setToAllConfirmationOpen(true)
                            : submitForm();
                        }}
                        color="primary"
                        fullWidth
                      >
                        {t('Update')}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={3} md={2}>
                    <Button
                      className={'save-button'}
                      startIcon={<Save />}
                      variant="outlined"
                      disabled={readOnly}
                      size="small"
                      onClick={() => {
                        if (!validateInput()) return;
                        selectedWorkload ||
                        selectedWorkingDays ||
                        selectedIdleWindow ||
                        selectedIdleWindow ||
                        selectedRestBetweenDays ||
                        selectedRoomChangesDay ||
                        selectedConsecutiveTimes ||
                        selectedTravelTime ||
                        selectedDistinctSubjects ||
                        selectedForbiddenCombination
                          ? setToAllConfirmationOpen(true)
                          : submitForm();
                      }}
                      color="primary"
                      fullWidth
                    >
                      {t('Save')}
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={2} sm={1} md={1} lg={1} justifyContent={'center'}>
                <Box
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                      borderRadius: 100,
                      backgroundColor: selectedColor,
                      opacity: [0.9, 0.8, 0.7],
                    },
                  }}
                  style={{ width: 'fit-content', height: 'fit-content', padding: '0px', margin: '0px' }}
                  onClick={() => setAvatarPickerOpen(true)}
                >
                  <Avatar
                    translate="no"
                    sx={{ bgcolor: selectedColor, fontSize: short.length > 3 ? '12px' : '16px' }}
                    alt={name}
                    src={selectedPhotoURL}
                  >
                    {short}
                  </Avatar>
                </Box>
              </Grid>
              {/* Name */}
              <Grid item xs={8} sm={8} md={8} lg={9}>
                <TextField
                  id="name"
                  required
                  fullWidth
                  size="small"
                  label={t('Name')}
                  translate="no"
                  variant="outlined"
                  defaultValue={formik.initialValues.name}
                  onChange={(e) => handleNameChange(e.target.value)}
                  InputProps={{
                    autoFocus: true, // Use the autoFocus attribute
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={3} md={3} lg={2}>
                <MultipleSelectChip
                  single={true}
                  label={t('Priority')}
                  names={priorityOptions}
                  chipColor={priorityColors[selectedPriority]}
                  personName={[selectedPriority]}
                  setPersonName={setSelectedPriority}
                />
              </Grid>

              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Availability')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                      <Box sx={{ width: '100%' }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          variant="scrollable"
                          allowScrollButtonsMobile
                          aria-label="wrapped label tabs example"
                        >
                          <Tab label={t('Unavailable Times')} />
                          <Tab className={'undisired-times-tab'} label={t('Undesired Times')} />
                        </Tabs>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TabPanel value={value} index={0}>
                        <TableContainer className="availability-form" component={Paper}>
                          <Table size="small" padding="none">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" style={{ minWidth: '90px' }}>
                                  <ToggleButton
                                    value="check"
                                    id={`check_${0}_${0}`}
                                    fullWidth={true}
                                    selected={allCount > 0 && contSelected === allCount}
                                    size="small"
                                    color="error"
                                    onClick={(event) => handleSelectAllClick(event, '**')}
                                  >
                                    {t('Time')}
                                  </ToggleButton>
                                </TableCell>
                                {days.map((day, d_index) => {
                                  return (
                                    <TableCell key={`day_${d_index}`} align="center" style={{ minWidth: '90px' }}>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${d_index}:0`}
                                        fullWidth={true}
                                        selected={rowCount > 0 && contSelectedAtDay[d_index] === rowCount}
                                        size="small"
                                        color="error"
                                        onClick={(event) => handleSelectAllDayClick(event, d_index + ':*')}
                                      >
                                        {days[d_index]}
                                      </ToggleButton>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {times.map((time, t_index) => {
                                return (
                                  <TableRow
                                    key={`time_${t_index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${0}:${t_index}`}
                                        fullWidth={true}
                                        selected={colCount > 0 && contSelectedAtTime[t_index] === colCount}
                                        size="small"
                                        color="error"
                                        onClick={(event) => handleSelectAllTimeClick(event, '*:' + t_index)}
                                      >
                                        {times[t_index]}
                                      </ToggleButton>
                                    </TableCell>

                                    {days.map((day, d_index) => {
                                      const isItemSelected = isSelected('' + d_index + ':' + t_index);
                                      return (
                                        <TableCell key={`cell_${d_index}_${t_index}`} align="center">
                                          <ToggleButton
                                            value="check"
                                            id={`check_${d_index}_${t_index}`}
                                            fullWidth
                                            size="small"
                                            color="error"
                                            onClick={(event) => handleClick(event, '' + d_index + ':' + t_index)}
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                          >
                                            <CloseIcon />
                                          </ToggleButton>
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        <TableContainer component={Paper}>
                          <Table size="small" aria-label="Undesired Times" padding="none">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" style={{ minWidth: '90px' }}>
                                  <ToggleButton
                                    value="check"
                                    id={`check_${0}_${0}`}
                                    fullWidth={true}
                                    selected={allCount > 0 && contSelected2 === allCount}
                                    size="small"
                                    color="warning"
                                    onClick={(event) => handleSelectAllClick2(event, '**')}
                                  >
                                    {t('Time')}
                                  </ToggleButton>
                                </TableCell>
                                {days.map((day, d_index) => {
                                  return (
                                    <TableCell key={`day2_${d_index}`} align="center" style={{ minWidth: '90px' }}>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${d_index}:0`}
                                        fullWidth={true}
                                        selected={rowCount > 0 && contSelectedAtDay2[d_index] === rowCount}
                                        size="small"
                                        color="warning"
                                        onClick={(event) => handleSelectAllDayClick2(event, d_index + ':*')}
                                      >
                                        {days[d_index]}
                                      </ToggleButton>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {times.map((time, t_index) => {
                                return (
                                  <TableRow
                                    key={`time2_${t_index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${0}:${t_index}`}
                                        fullWidth={true}
                                        selected={colCount > 0 && contSelectedAtTime2[t_index] === colCount}
                                        size="small"
                                        color="warning"
                                        onClick={(event) => handleSelectAllTimeClick2(event, '*:' + t_index)}
                                      >
                                        {times[t_index]}
                                      </ToggleButton>
                                    </TableCell>

                                    {days.map((day, d_index) => {
                                      const isItemSelected = isSelected2('' + d_index + ':' + t_index);
                                      return (
                                        <TableCell key={`cell2_${d_index}_${t_index}`} align="center">
                                          <ToggleButton
                                            value="check"
                                            id={`check_${d_index}:${t_index}`}
                                            fullWidth
                                            size="small"
                                            color="warning"
                                            onClick={(event) => handleClick2(event, '' + d_index + ':' + t_index)}
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                          >
                                            <RemoveIcon />
                                          </ToggleButton>
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Constraints')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={1}>
                      {showWorkload && (
                        <MinMaxConstraintSlider
                          icon={<WorkHistoryIcon sx={{ marginRight: 1 }} />}
                          title={t('Working Times')}
                          tooltip={
                            <div>
                              {`${t('Limits the number of times having lessons for this')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Min and Max are set to 5, this')} ${t(type)} ${t(
                                'will have lessons in 5 times throughout the timetable:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/workingTimes.png'}
                                width={'100%'}
                                alt={t('Example of Working Times constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinWorkload}
                          setSelectedMin={setSelectedMinWorkload}
                          selectedMax={selectedMaxWorkload}
                          setSelectedMax={setSelectedMaxWorkload}
                          possibleMin={0}
                          possibleMax={times.length * days.length}
                          applyToAllSelected={selectedWorkload}
                          setApplyToAllSelected={setSelectedWorkload}
                          tooltipApplyToAll={`${t('Apply to all')} ${getLabelFromType()}`}
                        />
                      )}
                      {showWorkingDays && (
                        <MinMaxConstraintSlider
                          icon={<DateRangeIcon sx={{ marginRight: 1 }} />}
                          title={t('Working Days')}
                          tooltip={
                            <div>
                              {`${t('Limits the number of days with lessons for this')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Max is set to 2, this')} ${t(type)} ${t(
                                'will have lessons in at most 2 days:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/workingDays.png'}
                                width={'100%'}
                                alt={t('Example of Working Days constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinWorkingDays}
                          setSelectedMin={setSelectedMinWorkingDays}
                          selectedMax={selectedMaxWorkingDays}
                          setSelectedMax={setSelectedMaxWorkingDays}
                          possibleMin={0}
                          possibleMax={days.length}
                          applyToAllSelected={selectedWorkingDays}
                          setApplyToAllSelected={setSelectedWorkingDays}
                          tooltipApplyToAll={`${t('Apply to all')} ${getLabelFromType()}`}
                        />
                      )}
                      {showIdleWindow && (
                        <MinMaxConstraintSlider
                          icon={<ChairIcon sx={{ marginRight: 1 }} />}
                          title={t('Idle Window')}
                          tooltip={
                            <div>
                              {`${t('Limits the number of idle times between working times for this')} ${t(type)}`}
                              <hr />
                              {`${t(
                                'For example, if Max is set to 1, window between 2nd and 4th times on Tuesday would be allowed, but window between 1st and 4th times on Thursday would be forbidden:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/idleWindow.png'}
                                width={'100%'}
                                alt={t('Example of Idle Window constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinIdleWindow}
                          setSelectedMin={setSelectedMinIdleWindow}
                          selectedMax={selectedMaxIdleWindow}
                          setSelectedMax={setSelectedMaxIdleWindow}
                          possibleMin={0}
                          possibleMax={times.length - 2}
                          applyToAllSelected={selectedIdleWindow}
                          setApplyToAllSelected={setSelectedIdleWindow}
                          tooltipApplyToAll={`${t('Apply to all')} ${getLabelFromType()}`}
                        />
                      )}
                      {showDailyWorkload && (
                        <MinMaxConstraintSlider
                          icon={<EventRepeatIcon sx={{ marginRight: 1 }} />}
                          title={t('Daily Workload')}
                          tooltip={
                            <div>
                              {`${t('Limits the number of times with lessons per day for this')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Max is set to 2, this')} ${t(type)} ${t(
                                'will have at most 2 lessons per day:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/dailyWorkload.png'}
                                width={'100%'}
                                alt={t('Example of Daily Workload constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinDailyWorkload}
                          setSelectedMin={setSelectedMinDailyWorkload}
                          selectedMax={selectedMaxDailyWorkload}
                          setSelectedMax={setSelectedMaxDailyWorkload}
                          possibleMin={0}
                          possibleMax={times.length}
                          applyToAllSelected={selectedDailyWorkload}
                          setApplyToAllSelected={setSelectedDailyWorkload}
                          tooltipApplyToAll={`${t('Apply to all')} ${getLabelFromType()}`}
                        />
                      )}
                      {showRestBetweenDays && (
                        <MinMaxConstraintSlider
                          icon={<HotelIcon sx={{ marginRight: 1 }} />}
                          title={t('Rest between Days')}
                          tooltip={
                            <div>
                              {`${t('Limits the number of rest times between one day and the next')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Min is set to 1, this')} ${t(type)} ${t(
                                'will have at least 1 free slot between lessons from a day and the next, enforcing the 1st time on Friday to be free:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/restBetweenDays.png'}
                                width={'100%'}
                                alt={t('Example of Rest between Days constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinRestBetweenDays}
                          setSelectedMin={setSelectedMinRestBetweenDays}
                          selectedMax={selectedMaxRestBetweenDays}
                          setSelectedMax={setSelectedMaxRestBetweenDays}
                          possibleMin={0}
                          possibleMax={times.length * 2 - 2}
                          applyToAllSelected={selectedRestBetweenDays}
                          setApplyToAllSelected={setSelectedRestBetweenDays}
                          tooltipApplyToAll={`${t('Apply to all')} ${getLabelFromType()}`}
                        />
                      )}
                      {showRoomChangesDay && (
                        <MinMaxConstraintSlider
                          icon={<TransferWithinAStationIcon sx={{ marginRight: 1 }} />}
                          title={t('Rooms per Day')}
                          tooltip={
                            <div>
                              {`${t('Limits the number of different rooms used per day by this')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Max is set to 1, this')} ${t(type)} ${t(
                                "will have lessons in at most 1 room in any day, enforcing all lessons on Tuesday to be in the same room, same applies to Thursday's lessons:"
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/roomChangesDay.png'}
                                width={'100%'}
                                alt={t('Example of Rooms per Day constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinRoomChangesDay}
                          setSelectedMin={setSelectedMinRoomChangesDay}
                          selectedMax={selectedMaxRoomChangesDay}
                          setSelectedMax={setSelectedMaxRoomChangesDay}
                          possibleMin={0}
                          possibleMax={times.length - 1}
                          applyToAllSelected={selectedRoomChangesDay}
                          setApplyToAllSelected={setSelectedRoomChangesDay}
                          tooltipApplyToAll={`${t('Apply to all')} ${getLabelFromType()}`}
                        />
                      )}
                      {showConsecutiveTimes && (
                        <MinMaxConstraintSlider
                          icon={<LinearScaleIcon sx={{ marginRight: 1 }} />}
                          title={t('Consecutive Times')}
                          tooltip={
                            <div>
                              {`${t('Limits the number consecutive times with lessons for this')} ${t(type)}`}
                              <hr />
                              {`${t('For example, if Max is set to 2, this')} ${t(type)} ${t(
                                'will have at most 2 consecutive slots throughout the timetable:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/consecutiveTimes.png'}
                                width={'100%'}
                                alt={t('Example of Consecutive Times constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinConsecutiveTimes}
                          setSelectedMin={setSelectedMinConsecutiveTimes}
                          selectedMax={selectedMaxConsecutiveTimes}
                          setSelectedMax={setSelectedMaxConsecutiveTimes}
                          possibleMin={0}
                          possibleMax={times.length}
                          applyToAllSelected={selectedConsecutiveTimes}
                          setApplyToAllSelected={setSelectedConsecutiveTimes}
                          tooltipApplyToAll={`${t('Apply to all')} ${getLabelFromType()}`}
                        />
                      )}
                      {showDistinctSubjects && (
                        <MinMaxConstraintSlider
                          icon={<MenuBookIcon sx={{ marginRight: 1 }} />}
                          title={t('Distinct Subjects')}
                          tooltip={
                            <div>
                              {`${t('Limits the number of distinct subjects taught by this')} ${t(type)}`}
                              <hr />
                              {t(
                                'For example, if Max is set to 2, this teacher will lecture at most 2 different subjects, yellow (4 times) and blue (2 times):'
                              )}
                              <p />
                              <img
                                src={'/img/examples/distinctSubjects.png'}
                                width={'100%'}
                                alt={t('Example of Distinct Subjects constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinDistinctSubjects}
                          setSelectedMin={setSelectedMinDistinctSubjects}
                          selectedMax={selectedMaxDistinctSubjects}
                          setSelectedMax={setSelectedMaxDistinctSubjects}
                          possibleMin={0}
                          possibleMax={days.length * times.length}
                          applyToAllSelected={selectedDistinctSubjects}
                          setApplyToAllSelected={setSelectedDistinctSubjects}
                          tooltipApplyToAll={`${t('Apply to all')} ${getLabelFromType()}`}
                        />
                      )}
                      {showTravelTime && (
                        <Grid container item xs={12} spacing={1}>
                          <Grid item xs={12} sm={5}>
                            <Tooltip
                              title={
                                <div>
                                  {t('Travel time description')}
                                  <hr />
                                  {t(
                                    'Consider you are setting the room that hosts brown lesson. If you select the room in which blue lesson is assigned and set Times to 1, any teacher/class that takes both brown and blue lessons will have at least 1 free slot to commute:'
                                  )}
                                  <p />
                                  <img
                                    src={'/img/examples/travelTime.png'}
                                    width={'100%'}
                                    alt={t('Example of Travel Time constraint')}
                                  />
                                </div>
                              }
                            >
                              <ToggleButton
                                value="check"
                                fullWidth
                                size="small"
                                color="primary"
                                sx={{
                                  textTransform: 'none',
                                  justifyContent: widthLessThan600 ? 'center' : 'right',
                                  paddingRight: 2,
                                }}
                                style={{ borderWidth: 0, backgroundColor: '#ffffff' }}
                                selected={
                                  (selectedMinTravelTime as number) == 0 || selectedTravelTimeRooms.length === 0
                                    ? false
                                    : true
                                }
                              >
                                <EmojiTransportationIcon sx={{ marginRight: 1 }}></EmojiTransportationIcon>
                                {t('Travel Time')}
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={4} sm={2}>
                            <FormControl fullWidth size="small">
                              <MultipleSelectChip
                                label={t('Rooms')}
                                names={resources
                                  .filter((res) => res.type === 'room')
                                  .map((res) => {
                                    return res.name;
                                  })}
                                personName={selectedTravelTimeRooms}
                                setPersonName={setSelectedTravelTimeRooms}
                              ></MultipleSelectChip>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} sm={2}>
                            <FormControl fullWidth size="small">
                              <InputLabel id="min-travel-time-label">{t('Times')}</InputLabel>
                              <Select
                                size="small"
                                labelId="min-consecutive-times-select-label"
                                id="min-travel-time-select"
                                defaultValue={'' + formik.initialValues.minTravelTime}
                                onChange={handleChangeMinTravelTime}
                                sx={{
                                  color: +selectedMinTravelTime !== 0 ? 'default' : '#fff',
                                }}
                                label={t('Times')}
                              >
                                {Array.from({ length: +times.length - 1 + 1 }).map((_, index) => {
                                  return (
                                    <MenuItem key={`minTravelTimes_${index}`} value={`${index}`}>
                                      {index === 0 ? `↓` : index}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} sm={3} lg={3}>
                            <Tooltip title={`${t('Apply to all')} ${getLabelFromType()}`} placement="right-end">
                              <ToggleButton
                                value="check"
                                fullWidth
                                size="small"
                                color="primary"
                                selected={selectedTravelTime}
                                onChange={() => {
                                  setSelectedTravelTime(!selectedTravelTime);
                                }}
                              >
                                <DoubleArrowIcon />
                                {widthLessThan600 ? '' : t('All')}
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          {widthLessThan600 && (
                            <Grid item xs={12}>
                              <Divider></Divider>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      {showForbiddenCombination && (
                        <Grid container item xs={12} spacing={1}>
                          <Grid item xs={12} sm={5}>
                            <Tooltip
                              title={
                                <div>
                                  {t('Enforces to have at least one day without lessons among the selected days')}
                                  <hr />
                                  {`${t(
                                    'For example, if Monday and Friday are selected, at least one of them will be free for this '
                                  )} ${t(type)}:`}
                                  <p />
                                  <img
                                    src={'/img/examples/forbiddenCombination.png'}
                                    width={'100%'}
                                    alt={t('Example of Forbidden Combination constraint')}
                                  />
                                </div>
                              }
                            >
                              <ToggleButton
                                value="check"
                                fullWidth
                                size="small"
                                color="primary"
                                sx={{
                                  textTransform: 'none',
                                  justifyContent: widthLessThan600 ? 'center' : 'right',
                                  paddingRight: 2,
                                }}
                                style={{ borderWidth: 0, backgroundColor: '#ffffff' }}
                                selected={selectedDays.length === 0 ? false : true}
                              >
                                <MoreTimeIcon sx={{ marginRight: 1 }}></MoreTimeIcon>
                                {t('Forbidden Combination')}
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={8} sm={4}>
                            <FormControl fullWidth size="small">
                              <MultipleSelectChip
                                label={t('Days')}
                                names={days}
                                personName={selectedDays}
                                setPersonName={setSelectedDays}
                              ></MultipleSelectChip>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} sm={3} lg={3}>
                            <Tooltip title={`${t('Apply to all')} ${getLabelFromType()}`} placement="right-end">
                              <ToggleButton
                                value="check"
                                fullWidth
                                size="small"
                                color="primary"
                                selected={selectedForbiddenCombination}
                                onChange={() => {
                                  setSelectedForbiddenCombination(!selectedForbiddenCombination);
                                }}
                              >
                                <DoubleArrowIcon />
                                {widthLessThan600 ? '' : t('All')}
                              </ToggleButton>
                            </Tooltip>
                          </Grid>
                          {widthLessThan600 && (
                            <Grid item xs={12}>
                              <Divider></Divider>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <Button
                          startIcon={<VisibilityIcon />}
                          variant="outlined"
                          size="small"
                          onClick={() => setShowConstraintsOpen(true)}
                          color="primary"
                          fullWidth
                        >
                          {t('Show More Constraints')}
                        </Button>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>
      {/* <Onboarding run={runResourceCreation} steps={steps} storageFlagKey={resourceCreationStorageFlagKey} /> */}
    </Container>
  );
}
