import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function RuntimeSelect(props: any) {
  const current: string = props.current;
  const setCurrent = props.setCurrent;
  const options: string[] = props.options;
  const label: string = props.label;
  const maxWidth: number = props.maxWidth;

  const handleChange = (event: SelectChangeEvent) => {
    setCurrent(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={'' + current}
        label={label}
        sx={{ maxWidth: maxWidth ? maxWidth : 'inherit' }}
        onChange={handleChange}
      >
        {options.map((option, index) => {
          return (
            <MenuItem disabled={option.charAt(option.length - 1) === ')'} key={`${label}_${index}`} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
