import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from 'react-i18next';
import { SessionContext } from 'contexts';
import { useContext, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { ShareUser } from 'pages/Files/File';
import { Delete } from '@material-ui/icons';
import ShareModeSelect from './ShareModeSelect';
import { SelectChangeEvent } from '@mui/material';
import { toast } from 'react-toastify';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from 'services';
import { IUser, SharedWithMe } from 'contexts/SessionContext/SessionContext';
import { UserCredential, createUserWithEmailAndPassword } from 'firebase/auth';

interface ModeOptionsMap {
  [key: string]: string;
}

export default function FileShareDialog(props: any) {
  const { t } = useTranslation();
  const { user, files } = useContext(SessionContext);
  const open = props.open;
  const setOpen = props.setOpen;
  const clickedFileName = props.clickedFileName;
  const file = files.find((file) => file.name === clickedFileName);

  const [sharedWith, setSharedWith] = useState(file?.sharedWith || []);

  const [inputText, setInputText] = useState('');

  const handleConfirm = () => {
    if (file && user) {
      const oldSharedWith = file.sharedWith;
      //Update file
      const fileDoc = doc(db, 'users/' + user?.email + '/files/' + file?.name);
      updateDoc(fileDoc, {
        sharedWith: sharedWith,
      })
        .then(() => {
          //Update sharings
          sharedWith?.forEach(async (share) => {
            const sharingDocRef = doc(db, 'sharings/' + share.user);
            getDoc(sharingDocRef).then(async (docSnap) => {
              if (docSnap.exists()) {
                //Update sharings of this user
                const sharingDoc = docSnap.data().sharedWithMe as SharedWithMe[];
                const updatedFilesSharedWithUser = sharingDoc.filter((shared) => shared.file !== file?.name);
                updatedFilesSharedWithUser?.push({ user: user?.email, file: file?.name, mode: share.mode });
                updateDoc(sharingDocRef, {
                  sharedWithMe: updatedFilesSharedWithUser,
                })
                  .then(() => {
                    toast.success(t('File shared successfully'));
                  })
                  .catch((error) => {
                    toast.error(t('Error sharing file: ') + error);
                  });
              } else {
                const sharingRef = collection(db, 'sharings');
                const newSharing = [{ user: user?.email, file: file?.name, mode: share.mode } as SharedWithMe];
                await setDoc(doc(sharingRef, share.user), { sharedWithMe: newSharing })
                  .then(() => {
                    toast.success(t('File shared successfully'));
                  })
                  .catch((error) => {
                    toast.error(t('Error sharing file: ') + error);
                  });
              }
            });
          });

          //Remove sharing with users that are not in the new list
          oldSharedWith?.forEach(async (oldShare) => {
            if (!sharedWith?.find((newShare) => newShare.user === oldShare.user)) {
              const sharingDocRef = doc(db, 'sharings/' + oldShare.user);
              getDoc(sharingDocRef).then(async (docSnap) => {
                if (docSnap.exists()) {
                  const sharingDoc = docSnap.data().sharedWithMe as SharedWithMe[];
                  const updatedSharing = sharingDoc.filter((shared) => shared.file !== file?.name);
                  updateDoc(sharingDocRef, {
                    sharedWithMe: updatedSharing,
                  }).catch((error) => {
                    toast.error(t('Error unsharing file: ') + error);
                  });
                }
              });
            }
          });
        })
        .catch((error) => {
          toast.error(t('Error sharing file: ') + error);
        });
    }

    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modeMap: ModeOptionsMap = {
    read: 'Read',
    write: 'Write',
  };
  modeMap['read'] = 'Read';
  modeMap['write'] = 'Write';

  const modeReverseMap: ModeOptionsMap = {
    Read: 'read',
    Write: 'write',
    Leitor: 'read',
    Editor: 'write',
  };

  const handleDeleteClick = (event: React.MouseEvent<unknown>, shared: ShareUser) => {
    setSharedWith(sharedWith?.filter((share) => share.user !== shared.user));
  };

  const handleChange = (event: SelectChangeEvent, shared: ShareUser) => {
    setSharedWith(
      sharedWith?.map((share) => {
        return shared.user === share.user ? { ...share, mode: modeReverseMap[event.target.value] } : share;
      })
    );
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleAddShare = () => {
    const emailInput = document?.getElementById('name') as HTMLInputElement;
    const emails = emailInput?.value.split(',');
    const newShares: ShareUser[] = [];

    emails.forEach((email: string) => {
      const trimmedEmail = email.trim();
      const repeatedEmail = sharedWith?.find((share) => share.user === trimmedEmail);
      if (repeatedEmail) {
        toast.warning(t('File already shared with user: ') + trimmedEmail);
        return;
      }
      if (!validateEmail(trimmedEmail)) {
        toast.warning(t('Invalid email: ') + trimmedEmail);
        return;
      }
      if (user?.email === trimmedEmail) {
        toast.warning(t('User is already the owner of this file: ') + trimmedEmail);
        return;
      }
      newShares.push({ user: trimmedEmail, mode: 'read' });
    });

    setSharedWith(sharedWith?.concat(newShares));
    emailInput.value = '';
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>{t('Share file')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('Provide the user emails to share this file with')}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={t('Emails (separeted by comma)')}
            fullWidth
            required
            variant="standard"
            translate="no"
            defaultValue={inputText}
            // onChange={(e) => setInputText(e.target.value)}
          />
          <Button onClick={handleAddShare}>{t('Add')}</Button>
          {sharedWith?.map((shared) => (
            <Grid
              key={shared.user}
              container
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Grid item xs={8}>
                <p>{shared.user}</p>
              </Grid>
              <Grid item xs={4} container flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                <Grid item flex={1}>
                  {/* <p>{shared.mode}</p> */}
                  <ShareModeSelect
                    shared={shared}
                    current={t(modeMap[shared.mode])}
                    handleChange={handleChange}
                    options={[t('Write'), t('Read')]}
                    label={t('Mode')}
                  />
                </Grid>
                <Grid item>
                  <IconButton aria-label="delete" onClick={(event) => handleDeleteClick(event, shared)}>
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Cancel')}</Button>
          <Button onClick={handleConfirm}>{t('Share')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
