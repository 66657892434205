import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import { Grid, Stack, Typography, useMediaQuery } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { useTranslation } from 'react-i18next';

import { ISolution } from '../Solution';
import { SessionContext } from 'contexts';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

interface DefectData {
  id: string;
  type: string;
  appliesTo: string;
  description: string;
  times: number;
  weight: number;
}

function createData(
  id: string,
  type: string,
  appliesTo: string,
  description: string,
  times: number,
  weight: number
): DefectData {
  return {
    id,
    type,
    appliesTo,
    description,
    times,
    weight,
  };
}

export default function DefectsDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const solution: ISolution = props.solution;
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const widthLessThan600 = useMediaQuery('(max-width:600px)');
  const [pageSize, setPageSize] = React.useState<number>(100);
  const { user } = React.useContext(SessionContext);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: t('Id'),
      flex: 2,
      minWidth: 150,
      hide: true,
    },
    {
      field: 'type',
      headerName: t('Type'),
      flex: 3,
      minWidth: 100,
    },
    {
      field: 'appliesTo',
      headerName: t('Applies To'),
      flex: 4,
      minWidth: 100,
    },
    {
      field: 'description',
      headerName: t('Description'),
      flex: 9,
      minWidth: 150,
      hide: widthLessThan900,
    },
    {
      field: 'times',
      headerName: t('Count'),
      type: 'number',
      flex: 1,
      minWidth: 90,
      hide: widthLessThan600,
      headerAlign: 'right',
      align: 'right',
    },
    {
      field: 'weight',
      headerName: t('Weight'),
      type: 'number',
      flex: 1,
      minWidth: 90,
      hide: widthLessThan600,
      headerAlign: 'right',
      align: 'right',
    },
  ];

  let rows: any[] = [];
  solution.defects.map(function (defect, index) {
    return rows.push(
      createData(
        defect.type + ' ' + defect.appliesTo + ' ' + defect.description,
        t(defect.type),
        defect.appliesTo,
        defect.description,
        defect.times,
        defect.weight
      )
    );
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="xl"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
          <Grid container item xs={12} sx={{ height: '70vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={columns}
              // autoPageSize
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[20, 50, 100]}
              // hideFooterPagination
              density={'compact'}
              // checkboxSelection
              disableSelectionOnClick
              // onSelectionModelChange={(ids) => {
              //   setSelectionModel(ids);
              // }}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'weight', sort: 'desc' }],
                },
              }}
              localeText={user?.countryCode === 'BR' ? ptBR.components.MuiDataGrid.defaultProps.localeText : undefined}
              // onRowClick={(params, event) => handleClick(event, params.id.toString())}
              components={{
                NoRowsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <HighlightAltIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('This timetable has no DEFECTS')}
                    </Typography>
                  </Stack>
                ),
                NoResultsOverlay: () => (
                  <Stack height="100%" alignItems="center" justifyContent="center">
                    <SearchOffIcon style={{ color: grey[500], textAlign: 'center' }} />
                    <Typography color={grey[500]} textAlign="center">
                      {t('Local FILTER returns no data')}
                    </Typography>
                  </Stack>
                ),
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirm}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
