/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
  ToggleButton,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tabs,
  Tab,
  Container,
  Tooltip,
  colors,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  DialogContentText,
  AccordionDetails,
  IconButton,
} from '@material-ui/core';
import { ArrowBack, CollectionsBookmark, Help, Save } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { doc, setDoc, deleteDoc, updateDoc, writeBatch } from '@firebase/firestore';
import Paper from '@mui/material/Paper';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@material-ui/system';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import { blue } from '@mui/material/colors';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import SchoolIcon from '@mui/icons-material/School';
import { CoPresent } from '@mui/icons-material';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { db } from 'services';
import { SessionContext } from 'contexts';
import MiniDrawer from 'components/MiniDrawer';
import MultipleSelectChip from 'components/MultipleSelectChip';
import DraggableDialog from 'components/DraggableDialog';
import DraggableAvatarPicker from 'components/DraggableAvatarPicker';
import ValueSelectDialog from 'components/ValueSelectDialog';
import ValueSelectConfirmDialog from 'components/ValueSelectConfirmDialog';
import MinMaxConstraint from 'components/MinMaxConstraint';
import OnboardingContent from 'components/OnboardingContent';
import Onboarding from 'components/Onboarding';
import FormTitle from 'components/FormTitle';
import PlanInfoDialog from 'components/PlanInfoDialog';
import useUserPlan from 'stripe/useUserPlan';
import { planFeatures } from 'configs/planFeatures';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import MinMaxConstraintSlider from 'components/MinMaxConstraintSlider';
import VideoPlayerDialog from 'components/VideoPlayerDialog';

export interface ILesson {
  color: string;
  photoURL: string;
  name: string;
  short: string;

  subject: string[];
  lessonsWeek: number;
  split: string[];
  minGapLessons: number;
  maxGapLessons: number;

  classNum: number;
  classes: string[];
  teacherNum: number;
  teachers: string[];
  roomNum: number;
  rooms: string[];

  priority: string;
  predefinedTimes: string[];
  simultaneousWith: string[];
  notSimultaneousWith: string[];
  occurBefore: string[];
  occurBeforeMinDays: number;

  createdAt: Date;
  updatedAt: Date;
}

export default function Lesson(props: any) {
  const { t } = useTranslation();
  const { user, file, resources, subjects, lessons, solutions, ownerEmail, shareMode } = useContext(SessionContext);
  const lessonsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/lessons';
  const solutionsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/solutions';
  const history = useHistory();
  const updateLesson = history.location.state !== undefined;
  const [showPlanLimitDialog, setShowPlanLimitDialog] = useState(false);
  const userPlan = useUserPlan(user);

  const readOnly = ownerEmail !== user?.email && shareMode === 'read';

  const classes = resources
    .filter((resource) => {
      return resource.type === 'class';
    })
    .map((class_) => class_.name);

  const teachers = resources
    .filter((resource) => {
      return resource.type === 'teacher';
    })
    .map((teacher) => teacher.name);

  const rooms = resources
    .filter((resource) => {
      return resource.type === 'room';
    })
    .map((room) => room.name);

  const subjects_ = subjects.map((subject) => subject.name);
  const lessons_ = lessons.map((lesson) => lesson.name);

  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];
  const priorityOptions = ['Very low', 'Low', 'Average', 'High', 'Very high'];

  const defaultColor: string = blue[900];
  const previousName: string = updateLesson ? (history.location.state as ILesson).name : '';

  const lesson: ILesson = updateLesson
    ? (history.location.state as ILesson)
    : {
        color: defaultColor,
        photoURL: '',
        name: '',
        short: '',

        subject: [],
        lessonsWeek: 2,
        split: ['1-1'],
        minGapLessons: 0,
        maxGapLessons: days.length - 1,

        classNum: 1,
        classes: [],
        teacherNum: 1,
        teachers: [],
        roomNum: file?.roomsSetting === 2 ? 1 : 0,
        rooms: [],

        priority: priorityOptions[2],
        predefinedTimes: [],
        simultaneousWith: [],
        notSimultaneousWith: [],
        occurBefore: [],
        occurBeforeMinDays: 0,

        createdAt: new Date('2022-01-01T07:00:00'),
        updatedAt: new Date('2022-01-01T07:00:00'),
      };

  async function submitForm() {
    if (userPlan === 'free' && lessons.length + 1 > planFeatures.free.maxLessons) {
      setShowPlanLimitDialog(true);
    } else if (userPlan === 'basic' && lessons.length + 1 > planFeatures.basic.maxLessons) {
      setShowPlanLimitDialog(true);
    } else {
      try {
        const name = selectedSubject[0] + ' | ' + selectedClasses.toString();
        let predefinedTimes: string[] = [];
        for (let i = 0; i < selectedCells.length; i++) {
          predefinedTimes.push(selectedCells[i]);
        }

        const newLesson: ILesson = {
          color: selectedColor,
          photoURL: selectedPhotoURL,
          name: name,
          short: '',

          subject: selectedSubject,
          lessonsWeek: selectedLessonsWeek,
          split: selectedSplit,
          minGapLessons: +selectedMinGapLessons,
          maxGapLessons: +selectedMaxGapLessons,

          classNum: +selectedClassNum,
          classes: selectedClasses,
          teacherNum: +selectedTeacherNum,
          teachers: selectedTeachers,
          roomNum: +selectedRoomNum,
          rooms: selectedRooms,

          priority: subjects.find((sub) => sub.name === selectedSubject[0])?.priority ?? priorityOptions[2],
          predefinedTimes: predefinedTimes,
          simultaneousWith: selectedSimultaneousWith,
          notSimultaneousWith: selectedNotSimultaneousWith,
          occurBefore: selectedOccurBefore,
          occurBeforeMinDays: +selectedOccurBeforeMinDays,

          createdAt: new Date(),
          updatedAt: new Date(),
        };

        const batch = writeBatch(db);
        batch.set(doc(db, lessonsPath, name), newLesson);
        // Delete old record and update references if name has changed
        if (previousName !== '' && previousName !== name) {
          batch.delete(doc(db, lessonsPath, previousName));
        }

        //Check if Constraints Apply to All
        if (selectedGapLessons) {
          lessons.forEach((lesson) =>
            batch.update(doc(db, lessonsPath + '/' + lesson.name), {
              minGapLessons: selectedMinGapLessons,
              maxGapLessons: selectedMaxGapLessons,
              updatedAt: new Date(),
            })
          );
        }
        // if (selectedTimeSplit) {
        //   lessons.forEach((lesson) =>
        //     batch.update(doc(db, lessonsPath + '/' + lesson.name), {
        //       lessonsWeek: selectedLessonsWeek,
        //       split: selectedSplit,
        //       updatedAt: new Date(),
        //     })
        //   );
        // }
        //Set all solutions to outdated
        solutions.forEach((solution) => {
          batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
            status: 'Outdated',
          });
        });
        batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          updatedAt: new Date(),
        });
        batch
          .commit()
          .then(() => {
            updateLesson
              ? toast.success(selectedSubject[0] + t(' updated sucessfully'))
              : toast.success(selectedSubject[0] + t(' created sucessfully'));
            history.push('/lessons');
          })
          .catch(() => {
            toast.error(t('An error has ocurred'));
            history.push('/lessons');
          });
      } catch (error: any) {
        toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
        console.error({ error });
      }
    }
  }

  const formik = useFormik<ILesson>({
    initialValues: lesson,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // name: Yup.string().required('Required field'),
      // short: Yup.string().required('Required field'),
    }),
    onSubmit: async (values) => {
      submitForm();
    },
  });

  const [selectedColor, setSelectedColor] = React.useState<string>(formik.initialValues.color);
  const [selectedPhotoURL, setSelectedPhotoURL] = React.useState<string>(formik.initialValues.photoURL);

  const [selectedClassNum, setSelectedClassNum] = React.useState<number>(formik.initialValues.classNum);

  const [selectedClasses, setSelectedClasses] = React.useState<string[]>(formik.initialValues.classes);

  const [copyLessonOpen, setCopyLessonOpen] = useState(false);
  const [copyLessonConfirmed, setCopyLessonConfirmed] = useState(false);

  function checkCopyLessonConfirmation() {
    if (copyLessonConfirmed) {
      setCopyLessonConfirmed(false);
      copy();
    }
  }
  checkCopyLessonConfirmation();

  function copy() {
    if (userPlan === 'free' && lessons.length + 1 > planFeatures.free.maxLessons) {
      setShowPlanLimitDialog(true);
    } else if (userPlan === 'basic' && lessons.length + 1 > planFeatures.basic.maxLessons) {
      setShowPlanLimitDialog(true);
    } else {
      try {
        //Validation and newName adjustments
        const newClass = '' + localStorage.getItem('copyClass');
        if (newClass === '') toast.error(t('An error has ocurred'));
        const newName: string = lesson.subject[0].trim() + ' | ' + newClass;
        if (lessons.map((lesson) => lesson.name).includes(newName)) {
          toast.error(newName + t(' already registered'));
          return;
        }
        const newLesson = { ...lesson };
        newLesson.name = newName;
        newLesson.classes = [newClass];
        newLesson.createdAt = new Date();
        newLesson.updatedAt = new Date();
        setDoc(doc(db, lessonsPath, newName), newLesson).then(() => {
          //Set all solutions to outdated
          solutions.forEach((solution) => {
            updateDoc(doc(db, `${solutionsPath}/${solution.name}`), {
              status: 'Outdated',
            });
          });
          toast.success(lesson.subject[0].trim() + t(' copied sucessfully'));
          history.push('/lessons');
        });
        updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
          updatedAt: new Date(),
        });
      } catch (error: any) {
        toast.error(`${error?.message?.split(':').slice(-1)[0].trim() ?? t('An error has occurred')}`);
        console.error({ error });
      }
    }
  }

  const [selectedSubject, setSelectedSubject] = React.useState<string[]>(formik.initialValues.subject);

  // const [selectedPriority, setSelectedPriority] = React.useState<string>(formik.initialValues.priority);
  // const handleChangePriority = (event: SelectChangeEvent) => {
  //   setSelectedPriority(event.target.value as unknown as string);
  // };

  const [selectedLessonsWeek, setSelectedLessonsWeek] = React.useState<number>(formik.initialValues.lessonsWeek);
  const handleChangeLessonsWeek = (event: SelectChangeEvent) => {
    setSelectedLessonsWeek(event.target.value as unknown as number);
    setSelectedSplit([]);
  };

  const [selectedSplit, setSelectedSplit] = React.useState<string[]>(formik.initialValues.split);

  const [selectedGapLessons, setSelectedGapLessons] = React.useState(false);
  const [selectedMinGapLessons, setSelectedMinGapLessons] = React.useState<number>(formik.initialValues.minGapLessons);
  const [selectedMaxGapLessons, setSelectedMaxGapLessons] = React.useState<number>(formik.initialValues.maxGapLessons);

  const [selectedTeacherNum, setSelectedTeacherNum] = React.useState<number>(formik.initialValues.teacherNum);

  const [selectedTeachers, setSelectedTeachers] = React.useState<string[]>(formik.initialValues.teachers);

  const [selectedRoomNum, setSelectedRoomNum] = React.useState<number>(formik.initialValues.roomNum);

  const [selectedRooms, setSelectedRooms] = React.useState<string[]>(formik.initialValues.rooms);

  const [selectedSimultaneousWith, setSelectedSimultaneousWith] = React.useState<string[]>(
    formik.initialValues.simultaneousWith
  );

  const [selectedNotSimultaneousWith, setSelectedNotSimultaneousWith] = React.useState<string[]>(
    formik.initialValues.notSimultaneousWith
  );

  const [selectedOccurBefore, setSelectedOccurBefore] = React.useState<string[]>(formik.initialValues.occurBefore);

  const [selectedOccurBeforeMinDays, setSelectedOccurBeforeMinDays] = React.useState<number>(
    formik.initialValues.occurBeforeMinDays
  );
  const handleChangeOccurBeforeMinDays = (event: SelectChangeEvent) => {
    setSelectedOccurBeforeMinDays(event.target.value as unknown as number);
  };
  //#endregion

  const copyClassOptions = resources
    .filter(
      (res) =>
        res.type === 'class' &&
        !selectedClasses.includes(res.name) &&
        lessons
          .filter((lesson) => lesson.subject[0] === selectedSubject[0])
          .map((les) => {
            return les.classes[0];
          })
          .indexOf(res.name) === -1
    )
    .map((res) => {
      return res.name;
    });

  const [selectedCopyClass, setSelectedCopyClass] = React.useState<string>(
    copyClassOptions.length === 0 ? '' : copyClassOptions[0]
  );

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        key={`simple-tabpanel-${index}`}
        id={`simple-tabpanel-${index}`}
        style={{ maxWidth: '100%', width: '100%' }}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  //#region States for and Tables

  const [selectedCells, setSelectedCells] = React.useState<readonly string[]>(formik.initialValues.predefinedTimes);

  let cells: any[] = [];
  times.forEach((time, t_index) => {
    days.forEach((day, d_index) => {
      cells.push(d_index + ':' + t_index);
    });
  });

  const isSelected = (name: string) => selectedCells.indexOf(name) !== -1;

  const handleSelectAllDayClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelectedCells = [...selectedCells];
    cells.forEach((element) => {
      let cellDay = element.split(':')[0];
      let nameDay = name.split(':')[0];
      if (cellDay === nameDay) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelectedCells.push(element);
          contSelectedAtDay[nameDay as unknown as number]++;
          contSelected++;
        } else {
          newSelectedCells = newSelectedCells.filter((item) => item !== element);
          contSelectedAtDay[nameDay as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedCells(newSelectedCells);
  };

  const handleSelectAllTimeClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellTime = element.split(':')[1];
      let nameTime = name.split(':')[1];
      if (cellTime === nameTime) {
        const selectedIndex = selectedCells.indexOf(element);
        if (selectedIndex === -1) {
          newSelected.push(element);
          contSelectedAtTime[nameTime as unknown as number]++;
          contSelected++;
        } else {
          newSelected = newSelected.filter((item) => item !== element);
          contSelectedAtTime[nameTime as unknown as number]--;
          contSelected--;
        }
      }
    });
    setSelectedCells(newSelected);
  };

  const handleSelectAllClick = (event: React.MouseEvent<unknown>, name: string) => {
    let newSelected = [...selectedCells];
    cells.forEach((element) => {
      let cellTime = element.split(':')[1];
      let cellDay = element.split(':')[0];
      const selectedIndex = selectedCells.indexOf(element);
      if (selectedIndex === -1) {
        newSelected.push(element);
        contSelectedAtDay[cellDay as unknown as number]++;
        contSelectedAtTime[cellTime as unknown as number]++;
        contSelected++;
      } else {
        newSelected = newSelected.filter((item) => item !== element);
        contSelectedAtDay[cellDay as unknown as number]--;
        contSelectedAtTime[cellTime as unknown as number]--;
        contSelected--;
      }
    });
    setSelectedCells(newSelected);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selectedCells.indexOf(name);
    let newSelected: readonly string[] = [];
    let nameDay = name.split(':')[0];
    let nameTime = name.split(':')[1];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedCells, name);
      contSelectedAtDay[nameDay as unknown as number]++;
      contSelectedAtTime[nameTime as unknown as number]++;
      contSelected++;
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedCells.slice(1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex === selectedCells.length - 1) {
      newSelected = newSelected.concat(selectedCells.slice(0, -1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedCells.slice(0, selectedIndex), selectedCells.slice(selectedIndex + 1));
      contSelectedAtDay[nameDay as unknown as number]--;
      contSelectedAtTime[nameTime as unknown as number]--;
      contSelected--;
    }
    setSelectedCells(newSelected);
  };

  let contSelectedAtDay: number[] = [];
  days.forEach((element) => {
    contSelectedAtDay.push(0);
  });
  let contSelectedAtTime: number[] = [];
  times.forEach((element) => {
    contSelectedAtTime.push(0);
  });
  let contSelected: number = 0;

  cells.forEach((element) => {
    let cellDay = element.split(':')[0];
    let cellTime = element.split(':')[1];
    const selectedIndex = selectedCells.indexOf(element);
    if (selectedIndex !== -1) {
      contSelectedAtDay[cellDay as unknown as number]++;
      contSelectedAtTime[cellTime as unknown as number]++;
      contSelected++;
    }
  });

  const rowCount: number = times.length;
  const colCount: number = days.length;
  const allCount: number = days.length * times.length;

  //#endregion

  const [avatarPickerOpen, setAvatarPickerOpen] = useState(false);

  const [jointClassesConfirmationOpen, setJointClassesConfirmationOpen] = useState(false);
  const [jointClassesConfirmed, setJointClassesConfirmed] = useState(false);
  const checkJointClassesConfirmed = () => {
    if (jointClassesConfirmed) {
      setJointClassesConfirmed(false);
      submitForm();
    }
  };
  checkJointClassesConfirmed();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);
  const checkDeleteConfirmed = () => {
    if (deleteConfirmed) {
      setDeleteConfirmed(false);
      deleteDoc(doc(db, lessonsPath, lesson.name));
      toast.success(lesson.name + ' deleted sucessfully!');
      history.push('/lessons');
      // Update file stats
      updateDoc(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
    }
  };
  checkDeleteConfirmed();

  const [toAllConfirmationOpen, setToAllConfirmationOpen] = useState(false);
  const [toAllConfirmed, setToAllConfirmed] = useState(false);

  const checkToAllConfirmation = () => {
    if (toAllConfirmed) {
      setToAllConfirmed(false);
      submitForm();
    }
  };
  checkToAllConfirmation();

  const [valueClassesSelectOpen, setValueClassesSelectOpen] = useState(false);
  const [valueTeachersSelectOpen, setValueTeachersSelectOpen] = useState(false);
  const [valueRoomsSelectOpen, setValueRoomsSelectOpen] = useState(false);

  //#region Split options processing
  const numSplitOptions: number[][] = [];
  // Source: https://acervolima.com/encontre-todas-as-combinacoes-que-somam-um-determinado-numero/
  // Javascript program to find out
  // all combinations of positive
  // numbers that add upto given
  // number

  /* arr - array to store the
      combination
      index - next location in array
      num - given number
      reducedNum - reduced number */
  function findCombinationsUtil(arr: number[], index: number, num: number, reducedNum: number) {
    // Base condition
    if (reducedNum < 0) return;

    // If combination is
    // found, print it
    if (reducedNum === 0) {
      let numSplitOption = [];
      for (let i = 0; i < index; i++) numSplitOption.push(arr[i]);
      numSplitOptions.push(numSplitOption);
      return;
      // let splitOption = '';
      // for (let i = 0; i < index; i++) splitOption = splitOption + '' + arr[i] + '-';
      // splitOption = splitOption.substring(0, splitOption.length - 1);
      // splitOptions.push(splitOption);
      // return;
    }

    // Find the previous number
    // stored in arr[]. It helps
    // in maintaining increasing
    // order
    // eslint-disable-next-line eqeqeq
    let prev = index == 0 ? 1 : arr[index - 1];

    // note loop starts from
    // previous number i.e. at
    // array location index - 1
    for (let k = prev; k <= num; k++) {
      // next element of
      // array is k
      arr[index] = k;

      // call recursively with
      // reduced number
      findCombinationsUtil(arr, index + 1, num, reducedNum - k);
    }
  }

  /* Function to find out all
  combinations of positive
  numbers that add upto given
  number. It uses findCombinationsUtil() */
  function findCombinations(n: number) {
    // array to store the combinations
    // It can contain max n elements
    let arr: number[] = [];

    // find all combinations
    findCombinationsUtil(arr, 0, n, n);
  }

  // Driver Code
  findCombinations(selectedLessonsWeek);

  // Filter to remove invalid split options and conversion to string
  const splitOptions: string[] = [];
  for (let i = 0; i < numSplitOptions.length; ++i) {
    // if (numSplitOptions[i].length <= days.length) {
    const res = numSplitOptions[i].filter((num) => num <= times.length);
    if (res.length === numSplitOptions[i].length) {
      // add splitOption as string
      let strSplitOption = '';
      numSplitOptions[i].forEach((num) => (strSplitOption = strSplitOption + num + '-'));
      strSplitOption = strSplitOption.substring(0, strSplitOption.length - 1);
      splitOptions.push(strSplitOption);
    }
    // }
  }
  //#endregion

  function validateInput(): boolean {
    const lessonNames: string[] = lessons.map((lesson) => lesson.name);
    const name = selectedSubject[0] + ' | ' + selectedClasses.toString();
    if (previousName !== name && lessonNames.includes(name)) {
      toast.error(`${selectedSubject[0]} ${t('is already offered to class')} ${selectedClasses.toString()}`);
      return false;
    } else if (selectedSubject.length === 0) {
      toast.error(t('Subject cannot be empty'));
      return false;
      // } else if (selectedSplit === '1-1' && +selectedLessonsWeek !== 2) {
    } else if (+selectedSplit.length === 0) {
      toast.error(t('Split Options cannot be empty'));
      return false;
    } else if (selectedClasses.length < selectedClassNum) {
      toast.error(t('Number of selected classes is smaller than required num. of joint classes'));
      return false;
    } else if (selectedTeachers.length < selectedTeacherNum) {
      toast.error(t('Number of selected teachers is smaller than required num. of joint teachers'));
      return false;
    } else if (selectedRooms.length < selectedRoomNum) {
      toast.error(t('Number of selected rooms is smaller than required num. of joint rooms'));
      return false;
    } else if (selectedClasses.length !== 0 && selectedClassNum === 0) {
      toast.error(t('Required num of classes is 0 but you provided class(es) to be assigned'));
      return false;
    } else if (selectedTeachers.length !== 0 && selectedTeacherNum === 0) {
      toast.error(t('Required num of teachers is 0 but you provided teacher(s) to be assigned'));
      return false;
    } else if (selectedRooms.length !== 0 && selectedRoomNum === 0) {
      toast.error(t('Required num of rooms is 0 but you provided room(s) to be assigned'));
      return false;
    } else {
      return true;
    }
  }

  const [runLessonCreation, setRunLessonCreation] = useState(false);
  const lessonCreationStorageFlagKey = 'onboarding-lesson-creation';
  useEffect(() => {
    if (localStorage.getItem(lessonCreationStorageFlagKey) !== 'true') {
      setRunLessonCreation(true);
    }
  }, []);

  const steps = [
    {
      target: '.lesson-definition',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('Lesson definition')}
          message={
            file?.roomsSetting !== 0
              ? t('A lesson is a subject taught by a teacher to a class in a room a number of times per week')
              : t('A lesson is a subject taught by a teacher to a class a number of times per week')
          }
        />
      ),
    },
    {
      target: '.split-options',
      content: (
        <OnboardingContent
          title={t('Split options')}
          message={t(
            'You can set how you want the meets of the lesson to be split. For example, you may prefer double-time lessons or single-time lessons'
          )}
        />
      ),
    },
    {
      target: '.lesson-class',
      content: (
        <OnboardingContent title={t('Class')} message={t('Here you should set the class that attends this lesson')} />
      ),
    },
    {
      target: '.lesson-teacher',
      content: (
        <OnboardingContent
          title={t('Teachers')}
          message={t(
            'Here you set the teacher that lectures this lesson. You can set more than one teacher option and let Optables choose the one that best fits the specified constraints'
          )}
        />
      ),
    },
    {
      target: '.lesson-room',
      content: <OnboardingContent title={t('Rooms')} message={t('Same goes to the room options to host the lesson')} />,
    },
    {
      target: '.joint-classes',
      content: (
        <OnboardingContent
          title={t('Joint classes')}
          message={t(
            'You can set two or more classes to attend this lesson jointly. Just set the number of classes to attend it jointly and select which classes should do it'
          )}
        />
      ),
    },
    {
      target: '.joint-teachers',
      content: (
        <OnboardingContent
          title={t('Joint teachers')}
          message={t('You can also set two or more teachers to lecture this lesson together')}
        />
      ),
    },
    {
      target: '.predefined-times',
      content: (
        <OnboardingContent
          title={t('Predefined times')}
          message={t('You can enforce some of the meets of the lesson to occur at a set of predefined time slots')}
        />
      ),
    },
    {
      target: '.relations',
      content: (
        <OnboardingContent
          title={t('Relations')}
          message={t(
            'Last, you can set a lesson to occur simultaneously with other lesson(s), non-simultaneously with other lesson(s), and before other lesson(s)'
          )}
        />
      ),
    },
  ];

  if (file?.roomsSetting === 0) steps.splice(4, 1);

  const handleNavToUpgradePlan = () => {
    setShowPlanLimitDialog(false);
    history.push('/settings');
  };

  const [videoPlayerDialogOpen, setVideoPlayerDialogOpen] = useState(false);

  return (
    <Container
      maxWidth={false}
      style={{
        padding: '56px 8px 8px 65px',
        minHeight: '100vh',
        backgroundColor: colors.grey[50],
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <DraggableDialog
        open={jointClassesConfirmationOpen}
        setOpen={setJointClassesConfirmationOpen}
        setConfirmed={setJointClassesConfirmed}
        title={t('Warning')}
        message={`${t('Classes')} ${selectedClasses.toString()} ${t('will have lessons of')} ${selectedSubject[0]} ${t(
          'with the same teacher and room AT THE SAME TIME. Are you sure?'
        )}`}
      />
      <DraggableDialog
        open={deleteConfirmationOpen}
        setOpen={setDeleteConfirmationOpen}
        setConfirmed={setDeleteConfirmed}
        title={t('Warning')}
        message={lesson.name + t(' will be permanently deleted from this file. Are you sure?')}
      />
      {videoPlayerDialogOpen && (
        <VideoPlayerDialog
          open={videoPlayerDialogOpen}
          setOpen={setVideoPlayerDialogOpen}
          videoURL={t('video_url_help_lesson')}
        ></VideoPlayerDialog>
      )}
      {showPlanLimitDialog && (
        <PlanInfoDialog
          open={showPlanLimitDialog}
          positiveLabel={t('Upgrade plan')}
          negativeLabel={t('Back')}
          positiveAction={handleNavToUpgradePlan}
          negativeAction={() => {
            setShowPlanLimitDialog(false);
          }}
          title={t('Plan limit reached')}
          message={t(
            'You have reached the limit of lessons for this file in your plan! Please consider upgrading your plan to create more files.'
          )}
        />
      )}
      {toAllConfirmationOpen && (
        <DraggableDialog
          open={toAllConfirmationOpen}
          setOpen={setToAllConfirmationOpen}
          setConfirmed={setToAllConfirmed}
          title={t('Warning')}
          message={`${t('Constraints regarding')} \n${
            selectedGapLessons ? `(${t('Days between Lessons')}) \n` : ''
          } ${t('will be applied to all lessons. Are you sure?')}`}
        />
      )}
      {copyLessonOpen && (
        <ValueSelectConfirmDialog
          open={copyLessonOpen}
          setOpen={setCopyLessonOpen}
          setConfirmed={setCopyLessonConfirmed}
          value={selectedCopyClass}
          setValue={setSelectedCopyClass}
          valueOptions={copyClassOptions}
          title={t('Copy Lesson')}
          message={`${t('Select the')} ${file?.studentsSetting !== 0 ? t('class') : t('student')} ${t(
            'to which the copied lesson will be taught:'
          )}`}
        />
      )}
      {valueClassesSelectOpen && (
        <ValueSelectDialog
          open={valueClassesSelectOpen}
          setOpen={setValueClassesSelectOpen}
          // setConfirmed={setValueClassesSelectConfirmed}
          value={selectedClassNum}
          setValue={setSelectedClassNum}
          valueOptions={Array.from({ length: classes.length + 1 }, (x, i) => i)}
          title={`${file?.studentsSetting !== 0 ? t('Joint Classes') : t('Joint Students')}`}
          message={`${t('Select the number of')} ${file?.studentsSetting !== 0 ? t('classes') : t('students')} ${t(
            'that attend this lesson JOINTLY and AT THE SAME TIME:'
          )}`}
        />
      )}
      {valueTeachersSelectOpen && (
        <ValueSelectDialog
          open={valueTeachersSelectOpen}
          setOpen={setValueTeachersSelectOpen}
          // setConfirmed={setValueTeachersSelectConfirmed}
          value={selectedTeacherNum}
          setValue={setSelectedTeacherNum}
          valueOptions={Array.from({ length: teachers.length + 1 }, (x, i) => i)}
          title={t('Joint Teachers')}
          message={t('Select the number of teachers that teach this lesson JOINTLY and AT THE SAME TIME:')}
        />
      )}
      {valueRoomsSelectOpen && (
        <ValueSelectDialog
          open={valueRoomsSelectOpen}
          setOpen={setValueRoomsSelectOpen}
          // setConfirmed={setValueTeachersSelectConfirmed}
          value={selectedRoomNum}
          setValue={setSelectedRoomNum}
          valueOptions={Array.from({ length: rooms.length + 1 }, (x, i) => i)}
          title={t('Joint Rooms')}
          message={t('Select the number of rooms that host this lesson JOINTLY and AT THE SAME TIME:')}
        />
      )}
      <DraggableAvatarPicker
        open={avatarPickerOpen}
        setOpen={setAvatarPickerOpen}
        selectedColor={selectedColor}
        selectedPhotoURL={selectedPhotoURL}
        setSelectedColor={setSelectedColor}
        setSelectedPhotoURL={setSelectedPhotoURL}
        resource={lesson}
      />
      <MiniDrawer />
      <form onSubmit={formik.handleSubmit}>
        <Grid container justifyContent="center" paddingBottom={8}>
          <Paper elevation={3} sx={{ width: '100%', maxWidth: 'lg' }}>
            <Grid container item xs={12} justifyContent={'space-between'} padding={2} spacing={1} flexDirection={'row'}>
              <Grid item container xs={12} spacing={1} padding={1}>
                <Grid item flexGrow={1}>
                  <Typography variant="body1" gutterBottom sx={{ color: '#1976D2' }}>
                    <Tooltip title={t('Back')}>
                      <IconButton
                        style={{ height: 24, width: 24, marginRight: 4 }}
                        color="primary"
                        onClick={() => history.goBack()}
                      >
                        <ArrowBack style={{ height: 20, width: 20 }} />
                      </IconButton>
                    </Tooltip>
                    {updateLesson ? t('Update ') + t('lesson') : t('New lesson')}
                  </Typography>
                  <Divider color="#1976D2" />
                </Grid>
                <Grid item alignSelf={'center'}>
                  <Tooltip title={t('Watch a helpful video')}>
                    <IconButton
                      style={{ height: 24, width: 24 }}
                      color="primary"
                      onClick={() => setVideoPlayerDialogOpen(true)}
                    >
                      <Help style={{ height: 24, width: 24 }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                {updateLesson ? (
                  <>
                    <Grid item xs={3} md={2}>
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={() => setDeleteConfirmationOpen(true)}
                        color="error"
                        fullWidth
                      >
                        {t('Delete')}
                      </Button>
                    </Grid>
                    <Grid item xs={3} md={2}>
                      <Button
                        startIcon={<FileCopyIcon />}
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={() => copy()}
                        color="primary"
                        fullWidth
                      >
                        {t('Copy')}
                      </Button>
                    </Grid>
                    <Grid item xs={3} md={2}>
                      <Button
                        className={'update-button'}
                        startIcon={<Save />}
                        variant="outlined"
                        size="small"
                        disabled={readOnly}
                        onClick={() => {
                          if (!validateInput()) return;
                          if (selectedGapLessons) setToAllConfirmationOpen(true);
                          else if (!jointClassesConfirmed && selectedClasses.length > 1)
                            setJointClassesConfirmationOpen(true);
                          else submitForm();
                        }}
                        color="primary"
                        fullWidth
                      >
                        {t('Update')}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={3} md={2}>
                    <Button
                      className={'save-button'}
                      startIcon={<Save />}
                      variant="outlined"
                      size="small"
                      disabled={readOnly}
                      onClick={() => {
                        if (!validateInput()) return;
                        if (selectedGapLessons) setToAllConfirmationOpen(true);
                        else if (!jointClassesConfirmed && selectedClasses.length > 1)
                          setJointClassesConfirmationOpen(true);
                        else submitForm();
                      }}
                      color="primary"
                      fullWidth
                    >
                      {t('Save')}
                    </Button>
                  </Grid>
                )}
              </Grid>
              {/* Subject */}
              <Grid item xs={12}>
                <FormControl fullWidth size="small">
                  <MultipleSelectChip
                    single={true}
                    label={t('Subject')}
                    names={subjects_}
                    personName={selectedSubject}
                    chipColor={subjects.find((sub) => sub.name === selectedSubject[0])?.color}
                    setPersonName={setSelectedSubject}
                  ></MultipleSelectChip>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Distribution of lessons')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Lessons per Week and Split */}
                    <Grid item container xs={12} spacing={1} padding={1} flexDirection={'row'}>
                      <Grid item xs={6}>
                        <Tooltip placement="top-end" title={t('Sets the required number of times for this lesson')}>
                          <FormControl fullWidth size="small">
                            <InputLabel id="days-select-label">{t('Times per Week')}</InputLabel>
                            <Select
                              size="small"
                              labelId="lessons-week-select-label"
                              id="lessons-week-select"
                              defaultValue={'' + selectedLessonsWeek}
                              onChange={handleChangeLessonsWeek}
                              label={t('Times per Week')}
                            >
                              {Array.from({ length: days.length * times.length + 1 }).map((_, index) => {
                                return (
                                  <MenuItem key={`lessonsWeek_${index}`} value={`${index}`}>
                                    {index === 0 ? `↓` : index}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Tooltip>
                      </Grid>
                      <Tooltip
                        placement="right-end"
                        title={
                          <div>
                            {t('Sets the way sub-lessons of this lesson must be divided into shorter lessons')}
                            <hr />
                            {t(
                              'For example, 1-1-2 means two single-time lessons and one double-time lesson (you can set several suitable options):'
                            )}
                            <p />
                            <img
                              src={'/img/examples/splitLesson.png'}
                              width={'100%'}
                              alt={t('Example of Split option')}
                            />
                          </div>
                        }
                      >
                        <Grid item xs={6}>
                          <MultipleSelectChip
                            single={false}
                            label={t('Split Options')}
                            names={splitOptions}
                            personName={selectedSplit}
                            setPersonName={setSelectedSplit}
                          ></MultipleSelectChip>
                        </Grid>
                      </Tooltip>
                      {/* Gap between Lessons */}
                      <Grid item xs={12} marginTop={4}>
                        <MinMaxConstraintSlider
                          icon={<FormatLineSpacingIcon sx={{ transform: 'rotate(90deg)', marginRight: 1 }} />}
                          title={t('Days between Lessons')}
                          tooltip={
                            <div>
                              {t('Limits the number of days between classes of this lesson')}
                              <hr />
                              {`${t(
                                'For example, if Min is set to 2, there will be at least 2 days between classes of this lesson, hence if a class occurs on Tuesday, another class of this lesson can only occur on Thursday or Friday:'
                              )}`}
                              <p />
                              <img
                                src={'/img/examples/daysBetweenLessons.png'}
                                width={'100%'}
                                alt={t('Example of Days between Lessons constraint')}
                              />
                            </div>
                          }
                          selectedMin={selectedMinGapLessons}
                          setSelectedMin={setSelectedMinGapLessons}
                          selectedMax={selectedMaxGapLessons}
                          setSelectedMax={setSelectedMaxGapLessons}
                          possibleMin={0}
                          possibleMax={days.length - 1}
                          applyToAllSelected={selectedGapLessons}
                          setApplyToAllSelected={setSelectedGapLessons}
                          tooltipApplyToAll={t('Apply to all lessons')}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              <Grid item xs={12}>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Resources')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item container xs={12} spacing={1} padding={1}>
                      <Grid item xs={false} sm={1} display={{ xs: 'none', sm: 'block' }}>
                        <Tooltip
                          title={`${
                            file?.studentsSetting !== 0
                              ? t('The class(es) that attend this lesson together')
                              : t('The student(s) that attend this lesson together')
                          }`}
                        >
                          <ToggleButton
                            value="check"
                            fullWidth
                            size="small"
                            color="primary"
                            style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                            selected={selectedClasses.length === 0 ? false : true}
                          >
                            <SchoolIcon />
                          </ToggleButton>
                        </Tooltip>
                      </Grid>
                      <Grid className={'lesson-class'} item xs={10} sm={8}>
                        <FormControl fullWidth size="small">
                          <MultipleSelectChip
                            single={selectedClassNum === 1 && file?.studentsSetting !== 0 ? true : false}
                            label={file?.studentsSetting !== 0 ? t('Class') : t('Students')}
                            names={classes}
                            // chipColor={resources.find((res) => res.name === selectedClasses[0])?.color}
                            personName={selectedClasses}
                            setPersonName={setSelectedClasses}
                          ></MultipleSelectChip>
                        </FormControl>
                      </Grid>
                      <Grid className={'joint-classes'} item xs={2} sm={3}>
                        <Tooltip
                          placement="right"
                          title={`${
                            file?.studentsSetting !== 0
                              ? t('Number of classes that attend this lesson together')
                              : t('Number of students that attend this lesson together')
                          }`}
                        >
                          <ToggleButton
                            value="check"
                            fullWidth
                            size="small"
                            color="primary"
                            onClick={() => setValueClassesSelectOpen(true)}
                            selected={selectedClassNum !== 0}
                            sx={{ textTransform: 'none' }}
                          >
                            <SchoolIcon />
                            {`×${selectedClassNum}`}
                          </ToggleButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1} padding={1}>
                      {/* Teachers */}
                      <Grid item xs={false} sm={1} display={{ xs: 'none', sm: 'block' }}>
                        <Tooltip title={t('The teacher(s) that can be assigned to teach this lesson')}>
                          <ToggleButton
                            value="check"
                            fullWidth
                            size="small"
                            color="primary"
                            style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                            selected={selectedTeachers.length === 0 ? false : true}
                          >
                            <CoPresent />
                          </ToggleButton>
                        </Tooltip>
                      </Grid>
                      <Grid className={'lesson-teacher'} item xs={10} sm={8}>
                        <FormControl fullWidth size="small">
                          <MultipleSelectChip
                            single={selectedTeacherNum === 1 && file?.teachersSetting === 2 ? true : false}
                            label={`${file?.teachersSetting === 2 ? t('Teacher') : t('Teacher Options')}`}
                            names={teachers}
                            // chipColor={resources.find((res) => res.name === selectedTeachers[0])?.color}
                            personName={selectedTeachers}
                            setPersonName={setSelectedTeachers}
                          ></MultipleSelectChip>
                        </FormControl>
                      </Grid>
                      <Grid className={'joint-teachers'} item xs={2} sm={3}>
                        <Tooltip placement="right" title={t('Number of teachers that teach this lesson together')}>
                          <ToggleButton
                            value="check"
                            fullWidth
                            size="small"
                            color="primary"
                            selected={selectedTeacherNum !== 0}
                            onClick={() => setValueTeachersSelectOpen(true)}
                            sx={{ textTransform: 'none' }}
                          >
                            <CoPresent />
                            {`×${selectedTeacherNum}`}
                          </ToggleButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={1} padding={1}>
                      {/* Rooms */}
                      {file?.roomsSetting !== 0 && (
                        <Grid item xs={false} sm={1} display={{ xs: 'none', sm: 'block' }}>
                          <Tooltip title={t('The room(s) that can be assigned to host this lesson')}>
                            <ToggleButton
                              value="check"
                              fullWidth
                              size="small"
                              color="primary"
                              style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                              selected={selectedRooms.length === 0 ? false : true}
                            >
                              <MapsHomeWorkIcon />
                            </ToggleButton>
                          </Tooltip>
                        </Grid>
                      )}
                      {file?.roomsSetting !== 0 && (
                        <Grid className={'lesson-room'} item xs={10} sm={8}>
                          <FormControl fullWidth size="small">
                            <MultipleSelectChip
                              label={t('Room Options')}
                              names={rooms}
                              personName={selectedRooms}
                              setPersonName={setSelectedRooms}
                            ></MultipleSelectChip>
                          </FormControl>
                        </Grid>
                      )}
                      {file?.roomsSetting !== 0 && (
                        <Grid item xs={2} sm={3}>
                          <Tooltip placement="right" title={t('Number of rooms that host this lesson jointly')}>
                            <ToggleButton
                              value="check"
                              fullWidth
                              size="small"
                              color="primary"
                              selected={selectedRoomNum !== 0}
                              onClick={() => setValueRoomsSelectOpen(true)}
                              sx={{ textTransform: 'none' }}
                            >
                              <MapsHomeWorkIcon />
                              {` ×${selectedRoomNum}`}
                            </ToggleButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
                {/* <FormTitle title={t('Resources')} icon={<CastForEducationIcon />} /> */}
              </Grid>
              <Grid item xs={12}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <DialogContentText>{t('Advanced')}</DialogContentText>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
                      <Box sx={{ width: '100%' }}>
                        <Tabs value={selectedTab} onChange={handleChange} variant="scrollable" allowScrollButtonsMobile>
                          <Tab label={t('Predefined Times')} />
                          <Tab className={'relations'} label={t('Relations')} />
                        </Tabs>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <TabPanel value={selectedTab} index={0}>
                        <TableContainer className={'predefined-times'} component={Paper}>
                          <Table size="small" padding="none">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center" style={{ minWidth: '90px' }}>
                                  <ToggleButton
                                    value="check"
                                    id={`check_${0}_${0}`}
                                    fullWidth={true}
                                    selected={allCount > 0 && contSelected === allCount}
                                    size="small"
                                    color="primary"
                                    onClick={(event) => handleSelectAllClick(event, '**')}
                                  >
                                    {t('Time')}
                                  </ToggleButton>
                                </TableCell>
                                {days.map((day, d_index) => {
                                  return (
                                    <TableCell key={`day_${d_index}`} align="center" style={{ minWidth: '90px' }}>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${d_index}:0`}
                                        fullWidth={true}
                                        selected={rowCount > 0 && contSelectedAtDay[d_index] === rowCount}
                                        size="small"
                                        color="primary"
                                        onClick={(event) => handleSelectAllDayClick(event, d_index + ':*')}
                                      >
                                        {days[d_index]}
                                      </ToggleButton>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {times.map((time, t_index) => {
                                return (
                                  <TableRow
                                    key={`time_${t_index}`}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >
                                    <TableCell>
                                      <ToggleButton
                                        value="check"
                                        translate="no"
                                        id={`check_${0}:${t_index}`}
                                        fullWidth={true}
                                        selected={colCount > 0 && contSelectedAtTime[t_index] === colCount}
                                        size="small"
                                        color="primary"
                                        onClick={(event) => handleSelectAllTimeClick(event, '*:' + t_index)}
                                      >
                                        {times[t_index]}
                                      </ToggleButton>
                                    </TableCell>

                                    {days.map((day, d_index) => {
                                      const isItemSelected = isSelected('' + d_index + ':' + t_index);
                                      return (
                                        <TableCell key={`cell_${d_index}_${t_index}`} align="center">
                                          <ToggleButton
                                            value="check"
                                            id={`check_${d_index}_${t_index}`}
                                            fullWidth
                                            size="small"
                                            color="primary"
                                            onClick={(event) => handleClick(event, '' + d_index + ':' + t_index)}
                                            aria-checked={isItemSelected}
                                            selected={isItemSelected}
                                          >
                                            {isItemSelected ? <LockIcon /> : <LockOpenIcon />}
                                          </ToggleButton>
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </TabPanel>
                      <TabPanel value={selectedTab} index={1}>
                        {/* Relations */}
                        {/* Simultaneous With */}
                        <Grid container spacing={1}>
                          <Grid container item spacing={1}>
                            <Grid item xs={1} sm={1}>
                              <Tooltip
                                title={
                                  <div>
                                    {t('Enforces this lesson to occur at the same times of selected lessons')}
                                    <hr />
                                    {t(
                                      'This option is well suited to schedule optative subjects. For example, you can enforce lessons of Spanish and French to occur at the same time'
                                    )}
                                    {/* <p />
                          <img
                            src={'/img/examples/workingTimes.png'}
                            width={'100%'}
                            alt={t('Example of Working Times constraint')}
                          /> */}
                                  </div>
                                }
                              >
                                <ToggleButton
                                  value="check"
                                  fullWidth
                                  size="small"
                                  color="primary"
                                  style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                                  selected={selectedSimultaneousWith.length === 0 ? false : true}
                                >
                                  <LinkIcon />
                                </ToggleButton>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={11} sm={11}>
                              <FormControl fullWidth size="small">
                                <MultipleSelectChip
                                  label={t('Simultaneous with')}
                                  names={lessons_}
                                  personName={selectedSimultaneousWith}
                                  setPersonName={setSelectedSimultaneousWith}
                                ></MultipleSelectChip>
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* Not Simultaneous With */}
                          <Grid container item spacing={1}>
                            <Grid item xs={1} sm={1}>
                              <Tooltip
                                title={
                                  <div>
                                    {t('Enforces this lesson to occur at different times of selected lessons')}
                                    <hr />
                                    {t(
                                      'This option is well suited to couple of teachers who have children. For example, you can enforce lessons of John to not overlap with lessons of Mary'
                                    )}
                                  </div>
                                }
                              >
                                <ToggleButton
                                  value="check"
                                  fullWidth
                                  size="small"
                                  color="primary"
                                  style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                                  selected={selectedNotSimultaneousWith.length === 0 ? false : true}
                                >
                                  <LinkOffIcon />
                                </ToggleButton>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={11} sm={11}>
                              <FormControl fullWidth size="small">
                                <MultipleSelectChip
                                  label={t('Not Simultaneous with')}
                                  names={lessons_}
                                  personName={selectedNotSimultaneousWith}
                                  setPersonName={setSelectedNotSimultaneousWith}
                                ></MultipleSelectChip>
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* Occur Before */}
                          <Grid container item spacing={1}>
                            <Grid item xs={1} sm={1}>
                              <Tooltip
                                title={
                                  <div>
                                    {t('Enforces this lesson to occurr before the selected lessons')}
                                    <hr />
                                    {t(
                                      'This option is well suited to schedule theorical lessons before practice. You can set the Min of days separating them. For example, you can enforce theorical lessons of Chemestry to occur at least 2 days before the practical lessons'
                                    )}
                                  </div>
                                }
                              >
                                <ToggleButton
                                  value="check"
                                  fullWidth
                                  size="small"
                                  color="primary"
                                  style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
                                  selected={selectedOccurBefore.length === 0 ? false : true}
                                >
                                  <BrowseGalleryIcon />
                                </ToggleButton>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={9} sm={9}>
                              <FormControl fullWidth size="small">
                                <MultipleSelectChip
                                  label={t('Occur before')}
                                  names={lessons_}
                                  personName={selectedOccurBefore}
                                  setPersonName={setSelectedOccurBefore}
                                ></MultipleSelectChip>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                              <FormControl fullWidth size="small">
                                <InputLabel id="days-select-label">{t('Min Days Gap')}</InputLabel>
                                <Select
                                  size="small"
                                  labelId="days-gap-label"
                                  id="days-gap-select"
                                  defaultValue={'' + selectedOccurBeforeMinDays}
                                  onChange={handleChangeOccurBeforeMinDays}
                                  label={t('Min Days Gap')}
                                  sx={{
                                    color: +selectedOccurBeforeMinDays !== 0 ? 'default' : '#fff',
                                  }}
                                >
                                  {Array.from({ length: days.length }).map((_, index) => {
                                    return (
                                      <MenuItem key={`lessonsWeek_${index}`} value={`${index}`}>
                                        {index === 0 ? `↓` : index}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>
      {/* <Onboarding run={runLessonCreation} steps={steps} storageFlagKey={lessonCreationStorageFlagKey} /> */}
    </Container>
  );
}
