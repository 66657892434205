import { Grid, Paper, Radio, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { theme } from 'styles';

export default function ExportOptionCard(props: any) {
  const { t } = useTranslation();
  const title = props.title;
  const image = props.image;
  const selected = props.selected;
  const setSelected = props.setSelected;
  const disabled = props.disabled;

  return (
    <Paper
      elevation={3}
      sx={{
        display: 'inline-block',
        padding: 1,
        paddingBottom: 4,
        border: `${selected ? '4px' : '0px'} solid ${theme.palette.primary.main}`,
      }}
      onClick={() => !disabled && setSelected(title)}
    >
      <Grid container direction={'column'}>
        <Grid container item direction={'row'} justifyContent={'space-between'}>
          <Grid item>
            <Typography>{title}</Typography>
          </Grid>
          <Grid item>
            <Radio
              checked={selected}
              onChange={() => setSelected(title)}
              value={title}
              name={title}
              disabled={disabled}
              inputProps={{ 'aria-label': title }}
            />
          </Grid>
        </Grid>
        <img src={image} width={'120px'} height={'120px'} alt={t('Export format ') + title} />
      </Grid>
    </Paper>
  );
}
