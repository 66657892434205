import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { doc, writeBatch } from '@firebase/firestore';
import { Grid } from '@material-ui/core';
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
} from '@mui/material/colors';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import ControlledSwitch from '../../../components/ControlledSwitch';
import ImportDataGrid from '../../../components/ImportDataGrid';
import BasicSelect from '../../../components/BasicSelect';
import { SessionContext } from 'contexts';
import ImportConfirmationDialog from '../../../components/ImportConfirmationDialog';
import SimpleBackdrop from 'components/SimpleBackdrop';
import { db } from 'services';
import OnboardingContent from 'components/OnboardingContent';
import { useContainerDimensions } from 'hooks/useContainerDimensions';
import Onboarding from 'components/Onboarding';
import InfoDialog from 'components/InfoDialog';
import { Info } from '@material-ui/icons';
import { ISolution } from '../Solution';
import ImportAssignmentsAccordion from './ImportAssignmentsAccordion';
import { ILesson } from 'pages/Lessons/Lesson';
import { ContentPaste } from '@mui/icons-material';

export interface IAssignmentImport {
  lessonRef: string;
  subject: string[];
  classes: string[];
  teachers: string[];
  rooms: string[];
  timeslots: string[];
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export interface IImportWarning {
  line: number;
  column: string;
  description: string;
  type: 'error' | 'warning';
}

export default function ImportAssignmentsDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const solution: ISolution = props.solution;
  const { user, file, resources, subjects, solutions, lessons, ownerEmail } = React.useContext(SessionContext);
  const resourcesPath = 'users/' + ownerEmail + '/files/' + file?.name + '/resources';
  const subjectsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/subjects';
  const lessonsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/lessons';
  const solutionsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/solutions';
  const [ignoreFirstLine, setIgnoreFirstLine] = React.useState<boolean>(true);

  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];
  const priorityOptions = ['Very low', 'Low', 'Average', 'High', 'Very high'];

  const handleClose = () => {
    setOpen(false);
  };

  let warnings: IImportWarning[] = [];

  function validateSubjectNameInput(name: string, lineNum: number, column: string): boolean {
    // const subjectNames: string[] = subjects.map((sub) => sub.name);
    if (name.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty name for')} ${column} ${t('will be ignored')}`,
        type: 'warning',
      });
      return false;
    } else if (name.length > 50) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name having more than 50 chars for')} ${column} ${t('will be ignored')}`,
        type: 'warning',
      });
      return false;
    } else if (
      name.includes('/') ||
      name.includes('\\') ||
      name.includes('|') ||
      name.includes(';') ||
      name.includes('#') ||
      name.includes('=') ||
      name.includes(':') ||
      name.includes('_') ||
      name.includes('{') ||
      name.includes('}') ||
      name.includes('&')
    ) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name cannot contain: / \\ | ; # = : _ { } & for')} ${column}`,
        type: 'warning',
      });
      return false;
    } else return true;
  }

  function validateResourceNameInput(name: string, lineNum: number, column: string, type: string): boolean {
    //const resourceNames: string[] = resources.filter((resource) => resource.type === type).map((res) => res.name);
    if (name.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty name for')} ${column} ${t('will be ignored')}`,
        type: 'warning',
      });
      return false;
    } else if (name.length >= 40) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name having more than 40 chars for')} ${column} ${t('will be inogred')}`,
        type: 'warning',
      });
      return false;
    } else if (
      name.includes('/') ||
      name.includes('\\') ||
      name.includes('|') ||
      name.includes(';') ||
      name.includes('#') ||
      name.includes('=') ||
      name.includes(':') ||
      name.includes('_') ||
      name.includes('{') ||
      name.includes('}') ||
      name.includes('&')
    ) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name containing: / \\ | ; # = : _ { } & for')} ${column} ${t('will be ignored')}`,
        type: 'warning',
      });
      return false;
    } else return true;
  }

  function handleSubject(name: string, lineNum: number, column: string, subject: string[]) {
    if (validateSubjectNameInput(name.trim(), lineNum, column)) {
      subject.push(name.trim());
    }
  }

  function handleResource(name: string, lineNum: number, column: string, type: string, resources: string[]) {
    const resourceNames = name.split('+');
    resourceNames.forEach((resourceName) => {
      if (validateResourceNameInput(resourceName.trim(), lineNum, column, type)) {
        resources.push(resourceName.trim());
      }
    });
  }

  function handleArrayOfTimes(inputText: string, lineNum: number, column: string, arrayOfTimes: string[]) {
    if (inputText.trim().length === 0) {
      // Empty cells are allowed for this field
      return;
    }
    const inputSplit = inputText.split('+').map((text) => {
      return text.trim();
    });

    inputSplit.forEach((input) => {
      let inputData = input.split(':');
      if (inputData.length < 2) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${column} ${inputData} ${t('is not in the required format (day:time) and will be ignored')}`,
          type: 'warning',
        });
      } else {
        const allDays = inputData[0].trim() === '*';
        const allTimes = inputData[1].trim() === '*';
        const day = Number(inputData[0].trim());
        const time = Number(inputData[1].trim());
        const dayIsValid = Array.from({ length: days.length + 1 }, (x, i) => i).includes(day) || day < 1 || allDays;
        const timeIsValid =
          Array.from({ length: times.length + 1 }, (x, i) => i).includes(time) || time < 1 || allTimes;
        if (!dayIsValid) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${t('Day')} ${day} ${t('in')} ${column} ${input} ${t('is not in the allowed range')} (1, ${
              days.length
            }) ${t('and will be ignored')}`,
            type: 'warning',
          });
        } else if (!timeIsValid) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${t('Time')} ${time} ${t('in')} ${column} ${input} ${t('is not in the allowed range')} (1, ${
              times.length
            }) ${t('and will be ignored')}`,
            type: 'warning',
          });
        } else {
          if (allDays && allTimes) {
            days.forEach((day, indexDay) => {
              times.forEach((time, indexTime) => {
                !arrayOfTimes.includes(indexDay + ':' + indexTime) && arrayOfTimes.push(indexDay + ':' + indexTime);
              });
            });
          } else if (allDays) {
            days.forEach((day, index) => {
              !arrayOfTimes.includes(index + ':' + (time - 1)) && arrayOfTimes.push(index + ':' + (time - 1));
            });
          } else if (allTimes) {
            times.forEach((time, index) => {
              !arrayOfTimes.includes(day - 1 + ':' + index) && arrayOfTimes.push(day - 1 + ':' + index);
            });
          } else {
            !arrayOfTimes.includes(day - 1 + ':' + (time - 1)) && arrayOfTimes.push(day - 1 + ':' + (time - 1));
          }
        }
      }
    });
  }

  const getInitials = (input: string, maxLength: number) => {
    if (input.length <= maxLength) {
      return input.toUpperCase();
    }
    var names = input.trim().split(' ');
    if (names.length === 1) {
      return names[0].substring(0, maxLength).toUpperCase();
    }
    var initials: string = '';
    var midChars: number = 0;
    for (let i = 0; i < names.length; i++) {
      if (i === 0 || i === names.length - 1) initials += names[i].charAt(0).toUpperCase();
      else if (names[i].charAt(0) === names[i].charAt(0).toUpperCase() && midChars + 2 < maxLength) {
        initials += names[i].substring(0, 1).toUpperCase();
        midChars++;
      }
    }
    return initials;
  };

  const colors: any[] = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    // yellow,
    amber,
    orange,
    deepOrange,
    brown,
    // grey,
    // blueGrey,
  ];
  const intensities: number[] = [400, 500, 600, 700, 800, 900];

  function getRandomColor() {
    return colors[Math.floor(Math.random() * colors.length)][
      intensities[Math.floor(Math.random() * intensities.length)]
    ];
  }

  function getDefaultSplit(lessonsWeek: number[]) {
    let lessons = 1;
    if (lessonsWeek.length > 0) {
      lessons = lessonsWeek[0];
    }
    let defaultSplit = '';
    Array.from({ length: lessons }).forEach((num) => {
      defaultSplit += '1-';
    });
    defaultSplit = defaultSplit.slice(0, -1);
    return [defaultSplit];
  }

  let assignmentsToImport: any[] = [];

  const handleProceed = () => {
    // Check for repeated columns
    const columns: string[] = [column1, column2, column3, column4, column5, column6, column7, column8];
    let ignoredColCount = 0;
    for (let i = 0; i < columns.length; ++i) {
      for (let j = i + 1; j < columns.length; ++j) {
        if (columns[i] === columns[j] && columns[i] !== t('Ignore column')) {
          toast.error(`${t('Cannot have more than one column with value')} ${columns[i]}`);
          return;
        }
      }
      columns[i] === t('Ignore column') && ignoredColCount++;
    }
    if (ignoredColCount === columns.length) {
      toast.error(t("At least one column should be marked as different than 'Ignore column'"));
      return;
    }
    assignmentsToImport = [];
    navigator.clipboard
      .readText()
      .then((text) => {
        text.split('\n').forEach((line, index) => {
          //Ignoring (or not) the first line
          if (index === 0 && ignoreFirstLine) return;
          //Ignoring empty lines
          if (line === '') return;
          // Getting cell values
          const cells = line.split('\t');
          const columnVal1 = cells[0];
          const columnVal2 = cells.length > 1 ? cells[1] : '';
          const columnVal3 = cells.length > 2 ? cells[2] : '';
          const columnVal4 = cells.length > 3 ? cells[3] : '';
          const columnVal5 = cells.length > 4 ? cells[4] : '';
          const columnVal6 = cells.length > 5 ? cells[5] : '';
          const columnVal7 = cells.length > 6 ? cells[6] : '';
          const columnVal8 = cells.length > 7 ? cells[7] : '';
          // Process new or existing subject
          let subject: string[] = [];
          column1 === t('Subject') && handleSubject(columnVal1, index, column1, subject);
          column2 === t('Subject') && handleSubject(columnVal2, index, column2, subject);
          column3 === t('Subject') && handleSubject(columnVal3, index, column3, subject);
          column4 === t('Subject') && handleSubject(columnVal4, index, column4, subject);
          column5 === t('Subject') && handleSubject(columnVal5, index, column5, subject);
          column6 === t('Subject') && handleSubject(columnVal6, index, column6, subject);
          column7 === t('Subject') && handleSubject(columnVal7, index, column7, subject);
          column8 === t('Subject') && handleSubject(columnVal8, index, column8, subject);
          // Process new or existing class
          let classes: string[] = [];
          column1 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal1, index, column1, 'class', classes);
          column2 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal2, index, column2, 'class', classes);
          column3 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal3, index, column3, 'class', classes);
          column4 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal4, index, column4, 'class', classes);
          column5 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal5, index, column5, 'class', classes);
          column6 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal6, index, column6, 'class', classes);
          column7 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal7, index, column7, 'class', classes);
          column8 === `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}` &&
            handleResource(columnVal8, index, column8, 'class', classes);
          // Process new or existing teacher
          let teachers: string[] = [];
          column2 === t('Teachers') && handleResource(columnVal2, index, column2, 'teacher', teachers);
          column1 === t('Teachers') && handleResource(columnVal1, index, column1, 'teacher', teachers);
          column3 === t('Teachers') && handleResource(columnVal3, index, column3, 'teacher', teachers);
          column4 === t('Teachers') && handleResource(columnVal4, index, column4, 'teacher', teachers);
          column5 === t('Teachers') && handleResource(columnVal5, index, column5, 'teacher', teachers);
          column6 === t('Teachers') && handleResource(columnVal6, index, column6, 'teacher', teachers);
          column7 === t('Teachers') && handleResource(columnVal7, index, column7, 'teacher', teachers);
          column8 === t('Teachers') && handleResource(columnVal8, index, column8, 'teacher', teachers);
          // Process new or existing room
          let rooms: string[] = [];
          column1 === t('Rooms') && handleResource(columnVal1, index, column1, 'room', rooms);
          column2 === t('Rooms') && handleResource(columnVal2, index, column2, 'room', rooms);
          column3 === t('Rooms') && handleResource(columnVal3, index, column3, 'room', rooms);
          column4 === t('Rooms') && handleResource(columnVal4, index, column4, 'room', rooms);
          column5 === t('Rooms') && handleResource(columnVal5, index, column5, 'room', rooms);
          column6 === t('Rooms') && handleResource(columnVal6, index, column6, 'room', rooms);
          column7 === t('Rooms') && handleResource(columnVal7, index, column7, 'room', rooms);
          column8 === t('Rooms') && handleResource(columnVal8, index, column8, 'room', rooms);
          // Process predefined times
          let timeslots: string[] = [];
          column1 === t('Timeslots') && handleArrayOfTimes(columnVal1, index, column1, timeslots);
          column2 === t('Timeslots') && handleArrayOfTimes(columnVal2, index, column2, timeslots);
          column3 === t('Timeslots') && handleArrayOfTimes(columnVal3, index, column3, timeslots);
          column4 === t('Timeslots') && handleArrayOfTimes(columnVal4, index, column4, timeslots);
          column5 === t('Timeslots') && handleArrayOfTimes(columnVal5, index, column5, timeslots);
          column6 === t('Timeslots') && handleArrayOfTimes(columnVal6, index, column6, timeslots);
          column7 === t('Timeslots') && handleArrayOfTimes(columnVal7, index, column7, timeslots);
          column8 === t('Timeslots') && handleArrayOfTimes(columnVal8, index, column8, timeslots);

          const assignmentToImport = {
            lessonRef: subject[0] + ' | ' + classes[0],
            subject: subject,
            classes: classes,
            teachers: teachers,
            rooms: rooms,
            timeslots: timeslots,
          };
          let addSolution = true;
          if (assignmentToImport.subject.length !== 1) {
            warnings.push({
              line: index + 1,
              column: t('Subject'),
              description: t('Assignment has an empty Subject and will be ignored'),
              type: 'error',
            });
            addSolution = false;
          }
          if (assignmentToImport.classes.length === 0) {
            warnings.push({
              line: index + 1,
              column: t('Class'),
              description: t('Assignment has no class'),
              type: 'warning',
            });
          }
          if (assignmentToImport.teachers.length === 0) {
            warnings.push({
              line: index + 1,
              column: t('Teachers'),
              description: t('Assignment has no teachers'),
              type: 'warning',
            });
          }
          // if (lessons.find((lesson) => lesson.name === assignmentToImport.name) !== undefined) {
          //   warnings.push({
          //     line: index + 1,
          //     column: 'Subject',
          //     description: `${t('Lesson')} ${assignmentToImport.name} ${t('already exists and will be ignored')}`,
          //     type: 'error',
          //   });
          //   addSolution = false;
          // }
          addSolution && assignmentsToImport.push(assignmentToImport);
        });
        // See warnings, erros and confirm
        localStorage.setItem('warnings', JSON.stringify(warnings));
        localStorage.setItem('assignmentsToImport', JSON.stringify(assignmentsToImport));
        if (warnings.length > 0) setConfirmationOpen(true);
        else {
          setImportConfirmed(true);
          setOpen(false);
        }
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
    // setOpen(false);
  };

  const importFields = [
    t('Ignore column'),
    t('Subject'),
    `${file?.studentsSetting !== 0 ? t('Class') : t('Students')}`,
    t('Teachers'),
    t('Rooms'),
    t('Timeslots'),
  ];
  const [column1, setColumn1] = React.useState<string>(importFields[0]);
  const [column2, setColumn2] = React.useState<string>(importFields[0]);
  const [column3, setColumn3] = React.useState<string>(importFields[0]);
  const [column4, setColumn4] = React.useState<string>(importFields[0]);
  const [column5, setColumn5] = React.useState<string>(importFields[0]);
  const [column6, setColumn6] = React.useState<string>(importFields[0]);
  const [column7, setColumn7] = React.useState<string>(importFields[0]);
  const [column8, setColumn8] = React.useState<string>(importFields[0]);

  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [importConfirmed, setImportConfirmed] = React.useState(false);

  const checkImportConfirmation = () => {
    if (importConfirmed) {
      setLoadingOpen(true);
      setImportConfirmed(false);
      const batch = writeBatch(db);
      const assignmentsToImport: IAssignmentImport[] = JSON.parse(
        localStorage.getItem('assignmentsToImport') as string
      );
      let importedAssigments = solution.assignments;
      assignmentsToImport.forEach((assignmentToImport) => {
        // Add subject if it does not exists
        if (subjects.find((subject) => subject.name === assignmentToImport.subject[0]) === undefined) {
          const subjectToImport = {
            color: getRandomColor(),
            photoURL: '',
            name: assignmentToImport.subject[0],
            short: getInitials(assignmentToImport.subject[0], 4),
            priority: priorityOptions[2],
            unavailableTimes: [],
            undesiredTimes: [],
            createdAt: new Date(),
            updatedAt: new Date(),
          };
          subjects.push(subjectToImport);
          batch.set(doc(db, subjectsPath, subjectToImport.name), subjectToImport);
        }
        // Add class if it does not exists
        assignmentToImport.classes.forEach((class_) => {
          if (resources.find((resource) => resource.name === class_) === undefined) {
            const resourceToImport = {
              color: getRandomColor(),
              photoURL: '',
              name: class_,
              short: getInitials(class_, 4),
              type: 'class',
              minWorkload: 0,
              maxWorkload: days.length * times.length,
              minWorkingDays: 0,
              maxWorkingDays: days.length,
              minIdleWindow: 0,
              maxIdleWindow: times.length - 2,
              minDailyWorkload: 0,
              maxDailyWorkload: times.length,
              minRestBetweenDays: 0,
              maxRestBetweenDays: times.length * 2 - 2,
              minRoomChangesDay: 0,
              maxRoomChangesDay: times.length - 1,
              minDistinctSubjects: 0,
              maxDistinctSubjects: days.length * times.length,
              minConsecutiveTimes: 0,
              maxConsecutiveTimes: times.length,
              travelTimeRooms: [],
              minTravelTime: 0,
              forbiddenCombination: [],
              unavailableTimes: [],
              undesiredTimes: [],
              priority: priorityOptions[2],
              createdAt: new Date(),
              updatedAt: new Date(),
            };
            resources.push(resourceToImport);
            batch.set(doc(db, resourcesPath, resourceToImport.name), resourceToImport);
          }
        });
        // Add teacher if it does not exists
        assignmentToImport.teachers.forEach((teacher) => {
          if (resources.find((resource) => resource.name === teacher) === undefined) {
            const resourceToImport = {
              color: getRandomColor(),
              photoURL: '',
              name: teacher,
              short: getInitials(teacher, 4),
              type: 'teacher',
              minWorkload: 0,
              maxWorkload: days.length * times.length,
              minWorkingDays: 0,
              maxWorkingDays: days.length,
              minIdleWindow: 0,
              maxIdleWindow: times.length - 2,
              minDailyWorkload: 0,
              maxDailyWorkload: times.length,
              minRestBetweenDays: 0,
              maxRestBetweenDays: times.length * 2 - 2,
              minRoomChangesDay: 0,
              maxRoomChangesDay: times.length - 1,
              minDistinctSubjects: 0,
              maxDistinctSubjects: days.length * times.length,
              minConsecutiveTimes: 0,
              maxConsecutiveTimes: times.length,
              travelTimeRooms: [],
              minTravelTime: 0,
              priority: priorityOptions[2],
              forbiddenCombination: [],
              unavailableTimes: [],
              undesiredTimes: [],
              createdAt: new Date(),
              updatedAt: new Date(),
            };
            resources.push(resourceToImport);
            batch.set(doc(db, resourcesPath, resourceToImport.name), resourceToImport);
          }
        });
        // Add room if it does not exists
        assignmentToImport.rooms.forEach((room) => {
          if (resources.find((resource) => resource.name === room) === undefined) {
            const resourceToImport = {
              color: getRandomColor(),
              photoURL: '',
              name: room,
              short: getInitials(room, 4),
              type: 'room',
              minWorkload: 0,
              maxWorkload: days.length * times.length,
              minWorkingDays: 0,
              maxWorkingDays: days.length,
              minIdleWindow: 0,
              maxIdleWindow: times.length - 2,
              minDailyWorkload: 0,
              maxDailyWorkload: times.length,
              minRestBetweenDays: 0,
              maxRestBetweenDays: times.length * 2 - 2,
              minRoomChangesDay: 0,
              maxRoomChangesDay: times.length - 1,
              minDistinctSubjects: 0,
              maxDistinctSubjects: days.length * times.length,
              minConsecutiveTimes: 0,
              maxConsecutiveTimes: times.length,
              travelTimeRooms: [],
              minTravelTime: 0,
              priority: priorityOptions[2],
              forbiddenCombination: [],
              unavailableTimes: [],
              undesiredTimes: [],
              createdAt: new Date(),
              updatedAt: new Date(),
            };
            resources.push(resourceToImport);
            batch.set(doc(db, resourcesPath, resourceToImport.name), resourceToImport);
          }
        });
        // Add lesson if it does not exists
        if (lessons.find((lesson) => lesson.name === assignmentToImport.lessonRef) === undefined) {
          const lessonToImport: ILesson = {
            color: '',
            photoURL: '',
            name: assignmentToImport.lessonRef,
            short: '',

            subject: assignmentToImport.subject,
            lessonsWeek: assignmentToImport.timeslots.length,
            split: getDefaultSplit([assignmentToImport.timeslots.length]),
            minGapLessons: 0,
            maxGapLessons: days.length - 1,

            classNum: assignmentToImport.classes.length,
            classes: assignmentToImport.classes,
            teacherNum: assignmentToImport.teachers.length,
            teachers: assignmentToImport.teachers,
            roomNum: assignmentToImport.rooms.length,
            rooms: assignmentToImport.rooms,

            priority: priorityOptions[2],
            predefinedTimes: [],
            simultaneousWith: [],
            notSimultaneousWith: [],
            occurBefore: [],
            occurBeforeMinDays: 0,

            createdAt: new Date(),
            updatedAt: new Date(),
          };
          lessons.push(lessonToImport);
          batch.set(doc(db, lessonsPath, lessonToImport.name), lessonToImport);
        }
        // Add/replace assignments
        //Remove assignment if existing
        importedAssigments.filter(
          (existingAssignment) => existingAssignment.lessonRef !== assignmentToImport.lessonRef
        );
        let assignmentToImportResources = assignmentToImport.classes;
        assignmentToImportResources = assignmentToImportResources.concat(assignmentToImport.teachers);
        assignmentToImportResources = assignmentToImportResources.concat(assignmentToImport.rooms);
        const newAssignment = {
          lessonRef: assignmentToImport.lessonRef,
          resources: assignmentToImportResources,
          timeslots: assignmentToImport.timeslots,
        };
        importedAssigments.push(newAssignment);
      });
      solution.assignments = importedAssigments;
      solution.status = 'Outdated';
      solution.updatedAt = new Date();
      batch.set(doc(db, solutionsPath, solution.name), solution);
      //Set solutions to outdated
      solutions.forEach((solution) => {
        batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
          status: 'Outdated',
        });
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch
        .commit()
        .then(() => {
          setLoadingOpen(false);
          toast.success(`${assignmentsToImport.length} ${t('timetable assignments imported successfully')}`);
        })
        .catch(() => {
          setLoadingOpen(false);
          toast.warning(t('Data import limit per operation exceeded, please input less data at a time'));
        });
    }
  };

  checkImportConfirmation();

  const [infoDialogOpen, setInfoDialogOpen] = React.useState(false);

  const gridRef = React.useRef<any>();
  let { width } = useContainerDimensions(gridRef);

  let maxSelectWidth = width !== 0 ? Math.floor(width / 8) : 172;

  const importStorageFlagKey = 'onboarding_import';
  const [runImportOnboarding, setRunImportOnboarding] = React.useState(false);

  React.useEffect(() => {
    // To ensure columns are resized on fisrt load
    if (open) {
      setLoadingOpen(true);
    } else {
      setLoadingOpen(false);
    }
    if (localStorage.getItem(importStorageFlagKey) !== 'true') {
      setRunImportOnboarding(true);
    } else {
      setRunImportOnboarding(false);
    }
  }, [open, runImportOnboarding, width]);

  const stepsImport = [
    {
      target: '.import-list',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('Content on CTRL+C')}
          message={t('The content in your clipboard (CTRL+C) is tabled and displayed here')}
        />
      ),
    },
    {
      target: '.columns-grid',
      content: (
        <OnboardingContent
          title={t('Columns')}
          message={t('You can select which field is informed in which column here')}
        />
      ),
    },
    {
      target: '.info-button',
      content: (
        <OnboardingContent
          title={t('Input Format')}
          message={t('You can see the required format for importing data for each field here')}
        />
      ),
    },
    {
      target: '.proceed-button',
      content: (
        <OnboardingContent title={t('Proceed')} message={t('When you are done, click here to import the data')} />
      ),
    },
  ];

  const [clipboardText, setClipboardText] = React.useState<string>('');

  const handlePaste = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        setClipboardText(text);
        toast.success(t('Content successfully loaded from clipboard'));
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
  };

  return (
    <div>
      {infoDialogOpen && (
        <InfoDialog
          open={infoDialogOpen}
          setOpen={setInfoDialogOpen}
          title={t('Columns Data Format')}
          message={<ImportAssignmentsAccordion />}
        />
      )}
      {confirmationOpen && (
        <ImportConfirmationDialog
          setImportOpen={setOpen}
          open={confirmationOpen}
          setOpen={setConfirmationOpen}
          setConfirmed={setImportConfirmed}
          title={t('Import confirmation')}
          message={t('Please check the warnings below. If you confirm, the subjects will be imported.')}
          warnings={warnings}
        />
      )}
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} justifyContent="space-between" alignItems={'center'}>
            <Grid item xs={12} md={4}>
              <DialogContentText>{message}</DialogContentText>
            </Grid>
            <Grid item xs={4} md={2}>
              <Button
                startIcon={<ContentPaste />}
                sx={{ width: '160px' }}
                variant={'outlined'}
                onClick={() => handlePaste()}
              >
                {t('Paste')}
              </Button>
            </Grid>
            <Grid item xs={4} md={3}>
              <ControlledSwitch
                checked={ignoreFirstLine}
                setChecked={setIgnoreFirstLine}
                label={t('Ignore first line')}
              ></ControlledSwitch>
            </Grid>
            <Grid item xs={6} md={3}>
              <Button
                className="info-button"
                startIcon={<Info />}
                variant="text"
                size="small"
                onClick={() => setInfoDialogOpen(true)}
                color="primary"
                fullWidth
              >
                {t('Column Format')}
              </Button>
            </Grid>
          </Grid>
          <Grid className="columns-grid" container spacing={0} paddingTop={2} ref={gridRef}>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column1}
                setCurrent={setColumn1}
                options={importFields}
                label={`${t('Column')} 1`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column2}
                setCurrent={setColumn2}
                options={importFields}
                label={`${t('Column')} 2`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column3}
                setCurrent={setColumn3}
                options={importFields}
                label={`${t('Column')} 3`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column4}
                setCurrent={setColumn4}
                options={importFields}
                label={`${t('Column')} 4`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column5}
                setCurrent={setColumn5}
                options={importFields}
                label={`${t('Column')} 5`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column6}
                setCurrent={setColumn6}
                options={importFields}
                label={`${t('Column')} 6`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column7}
                setCurrent={setColumn7}
                options={importFields}
                label={`${t('Column')} 7`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column8}
                setCurrent={setColumn8}
                options={importFields}
                label={`${t('Column')} 8`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
          </Grid>
          <Grid className="import-list" container spacing={1}>
            <ImportDataGrid
              clipboardText={clipboardText}
              setClipboardText={setClipboardText}
              ignoreFirstLine={ignoreFirstLine}
            ></ImportDataGrid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
          <Button className="proceed-button" onClick={handleProceed}>
            {t('Proceed')}
          </Button>
        </DialogActions>
        <Onboarding steps={stepsImport} run={runImportOnboarding} storageFlagKey={importStorageFlagKey} />
      </Dialog>
    </div>
  );
}
