import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { doc, writeBatch } from '@firebase/firestore';
import ControlledSwitch from '../../../components/ControlledSwitch';
import { Grid } from '@material-ui/core';
import ImportDataGrid from '../../../components/ImportDataGrid';
import BasicSelect from '../../../components/BasicSelect';
import { SessionContext } from 'contexts';
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
} from '@mui/material/colors';
import { db } from 'services';
import { toast } from 'react-toastify';
import ImportConfirmationDialog from '../../../components/ImportConfirmationDialog';
import { ISubject } from '../Subject';
import SimpleBackdrop from 'components/SimpleBackdrop';
import { useTranslation } from 'react-i18next';
import OnboardingContent from 'components/OnboardingContent';
import Onboarding from 'components/Onboarding';
import { useContainerDimensions } from 'hooks/useContainerDimensions';
import { Info } from '@material-ui/icons';
import InfoDialog from 'components/InfoDialog';
import ImportSubjectsAccordion from './ImportSubjectsAccordion';
import { ContentPaste } from '@mui/icons-material';
import { error } from 'console';
import { set } from 'date-fns';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export interface IImportWarning {
  line: number;
  column: string;
  description: string;
  type: 'error' | 'warning';
}

export default function ImportSubjectsDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const type = props.type;
  const { user, file, subjects, solutions, ownerEmail } = React.useContext(SessionContext);
  const subjectsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/subjects';
  const solutionsPath = 'users/' + ownerEmail + '/files/' + file?.name + '/solutions';
  const [ignoreFirstLine, setIgnoreFirstLine] = React.useState<boolean>(true);

  const days = file?.days ? file?.days : [];
  const times = file?.times ? file?.times : [];
  const priorityOptions = ['Very low', 'Low', 'Average', 'High', 'Very high'];

  const handleClose = () => {
    setOpen(false);
  };

  let warnings: IImportWarning[] = [];

  function validateResourceNameInput(name: string, lineNum: number, column: string): boolean {
    if (name.length === 0) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Empty')} ${column} ${t('will be ignored')}`,
        type: 'error',
      });
      return false;
    } else if (name.length > 50) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name having more than 50 chars for')} ${column} ${t('will be inogred')}`,
        type: 'error',
      });
      return false;
    } else if (
      name.includes('/') ||
      name.includes('\\') ||
      name.includes('|') ||
      name.includes(';') ||
      name.includes('#') ||
      name.includes('=') ||
      name.includes(':') ||
      name.includes('_') ||
      name.includes('{') ||
      name.includes('}') ||
      name.includes('&')
    ) {
      warnings.push({
        line: lineNum + 1,
        column: column,
        description: `${t('Name containing: / \\ | ; # = : _ { } & for')} ${column} ${t('will be ignored')}`,
        type: 'error',
      });
      return false;
      // } else if (resourceNames.includes(name)) {
      //   warnings.push({
      //     line: lineNum + 1,
      //     column: column,
      //     description: `${name} already exists and will be overwritten`,
      //     type: 'warning',
      //   });
      //   return true;
    } else return true;
  }

  function handleName(inputText: string, lineNum: number, column: string, names: string[]) {
    if (validateResourceNameInput(inputText.trim(), lineNum, column)) {
      names.push(inputText.trim());
    }
    return;
  }

  function handleArrayOfTimeslots(inputText: string, lineNum: number, column: string, arrayOfTimes: string[]) {
    if (inputText.trim().length === 0) {
      // Empty cells are allowed for this field
      return;
    }
    const inputSplit = inputText.split('+').map((text) => {
      return text.trim();
    });

    inputSplit.forEach((input) => {
      let inputData = input.split(':');
      if (inputData.length < 2) {
        warnings.push({
          line: lineNum + 1,
          column: column,
          description: `${column} ${inputData} ${t('is not in the required format (day:time) and will be ignored')}`,
          type: 'warning',
        });
      } else {
        const allDays = inputData[0].trim() === '*';
        const allTimes = inputData[1].trim() === '*';
        const day = Number(inputData[0].trim());
        const time = Number(inputData[1].trim());
        const dayIsValid = Array.from({ length: days.length + 1 }, (x, i) => i).includes(day) || day < 1 || allDays;
        const timeIsValid =
          Array.from({ length: times.length + 1 }, (x, i) => i).includes(time) || time < 1 || allTimes;
        if (!dayIsValid) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${t('Day')} ${day} ${t('in')} ${column} ${input} ${t('is not in the allowed range')} (1, ${
              days.length
            }) ${t('and will be ignored')}`,
            type: 'warning',
          });
        } else if (!timeIsValid) {
          warnings.push({
            line: lineNum + 1,
            column: column,
            description: `${t('Time')} ${time} ${t('in')} ${column} ${input} ${t('is not in the allowed range')} (1, ${
              times.length
            }) ${t('and will be ignored')}`,
            type: 'warning',
          });
        } else {
          if (allDays && allTimes) {
            days.forEach((day, indexDay) => {
              times.forEach((time, indexTime) => {
                !arrayOfTimes.includes(indexDay + ':' + indexTime) && arrayOfTimes.push(indexDay + ':' + indexTime);
              });
            });
          } else if (allDays) {
            days.forEach((day, index) => {
              !arrayOfTimes.includes(index + ':' + (time - 1)) && arrayOfTimes.push(index + ':' + (time - 1));
            });
          } else if (allTimes) {
            times.forEach((time, index) => {
              !arrayOfTimes.includes(day - 1 + ':' + index) && arrayOfTimes.push(day - 1 + ':' + index);
            });
          } else {
            !arrayOfTimes.includes(day - 1 + ':' + (time - 1)) && arrayOfTimes.push(day - 1 + ':' + (time - 1));
          }
        }
      }
    });
  }

  var getInitials = function (string: string, maxLength: number) {
    if (string.length <= maxLength) {
      return string.toUpperCase();
    }
    var names = string.trim().split(' ');
    if (names.length === 1) {
      return names[0].substring(0, maxLength).toUpperCase();
    }
    var initials: string = '';
    var midChars: number = 0;
    for (let i = 0; i < names.length; i++) {
      if (i === 0 || i === names.length - 1) initials += names[i].charAt(0).toUpperCase();
      else if (names[i].charAt(0) === names[i].charAt(0).toUpperCase() && midChars + 2 < maxLength) {
        initials += names[i].substring(0, 1).toUpperCase();
        midChars++;
      }
    }
    return initials;
  };

  const colors: any[] = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    // yellow,
    amber,
    orange,
    deepOrange,
    brown,
    // grey,
    // blueGrey,
  ];
  const intensities: number[] = [400, 500, 600, 700, 800, 900];

  function getRandomColor() {
    return colors[Math.floor(Math.random() * colors.length)][
      intensities[Math.floor(Math.random() * intensities.length)]
    ];
  }

  let subjectsToImport: ISubject[] = [];

  const handleProceed = () => {
    // Check for repeated columns
    const columns: string[] = [column1, column2, column3, column4, column5, column6, column7, column8];
    let ignoredColCount = 0;
    for (let i = 0; i < columns.length; ++i) {
      for (let j = i + 1; j < columns.length; ++j) {
        if (columns[i] === columns[j] && columns[i] !== t('Ignore column')) {
          toast.error(`${t('Cannot have more than one column with value')} ${columns[i]}`);
          return;
        }
      }
      columns[i] === t('Ignore column') && ignoredColCount++;
    }
    if (ignoredColCount === columns.length) {
      toast.error(t("At least one column should be marked as different than 'Ignore column'"));
      return;
    }
    subjectsToImport = [];
    navigator.clipboard
      .readText()
      .then((text) => {
        text.split('\n').forEach((line, index) => {
          //Ignoring (or not) the first line
          if (index === 0 && ignoreFirstLine) return;
          //Ignoring empty lines
          if (line === '') return;
          // Getting cell values
          const cells = line.split('\t');
          const columnVal1 = cells[0];
          const columnVal2 = cells.length > 1 ? cells[1] : '';
          const columnVal3 = cells.length > 2 ? cells[2] : '';
          const columnVal4 = cells.length > 3 ? cells[3] : '';
          const columnVal5 = cells.length > 4 ? cells[4] : '';
          const columnVal6 = cells.length > 5 ? cells[5] : '';
          const columnVal7 = cells.length > 6 ? cells[6] : '';
          const columnVal8 = cells.length > 7 ? cells[7] : '';
          // Process new or existing subject
          let name: string[] = [];
          column1 === t('Name') && handleName(columnVal1, index, column1, name);
          column2 === t('Name') && handleName(columnVal2, index, column2, name);
          column3 === t('Name') && handleName(columnVal3, index, column3, name);
          column4 === t('Name') && handleName(columnVal4, index, column4, name);
          column5 === t('Name') && handleName(columnVal5, index, column5, name);
          column6 === t('Name') && handleName(columnVal6, index, column6, name);
          column7 === t('Name') && handleName(columnVal7, index, column7, name);
          column8 === t('Name') && handleName(columnVal8, index, column8, name);
          // Process unavailable times
          let unavailableTimes: string[] = [];
          column1 === t('Unavailable Times') && handleArrayOfTimeslots(columnVal1, index, column1, unavailableTimes);
          column2 === t('Unavailable Times') && handleArrayOfTimeslots(columnVal2, index, column2, unavailableTimes);
          column3 === t('Unavailable Times') && handleArrayOfTimeslots(columnVal3, index, column3, unavailableTimes);
          column4 === t('Unavailable Times') && handleArrayOfTimeslots(columnVal4, index, column4, unavailableTimes);
          column5 === t('Unavailable Times') && handleArrayOfTimeslots(columnVal5, index, column5, unavailableTimes);
          column6 === t('Unavailable Times') && handleArrayOfTimeslots(columnVal6, index, column6, unavailableTimes);
          column7 === t('Unavailable Times') && handleArrayOfTimeslots(columnVal7, index, column7, unavailableTimes);
          column8 === t('Unavailable Times') && handleArrayOfTimeslots(columnVal8, index, column8, unavailableTimes);
          // Process unavailable times
          let undesiredTimes: string[] = [];
          column1 === t('Undesired Times') && handleArrayOfTimeslots(columnVal1, index, column1, undesiredTimes);
          column2 === t('Undesired Times') && handleArrayOfTimeslots(columnVal2, index, column2, undesiredTimes);
          column3 === t('Undesired Times') && handleArrayOfTimeslots(columnVal3, index, column3, undesiredTimes);
          column4 === t('Undesired Times') && handleArrayOfTimeslots(columnVal4, index, column4, undesiredTimes);
          column5 === t('Undesired Times') && handleArrayOfTimeslots(columnVal5, index, column5, undesiredTimes);
          column6 === t('Undesired Times') && handleArrayOfTimeslots(columnVal6, index, column6, undesiredTimes);
          column7 === t('Undesired Times') && handleArrayOfTimeslots(columnVal7, index, column7, undesiredTimes);
          column8 === t('Undesired Times') && handleArrayOfTimeslots(columnVal8, index, column8, undesiredTimes);

          const subjectToImport = {
            color: getRandomColor(),
            photoURL: '',
            name: name[0],
            short: name.length === 0 ? '' : getInitials(name[0], 4),
            priority: priorityOptions[2],
            unavailableTimes: unavailableTimes,
            undesiredTimes: undesiredTimes,
            createdAt: new Date(),
            updatedAt: new Date(),
          };
          if (subjects.find((subject) => subject.name === subjectToImport.name) !== undefined) {
            warnings.push({
              line: index + 1,
              column: t('Name'),
              description: `${t('Subject')} ${subjectToImport.name} ${t('already exists and will be ignored')}`,
              type: 'error',
            });
          } else if (subjectToImport.name !== undefined && subjectToImport.name !== '') {
            subjectsToImport.push(subjectToImport);
          }
        });
        // See warnings, erros and confirm
        localStorage.setItem('warnings', JSON.stringify(warnings));
        localStorage.setItem('subjectsToImport', JSON.stringify(subjectsToImport));
        if (warnings.length > 0) setConfirmationOpen(true);
        else {
          setImportConfirmed(true);
          setOpen(false);
        }
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
    // setOpen(false);
  };

  const importFields = [t('Ignore column'), t('Name'), t('Unavailable Times'), t('Undesired Times')];
  const [column1, setColumn1] = React.useState<string>(importFields[0]);
  const [column2, setColumn2] = React.useState<string>(importFields[0]);
  const [column3, setColumn3] = React.useState<string>(importFields[0]);
  const [column4, setColumn4] = React.useState<string>(importFields[0]);
  const [column5, setColumn5] = React.useState<string>(importFields[0]);
  const [column6, setColumn6] = React.useState<string>(importFields[0]);
  const [column7, setColumn7] = React.useState<string>(importFields[0]);
  const [column8, setColumn8] = React.useState<string>(importFields[0]);

  const [loadingOpen, setLoadingOpen] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [importConfirmed, setImportConfirmed] = React.useState(false);

  const checkImportConfirmation = () => {
    if (importConfirmed) {
      setLoadingOpen(true);
      setImportConfirmed(false);
      const batch = writeBatch(db);
      const subjectsToImport: ISubject[] = JSON.parse(localStorage.getItem('subjectsToImport') as string);
      subjectsToImport.forEach((subject) => {
        // Add resource
        subject.createdAt = new Date();
        subject.updatedAt = new Date();
        batch.set(doc(db, subjectsPath, subject.name), subject);
        //Set solutions to outdated
        solutions.forEach((solution) => {
          batch.update(doc(db, `${solutionsPath}/${solution.name}`), {
            status: 'Outdated',
          });
        });
      });
      // Update file stats
      batch.update(doc(db, `users/${ownerEmail}/files/${user?.selectedFile}`), {
        updatedAt: new Date(),
      });
      batch
        .commit()
        .then(() => {
          setLoadingOpen(false);
          toast.success(`${subjectsToImport.length} ${t('subjects imported successfully')}`);
        })
        .catch(() => {
          setLoadingOpen(false);
          toast.warning(t('Data import limit per operation exceeded, please input less data at a time'));
        });
    }
  };

  checkImportConfirmation();

  const [infoDialogOpen, setInfoDialogOpen] = React.useState(false);

  const gridRef = React.useRef<any>();
  let { width } = useContainerDimensions(gridRef);

  let maxSelectWidth = width !== 0 ? Math.floor(width / 8) : 172;

  const importStorageFlagKey = 'onboarding_import';
  const [runImportOnboarding, setRunImportOnboarding] = React.useState(false);

  React.useEffect(() => {
    // To ensure columns are resized on fisrt load
    if (open) {
      setLoadingOpen(true);
    } else {
      setLoadingOpen(false);
    }
    if (localStorage.getItem(importStorageFlagKey) !== 'true') {
      setRunImportOnboarding(true);
    } else {
      setRunImportOnboarding(false);
    }
  }, [open, runImportOnboarding, width]);

  const stepsImport = [
    {
      target: '.import-list',
      disableBeacon: true,
      content: (
        <OnboardingContent
          title={t('Content on CTRL+C')}
          message={t('The content in your clipboard (CTRL+C) is tabled and displayed here')}
        />
      ),
    },
    {
      target: '.columns-grid',
      content: (
        <OnboardingContent
          title={t('Columns')}
          message={t('You can select which field is informed in which column here')}
        />
      ),
    },
    {
      target: '.info-button',
      content: (
        <OnboardingContent
          title={t('Input Format')}
          message={t('You can see the required format for importing data for each field here')}
        />
      ),
    },
    {
      target: '.proceed-button',
      content: (
        <OnboardingContent title={t('Proceed')} message={t('When you are done, click here to import the data')} />
      ),
    },
  ];

  const [clipboardText, setClipboardText] = React.useState<string>('');

  const handlePaste = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        setClipboardText(text);
        toast.success(t('Content successfully loaded from clipboard'));
      })
      .catch((err) => {
        console.error('Failed to read clipboard contents: ', err);
      });
  };

  return (
    <div>
      {infoDialogOpen && (
        <InfoDialog
          open={infoDialogOpen}
          setOpen={setInfoDialogOpen}
          title={t('Columns Data Format')}
          message={<ImportSubjectsAccordion />}
        />
      )}
      {confirmationOpen && (
        <ImportConfirmationDialog
          setImportOpen={setOpen}
          open={confirmationOpen}
          setOpen={setConfirmationOpen}
          setConfirmed={setImportConfirmed}
          title={t('Import confirmation')}
          message={t('Please check the warnings below. If you confirm, the subjects will be imported.')}
          warnings={warnings}
        />
      )}
      {loadingOpen && <SimpleBackdrop open={loadingOpen} setOpen={setLoadingOpen} />}
      <Dialog
        fullWidth
        maxWidth={false}
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1} justifyContent="space-between" alignItems={'center'}>
            <Grid item xs={12} md={4}>
              <DialogContentText>{message}</DialogContentText>
            </Grid>
            <Grid item xs={4} md={2}>
              <Button
                startIcon={<ContentPaste />}
                sx={{ width: '160px' }}
                variant={'outlined'}
                onClick={() => handlePaste()}
              >
                {t('Paste')}
              </Button>
            </Grid>
            <Grid item xs={4} md={3}>
              <ControlledSwitch
                checked={ignoreFirstLine}
                setChecked={setIgnoreFirstLine}
                label={t('Ignore first line')}
              ></ControlledSwitch>
            </Grid>
            <Grid item xs={4} md={3}>
              <Button
                className="info-button"
                startIcon={<Info />}
                variant="text"
                size="small"
                onClick={() => setInfoDialogOpen(true)}
                color="primary"
                fullWidth
              >
                {t('Column Format')}
              </Button>
            </Grid>
          </Grid>
          <Grid className="columns-grid" container spacing={0} paddingTop={2} ref={gridRef}>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column1}
                setCurrent={setColumn1}
                options={importFields}
                label={`${t('Column')} 1`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column2}
                setCurrent={setColumn2}
                options={importFields}
                label={`${t('Column')} 2`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column3}
                setCurrent={setColumn3}
                options={importFields}
                label={`${t('Column')} 3`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column4}
                setCurrent={setColumn4}
                options={importFields}
                label={`${t('Column')} 4`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column5}
                setCurrent={setColumn5}
                options={importFields}
                label={`${t('Column')} 5`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column6}
                setCurrent={setColumn6}
                options={importFields}
                label={`${t('Column')} 6`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column7}
                setCurrent={setColumn7}
                options={importFields}
                label={`${t('Column')} 7`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
            <Grid item width={maxSelectWidth}>
              <BasicSelect
                current={column8}
                setCurrent={setColumn8}
                options={importFields}
                label={`${t('Column')} 8`}
                maxWidth={maxSelectWidth}
                minWidth={maxSelectWidth}
              ></BasicSelect>
            </Grid>
          </Grid>
          <Grid className="import-list" container spacing={1}>
            <ImportDataGrid
              clipboardText={clipboardText}
              setClipboardText={setClipboardText}
              ignoreFirstLine={ignoreFirstLine}
            ></ImportDataGrid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {t('Close')}
          </Button>
          <Button className="proceed-button" onClick={handleProceed}>
            {t('Proceed')}
          </Button>
        </DialogActions>
        {/* <Onboarding steps={stepsImport} run={runImportOnboarding} storageFlagKey={importStorageFlagKey} /> */}
      </Dialog>
    </div>
  );
}
