import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Chip } from '@material-ui/core';

export default function PrioritySelect(props: any) {
  const current: string = props.current;
  const setCurrent = props.setCurrent;
  const options: string[] = props.options;
  const backgroundColors: string[] = props.backgroundColors;
  const colors: string[] = props.colors;
  const label: string = props.label;

  const handleChange = (event: SelectChangeEvent) => {
    setCurrent(event.target.value);
  };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-select-small">{label}</InputLabel>
      <Select labelId="demo-select-small" id="demo-select-small" value={current} label={label} onChange={handleChange}>
        {options.map((option, index) => {
          return (
            <MenuItem key={`${label}_${index}`} value={option}>
              <Chip
                size="small"
                translate="no"
                sx={{
                  minWidth: '100%',

                  // color: backgroundColors[index],
                  // backgroundColor: colors[index],
                  // borderColor: backgroundColors[index],
                  // borderWidth: 1,
                  // border: 1,
                  color: colors[index],
                  backgroundColor: backgroundColors[index],
                }}
                label={option}
              />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
