import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ShareUser } from 'pages/Files/File';

export default function ShareModeSelect(props: any) {
  const current: string = props.current;
  const shared: ShareUser = props.shared;
  // const setCurrent = props.setCurrent;
  const options: string[] = props.options;
  const label: string = props.label;
  const handleChange: (event: SelectChangeEvent, shared: ShareUser) => void = props.handleChange;

  // const handleChange = (event: SelectChangeEvent) => {
  //   setCurrent(event.target.value);
  // };

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={'' + current}
        label={label}
        fullWidth
        onChange={(event) => handleChange(event, shared)}
      >
        {options.map((option, index) => {
          return (
            <MenuItem key={`${label}_${index}`} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
