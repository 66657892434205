import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  ToggleButton,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import React from 'react';

export default function MinMaxConstraintSlider(props: any) {
  const icon = props.icon;
  const title = props.title;
  const tooltip = props.tooltip;
  const selectedMin = props.selectedMin;
  const setSelectedMin = props.setSelectedMin;
  const selectedMax = props.selectedMax;
  const setSelectedMax = props.setSelectedMax;
  const possibleMin = props.possibleMin;
  const possibleMax = props.possibleMax;
  const applyToAllSelected = props.applyToAllSelected;
  const setApplyToAllSelected = props.setApplyToAllSelected;
  const tooltipApplyToAll = props.tooltipApplyToAll;

  const { t } = useTranslation();
  const widthLessThan600 = useMediaQuery('(max-width:600px)');

  const [value, setValue] = React.useState<number[]>([selectedMin, selectedMax]);
  const handleChange = (event: Event, newValue: number | number[]) => {
    setSelectedMin((newValue as number[])[0]);
    setSelectedMax((newValue as number[])[1]);
    setValue(newValue as number[]);
  };

  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12} sm={5}>
        <Tooltip className={'constraint-description'} title={tooltip}>
          <ToggleButton
            value="check"
            fullWidth
            size="small"
            color="primary"
            sx={{
              textTransform: 'none',
              justifyContent: widthLessThan600 ? 'center' : 'right',
              paddingRight: 2,
            }}
            style={{ borderWidth: 0, backgroundColor: 'rgba(255, 255, 255, 0)' }}
            selected={(selectedMin as number) == 0 && selectedMax === possibleMax ? false : true}
          >
            {icon}
            {title}
          </ToggleButton>
        </Tooltip>
      </Grid>
      <Grid item xs={8} sm={4} paddingRight={2}>
        <Slider
          getAriaLabel={() => 'Min max range'}
          value={value}
          onChange={handleChange}
          valueLabelDisplay={selectedMin === possibleMin && selectedMax === possibleMax ? 'auto' : 'on'}
          size="small"
          marks
          min={possibleMin}
          max={possibleMax}
          style={{
            color: selectedMin === possibleMin && selectedMax === possibleMax ? '#BDBDBD' : '#1976D2',
          }}
        />
      </Grid>
      <Grid item xs={4} sm={3} lg={3}>
        <Tooltip title={tooltipApplyToAll} placement="right-end">
          <ToggleButton
            value="check"
            fullWidth
            size="small"
            color="primary"
            selected={applyToAllSelected}
            onChange={() => {
              setApplyToAllSelected(!applyToAllSelected);
            }}
          >
            <DoubleArrowIcon />
            {widthLessThan600 ? '' : t('All')}
          </ToggleButton>
        </Tooltip>
      </Grid>
      {widthLessThan600 && (
        <Grid item xs={12}>
          <Divider></Divider>
        </Grid>
      )}
    </Grid>
  );
}
