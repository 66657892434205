import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { red, green, lime, yellow, orange, grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import PrioritySelect from 'components/PrioritySelect';
import BasicSelect from '../../../components/BasicSelect';
import { SessionContext } from 'contexts';
import { ISolution } from '../Solution';
import FormTitle from 'components/FormTitle';
import RuntimeSelect from 'components/RuntimeSelect';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { lightGreen } from '@material-ui/core/colors';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export interface SolverTypesMap {
  [key: string]: string;
}

export interface DisplayTypesMap {
  [key: string]: string;
}

export interface RunningTimeSecondsMap {
  [key: number]: string;
}

export interface RunningTimeLabelsMap {
  [key: string]: number;
}

export interface PriorityOptionsMap {
  [key: string]: string;
}

export default function ConfigsDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  const title = props.title;
  const message = props.message;
  const solution: ISolution = props.solution;
  const setSolution = props.setSolution;
  const setConfirmed = props.setConfirmed;
  const priorityOptions: string[] = props.priorityOptions;
  const setStatus = props.setStatus;
  const userPlan = props.userPlan;
  const runtimeSuggestion = props.runtimeSuggestion;
  const widthLessThan900 = useMediaQuery('(max-width:900px)');
  const { file, lessons } = React.useContext(SessionContext);

  const solverTypesMap: SolverTypesMap = {};
  solverTypesMap['Optimal'] = 'Optimal';
  solverTypesMap['Fast'] = 'Fast';
  solverTypesMap['Ótimo'] = 'Optimal';
  solverTypesMap['Rápido'] = 'Fast';

  const displayTypesMap: DisplayTypesMap = {};
  displayTypesMap['Compact'] = 'Compact';
  displayTypesMap['Individual tables'] = 'Individual tables';
  displayTypesMap['Compacto'] = 'Compact';
  displayTypesMap['Tabelas individuais'] = 'Individual tables';

  const priorityOptionsMap: PriorityOptionsMap = {};
  priorityOptionsMap['Disabled'] = 'Disabled';
  priorityOptionsMap['Very low'] = 'Very low';
  priorityOptionsMap['Very Low'] = 'Very low';
  priorityOptionsMap['Low'] = 'Low';
  priorityOptionsMap['Average'] = 'Average';
  priorityOptionsMap['High'] = 'High';
  priorityOptionsMap['Very high'] = 'Very high';
  priorityOptionsMap['Very High'] = 'Very high';
  priorityOptionsMap['Desabilitado'] = 'Disabled';
  priorityOptionsMap['Muito baixo'] = 'Very low';
  priorityOptionsMap['Baixo'] = 'Low';
  priorityOptionsMap['Normal'] = 'Average';
  priorityOptionsMap['Alto'] = 'High';
  priorityOptionsMap['Muito alto'] = 'Very high';

  const handleClose = () => {
    setDisplay(t(solution.display));
    setSolver(t(solution.solver));
    setRunningTimeLabel(mapRunningTimeSecondsToLabels[solution.runningTime]);

    setClashesClasses(t(solution.clashesClasses));
    setWorkloadClasses(t(solution.workloadClasses));
    setWorkingDaysClasses(t(solution.workingDaysClasses));
    setIdleWindowClasses(t(solution.idleWindowClasses));
    setDailyWorkloadClasses(t(solution.dailyWorkloadClasses));
    setDistinctSubjectsClasses(t(solution.distinctSubjectsClasses));
    setForbiddenCombinationClasses(t(solution.forbiddenCombinationClasses));
    setUnavailableTimesClasses(t(solution.unavailableTimesClasses));
    setUndesiredTimesClasses(t(solution.undesiredTimesClasses));

    setClashesTeachers(t(solution.clashesTeachers));
    setWorkloadTeachers(t(solution.workloadTeachers));
    setWorkingDaysTeachers(t(solution.workingDaysTeachers));
    setIdleWindowTeachers(t(solution.idleWindowTeachers));
    setDailyWorkloadTeachers(t(solution.dailyWorkloadTeachers));
    setDistinctSubjectsTeachers(t(solution.distinctSubjectsTeachers));
    setForbiddenCombinationTeachers(t(solution.forbiddenCombinationTeachers));
    setUnavailableTimesTeachers(t(solution.unavailableTimesTeachers));
    setUndesiredTimesTeachers(t(solution.undesiredTimesTeachers));

    setClashesRooms(t(solution.clashesRooms));
    setWorkloadRooms(t(solution.workloadRooms));
    setWorkingDaysRooms(t(solution.workingDaysRooms));
    setIdleWindowRooms(t(solution.idleWindowRooms));
    setDailyWorkloadRooms(t(solution.dailyWorkloadRooms));
    setDistinctSubjectsRooms(t(solution.distinctSubjectsRooms));
    setForbiddenCombinationRooms(t(solution.forbiddenCombinationRooms));
    setUnavailableTimesRooms(t(solution.unavailableTimesRooms));
    setUndesiredTimesRooms(t(solution.undesiredTimesRooms));

    setUnavailableTimesSubjects(t(solution.unavailableTimesSubjects));
    setUndesiredTimesSubjects(t(solution.undesiredTimesSubjects));

    setAssignTimes(t(solution.assignTimes));
    setAssignResources(t(solution.assignResources));
    setSplit(t(solution.split));
    setDaysBetweenLessons(t(solution.daysBetweenLessons));
    setPredefinedTimes(t(solution.predefinedTimes));
    setSimultaneousWith(t(solution.simultaneousWith));
    setNotSimultaneousWith(t(solution.notSimultaneousWith));
    setOccurBefore(t(solution.occurBefore));

    setRestBetweenDaysClasses(t(solution.restBetweenDaysClasses));
    setRoomChangesDayClasses(t(solution.roomChangesDayClasses));
    setConsecutiveTimesClasses(t(solution.consecutiveTimesClasses));
    setRestBetweenDaysTeachers(t(solution.restBetweenDaysTeachers));
    setRoomChangesDayTeachers(t(solution.roomChangesDayTeachers));
    setConsecutiveTimesTeachers(t(solution.consecutiveTimesTeachers));
    setConsecutiveTimesRooms(t(solution.consecutiveTimesRooms));
    setTravelTimeRooms(t(solution.travelTimeRooms));

    setOpen(false);
  };

  const handleDefault = () => {
    setDisplay(t('Compact'));
    setSolver(t('Optimal'));
    setRunningTimeLabel(mapRunningTimeSecondsToLabels[runtimeSuggestion]);

    setClashesClasses(priorityOptionsMap[priorityOptions[5]]);
    setWorkloadClasses(priorityOptionsMap[priorityOptions[5]]);
    setWorkingDaysClasses(priorityOptionsMap[priorityOptions[3]]);
    setIdleWindowClasses(priorityOptionsMap[priorityOptions[3]]);
    setDailyWorkloadClasses(priorityOptionsMap[priorityOptions[3]]);
    setDistinctSubjectsClasses(priorityOptionsMap[priorityOptions[3]]);
    setForbiddenCombinationClasses(priorityOptionsMap[priorityOptions[3]]);
    setUnavailableTimesClasses(priorityOptionsMap[priorityOptions[5]]);
    setUndesiredTimesClasses(priorityOptionsMap[priorityOptions[1]]);
    setRestBetweenDaysClasses(priorityOptionsMap[priorityOptions[1]]);
    setRoomChangesDayClasses(priorityOptionsMap[priorityOptions[1]]);
    setConsecutiveTimesClasses(priorityOptionsMap[priorityOptions[1]]);

    setClashesTeachers(priorityOptionsMap[priorityOptions[5]]);
    setWorkloadTeachers(priorityOptionsMap[priorityOptions[5]]);
    setWorkingDaysTeachers(priorityOptionsMap[priorityOptions[3]]);
    setIdleWindowTeachers(priorityOptionsMap[priorityOptions[1]]);
    setDailyWorkloadTeachers(priorityOptionsMap[priorityOptions[1]]);
    setDistinctSubjectsTeachers(priorityOptionsMap[priorityOptions[1]]);
    setForbiddenCombinationTeachers(priorityOptionsMap[priorityOptions[3]]);
    setUnavailableTimesTeachers(priorityOptionsMap[priorityOptions[5]]);
    setUndesiredTimesTeachers(priorityOptionsMap[priorityOptions[1]]);
    setRestBetweenDaysTeachers(priorityOptionsMap[priorityOptions[1]]);
    setRoomChangesDayTeachers(priorityOptionsMap[priorityOptions[1]]);
    setConsecutiveTimesTeachers(priorityOptionsMap[priorityOptions[1]]);

    setClashesRooms(priorityOptionsMap[priorityOptions[5]]);
    setWorkloadRooms(priorityOptionsMap[priorityOptions[5]]);
    setWorkingDaysRooms(priorityOptionsMap[priorityOptions[3]]);
    setIdleWindowRooms(priorityOptionsMap[priorityOptions[3]]);
    setDailyWorkloadRooms(priorityOptionsMap[priorityOptions[3]]);
    setDistinctSubjectsRooms(priorityOptionsMap[priorityOptions[3]]);
    setForbiddenCombinationRooms(priorityOptionsMap[priorityOptions[3]]);
    setUnavailableTimesRooms(priorityOptionsMap[priorityOptions[5]]);
    setUndesiredTimesRooms(priorityOptionsMap[priorityOptions[1]]);
    setConsecutiveTimesRooms(priorityOptionsMap[priorityOptions[1]]);
    setTravelTimeRooms(priorityOptionsMap[priorityOptions[1]]);

    setAssignTimes(priorityOptionsMap[priorityOptions[5]]);
    setAssignResources(priorityOptionsMap[priorityOptions[5]]);
    setSplit(priorityOptionsMap[priorityOptions[5]]);
    setDaysBetweenLessons(priorityOptionsMap[priorityOptions[5]]);
    setPredefinedTimes(priorityOptionsMap[priorityOptions[5]]);
    setSimultaneousWith(priorityOptionsMap[priorityOptions[5]]);
    setNotSimultaneousWith(priorityOptionsMap[priorityOptions[5]]);
    setOccurBefore(priorityOptionsMap[priorityOptions[5]]);
    setUnavailableTimesSubjects(priorityOptionsMap[priorityOptions[5]]);
    setUndesiredTimesSubjects(priorityOptionsMap[priorityOptions[1]]);
    toast.success(t('Default configs have been restored'));
  };
  const handleConfirm = () => {
    setConfirmed(true);
    setStatus('Outdated');
    let newSolution = { ...solution };
    newSolution.status = 'Outdated';
    newSolution.display = displayTypesMap[display];
    newSolution.solver = solverTypesMap[solver];
    newSolution.runningTime = mapRunningTimeLabelsToSeconds[runningTimeLabel];
    newSolution.clashesClasses = priorityOptionsMap[clashesClasses];
    newSolution.workloadClasses = priorityOptionsMap[workloadClasses];
    newSolution.workingDaysClasses = priorityOptionsMap[workingDaysClasses];
    newSolution.idleWindowClasses = priorityOptionsMap[idleWindowClasses];
    newSolution.dailyWorkloadClasses = priorityOptionsMap[dailyWorkloadClasses];
    newSolution.distinctSubjectsClasses = priorityOptionsMap[distinctSubjectsClasses];
    newSolution.forbiddenCombinationClasses = priorityOptionsMap[forbiddenCombinationClasses];
    newSolution.unavailableTimesClasses = priorityOptionsMap[unavailableTimesClasses];
    newSolution.undesiredTimesClasses = priorityOptionsMap[undesiredTimesClasses];
    newSolution.restBetweenDaysClasses = priorityOptionsMap[restBetweenDaysClasses];
    newSolution.roomChangesDayClasses = priorityOptionsMap[roomChangesDayClasses];
    newSolution.consecutiveTimesClasses = priorityOptionsMap[consecutiveTimesClasses];

    newSolution.clashesTeachers = priorityOptionsMap[clashesTeachers];
    newSolution.workloadTeachers = priorityOptionsMap[workloadTeachers];
    newSolution.workingDaysTeachers = priorityOptionsMap[workingDaysTeachers];
    newSolution.idleWindowTeachers = priorityOptionsMap[idleWindowTeachers];
    newSolution.dailyWorkloadTeachers = priorityOptionsMap[dailyWorkloadTeachers];
    newSolution.distinctSubjectsTeachers = priorityOptionsMap[distinctSubjectsTeachers];
    newSolution.forbiddenCombinationTeachers = priorityOptionsMap[forbiddenCombinationTeachers];
    newSolution.unavailableTimesTeachers = priorityOptionsMap[unavailableTimesTeachers];
    newSolution.undesiredTimesTeachers = priorityOptionsMap[undesiredTimesTeachers];
    newSolution.restBetweenDaysTeachers = priorityOptionsMap[restBetweenDaysTeachers];
    newSolution.roomChangesDayTeachers = priorityOptionsMap[roomChangesDayTeachers];
    newSolution.consecutiveTimesTeachers = priorityOptionsMap[consecutiveTimesTeachers];

    newSolution.clashesRooms = priorityOptionsMap[clashesRooms];
    newSolution.workloadRooms = priorityOptionsMap[workloadRooms];
    newSolution.workingDaysRooms = priorityOptionsMap[workingDaysRooms];
    newSolution.idleWindowRooms = priorityOptionsMap[idleWindowRooms];
    newSolution.dailyWorkloadRooms = priorityOptionsMap[dailyWorkloadRooms];
    newSolution.distinctSubjectsRooms = priorityOptionsMap[distinctSubjectsRooms];
    newSolution.forbiddenCombinationRooms = priorityOptionsMap[forbiddenCombinationRooms];
    newSolution.unavailableTimesRooms = priorityOptionsMap[unavailableTimesRooms];
    newSolution.undesiredTimesRooms = priorityOptionsMap[undesiredTimesRooms];
    newSolution.consecutiveTimesRooms = priorityOptionsMap[consecutiveTimesRooms];
    newSolution.travelTimeRooms = priorityOptionsMap[travelTimeRooms];

    newSolution.assignTimes = priorityOptionsMap[assignTimes];
    newSolution.assignResources = priorityOptionsMap[assignResources];
    newSolution.split = priorityOptionsMap[split];
    newSolution.daysBetweenLessons = priorityOptionsMap[daysBetweenLessons];
    newSolution.predefinedTimes = priorityOptionsMap[predefinedTimes];
    newSolution.simultaneousWith = priorityOptionsMap[simultaneousWith];
    newSolution.notSimultaneousWith = priorityOptionsMap[notSimultaneousWith];
    newSolution.occurBefore = priorityOptionsMap[occurBefore];
    newSolution.unavailableTimesSubjects = priorityOptionsMap[unavailableTimesSubjects];
    newSolution.undesiredTimesSubjects = priorityOptionsMap[undesiredTimesSubjects];

    setSolution(newSolution);
    setOpen(false);
  };

  // const backgroundColors = [grey[300], green[300], lime[300], yellow[300], orange[300], red[300]];
  // const textColors = [grey[900], green[900], lime[900], yellow[900], orange[900], red[900]];
  const backgroundColors = [grey[700], red[700], orange[700], grey[700], lightGreen[700], green[700]];
  const textColors = [grey[50], grey[50], grey[50], grey[50], grey[50], grey[50]];
  // const textColors = [grey[100], green[100], lime[100], yellow[100], orange[100], red[100]];

  const constraintTypesClasses = [
    // t('Avoid Clashes'),
    t('Working Times'),
    t('Working Days'),
    t('Idle Window'),
    t('Daily Workload'),
    t('Distinct Subjects'),
    t('Forbidden Combination'),
    t('Unavailable Times'),
    t('Undesired Times'),
    t('Rest between Days'),
    t('Rooms per Day'),
    t('Consecutive Times'),
  ];
  const constraintTypesTeachers = [
    // t('Avoid Clashes'),
    t('Working Times'),
    t('Working Days'),
    t('Idle Window'),
    t('Daily Workload'),
    t('Distinct Subjects'),
    t('Forbidden Combination'),
    t('Unavailable Times'),
    t('Undesired Times'),
    t('Rest between Days'),
    t('Rooms per Day'),
    t('Consecutive Times'),
  ];
  const constraintTypesRooms = [
    // t('Avoid Clashes'),
    t('Working Times'),
    t('Working Days'),
    t('Idle Window'),
    t('Daily Workload'),
    t('Distinct Subjects'),
    t('Forbidden Combination'),
    t('Unavailable Times'),
    t('Undesired Times'),
    t('Consecutive Times'),
    t('Travel Time'),
  ];
  const constraintTypesLessons = [
    // t('Assign Times'),
    // t('Assign Resources'),
    t('Split'),
    t('Days between Lessons'),
    t('Predefined Times'),
    t('Simultaneous with'),
    t('Not Simultaneous with'),
    t('Occur before'),
    t('Subject Unavailable Times'),
    t('Subject Undesired Times'),
  ];

  const [clashesClasses, setClashesClasses] = React.useState<string>(t(solution.clashesClasses));
  const [workloadClasses, setWorkloadClasses] = React.useState<string>(t(solution.workloadClasses));
  const [workingDaysClasses, setWorkingDaysClasses] = React.useState<string>(t(solution.workingDaysClasses));
  const [idleWindowClasses, setIdleWindowClasses] = React.useState<string>(t(solution.idleWindowClasses));
  const [dailyWorkloadClasses, setDailyWorkloadClasses] = React.useState<string>(t(solution.dailyWorkloadClasses));
  const [distinctSubjectsClasses, setDistinctSubjectsClasses] = React.useState<string>(
    t(solution.distinctSubjectsClasses)
  );
  const [forbiddenCombinationClasses, setForbiddenCombinationClasses] = React.useState<string>(
    t(solution.forbiddenCombinationClasses)
  );
  const [unavailableTimesClasses, setUnavailableTimesClasses] = React.useState<string>(
    t(solution.unavailableTimesClasses)
  );
  const [undesiredTimesClasses, setUndesiredTimesClasses] = React.useState<string>(t(solution.undesiredTimesClasses));
  const [restBetweenDaysClasses, setRestBetweenDaysClasses] = React.useState<string>(
    t(solution.restBetweenDaysClasses)
  );
  const [consecutiveTimesClasses, setConsecutiveTimesClasses] = React.useState<string>(
    t(solution.consecutiveTimesClasses)
  );
  const [roomChangesDayClasses, setRoomChangesDayClasses] = React.useState<string>(t(solution.roomChangesDayClasses));

  const [clashesTeachers, setClashesTeachers] = React.useState<string>(t(solution.clashesTeachers));
  const [workloadTeachers, setWorkloadTeachers] = React.useState<string>(t(solution.workloadTeachers));
  const [workingDaysTeachers, setWorkingDaysTeachers] = React.useState<string>(t(solution.workingDaysTeachers));
  const [idleWindowTeachers, setIdleWindowTeachers] = React.useState<string>(t(solution.idleWindowTeachers));
  const [dailyWorkloadTeachers, setDailyWorkloadTeachers] = React.useState<string>(t(solution.dailyWorkloadTeachers));
  const [distinctSubjectsTeachers, setDistinctSubjectsTeachers] = React.useState<string>(
    t(solution.distinctSubjectsTeachers)
  );
  const [forbiddenCombinationTeachers, setForbiddenCombinationTeachers] = React.useState<string>(
    t(solution.forbiddenCombinationTeachers)
  );
  const [unavailableTimesTeachers, setUnavailableTimesTeachers] = React.useState<string>(
    t(solution.unavailableTimesTeachers)
  );
  const [undesiredTimesTeachers, setUndesiredTimesTeachers] = React.useState<string>(
    t(solution.undesiredTimesTeachers)
  );
  const [restBetweenDaysTeachers, setRestBetweenDaysTeachers] = React.useState<string>(
    t(solution.restBetweenDaysTeachers)
  );
  const [consecutiveTimesTeachers, setConsecutiveTimesTeachers] = React.useState<string>(
    t(solution.consecutiveTimesTeachers)
  );
  const [roomChangesDayTeachers, setRoomChangesDayTeachers] = React.useState<string>(
    t(solution.roomChangesDayTeachers)
  );

  const [clashesRooms, setClashesRooms] = React.useState<string>(t(solution.clashesRooms));
  const [workloadRooms, setWorkloadRooms] = React.useState<string>(t(solution.workloadRooms));
  const [workingDaysRooms, setWorkingDaysRooms] = React.useState<string>(t(solution.workingDaysRooms));
  const [idleWindowRooms, setIdleWindowRooms] = React.useState<string>(t(solution.idleWindowRooms));
  const [dailyWorkloadRooms, setDailyWorkloadRooms] = React.useState<string>(t(solution.dailyWorkloadRooms));
  const [distinctSubjectsRooms, setDistinctSubjectsRooms] = React.useState<string>(t(solution.distinctSubjectsRooms));
  const [forbiddenCombinationRooms, setForbiddenCombinationRooms] = React.useState<string>(
    t(solution.forbiddenCombinationRooms)
  );
  const [unavailableTimesRooms, setUnavailableTimesRooms] = React.useState<string>(t(solution.unavailableTimesRooms));
  const [undesiredTimesRooms, setUndesiredTimesRooms] = React.useState<string>(t(solution.undesiredTimesRooms));
  const [consecutiveTimesRooms, setConsecutiveTimesRooms] = React.useState<string>(t(solution.consecutiveTimesRooms));
  const [travelTimeRooms, setTravelTimeRooms] = React.useState<string>(t(solution.travelTimeRooms));

  const [unavailableTimesSubjects, setUnavailableTimesSubjects] = React.useState<string>(
    t(solution.unavailableTimesSubjects)
  );
  const [undesiredTimesSubjects, setUndesiredTimesSubjects] = React.useState<string>(
    t(solution.undesiredTimesSubjects)
  );
  const [assignTimes, setAssignTimes] = React.useState<string>(t(solution.assignTimes));
  const [assignResources, setAssignResources] = React.useState<string>(t(solution.assignResources));
  const [split, setSplit] = React.useState<string>(t(solution.split));
  const [daysBetweenLessons, setDaysBetweenLessons] = React.useState<string>(t(solution.daysBetweenLessons));
  const [predefinedTimes, setPredefinedTimes] = React.useState<string>(t(solution.predefinedTimes));
  const [simultaneousWith, setSimultaneousWith] = React.useState<string>(t(solution.simultaneousWith));
  const [notSimultaneousWith, setNotSimultaneousWith] = React.useState<string>(t(solution.notSimultaneousWith));
  const [occurBefore, setOccurBefore] = React.useState<string>(t(solution.occurBefore));

  const valuesClasses = [
    // clashesClasses,
    workloadClasses,
    workingDaysClasses,
    idleWindowClasses,
    dailyWorkloadClasses,
    distinctSubjectsClasses,
    forbiddenCombinationClasses,
    unavailableTimesClasses,
    undesiredTimesClasses,
    restBetweenDaysClasses,
    roomChangesDayClasses,
    consecutiveTimesClasses,
  ];
  const valuesTeachers = [
    // clashesTeachers,
    workloadTeachers,
    workingDaysTeachers,
    idleWindowTeachers,
    dailyWorkloadTeachers,
    distinctSubjectsTeachers,
    forbiddenCombinationTeachers,
    unavailableTimesTeachers,
    undesiredTimesTeachers,
    restBetweenDaysTeachers,
    roomChangesDayTeachers,
    consecutiveTimesTeachers,
  ];
  const valuesRooms = [
    // clashesRooms,
    workloadRooms,
    workingDaysRooms,
    idleWindowRooms,
    dailyWorkloadRooms,
    distinctSubjectsRooms,
    forbiddenCombinationRooms,
    unavailableTimesRooms,
    undesiredTimesRooms,
    consecutiveTimesRooms,
    travelTimeRooms,
  ];
  const valuesLessons = [
    // assignTimes,
    // assignResources,
    split,
    daysBetweenLessons,
    predefinedTimes,
    simultaneousWith,
    notSimultaneousWith,
    occurBefore,
    unavailableTimesSubjects,
    undesiredTimesSubjects,
  ];
  const settersClasses = [
    // setClashesClasses,
    setWorkloadClasses,
    setWorkingDaysClasses,
    setIdleWindowClasses,
    setDailyWorkloadClasses,
    setDistinctSubjectsClasses,
    setForbiddenCombinationClasses,
    setUnavailableTimesClasses,
    setUndesiredTimesClasses,
    setRestBetweenDaysClasses,
    setRoomChangesDayClasses,
    setConsecutiveTimesClasses,
  ];
  const settersTeachers = [
    // setClashesTeachers,
    setWorkloadTeachers,
    setWorkingDaysTeachers,
    setIdleWindowTeachers,
    setDailyWorkloadTeachers,
    setDistinctSubjectsTeachers,
    setForbiddenCombinationTeachers,
    setUnavailableTimesTeachers,
    setUndesiredTimesTeachers,
    setRestBetweenDaysTeachers,
    setRoomChangesDayTeachers,
    setConsecutiveTimesTeachers,
  ];
  const settersRooms = [
    // setClashesRooms,
    setWorkloadRooms,
    setWorkingDaysRooms,
    setIdleWindowRooms,
    setDailyWorkloadRooms,
    setDistinctSubjectsRooms,
    setForbiddenCombinationRooms,
    setUnavailableTimesRooms,
    setUndesiredTimesRooms,
    setConsecutiveTimesRooms,
    setTravelTimeRooms,
  ];
  const settersLessons = [
    // setAssignTimes,
    // setAssignResources,
    setSplit,
    setDaysBetweenLessons,
    setPredefinedTimes,
    setSimultaneousWith,
    setNotSimultaneousWith,
    setOccurBefore,
    setUnavailableTimesSubjects,
    setUndesiredTimesSubjects,
  ];

  let mapRunningTimeLabelsToSeconds: RunningTimeLabelsMap = {};
  mapRunningTimeLabelsToSeconds[t('1 minute')] = 60;
  mapRunningTimeLabelsToSeconds[t('5 minutes')] = 300;
  mapRunningTimeLabelsToSeconds[t('30 minutes')] = 1800;
  mapRunningTimeLabelsToSeconds[t('1 hour')] = 3600;
  mapRunningTimeLabelsToSeconds[t('2 hours')] = 7200;
  mapRunningTimeLabelsToSeconds[t('10 hours')] = 36000;

  let mapRunningTimeSecondsToLabels: RunningTimeSecondsMap = {};
  mapRunningTimeSecondsToLabels[60] = t('1 minute');
  mapRunningTimeSecondsToLabels[300] = t('5 minutes');
  mapRunningTimeSecondsToLabels[1800] = t('30 minutes');
  mapRunningTimeSecondsToLabels[3600] = t('1 hour');
  mapRunningTimeSecondsToLabels[7200] = t('2 hours');
  mapRunningTimeSecondsToLabels[36000] = t('10 hours');

  const [display, setDisplay] = React.useState<string>(t(solution.display));
  const [solver, setSolver] = React.useState<string>(t(solution.solver));
  const [runningTimeLabel, setRunningTimeLabel] = React.useState<string>(
    mapRunningTimeSecondsToLabels[solution.runningTime]
  );

  function getRunningTimeLabels() {
    if (userPlan === 'free') {
      return [
        t('1 minute'),
        t('5 minutes') + ' ' + t('(Upgrade plan)'),
        t('30 minutes') + ' ' + t('(Upgrade plan)'),
        t('1 hour') + ' ' + t('(Upgrade plan)'),
        t('2 hours') + ' ' + t('(Upgrade plan)'),
        t('10 hours') + ' ' + t('(Upgrade plan)'),
      ];
    } else if (userPlan === 'basic') {
      return [
        t('1 minute'),
        t('5 minutes'),
        t('30 minutes'),
        t('1 hour') + ' ' + t('(Upgrade plan)'),
        t('2 hours') + ' ' + t('(Upgrade plan)'),
        t('10 hours') + ' ' + t('(Upgrade plan)'),
      ];
    } else {
      return [t('1 minute'), t('5 minutes'), t('30 minutes'), t('1 hour'), t('2 hours'), t('10 hours')];
    }
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <DialogContentText>{t('Overall')}</DialogContentText>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} paddingTop={1} alignItems="flex-start">
                <Grid item xs={12} md={4}>
                  {/* VISUALIZATION */}
                  <BasicSelect
                    current={display}
                    setCurrent={setDisplay}
                    options={[t('Compact'), t('Individual tables')]}
                    label={t('Timetable Display')}
                  ></BasicSelect>
                </Grid>
                <Grid item xs={12} md={4}>
                  {/* ALGORITHM */}
                  <BasicSelect
                    current={solver}
                    setCurrent={setSolver}
                    options={[t('Optimal'), t('Fast')]}
                    label={t('Algorithm')}
                  ></BasicSelect>
                </Grid>
                <Grid item xs={12} md={4}>
                  {/* RUNNING TIME */}
                  <RuntimeSelect
                    current={runningTimeLabel}
                    setCurrent={setRunningTimeLabel}
                    options={getRunningTimeLabels()}
                    label={t('Max Running Time')}
                  ></RuntimeSelect>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <DialogContentText>{t('Priority')}</DialogContentText>
            </AccordionSummary>
            <AccordionDetails>
              <DialogContentText paddingTop={1}>{message}</DialogContentText>
              <Grid container spacing={2} paddingTop={1} alignItems="flex-start">
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{file?.studentsSetting !== 0 ? t('Classes') : t('Students')}</Typography>
                    {constraintTypesClasses.map((type, index) => {
                      return (
                        <Grid item xs={12} key={index} paddingTop={1}>
                          <PrioritySelect
                            current={valuesClasses[index]}
                            setCurrent={settersClasses[index]}
                            options={priorityOptions}
                            backgroundColors={backgroundColors}
                            colors={textColors}
                            label={type}
                          ></PrioritySelect>
                        </Grid>
                      );
                    })}
                  </Paper>
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{t('Teachers')}</Typography>
                    {constraintTypesTeachers.map((type, index) => {
                      return (
                        <Grid item xs={12} key={index} paddingTop={1}>
                          <PrioritySelect
                            current={valuesTeachers[index]}
                            setCurrent={settersTeachers[index]}
                            options={priorityOptions}
                            backgroundColors={backgroundColors}
                            colors={textColors}
                            label={type}
                          ></PrioritySelect>
                        </Grid>
                      );
                    })}
                  </Paper>
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{t('Rooms')}</Typography>
                    {constraintTypesRooms.map((type, index) => {
                      return (
                        <Grid item xs={12} key={index} paddingTop={1}>
                          <PrioritySelect
                            current={valuesRooms[index]}
                            setCurrent={settersRooms[index]}
                            options={priorityOptions}
                            backgroundColors={backgroundColors}
                            colors={textColors}
                            label={type}
                          ></PrioritySelect>
                        </Grid>
                      );
                    })}
                  </Paper>
                </Grid>
                <Grid container item xs={12} sm={6} lg={3} spacing={1}>
                  <Paper elevation={3} sx={{ display: 'inline-block', width: '100%', padding: 1 }}>
                    <Typography>{t('Lessons')}</Typography>
                    {constraintTypesLessons.map((type, index) => {
                      return (
                        <Grid item xs={12} key={index} paddingTop={1}>
                          <PrioritySelect
                            current={valuesLessons[index]}
                            setCurrent={settersLessons[index]}
                            options={priorityOptions}
                            backgroundColors={backgroundColors}
                            colors={textColors}
                            label={type}
                          ></PrioritySelect>
                        </Grid>
                      );
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleDefault}>
            {widthLessThan900 ? t('Default') : t('Restore Default')}
          </Button>
          <Button autoFocus onClick={handleClose}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleConfirm}>{t('Confirm')}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
