import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Box, Tab } from '@material-ui/core';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { SignInHome } from 'pages/SignIn/SignInHome';
import { SignUpHome } from 'pages/SignIn/SignUpHome';
import TabPanel from '@mui/lab/TabPanel';
import { useTranslation } from 'react-i18next';

function PaperComponent(props: PaperProps) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function AuthDialog(props: any) {
  const { t } = useTranslation();
  const open = props.open;
  const setOpen = props.setOpen;
  // const setConfirmed = props.setConfirmed;
  // const title = props.title;
  // const message = props.message;
  // const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label={t('Login')} value="1" />
                <Tab label={t('Register')} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <SignInHome></SignInHome>
            </TabPanel>
            <TabPanel value="2">
              <SignUpHome></SignUpHome>
            </TabPanel>
          </TabContext>
        </Box>

        {/* <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleConfirm}>Confirm</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
